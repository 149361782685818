/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestSuggestedGroups } from '../model/models';
import { ApiResponseSuggestedGroups } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface GroupV2SuggestionServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestSuggestedGroups 
     */
    groupV2SuggestionControllerGetSuggestedGroups(apiRequestSuggestedGroups: ApiRequestSuggestedGroups, extraHttpRequestParams?: any): Observable<ApiResponseSuggestedGroups>;

    /**
     * 
     * 
     * @param apiRequestSuggestedGroups 
     */
    groupV2SuggestionControllerGetSuggestedGroups_1(apiRequestSuggestedGroups: ApiRequestSuggestedGroups, extraHttpRequestParams?: any): Observable<ApiResponseSuggestedGroups>;

    /**
     * 
     * 
     * @param apiRequestSuggestedGroups 
     */
    groupV2SuggestionControllerGetSuggestedGroups_2(apiRequestSuggestedGroups: ApiRequestSuggestedGroups, extraHttpRequestParams?: any): Observable<ApiResponseSuggestedGroups>;

    /**
     * 
     * 
     * @param apiRequestSuggestedGroups 
     */
    groupV2SuggestionControllerGetSuggestedGroups_3(apiRequestSuggestedGroups: ApiRequestSuggestedGroups, extraHttpRequestParams?: any): Observable<ApiResponseSuggestedGroups>;

}
