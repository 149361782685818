import { Dialog } from '@angular/cdk/dialog';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { Region } from '@core/enums/region.enum';
import { AuthenticationStatusService } from '@core/services/authentication-status/authentication-status.service';
import { DisplayNameHelperService } from '@core/services/display-name-helper';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { PartialDimensionsSet } from '@core/services/google-analytics/data-layer-custom-dimension.type';
import { DataLayerCustomDimensions } from '@core/services/google-analytics/data-layer-custom-dimensions.enum';
import { DataLayerCustomDimensionsService } from '@core/services/google-analytics/data-layer-custom-dimensions.service';
import { DataLayerEvent } from '@core/services/google-analytics/data-layer-event.enum';
import { IdentifiedUser } from '@core/services/google-analytics/interfaces/identified-user.interface';
import { RegionService } from '@core/services/region.service';
import { UserDataService } from '@core/services/user/user-data.service';
import { combineLatestWith, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly dataLayerService: DataLayerCustomDimensionsService,
    private readonly regionService: RegionService,
    private readonly authenticationStatusService: AuthenticationStatusService,
    private readonly userDataService: UserDataService,
    private readonly employerWrapperService: EmployerWrapperService,
    private readonly router: Router,
    private readonly dialog: Dialog,
    private readonly displayNameHelperService: DisplayNameHelperService
  ) {}

  public listenToEvents(): void {
    this.regionService.regionStream.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((region) => {
      this.setRegion(region);
    });
    this.authenticationStatusService.isLoggedInStream().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.removeIdentity();
      }
    });
    this.userDataService
      .userStream()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        combineLatestWith(this.employerWrapperService.getEmployerStream().pipe(startWith(null)))
      )
      .subscribe(([user, employer]) => {
        let workAddress = this.userDataService.getWorkAddress(user);

        workAddress ??= employer?.address ?? undefined;

        this.identify({
          username: user.username,
          email: user.email,
          firstName: this.displayNameHelperService.getFirstName(user.displayName),
          lastName: this.displayNameHelperService.getLastName(user.displayName),
          employerId: employer?.id,
          employerName: employer?.name,
          addressPostalCode: workAddress?.zipCode,
        });
      });

    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = window.location.href;

        this.trackEvent(DataLayerEvent.virtualPageView, { [DataLayerCustomDimensions.virtualPageUrl]: url });
      }
    });

    this.dialog.afterOpened.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((ref) => {
      this.router.navigate([], {
        queryParams: { dialog: ref.id },
        queryParamsHandling: 'merge',
      });
    });
  }

  private setRegion(region: Region): void {
    this.trackEvent(DataLayerEvent.regionSet, { region });
  }

  public identify(identifiedUser: IdentifiedUser): void {
    this.trackEvent(DataLayerEvent.userIdentified, {
      username: identifiedUser.username,
      email: identifiedUser.email,
      firstName: identifiedUser.firstName,
      lastName: identifiedUser.lastName,
      employerId: identifiedUser.employerId,
      employerName: identifiedUser.employerName,
      addressPostalCode: identifiedUser.addressPostalCode,
    });
  }

  public removeIdentity(): void {
    this.dataLayerService.removeIdentityConstantDimensions();
    this.trackEvent(DataLayerEvent.identityRemoved);
  }

  public pageView(url: string): void {
    this.trackEvent(DataLayerEvent.virtualPageView, { [DataLayerCustomDimensions.virtualPageUrl]: url });
  }

  public trackEvent(event: DataLayerEvent, params?: PartialDimensionsSet): void {
    this.dataLayerService.setDimensions({
      event,
      ...params,
    });
  }
}
