import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DeeplinkService } from '@core/services/deeplink.service';
import { QrCodeAuthWrapperService } from '@core/services/qr-code-auth-wrapper.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SecondsCounterDirective } from '@shared/directives/seconds-counter.directive';
import { InfoExplanationComponent } from '@shared/standalone/info-explanation/info-explanation.component';

import { PlatformService } from '@core/services/platform.service';
import { ToastService } from '@core/services/toast/toast.service';
import { DeepLinkAction } from '@shared/enums/deep-link-action.enum';
import { DialogId } from '@shared/enums/dialog-id.enum';
import { MaterialModule } from '@shared/modules/material/material.module';
import { DownloadQrCodeComponent } from '@shared/standalone/download-qr-code/download-qr-code.component';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'checkout-mobile-app-dialog',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    QRCodeModule,
    MaterialModule,
    InfoExplanationComponent,
    SecondsCounterDirective,
    DownloadQrCodeComponent,
  ],
  templateUrl: './checkout-mobile-app-dialog.component.html',
  styleUrl: './checkout-mobile-app-dialog.component.scss',
})
export class CheckoutMobileAppDialogComponent implements OnInit {
  public deepLink?: string;

  constructor(
    public readonly dialogRef: DialogRef<void>,
    private readonly qrCodeAuthWrapperService: QrCodeAuthWrapperService,
    private readonly deeplinkService: DeeplinkService,
    public readonly responsiveService: ResponsiveService,
    public readonly platformService: PlatformService,
    public readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.getCode();
  }

  public getCode(silentLoading = true): void {
    this.qrCodeAuthWrapperService.getCode(silentLoading, this.responsiveService.isMobileView()).subscribe((code) => {
      this.deepLink = this.deeplinkService.createAppDeeplink(DeepLinkAction.qrCodeAuth, code);
    });
  }

  public onContinueInApp(): void {
    if (!this.deepLink) {
      this.toastService.show(true, this.translateService.instant('mobileCheckoutDialog.continueInAppError'));
      this.getCode();

      return;
    }

    window.open(this.deepLink!, '_blank');
  }

  public static open(dialog: Dialog, showFullscreen: boolean): DialogRef<void, unknown> {
    const config = {
      id: DialogId.CHECKOUT_MOBILE_APP_DIALOG,
    };

    if (showFullscreen) {
      Object.assign(config, {
        height: '100vh',
        width: '100vw',
        maxHeight: '100vh',
        maxWidth: '100vw',
      });
    }

    return dialog.open<void>(CheckoutMobileAppDialogComponent, config);
  }
}
