<div class="flex flex-col h-full w-full">
  <free-trial-banner></free-trial-banner>
  <payment-pending-banner></payment-pending-banner>
  <subscription-expires-soon-banner></subscription-expires-soon-banner>
  <mat-sidenav-container
    [class.bg-light]="(layoutService.isFullScreenPage$ | async) === true"
    class="bg-gray-light flex-grow"
  >
    <mat-sidenav
      *ngIf="(layoutService.isFullScreenPage$ | async) === false"
      class="shadow-xl border-none w-sidebar pb-16 overflow-x-hidden"
      mode="side"
      [opened]="(layoutService.isSidebarHidden$ | async) !== true"
      fixedInViewport="false"
      [disableClose]="true"
    >
      <app-sidebar-content> </app-sidebar-content>
    </mat-sidenav>
    <mat-sidenav-content #sidenavContent class="p-12" style="scrollbar-gutter: stable">
      <div
        *ngIf="layoutService.isSidebarHidden$ | async"
        class="w-10 left-0 h-10 flex items-center justify-start fixed"
      >
        <button
          *ngIf="(layoutService.isFullScreenPage$ | async) === false"
          class="bg-light shadow w-8 h-8 rounded-r-full rounded-l-none flex items-center justify-center p-0"
          (click)="layoutService.toggleSidebar(false)"
        >
          <mat-icon class="text-dark">last_page</mat-icon>
        </button>
      </div>

      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
