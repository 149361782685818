import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { defaultRequestTimeoutInMs } from '../constants/timeout.constant';

export const TIMEOUT = new HttpContextToken(() => defaultRequestTimeoutInMs);

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(TIMEOUT) protected defaultTimeout: number) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = req.context.get(TIMEOUT) || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);

    return next.handle(req).pipe(timeout(timeoutValueNumeric));
  }
}
