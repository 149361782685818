<app-preview>
  <ng-container header>
    <div class="flex justify-between">
      <button (click)="navigateBack()" mat-button class="font-redHatTextRegular gap-x-1 w-min">
        <mat-icon class="transform scale-150">arrow_back</mat-icon>
        {{ 'general.back' | translate }}
      </button>

      <button
        [class.collapse]="!navigatedFromOnboarding"
        (click)="navigateToDashboard()"
        mat-button
        class="ml-auto font-redHatTextRegular gap-x-1 w-min whitespace-nowrap"
      >
        {{ 'appPreview.continueToDashboard' | translate }}
        <mat-icon iconPositionEnd class="transform scale-150">arrow_forward</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-container title>
    {{ 'appPreview.title' | translate }}
  </ng-container>

  <ng-container description>
    {{ 'appPreview.description' | translate }}
  </ng-container>
</app-preview>
