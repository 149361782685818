import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private hideSidebar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private showFullScreenPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * show fullscreen page: hide header and sidebar
   */
  public toggleFullscreenPage(hide: boolean): void {
    this.showFullScreenPage$.next(hide);
    this.toggleSidebar(hide);
  }

  /**
   * returns an Observable of the fullscreen page status
   */
  public get isFullScreenPage$(): Observable<boolean> {
    return this.showFullScreenPage$.asObservable();
  }

  /**
   * returns an Observable of the sidebar status
   */
  public get isSidebarHidden$(): Observable<boolean> {
    return this.hideSidebar$.asObservable();
  }

  /**
   * toggle sidebar
   */
  public toggleSidebar(hide: boolean): void {
    this.hideSidebar$.next(hide);
  }
}
