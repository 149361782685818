import { Component, HostBinding, HostListener, Input, TemplateRef } from '@angular/core';

/**
 * This component can be used to add a file upload area
 * to an existing component like a file list
 */
@Component({
  selector: 'upload-area-wrapper',
  templateUrl: './upload-area-wrapper.component.html',
  styleUrls: ['./upload-area-wrapper.component.scss'],
})
export class UploadAreaWrapperComponent {
  @HostBinding('class') public class = 'container';

  @HostBinding('class.fileover') public fileOver!: boolean;

  @Input() public template!: TemplateRef<any>;

  // Dragover listener
  @HostListener('dragover', ['$event']) public onDragOver(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(): void {
    this.fileOver = false;
  }
}
