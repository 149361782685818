import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'google-places-input',
  templateUrl: './google-places-input.component.html',
  styleUrls: ['./google-places-input.component.scss'],
})
export class GooglePlacesInputComponent implements AfterViewInit, OnDestroy {
  @ViewChild('addressText', { static: false }) public addressText!: ElementRef<any>;

  @Input() public defaultValue?: string = '';

  @Input() public autofocus = false;

  @Input() public autofocusDelay = 0;

  @Input() public appearance: MatFormFieldAppearance = 'fill';

  @Input() public showPrefixSearchIcon = true;

  @Input() public tooltipContent = '';

  @Input() public isInvalid = false;

  @Input() public isDisabled = false;

  @Input() public placeholder?: string;

  @Input() public clearValueAfterSelection = true;

  @Output() public placeSelected: EventEmitter<google.maps.places.PlaceResult | null> = new EventEmitter();

  private autocompleteListener!: google.maps.MapsEventListener;

  private autocomplete!: google.maps.places.Autocomplete;

  constructor(private readonly zone: NgZone) {}

  public ngAfterViewInit(): void {
    this.initPlaceAutocomplete();
  }

  public ngOnDestroy(): void {
    google.maps.event.removeListener(this.autocompleteListener);
    google.maps.event.clearInstanceListeners(this.autocomplete);
  }

  private initPlaceAutocomplete(): void {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement);

    this.autocompleteListener = google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();

      this.zone.run(() => {
        if ('place_id' in place === false) {
          return;
        }

        if (this.clearValueAfterSelection) {
          this.addressText.nativeElement.value = '';
        }

        this.placeSelected.next(place);
      });
    });
  }

  public clearInput(): void {
    this.addressText.nativeElement.value = '';
    this.placeSelected.next(null);
  }
}
