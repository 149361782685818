export const pageRoutes = {
  ADMIN: {
    path: 'admin',
    children: {
      EMPLOYERS: {
        path: 'employers',
        children: {
          EMPLOYER_LIST: {
            path: 'list',
            title: 'Employer List',
          },
          EMPLOYER_NETWORKS: {
            path: 'networks',
            title: 'Employer Networks',
          },
        },
      },
      ALL_USER_LIST: {
        path: 'all-user-list',
        title: 'All User List',
      },
      TRIP_INSIGHT: {
        path: 'trip-insight',
        title: 'Trip Insights',
      },
      CARPOOL_VALIDATION: {
        path: 'carpool-validation',
        title: 'Carpool Validation',
        children: {
          RESULT: {
            path: 'result',
            title: 'Carpool Validation Result',
          },
        },
      },
      TRANSIT_VALIDATION: {
        path: 'transit-validation',
        title: 'Transit Validation',
        children: {
          RESULT: {
            path: 'result',
            title: 'Transit Validation Result',
          },
        },
      },
      GLOBAL_REWARDS: {
        path: 'global-rewards',
        title: 'Global Rewards',
      },
    },
  },
  STATISTICS: {
    path: '',
    title: 'Home',
  },
  REWARDS: {
    path: 'rewards',
    children: {
      OVERVIEW: {
        path: 'overview',
        title: 'Available Rewards',
      },
      FUNDING: {
        path: 'funding',
        title: 'Reward Funding',
      },
    },
  },
  USERS: {
    path: 'users',
    children: {
      USER_LIST: { path: 'list', title: 'User List', children: { USER_DETAILS: { title: 'User Details' } } },
      JOIN_REQUESTS: { path: 'join-requests', title: 'Join Requests' },
      IMPORT_USERS: { path: 'import', title: 'Import Users' },
      DATA_EXPORT: {
        title: 'Data Export',
        path: 'data-export',
      },
    },
  },
  OTP: {
    path: 'otp/:code',
    title: 'OTP Code',
  },
  AUTH_EMAIL_VERIFICATION: {
    path: 'auth-email-verification',
    title: 'Auth Email Verification',
  },
  ONBOARDING: {
    path: 'onboarding',
    title: 'Onboarding',
  },
  SETTINGS: {
    path: 'settings',
    children: {
      BILLING: { path: 'billing', title: 'Billing & Plans' },
      API: { path: 'api', title: 'API Keys' },
      MY_PROFILE: { path: 'my-profile', title: 'My Profile' },
      MARKETING_ASSETS: {
        title: 'Marketing assets',
        path: 'marketing-assets',
      },
      APP_SETTINGS: { path: 'app-settings', title: 'App Settings' },
    },
  },
  SELECT_EXISTING_COMPANY: {
    title: 'Select existing company',
    path: 'select-existing-company',
  },
  MANAGER_APP_PREVIEW: {
    title: 'Manager app preview',
    path: 'manager-app-preview',
  },
  USER_APP_PREVIEW: {
    title: 'User app preview',
    path: 'user-app-missing',
  },
  CRITICAL_DATA_LOADING_FAILURE: {
    title: 'Data loading failure',
    path: 'critical-data-loading-failure',
  },
  404: {
    path: '404',
    title: 'Not Found',
  },
};
