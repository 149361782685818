/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildCarpoolValidationResult } from './childCarpoolValidationResult';
import { ChildTripWithCarRoute } from './childTripWithCarRoute';
import { ChildCarpoolSection } from './childCarpoolSection';


export interface ApiResponseCarpoolValidationResult { 
    id: string;
    commutesWithCarRoute: Array<ChildTripWithCarRoute>;
    carpoolSections: Array<Array<ChildCarpoolSection>>;
    probability: number;
    validationResults: Array<ChildCarpoolValidationResult>;
    isCarpool: boolean;
}

