/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SentianceWaypoint { 
    latitude: number;
    longitude: number;
    timestamp: string;
    road_type: string;
    /**
     * Speed is measured in km/h https://sentiance.gitbook.io/sentiance-graphql-v4-beta/epqJwt3Y33D3o7uj5KQ3/backend/data-reference/data-reference-v4#mapmatchedwaypoint
     */
    speed: number;
    distance: number;
    speed_limit: number;
}

