/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildEmployerCarpoolConfig } from './childEmployerCarpoolConfig';
import { ChildEmployerRecommendationConfig } from './childEmployerRecommendationConfig';
import { RecommendationMode } from './recommendationMode';


export interface ChildEmployerGroupsConfig { 
    recommendationMode: RecommendationMode;
    threshold: number;
    firstMatchingOccurredAt?: string | null;
    recommendationConfig: ChildEmployerRecommendationConfig;
    carpoolConfig: ChildEmployerCarpoolConfig;
}



