/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildAddressModel } from './childAddressModel';
import { ChildEmployerDetails } from './childEmployerDetails';


export interface ChildGroupUser { 
    username: string;
    employerId: string;
    employer: ChildEmployerDetails;
    displayName: string;
    origin?: ChildAddressModel;
    destination?: ChildAddressModel;
    hasShiftWorkLink: boolean;
    commuteDayPref: object;
}

