import { Injectable } from '@angular/core';
import { defaultLanguage, supportedLanguages } from '@core/constants/languages.constant';
import { isLocalhost } from '@core/helpers/host-name-check.helper';
import { IntercomService } from '@core/services/intercom.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageKey } from '@shared/enums/local-storage-key.enum';
import { LocalStorageService } from './local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardLanguageService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly localStorageService: LocalStorageService,
    private readonly intercomService: IntercomService
  ) {}

  public init(): void {
    this.translateService.addLangs(supportedLanguages);
    this.translateService.setDefaultLang(defaultLanguage);
    const preferredLanguageCode = this.getPreferredDashboardLanguage();

    this.translateService.use(preferredLanguageCode);
    this.setHtmlLang(preferredLanguageCode);
  }

  public getPreferredDashboardLanguage(): string {
    const localPreferredLanguage = this.localStorageService.getItem(LocalStorageKey.preferredDashboardLanguage);

    if (localPreferredLanguage) {
      return localPreferredLanguage;
    }

    const browserLang = this.translateService.getBrowserLang();

    if (browserLang && supportedLanguages.includes(browserLang)) {
      return browserLang;
    }

    return defaultLanguage;
  }

  private setPreferredDashboardLanguage(languageCode: string): void {
    this.localStorageService.setItem(LocalStorageKey.preferredDashboardLanguage, languageCode);
  }

  public getCurrentDashboardLanguage(): string {
    return this.translateService.currentLang;
  }

  public changeLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
    this.setPreferredDashboardLanguage(languageCode);
    this.setHtmlLang(languageCode);
    this.intercomService.update({ language_override: languageCode });
    this.checkReload();
  }

  private setHtmlLang(languageCode: string): void {
    document.documentElement.lang = languageCode;
  }

  /**
   * Check for potential reload to make sure language change is reflected in CookieScript Popup
   */
  private checkReload(): void {
    const cookieScript = (window as any)?.CookieScript?.instance;
    const cookieState = cookieScript?.currentState();

    if (cookieState?.action === undefined && !isLocalhost) {
      window.location.reload();
    }
  }
}
