/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestAddUserByEmailModel } from '../model/models';
import { ApiRequestConnectToEmployerModel } from '../model/models';
import { ApiRequestConnectUserModel } from '../model/models';
import { ApiResponseSuccessModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface EmployerConnectServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestAddUserByEmailModel 
     */
    employerConnectControllerAddUserWithEmail(apiRequestAddUserByEmailModel: ApiRequestAddUserByEmailModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestAddUserByEmailModel 
     */
    employerConnectControllerAddUserWithEmail_1(apiRequestAddUserByEmailModel: ApiRequestAddUserByEmailModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestConnectToEmployerModel 
     */
    employerConnectControllerConnectToPublic(apiRequestConnectToEmployerModel: ApiRequestConnectToEmployerModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestConnectToEmployerModel 
     */
    employerConnectControllerConnectToPublic_2(apiRequestConnectToEmployerModel: ApiRequestConnectToEmployerModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestConnectUserModel 
     */
    employerConnectControllerConnectUserRequest(apiRequestConnectUserModel: ApiRequestConnectUserModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestConnectUserModel 
     */
    employerConnectControllerConnectUserRequest_3(apiRequestConnectUserModel: ApiRequestConnectUserModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     */
    employerConnectControllerConnectedUserStatus(extraHttpRequestParams?: any): Observable<object>;

    /**
     * 
     * 
     */
    employerConnectControllerConnectedUserStatus_4(extraHttpRequestParams?: any): Observable<object>;

    /**
     * 
     * 
     */
    employerConnectControllerDisconnectMe(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     */
    employerConnectControllerDisconnectMe_5(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param employerId 
     */
    employerConnectControllerJoinCompanyViaDashboard(employerId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param employerId 
     */
    employerConnectControllerJoinCompanyViaDashboard_6(employerId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestConnectToEmployerModel 
     */
    employerConnectControllerSelfConnect(apiRequestConnectToEmployerModel: ApiRequestConnectToEmployerModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestConnectToEmployerModel 
     */
    employerConnectControllerSelfConnect_7(apiRequestConnectToEmployerModel: ApiRequestConnectToEmployerModel, extraHttpRequestParams?: any): Observable<{}>;

}
