import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

/**
 * A custom route reuse strategy that determines when to reuse a route and when to reload it.
 * It caches and reuses routes based on the route's configuration.
 */

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private static cache: { [key: string]: DetachedRouteHandle } = {};

  private static clearCacheScheduled = false;

  /**
   * Determines if a route should be detached and stored for later reuse.
   * (runs when navigating away from a route)
   *
   * @param {ActivatedRouteSnapshot} route The current route snapshot.
   * @returns {boolean} True if the route should be stored, false otherwise.
   */
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const shouldDetach = route?.routeConfig?.data && route.routeConfig.data.cacheRoute;

    // if cache clear is scheduled clear cache
    if (shouldDetach && CustomRouteReuseStrategy.clearCacheScheduled) {
      CustomRouteReuseStrategy.clearCache();

      return false;
    }

    return shouldDetach;
  }

  /**
   * Stores the given detached route.
   * (runs when shouldDetach returns true)
   *
   * @param {ActivatedRouteSnapshot} route The route snapshot to be stored.
   * @param {DetachedRouteHandle} handler The handle for the detached route.
   */
  public store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
    if (handler) {
      const path = this.getPath(route);

      if (path) {
        CustomRouteReuseStrategy.cache[path] = handler;
      }
    }
  }

  /**
   * Determines if a stored route should be reattached.
   * (runs when navigating to a route)
   *
   * @param {ActivatedRouteSnapshot} route The current route snapshot.
   * @returns {boolean} True if a stored route exists and should be reattached, false otherwise.
   */
  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);

    if (!path) {
      return false;
    }

    return CustomRouteReuseStrategy.cache[path] ? true : false;
  }

  /**
   * Retrieves a stored route, if it exists.
   * (runs when shouldAttach returns true)
   *
   * @param {ActivatedRouteSnapshot} route The route snapshot for which to retrieve a stored route.
   * @returns {DetachedRouteHandle | null} The stored route or null if none exists.
   */

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return null;
    }

    const path = this.getPath(route);

    if (!path) {
      return null;
    }

    const handle: DetachedRouteHandle = CustomRouteReuseStrategy.cache[path];

    if (handle && (handle as any).componentRef && (handle as any).componentRef.destroyed) {
      return null;
    }

    return handle;
  }

  /**
   * Determines if the future route should be reused.
   *
   * @param {ActivatedRouteSnapshot} future The future route snapshot.
   * @param {ActivatedRouteSnapshot} current The current route snapshot.
   * @returns {boolean} True if the future route should be reused, false otherwise.
   */
  public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    const futurePath = this.getPath(future);
    const currentPath = this.getPath(current);

    if (futurePath && CustomRouteReuseStrategy.cache[futurePath]) {
      return futurePath === currentPath;
    }

    return future.routeConfig === current.routeConfig;
  }

  /**
   * Gets the path of a route snapshot, if it exists.
   * A path is prefixed by the component name to enable caching of page routes without route suffixes (e.g. "/" route)
   *
   * @param {ActivatedRouteSnapshot} route The route snapshot.
   * @returns {string | undefined} The path of the route or undefined if not applicable.
   */
  private getPath(route: ActivatedRouteSnapshot): string | undefined {
    const { routeConfig } = route;

    if (routeConfig && routeConfig.data && routeConfig.data.cacheRoute) {
      const componentName = routeConfig.component?.name;

      if (componentName) {
        const path = route.pathFromRoot
          .map((r) => r.url.map((segment) => segment.toString()).join(''))
          .filter((i) => i)
          .join('/');

        return `${componentName}-${path ? path : '/'}`;
      }
    }

    return undefined;
  }

  /**
   * Schedules a clear cache for the next navigation event
   */
  public static scheduleClearCache(): void {
    this.clearCacheScheduled = true;
  }

  /**
   * Clears the cache of stored routes, destroying any cached components.
   */
  private static clearCache(): void {
    Object.keys(this.cache).forEach((key) => {
      const handle: DetachedRouteHandle = this.cache[key];

      if (handle && (handle as any).componentRef && typeof (handle as any).componentRef.destroy === 'function') {
        (handle as any).componentRef.destroy();
      }
    });

    this.cache = {};
    this.clearCacheScheduled = false;
  }

  /**
   * Removes a specific route from the cache based on both component name and path.
   * @param componentName The name of the component associated with the route.
   * @param path The path of the route.
   */
  public static removeCachedRoute(componentName: string, path: string): void {
    const criterion = `${componentName}-${path}`;

    Object.keys(this.cache).forEach((key) => {
      if (key === criterion) {
        const handle: DetachedRouteHandle = this.cache[key];

        if (handle && (handle as any).componentRef && typeof (handle as any).componentRef.destroy === 'function') {
          (handle as any).componentRef.destroy();
        }

        delete this.cache[key];
      }
    });
  }
}
