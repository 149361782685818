/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestCreateStory } from '../model/models';
import { ApiResponseStory } from '../model/models';
import { ApiResponseUnseenAndLastPublishedStory } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface StoryServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestCreateStory 
     */
    storyControllerCreateStory(apiRequestCreateStory: ApiRequestCreateStory, extraHttpRequestParams?: any): Observable<ApiResponseStory>;

    /**
     * 
     * 
     * @param apiRequestCreateStory 
     */
    storyControllerCreateStory_1(apiRequestCreateStory: ApiRequestCreateStory, extraHttpRequestParams?: any): Observable<ApiResponseStory>;

    /**
     * 
     * 
     */
    storyControllerGetLatestAndUnseenStories(extraHttpRequestParams?: any): Observable<ApiResponseUnseenAndLastPublishedStory>;

    /**
     * 
     * 
     */
    storyControllerGetLatestAndUnseenStories_2(extraHttpRequestParams?: any): Observable<ApiResponseUnseenAndLastPublishedStory>;

    /**
     * 
     * 
     * @param storyId 
     */
    storyControllerMarkStorySeen(storyId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param storyId 
     */
    storyControllerMarkStorySeen_3(storyId: string, extraHttpRequestParams?: any): Observable<{}>;

}
