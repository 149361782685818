import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';

@Component({
  selector: 'domain-suggestion',
  standalone: true,
  imports: [TranslateModule, SafeHtmlPipe],
  templateUrl: './domain-suggestion.component.html',
  styleUrl: './domain-suggestion.component.scss',
})
export class DomainSuggestionComponent {
  @Input({ required: true }) public domainSuggestion!: string;

  @Input({ required: true }) public email!: string;

  @Output() public suggestionUse: EventEmitter<string> = new EventEmitter();

  public onDomainSuggestionClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (target.tagName.toLowerCase() !== 'strong') {
      return;
    }

    if (target.id === 'suggestion') {
      event.preventDefault();
      this.suggestionUse.emit(this.correctedEmail);
    }
  }

  public get correctedEmail(): string {
    return `${this.email.split('@')[0]}@${this.domainSuggestion}`;
  }
}
