import { DestroyRef, Directive, EventEmitter, HostListener, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, throttleTime } from 'rxjs';

@Directive({
  selector: '[preventDoubleClick]',
})
export class PreventDoubleClickDirective implements OnInit {
  @Input() public throttleTime = 500;

  @Output() public throttledClick = new EventEmitter<Event>();

  private readonly clicks$ = new Subject<Event>();

  private destroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this.clicks$
      .pipe(takeUntilDestroyed(this.destroyRef), throttleTime(this.throttleTime))
      .subscribe((event: Event) => this.throttledClick.emit(event));
  }

  @HostListener('click', ['$event'])
  public clickEvent(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicks$.next(event);
  }
}
