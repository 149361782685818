import {
  getTheAppWebsiteLink,
  marketingAssetsLink,
  openOnPhoneLink,
  userExportLink,
} from '@core/constants/links.constant';

export class Redirect {
  public static check(): void {
    const path = window.location.pathname.substring(1);

    if (path.includes('continue-on-phone')) {
      window.location.href = openOnPhoneLink;

      return;
    }

    if (path.includes('user-welcome')) {
      window.location.href = getTheAppWebsiteLink;
    }

    // previously the 'data-export' was at the main menu -> now submenu of user
    if (path === 'data-export') {
      window.location.href = userExportLink;
    }

    // previously the 'data-export' was at the main menu -> now submenu of settings
    if (path === 'marketing-assets') {
      window.location.href = marketingAssetsLink;
    }
  }
}
