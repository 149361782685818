import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { UploadAreaWrapperComponent } from './components/upload-area-wrapper/upload-area-wrapper.component';
import { UploadAreaComponent } from './components/upload-area/upload-area.component';
import { UploadButtonComponent } from './components/upload-button/upload-button.component';
import { UploadAreaWrapperDirective } from './directives/upload-area-wrapper.directive';

@NgModule({
  declarations: [UploadAreaWrapperDirective, UploadAreaWrapperComponent, UploadAreaComponent, UploadButtonComponent],
  exports: [UploadAreaComponent, UploadButtonComponent, UploadAreaWrapperComponent, UploadAreaWrapperDirective],
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class FileUploadModule {}
