import { Injectable } from '@angular/core';
import { AdminService } from '@core/services/admin/admin.service';
import { AuthenticationTokenService } from '@core/services/authentication-token.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { SessionStorageKey } from '@shared/enums/session-storage-key.enum';
import { AuthTokens } from '@shared/interfaces/auth-tokens.interface';
import { jwtDecode } from 'jwt-decode';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { ApiResponseAuthRefreshModel } from '../../../api/model/apiResponseAuthRefreshModel';
import { JwtToken } from '../../../shared/interfaces/jwt-token.interface';
import { AuthenticationWrapperService } from '../authentication/authentication-wrapper.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationStatusService {
  private isLoggedIn$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public logoutReloadPath?: string;

  constructor(
    private readonly authenticationTokenService: AuthenticationTokenService,
    private readonly localStorageService: LocalStorageService,
    private readonly authenticationWrapperService: AuthenticationWrapperService,
    private readonly adminService: AdminService,
    private readonly utilsService: UtilsService
  ) {
    this.savePathBeforeAuthRedirect();
  }

  private savePathBeforeAuthRedirect(): void {
    if (window.location.pathname !== '/') {
      sessionStorage.setItem(
        SessionStorageKey.pathBeforeAuthRedirect,
        window.location.pathname + window.location.search
      );
    }
  }

  public isLoggedInStream(): Observable<boolean> {
    return this.isLoggedIn$.asObservable();
  }

  public setAsLoggedIn(): void {
    this.isLoggedIn$.next(true);
  }

  public onLogin(authTokens: AuthTokens): void {
    this.authenticationTokenService.setAllTokensAndUpdateLocalStorage(authTokens, this.adminService.isAdministrator());
  }

  public refresh(): Observable<ApiResponseAuthRefreshModel> {
    return this.authenticationWrapperService
      .fetchRefreshToken(this.authenticationTokenService.getRefreshToken(), this.getUsernameFromIdToken())
      .pipe(
        tap((data: ApiResponseAuthRefreshModel) => {
          this.authenticationTokenService.setAllTokensAndUpdateLocalStorage(
            {
              accessToken: data.accessToken,
              idToken: data.idToken,
              refreshToken: data.refreshToken,
            },
            this.adminService.isAdministrator()
          );
        })
      );
  }

  public onLogout(): void {
    this.isLoggedIn$.next(false);

    // do a full reload to make sure all singletons are cleared
    this.utilsService.customWait(0, () => {
      this.clearStorage();
      window.location.href = this.logoutReloadPath ?? '/';
    });
  }

  private clearStorage(): void {
    sessionStorage.clear();
    this.localStorageService.clear();
  }

  private getUsernameFromIdToken(): string {
    return this.decodeIdToken().sub;
  }

  public isAuthenticated(): boolean {
    if (
      this.authenticationTokenService.getAccessToken() &&
      this.authenticationTokenService.getRefreshToken() &&
      this.authenticationTokenService.getIdToken()
    ) {
      return true;
    }

    return false;
  }

  public isEmailVerified(): boolean {
    return this.decodeIdToken().email_verified;
  }

  public getEmailFromIdToken(): string {
    return this.decodeIdToken().email;
  }

  private decodeIdToken(): JwtToken {
    const idToken = this.authenticationTokenService.getIdToken();

    if (idToken === '') {
      throw new Error("Cannot decode IdToken because it's empty");
    }

    const decoded = jwtDecode(idToken) as JwtToken;

    return decoded;
  }
}
