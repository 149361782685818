import { Injectable } from '@angular/core';
import { SentryService } from '@core/services/sentry.service';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(private readonly sentryService: SentryService) {}

  public update(settings?: Intercom_.IntercomSettings | undefined): void {
    try {
      Intercom('update', settings);
    } catch (e) {
      this.sentryService.captureException(e);
    }
  }

  public show(): void {
    try {
      Intercom('show');
    } catch (e) {
      this.sentryService.captureException(e);
    }
  }

  public hide(): void {
    try {
      Intercom('hide');
    } catch (e) {
      this.sentryService.captureException(e);
    }
  }
}
