import { Component, Input } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { MaterialModule } from '@shared/modules/material/material.module';

@Component({
  standalone: true,
  imports: [MaterialModule],
  selector: 'back-navigation-title',
  templateUrl: './back-navigation-title.component.html',
  styleUrls: ['./back-navigation-title.component.scss'],
})
export class BackNavigationTitleComponent {
  @Input() public extras?: NavigationExtras;

  @Input() public fallbackRoute?: string[];

  constructor(private readonly navigationService: NavigationService) {}

  public navigateBack(): void {
    this.navigationService.navigateBack(this.extras, this.fallbackRoute);
  }
}
