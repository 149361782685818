<div class="content">
  <h1 class="content__heading flex items-center justify-center gap-x-1.5">
    {{ 'login.welcomeBackHeading' | translate }}
    <img src="assets/images/emojis/1f44b.svg" class="h-5" alt="wave emoji" />
  </h1>

  <div class="region-switch">
    <p>{{ 'signup.accountRegionHint' | translate }}&nbsp;</p>
    <region-switch></region-switch>
  </div>

  <form novalidate [formGroup]="form" class="form" id="login">
    <mat-form-field subscriptSizing="dynamic" class="w-full" appearance="outline">
      <input
        (ngModelChange)="onChange()"
        matInput
        type="email"
        formControlName="email"
        placeholder="{{ 'general.emailAddress' | translate }}"
        autofocus
        autocomplete="email"
        name="email"
      />

      <mat-spinner
        *ngIf="form.controls.email.value && form.pending"
        matSuffix
        diameter="18"
        style="display: inline-flex; position: relative; top: -3px"
      ></mat-spinner>

      <button type="button" (click)="onClearEmail()" *ngIf="form.controls.email.value" mat-icon-button matSuffix>
        <mat-icon>{{ 'close' }}</mat-icon>
      </button>

      <mat-error *ngIf="form.controls.email.invalid">
        <span *ngIf="form.controls.email.hasError('required')">{{
          'formValidation.requiredError' | translate: { field: 'general.email' | translate }
        }}</span>
        <span *ngIf="form.controls.email.hasError('email')">{{ 'formValidation.emailError' | translate }}&nbsp;</span>

        <domain-suggestion
          *ngIf="form.controls.email.hasError('domainSuggestion')"
          [domainSuggestion]="form.controls.email.errors!.domainSuggestion"
          [email]="form.controls.email.value!"
          (suggestionUse)="onSuggestionUse($event)"
        ></domain-suggestion>
      </mat-error>

      <mat-hint class="text-gray-dark" *ngIf="form.controls.email.valid && emailDomainSuggestionWarning">
        <domain-suggestion
          [domainSuggestion]="emailDomainSuggestionWarning"
          [email]="form.controls.email.value!"
          (suggestionUse)="onSuggestionUse($event)"
        ></domain-suggestion>
      </mat-hint>
    </mat-form-field>

    <div>
      <button (click)="onSubmit()" class="form__submit mt-4" type="submit" mat-flat-button color="primary">
        {{ 'login.logInNow' | translate }}
      </button>
    </div>
  </form>

  <social-options class="w-full" [isSignup]="false"></social-options>
</div>
