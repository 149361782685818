/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum GroupSearchTag {
    MATCHES_SCHEDULE = 'MATCHES_SCHEDULE',
    DOES_NOT_MATCH_SCHEDULE = 'DOES_NOT_MATCH_SCHEDULE',
    CLOSE_TO_YOU = 'CLOSE_TO_YOU',
    DETOUR_TOO_BIG = 'DETOUR_TOO_BIG',
    SMART_ROUTING = 'SMART_ROUTING'
}

