/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PublicGroupsConfig } from './publicGroupsConfig';


export interface PublicConfig { 
    phoneNumber?: string;
    email: string;
    logoFileName: string | null;
    possibleCommuteModes: Array<PublicConfigPossibleCommuteModesEnum>;
    groupsConfig: PublicGroupsConfig;
}
export enum PublicConfigPossibleCommuteModesEnum {
    car = 'car',
    bike = 'bike',
    walk = 'walk',
    scooter = 'scooter',
    transit = 'transit',
    carpool = 'carpool',
    internalShuttle = 'internalShuttle'
};



