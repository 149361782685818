import { DataLayerCustomDimensions } from '@core/services/google-analytics/data-layer-custom-dimensions.enum';

export const dataLayerConstantDimensions = [
  DataLayerCustomDimensions.employerId,
  DataLayerCustomDimensions.employerName,
  DataLayerCustomDimensions.region,
  DataLayerCustomDimensions.username,
  DataLayerCustomDimensions.email,
  DataLayerCustomDimensions.firstName,
  DataLayerCustomDimensions.lastName,
  DataLayerCustomDimensions.addressPostalCode,
];
