import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DateHelperService } from './date-helper/date-helper.service';

@Injectable({
  providedIn: 'root',
})
export class LastDashboardOpenService {
  private lastOpened: Date = new Date();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly dateHelperService: DateHelperService
  ) {}

  public init(): void {
    this.document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.onStateVisible();
      }
    });

    this.document.addEventListener('focus', () => {
      if (document.visibilityState === 'visible') {
        this.onStateVisible();
      }
    });
  }

  private onStateVisible(): void {
    const reloadDashboard = this.dateHelperService.isOnSameDay(this.lastOpened, new Date()) === false;

    if (reloadDashboard) {
      window.location.reload();
    }
  }
}
