import { Injectable } from '@angular/core';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import freeEmailDomains from 'free-email-domains';
import { catchError, Observable, of } from 'rxjs';
import {
  ApiRequestAllUsersListPagination,
  ApiRequestUsersListByEmployerPagination,
  ApiResponseUsersListPagination,
  ApiResponseUserStatistics,
  ChildEmployerWorkPlaceModel,
  UserService,
} from '../../../api';
import { SentryService } from '../sentry.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class UserWrapperService {
  constructor(
    private readonly userService: UserService,
    private readonly toastService: ToastService,
    private readonly sentryService: SentryService,
    private readonly employerWrapperService: EmployerWrapperService
  ) {}

  public getAllUsersByEmployerWithPagination(
    employerId: string,
    page: number,
    elementsPerBlock: number,
    searchText?: string,
    sortField?: string,
    sortAscending?: boolean
  ): Observable<ApiResponseUsersListPagination> {
    const body: ApiRequestUsersListByEmployerPagination = {
      employerId,
      page,
      elementsPerBlock,
      searchText,
      sortAscending,
      sortField,
    };

    return this.userService.userControllerGetUsersByEmployerWithPagination(body).pipe(
      catchError((error) => {
        this.toastService.show(true, 'user.loadingAllUsersByEmployerError');
        throw error;
      })
    );
  }

  public getAllUsersWithPagination(
    page: number,
    elementsPerBlock: number,
    searchText: string,
    sortField?: string,
    sortAscending?: boolean
  ): Observable<ApiResponseUsersListPagination> {
    const body: ApiRequestAllUsersListPagination = {
      page,
      elementsPerBlock,
      searchText,
      sortField,
      sortAscending,
    };

    return this.userService.userControllerGetAllUsersWithPagination(body).pipe(
      catchError((error) => {
        this.toastService.show(true, 'user.loadingAllUsersByEmployerError');
        throw error;
      })
    );
  }

  public getUserStatisticsByUsername(username: string): Observable<ApiResponseUserStatistics> {
    return this.userService
      .userControllerGetUserByUsernameWithStatistics(username, this.employerWrapperService.employerId)
      .pipe(
        catchError((error) => {
          this.toastService.show(true, 'general.tryAgainAndContactSupportErrorMsg');
          this.sentryService.captureException(error);
          throw error;
        })
      );
  }

  public detectedAutomaticEmailDomain(emailDomain: string): Observable<boolean> {
    if (freeEmailDomains.includes(emailDomain)) {
      return of(false);
    }

    return this.userService.userControllerIsPrivateDomainAlreadyUsed(emailDomain);
  }

  public isEmailRegistered(email: string): Observable<boolean> {
    return this.userService.userControllerIsEmailRegistered(email);
  }

  public countUsersByWorkAddress(workplace: ChildEmployerWorkPlaceModel, employerId: string): Observable<number> {
    return this.userService.userControllerCountUsersByWorkAddress({ employerId, workplace }).pipe(
      catchError((error) => {
        this.toastService.show(true, 'general.errorMessage');
        throw error;
      })
    );
  }
}
