import { Dialog } from '@angular/cdk/dialog';
import { Directive, HostListener, Input } from '@angular/core';
import { AuthenticationStatusService } from '@core/services/authentication-status/authentication-status.service';
import { take } from 'rxjs';
import { AuthEmailVerificationBlockDialogComponent } from 'src/app/pre-login/auth-email-verification/components/auth-email-verification-block-dialog/auth-email-verification-block-dialog.component';

@Directive({
  selector: '[verifiedEmailRequired]',
  standalone: true,
})
export class VerifiedEmailRequiredDirective {
  @Input() public clickVerifiedEmailFunc!: Function;

  constructor(
    private readonly authStatusService: AuthenticationStatusService,
    private readonly dialog: Dialog
  ) {}

  @HostListener('click', ['$event'])
  public onOpenAuthEmailVerificationBlockDialog($event: Event): void {
    if (!this.authStatusService.isEmailVerified()) {
      // doing a refresh call again to be sure about the latest state of the email_verified field
      this.authStatusService
        .refresh()
        .pipe(take(1))
        .subscribe(() => {
          if (!this.authStatusService.isEmailVerified()) {
            $event.preventDefault();
            AuthEmailVerificationBlockDialogComponent.open(this.dialog, this.authStatusService.getEmailFromIdToken());

            return;
          }

          this.clickVerifiedEmailFunc();
        });
    } else {
      this.clickVerifiedEmailFunc();
    }
  }
}
