import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LastNameForm } from '../../interfaces/last-name-form.interface';

@Component({
  selector: 'last-name-form',
  templateUrl: './last-name-form.component.html',
  styleUrls: ['./last-name-form.component.scss'],
})
export class LastNameFormComponent {
  @Input({ required: true }) public form!: FormGroup<LastNameForm>;

  @Input({ required: true }) public firstName!: string;

  @Input({ required: true }) public tabAnimationDuration!: number;
}
