/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildOptionModelDistance } from './childOptionModelDistance';
import { TripModeEnum } from './tripModeEnum';


export interface ChildOptionModel { 
    mode: TripModeEnum;
    /**
     * Currently only set for environmental option
     */
    value?: number;
    /**
     * Currently only set for environmental option
     */
    unit?: string;
    distance: ChildOptionModelDistance;
    minutes: number;
}



