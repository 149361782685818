<div class="cdk-dialog">
  <div class="cdk-dialog-header">
    <div class="cdk-dialog-header__wrap">
      <h1 class="cdk-dialog-header__title">
        {{ 'user.updateCoinsDialogTitle' | translate: { user: user.displayName } }}
      </h1>
    </div>
  </div>

  <div class="cdk-dialog-body">
    <form [formGroup]="formGroup" class="cdk-dialog-body__two-col-input-form" id="update-coins-form">
      <!-- point type -->
      <div class="custom-input">
        <label class="custom-input__label">{{ 'incentiveNetworks.pointType' | translate }}*</label>
        <point-type-selector [formGroup]="formGroup" [controlName]="'pointType'"></point-type-selector>
      </div>

      <!-- amount -->
      <div class="custom-input">
        <label class="custom-input__label">
          <span>{{ 'general.coinsAmount' | translate }}*</span>
        </label>

        <mat-form-field class="mt-0">
          <input matInput formControlName="amount" type="number" matInput />
          <button
            [style.visibility]="formGroup.controls.amount.value"
            (click)="this.formGroup.controls.amount.reset()"
            type="button"
            matSuffix
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div>
        <!-- description -->
        <div class="custom-input">
          <label class="custom-input__label">{{ 'incentiveNetworks.sendCoinsDescriptionForUser' | translate }} </label>
          <mat-form-field class="mt-0">
            <textarea
              [maxlength]="maxDescriptionCharacters"
              #descriptionInput
              formControlName="description"
              matInput
            ></textarea>
            <button (click)="this.formGroup.controls.description.reset()" matSuffix mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
            <mat-hint class="custom-input__hint--grey-dark" align="end">
              {{ descriptionInput.value.length }}/{{ maxDescriptionCharacters }}
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="cdk-dialog-actions">
    <button mat-flat-button (click)="onClose()">{{ 'general.cancel' | translate }}</button>
    <button
      color="primary"
      [disabled]="!this.formGroup.valid"
      mat-flat-button
      preventDoubleClick
      (throttledClick)="onSave()"
    >
      {{ 'general.save' | translate }}
    </button>
  </div>
</div>
