import { DialogModule } from '@angular/cdk/dialog';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CdkTableModule } from '@angular/cdk/table';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [DialogModule, CdkTableModule, CdkMenuModule, TextFieldModule],
  exports: [DialogModule, CdkTableModule, CdkMenuModule, TextFieldModule],
})
export class MaterialCdkModule {}
