/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestApproveLinkRequestModel } from '../model/models';
import { ApiRequestApproveLinkRequestsModel } from '../model/models';
import { ApiRequestDisconnectMultipleUsers } from '../model/models';
import { ApiRequestDisconnectUser } from '../model/models';
import { ApiRequestUpdateUserRole } from '../model/models';
import { ApiResponseSuccessModel } from '../model/models';
import { ChildConnectedUser } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface EmployerUserManagementServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestApproveLinkRequestModel 
     */
    employerUserManagementControllerApproveUserConnection(apiRequestApproveLinkRequestModel: ApiRequestApproveLinkRequestModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestApproveLinkRequestModel 
     */
    employerUserManagementControllerApproveUserConnection_1(apiRequestApproveLinkRequestModel: ApiRequestApproveLinkRequestModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestApproveLinkRequestsModel 
     */
    employerUserManagementControllerApproveUserConnections(apiRequestApproveLinkRequestsModel: ApiRequestApproveLinkRequestsModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestApproveLinkRequestsModel 
     */
    employerUserManagementControllerApproveUserConnections_2(apiRequestApproveLinkRequestsModel: ApiRequestApproveLinkRequestsModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestDisconnectUser 
     */
    employerUserManagementControllerDisconnectUser(apiRequestDisconnectUser: ApiRequestDisconnectUser, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestDisconnectUser 
     */
    employerUserManagementControllerDisconnectUser_3(apiRequestDisconnectUser: ApiRequestDisconnectUser, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestDisconnectMultipleUsers 
     */
    employerUserManagementControllerDisconnectUsers(apiRequestDisconnectMultipleUsers: ApiRequestDisconnectMultipleUsers, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestDisconnectMultipleUsers 
     */
    employerUserManagementControllerDisconnectUsers_4(apiRequestDisconnectMultipleUsers: ApiRequestDisconnectMultipleUsers, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param employerId 
     */
    employerUserManagementControllerGetPendingJoinRequests(employerId: string, extraHttpRequestParams?: any): Observable<Array<ChildConnectedUser>>;

    /**
     * 
     * 
     * @param employerId 
     */
    employerUserManagementControllerGetPendingJoinRequests_5(employerId: string, extraHttpRequestParams?: any): Observable<Array<ChildConnectedUser>>;

    /**
     * 
     * 
     * @param apiRequestApproveLinkRequestModel 
     */
    employerUserManagementControllerRejectUserConnection(apiRequestApproveLinkRequestModel: ApiRequestApproveLinkRequestModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestApproveLinkRequestModel 
     */
    employerUserManagementControllerRejectUserConnection_6(apiRequestApproveLinkRequestModel: ApiRequestApproveLinkRequestModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestUpdateUserRole 
     */
    employerUserManagementControllerUpdateUserRole(apiRequestUpdateUserRole: ApiRequestUpdateUserRole, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param apiRequestUpdateUserRole 
     */
    employerUserManagementControllerUpdateUserRole_7(apiRequestUpdateUserRole: ApiRequestUpdateUserRole, extraHttpRequestParams?: any): Observable<boolean>;

}
