/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestUpdateBaseIncentiveNetwork } from '../model/models';
import { ApiResponseBaseIncentiveNetwork } from '../model/models';
import { ApiResponseRewardStylesWithPurchaseInfo } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface IncentiveNetworkServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param employerId 
     */
    incentiveNetworkControllerGetBaseIncentiveNetworksByEmployer(employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseBaseIncentiveNetwork>;

    /**
     * 
     * 
     * @param employerId 
     */
    incentiveNetworkControllerGetBaseIncentiveNetworksByEmployer_1(employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseBaseIncentiveNetwork>;

    /**
     * 
     * 
     */
    incentiveNetworkControllerGetForUser(extraHttpRequestParams?: any): Observable<ApiResponseRewardStylesWithPurchaseInfo>;

    /**
     * 
     * 
     */
    incentiveNetworkControllerGetForUser_2(extraHttpRequestParams?: any): Observable<ApiResponseRewardStylesWithPurchaseInfo>;

    /**
     * 
     * 
     * @param apiRequestUpdateBaseIncentiveNetwork 
     */
    incentiveNetworkControllerUpdateBaseIncentiveNetwork(apiRequestUpdateBaseIncentiveNetwork: ApiRequestUpdateBaseIncentiveNetwork, extraHttpRequestParams?: any): Observable<ApiResponseBaseIncentiveNetwork>;

    /**
     * 
     * 
     * @param apiRequestUpdateBaseIncentiveNetwork 
     */
    incentiveNetworkControllerUpdateBaseIncentiveNetwork_3(apiRequestUpdateBaseIncentiveNetwork: ApiRequestUpdateBaseIncentiveNetwork, extraHttpRequestParams?: any): Observable<ApiResponseBaseIncentiveNetwork>;

}
