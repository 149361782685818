import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { UserWrapperService } from '@core/services/user/user-wrapper.service';
import { Observable, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

export function emailExistsValidator(userService: UserWrapperService, currentEmail: string): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const email = control.value;
    const delayInMs = 300;
    const errors: ValidationErrors = {};

    // email hasn't changed yet
    const sameEmail = email === currentEmail;

    if (!email || email === '' || sameEmail) {
      errors.warnings = null;
      errors.emailExists = false;

      return of(null);
    }

    return of(email).pipe(
      delay(delayInMs),
      switchMap((value) =>
        userService.isEmailRegistered(value).pipe(
          map((isEmailRegistered: boolean) => {
            if (isEmailRegistered) {
              errors.emailExists = true;
            }

            return Object.keys(errors).length ? errors : null;
          })
        )
      )
    );
  };
}
