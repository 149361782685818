import { isLocalhost } from '@core/helpers/host-name-check.helper';
import { Environment } from '@shared/enums/environment.enum';
import { environment } from './environments/environment';

export class EnvBodyClass {
  public static init(): void {
    const bodyEnvClass = isLocalhost ? Environment.local : environment.name;
    const body = document.getElementsByTagName('body');

    body[0].classList.add(bodyEnvClass);
  }
}
