/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum Granularity {
    year = 'year',
    quarter = 'quarter',
    month = 'month',
    week = 'week',
    day = 'day',
    hour = 'hour',
    minute = 'minute',
    second = 'second',
    millisecond = 'millisecond'
}

