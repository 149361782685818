import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { UserDataService } from '@core/services/user/user-data.service';
import { ApiResponseUserModel } from '@swagger/index';
import { Observable, catchError, map, take } from 'rxjs';
import { AuthenticationStatusService } from '../services/authentication-status/authentication-status.service';

export const LoggedInGuard: CanMatchFn = () => {
  const authStatusService = inject(AuthenticationStatusService);
  const userDataService = inject(UserDataService);

  let runtimeUser: ApiResponseUserModel | null;

  userDataService
    .userStream()
    .pipe(take(1))
    .subscribe((user) => {
      runtimeUser = user;
    });

  const handleLoggedOutState = (): boolean => false;

  const handleLoggedInState = (): boolean => {
    authStatusService.setAsLoggedIn();

    return true;
  };

  const checkLoginStatus = (): Promise<boolean> | boolean | Observable<boolean> => {
    if (!authStatusService.isAuthenticated()) {
      return handleLoggedOutState();
    }

    if (runtimeUser) {
      return handleLoggedInState();
    }

    return userDataService.getMyUser().pipe(
      catchError((e) => {
        handleLoggedOutState();
        throw e;
      }),
      map(() => handleLoggedInState())
    );
  };

  return checkLoginStatus();
};
