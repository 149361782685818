import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { DialogId } from '@shared/enums/dialog-id.enum';
import { lastValueFrom, take } from 'rxjs';
import { UpdateCoinsDialogComponent } from 'src/app/post-login/users/components/update-coins-dialog/update-coins-dialog.component';
import { ApiResponseBaseIncentiveNetwork, ApiResponseUserWithLimitedDetails } from '../../../api';
import { IncentiveNetworksWrapperService } from '../incentive-network-wrapper/incentive-networks-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class RewardCoinsService {
  constructor(
    private readonly incentiveNetworkWrapperService: IncentiveNetworksWrapperService,
    private readonly dialog: Dialog
  ) {}

  public async openRewardCoinsDialog(user: ApiResponseUserWithLimitedDetails): Promise<DialogRef<number, unknown>> {
    const employerId = user.pendingJoinRequestAtEmployer ? user.pendingJoinRequestAtEmployer : user.connectedToEmployer;

    const baseNetwork = await this.loadIncentiveNetworks(employerId);

    const dialog = this.dialog.open<number>(UpdateCoinsDialogComponent, {
      data: {
        user,
        baseNetwork,
      },
      id: DialogId.UPDATE_COINS_DIALOG,
    });

    return dialog;
  }

  private async loadIncentiveNetworks(employerId: string): Promise<ApiResponseBaseIncentiveNetwork> {
    const network: ApiResponseBaseIncentiveNetwork = await lastValueFrom(
      this.incentiveNetworkWrapperService.getBaseIncentiveNetworksByEmployer(employerId).pipe(take(1))
    );

    return network;
  }
}
