import { defaultHybridMapPolygonOptions } from './default-polygon-option.constant';

export function defaultDrawingManagerOptions(
  polygonOptions: google.maps.PolygonOptions = defaultHybridMapPolygonOptions
): google.maps.drawing.DrawingManagerOptions {
  const options = {
    drawingControl: true,
    drawingControlOptions: {
      drawingModes: [google.maps.drawing.OverlayType.POLYGON],
    },
    polygonOptions: {
      draggable: true,
      editable: true,
      clickable: true,
      ...polygonOptions,
    },

    drawingMode: google.maps.drawing.OverlayType.POLYGON,
  };

  return options;
}
