import { Component, Input } from '@angular/core';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { AmplifyService } from '@core/services/amplify/amplify.service';

@Component({
  selector: 'social-options',
  templateUrl: './social-options.component.html',
  styleUrls: ['./social-options.component.scss'],
})
export class SocialOptionsComponent {
  @Input() public isSignup = false;

  constructor(private readonly amplifyService: AmplifyService) {}

  public async onSignInWithApple(): Promise<void> {
    this.amplifyService.socialSignIn(CognitoHostedUIIdentityProvider.Apple, this.isSignup ? 'signup-Apple' : 'login');
  }

  public async onSignInWithGoogle(): Promise<void> {
    this.amplifyService.socialSignIn(CognitoHostedUIIdentityProvider.Google, this.isSignup ? 'signup-Google' : 'login');
  }
}
