<section class="h-full overflow-y-auto p-12">
  <app-preview>
    <ng-container header>
      <div class="flex justify-end gap-x-4">
        <button (click)="onOpenHelpCenter()" mat-button>
          {{ 'permissionMissing.helpCenterLinking' | translate }}<mat-icon iconPositionEnd>open_in_new</mat-icon>
        </button>
        <button (click)="onLogout()" mat-button class="">
          {{ 'general.logout' | translate }}<mat-icon iconPositionEnd>logout</mat-icon>
        </button>
      </div>
    </ng-container>

    <ng-container title>
      {{ 'userAppPreview.title' | translate }}
    </ng-container>

    <ng-container description>
      {{ 'userAppPreview.description' | translate }}
    </ng-container>

    <ng-container cta>
      <div class="flex justify-start flex-col gap-y-1.5">
        <p>{{ 'userAppPreview.askForManagerAccess' | translate: { employer: employer().name } }}</p>

        <div>
          <button
            [disabled]="hasRequestedManagerAccess()"
            (click)="onRequestAccess()"
            mat-button
            color="primary"
            class="text-primary -ml-5"
          >
            <mat-icon [class.hidden]="!hasRequestedManagerAccess()" fontSet="material-icons-round">check</mat-icon>

            @if (hasRequestedManagerAccess()) {
              {{ 'requestedAccess.title' | translate }}
            } @else {
              {{ 'userAppPreview.requestManagerAccess' | translate }}
            }
          </button>
        </div>

        @if (hasRequestedManagerAccess()) {
          <p class="text-gray-dark">{{ 'userAppPreview.nextStepsExplained' | translate }}</p>
        }
      </div>
    </ng-container>
  </app-preview>
</section>
