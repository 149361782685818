/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildUserAchievements } from './childUserAchievements';
import { ChildLastTripDetails } from './childLastTripDetails';
import { ChildProfileStats } from './childProfileStats';
import { ChildCurrentChallengeDetails } from './childCurrentChallengeDetails';


export interface ApiResponseUserDetailsStats { 
    username: string;
    displayName: string;
    lastTripDetails?: ChildLastTripDetails;
    userAchievements: ChildUserAchievements;
    profileStats: ChildProfileStats;
    currentChallengeDetails: ChildCurrentChallengeDetails;
}

