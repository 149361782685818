<mat-toolbar *ngIf="remainingDaysInFreeTrial && remainingDaysInFreeTrial >= 0" color="accent" class="banner">
  <div class="banner__content">
    <span>{{ 'freeTrialBanner.welcomeToPave' | translate }}</span>
    <img src="assets/images/emojis/1f44b.svg" class="h-4" alt="wave emoji" />
    <span [innerHTML]="'freeTrialBanner.trialEndingNotice' | translate: { days: remainingDaysInFreeTrial }"></span>
  </div>
  <button verifiedEmailRequired [clickVerifiedEmailFunc]="onUpgradeNow.bind(this)" mat-flat-button class="banner__cta">
    {{ 'general.upgradeNow' | translate }}
  </button>
</mat-toolbar>
