/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Route } from './route';


export interface ApiResponseDailyRecommendation { 
    recommendedMode: ApiResponseDailyRecommendationRecommendedModeEnum;
    outwardRoute: Route | null;
    returnRoute: Route | null;
}
export enum ApiResponseDailyRecommendationRecommendedModeEnum {
    car = 'car',
    bike = 'bike',
    walk = 'walk',
    scooter = 'scooter',
    transit = 'transit',
    carpool = 'carpool',
    internalShuttle = 'internalShuttle'
};



