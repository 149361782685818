/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildAddressModel } from './childAddressModel';
import { TripModeEnum } from './tripModeEnum';


export interface ApiRequestUserSearchModel { 
    commuteModes?: Array<TripModeEnum>;
    searchText?: string;
    schedule?: object;
    destinations?: Array<ChildAddressModel>;
}

