import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService
  ) {}

  public show(
    isError: boolean,
    message: string,
    interpolateParams?: Object | undefined,
    timeOut: number = this.toastrService.toastrConfig.timeOut
  ): void {
    const options: Partial<IndividualConfig<any>> = {
      timeOut,
      enableHtml: true,
    };

    const translatedMessage = this.translateService.instant(message, interpolateParams);

    if (isError) {
      this.showErrorToast(translatedMessage, options);

      return;
    }

    this.showSuccessToast(translatedMessage, options);
  }

  private showErrorToast(message: string, options?: Partial<IndividualConfig<any>> | undefined): void {
    this.toastrService.error(message, undefined, options);
  }

  private showSuccessToast(message: string, options?: Partial<IndividualConfig<any>> | undefined): void {
    this.toastrService.success(message, undefined, options);
  }

  public showDefaultErrorToast(): void {
    this.show(true, 'general.errorMessage');
  }

  public showDefaultSuccessToast(): void {
    this.show(false, 'general.successMessage');
  }
}
