import { ElementRef, Injectable } from '@angular/core';
import { IncomingWebEvent } from '@core/services/pave-web-app-event/enums/incoming-web-event.enum';
import { UtilsService } from '@core/services/utils/utils.service';
import { DeepLinkAction } from '@shared/enums/deep-link-action.enum';
import { firstValueFrom, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaveWebAppEventService {
  constructor(private readonly utilsService: UtilsService) {}

  public init(): void {
    window.addEventListener('message', this.handleMessage.bind(this));
  }

  private iframe$: ReplaySubject<ElementRef<HTMLIFrameElement>> = new ReplaySubject(1);

  public registerIframe(iframe: ElementRef<HTMLIFrameElement>): void {
    this.iframe$.next(iframe);
  }

  private handleMessage(messageEvent: MessageEvent): void {
    try {
      const eventEnum = messageEvent.data.outgoing;

      if (typeof eventEnum !== 'string') {
        return;
      }

      const event = this.utilsService.convertStringToEnum(IncomingWebEvent, eventEnum);

      if (!event) {
        return;
      }

      // TODO: react to events
      // eslint-disable-next-line no-console
      console.log('Message received ', event);
    } catch (_) {
      // nothing left to do
    }
  }

  public async sendEvent(action: DeepLinkAction, value: string): Promise<void> {
    const iFrame = await firstValueFrom(this.iframe$);

    iFrame.nativeElement.contentWindow?.postMessage(
      {
        action,
        value,
      },
      '*'
    );
  }
}
