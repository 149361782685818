/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum PushNotificationTypes {
    testPush = 'testPush',
    incompleteProfileReminder = 'incompleteProfileReminder',
    userReceivedPoints = 'userReceivedPoints',
    employerApprovedJoinRequest = 'employerApprovedJoinRequest',
    userTrackedPossibleCommute = 'userTrackedPossibleCommute'
}

