import { Injectable } from '@angular/core';
import { bookADemoUrlEU, bookADemoUrlUS, helpCenterUrl, supportEmailAddress } from '@core/constants/contact.constants';
import { pageRoutes } from '@core/models/page-routes.model';
import { RegionService } from '@core/services/region.service';
import { PageNames } from '@shared/enums/page-names.enum';
import { SidebarMenuItem } from '@shared/interfaces/sidebar-menu-item.interface';
import { ApiResponseBaseIncentiveNetwork } from '@swagger/index';
import { ApiResponseEmployer } from '../../../api/model/apiResponseEmployer';

@Injectable({
  providedIn: 'root',
})
export class SidebarMenuService {
  constructor(private readonly regionService: RegionService) {}

  public getManagerMenuItems(
    employer: ApiResponseEmployer,
    isAdmin: boolean,
    pendingJoinRequests?: number,
    baseIncentiveNetwork?: ApiResponseBaseIncentiveNetwork
  ): SidebarMenuItem[] {
    const menuItems = [
      {
        title: PageNames.STATISTICS,
        icon: 'window',
        iconFontSet: 'material-icons-round',
        link: `/${pageRoutes.STATISTICS.path}`,
      },
      this.getRewardsMenuItems(baseIncentiveNetwork),
      {
        title: PageNames.USERS,
        icon: 'group',
        iconFontSet: 'material-icons-round',
        link: `/${pageRoutes.USERS.path}`,
        notificationBadge: !employer.isPublic ? pendingJoinRequests : undefined,
        children: [
          {
            title: PageNames.USER_LIST,
            icon: 'format_list_bulleted',
            iconFontSet: 'material-icons-round',
            link: `/${pageRoutes.USERS.path}/${pageRoutes.USERS.children.USER_LIST.path}`,
          },
          ...this.insertMenuItemsInArray(!employer.isPublic, [
            {
              title: PageNames.JOIN_REQUESTS,
              icon: 'person_add',
              iconFontSet: 'material-icons-round',
              link: `/${pageRoutes.USERS.path}/${pageRoutes.USERS.children.JOIN_REQUESTS.path}`,
              notificationBadge: pendingJoinRequests,
            },
          ]),
          {
            title: PageNames.DATA_EXPORT,
            icon: 'cloud_download',
            iconFontSet: 'material-icons-round',
            link: `/${pageRoutes.USERS.path}/${pageRoutes.USERS.children.DATA_EXPORT.path}`,
          },
        ],
      },
      {
        title: PageNames.SETTINGS,
        icon: 'settings',
        iconFontSet: 'material-icons-round',
        link: `/${pageRoutes.SETTINGS.path}`,
        children: [
          {
            title: PageNames.MY_PROFILE,
            icon: 'person',
            iconFontSet: 'material-icons-round',
            link: `/${pageRoutes.SETTINGS.path}/${pageRoutes.SETTINGS.children.MY_PROFILE.path}`,
          },
          {
            title: PageNames.APP_SETTINGS,
            icon: 'app_settings_alt',
            iconFontSet: 'material-icons-round',
            link: `/${pageRoutes.SETTINGS.path}/${pageRoutes.SETTINGS.children.APP_SETTINGS.path}`,
          },
          ...this.insertMenuItemsInArray(employer.subscription !== undefined && employer.customer !== undefined, [
            {
              title: PageNames.BILLING,
              icon: 'credit_card',
              iconFontSet: 'material-icons-round',
              link: `/${pageRoutes.SETTINGS.path}/${pageRoutes.SETTINGS.children.BILLING.path}`,
            },
          ]),
          ...this.insertMenuItemsInArray(isAdmin, [
            {
              title: PageNames.API,
              icon: 'api',
              iconFontSet: 'material-icons-round',
              link: `/${pageRoutes.SETTINGS.path}/${pageRoutes.SETTINGS.children.API.path}`,
            },
          ]),
          {
            title: PageNames.MARKETING_ASSETS,
            icon: 'rocket_launch',
            iconFontSet: 'material-icons-round',
            link: `/${pageRoutes.SETTINGS.path}/${pageRoutes.SETTINGS.children.MARKETING_ASSETS.path}`,
          },
        ],
      },
      {
        title: PageNames.HELP_AND_SUPPORT,
        icon: 'help_outline',
        iconFontSet: 'material-icons-round',
        children: [
          {
            title: PageNames.HELP_CENTER,
            icon: 'launch',
            iconFontSet: 'material-icons-round',
            url: `${helpCenterUrl}`,
            target: '_blank',
          },
          {
            title: PageNames.CONTACT_SUPPORT,
            icon: 'mail_outline',
            iconFontSet: 'material-icons-round',
            url: `mailto:${supportEmailAddress}`,
            target: '_blank',
          },
          {
            title: PageNames.SCHEDULE_A_CALL,
            icon: 'calendar_month',
            iconFontSet: 'material-icons-round',
            url: this.regionService.isRegionEU ? bookADemoUrlEU : bookADemoUrlUS,
            target: '_blank',
          },
        ],
      },
    ];

    return menuItems;
  }

  public getAdminMenuItems(): SidebarMenuItem[] {
    return [
      {
        title: PageNames.EMPLOYERS,
        icon: 'work_outline',
        iconFontSet: 'material-icons-round',
        link: `/${pageRoutes.ADMIN.path}/${pageRoutes.ADMIN.children.EMPLOYERS.path}`,
        children: [
          {
            title: PageNames.EMPLOYER_LIST,
            icon: 'list',
            iconFontSet: 'material-icons-round',
            link: `/${pageRoutes.ADMIN.path}/${pageRoutes.ADMIN.children.EMPLOYERS.path}/${pageRoutes.ADMIN.children.EMPLOYERS?.children.EMPLOYER_LIST.path}`,
          },
          {
            title: PageNames.EMPLOYER_NETWORKS,
            icon: 'groups',
            iconFontSet: 'material-icons-round',
            link: `/${pageRoutes.ADMIN.path}/${pageRoutes.ADMIN.children.EMPLOYERS.path}/${pageRoutes.ADMIN.children.EMPLOYERS?.children.EMPLOYER_NETWORKS.path}`,
          },
        ],
      },
      {
        title: PageNames.ALL_USER_LIST,
        icon: 'group',
        iconFontSet: 'material-icons-round',
        link: `/${pageRoutes.ADMIN.path}/${pageRoutes.USERS.path}/${pageRoutes.ADMIN.children.ALL_USER_LIST.path}`,
      },
      {
        title: PageNames.COMMUTE_INSIGHT,
        icon: 'commute',
        iconFontSet: 'material-icons-round',
        link: `/${pageRoutes.ADMIN.path}/${pageRoutes.ADMIN.children.TRIP_INSIGHT.path}`,
      },
      {
        title: PageNames.CARPOOL_VALIDATION,
        icon: 'directions_car',
        iconFontSet: 'material-icons-round',
        link: `/${pageRoutes.ADMIN.path}/${pageRoutes.ADMIN.children.CARPOOL_VALIDATION.path}`,
      },
      {
        title: PageNames.TRANSIT_VALIDATION,
        icon: 'directions_bus',
        iconFontSet: 'material-icons-round',
        link: `/${pageRoutes.ADMIN.path}/${pageRoutes.ADMIN.children.TRANSIT_VALIDATION.path}`,
      },
      {
        title: PageNames.GLOBAL_REWARDS,
        icon: 'store',
        iconFontSet: 'material-icons-round',
        link: `/${pageRoutes.ADMIN.path}/${pageRoutes.REWARDS.path}/${pageRoutes.ADMIN.children.GLOBAL_REWARDS.path}`,
      },
    ];
  }

  public setMenuSelectedState(menu: SidebarMenuItem[], currentUrl: string): SidebarMenuItem[] {
    const menuToUpdate = menu.map((item) => {
      const itemToUpdate = item;

      if (itemToUpdate.children) {
        // go recursively through all children first
        itemToUpdate.children = this.setMenuSelectedState(itemToUpdate.children, currentUrl);

        if (itemToUpdate.children.some((e) => e.selected)) {
          // if one of the children is selected than set parent as selected
          itemToUpdate.selected = true;

          return itemToUpdate;
        }

        // none of the children is selected
        itemToUpdate.selected = false;

        return itemToUpdate;
      }

      if (itemToUpdate.link === currentUrl) {
        // url is identical
        itemToUpdate.selected = true;

        return itemToUpdate;
      }

      if (itemToUpdate.link && itemToUpdate.link === currentUrl.substring(0, currentUrl.lastIndexOf('/'))) {
        // fallback when everything except for string after last backslash is equal
        // e.g. routes with /edit or /create
        itemToUpdate.selected = true;

        return itemToUpdate;
      }

      itemToUpdate.selected = false;

      return itemToUpdate;
    });

    return menuToUpdate;
  }

  private insertMenuItemsInArray(condition: boolean, menuItems: SidebarMenuItem[]): SidebarMenuItem[] {
    return condition ? menuItems : [];
  }

  private getRewardsMenuItems(baseIncentiveNetwork?: ApiResponseBaseIncentiveNetwork): SidebarMenuItem {
    const isTangoRewardEnabled = (baseIncentiveNetwork?.rewardStyles?.tangoRewardStyleConfigs?.length ?? 0) > 0;

    const rewardsPage: SidebarMenuItem = {
      title: PageNames.REWARDS,
      icon: 'card_giftcard',
      iconFontSet: 'material-icons-round',
      link: `/${pageRoutes.REWARDS.path}`,
    };
    const overviewPage = {
      title: PageNames.REWARDS_OVERVIEW,
      icon: 'view_agenda',
      iconFontSet: 'material-icons-outlined',
      link: `/${pageRoutes.REWARDS.path}/${pageRoutes.REWARDS.children.OVERVIEW.path}`,
    };
    const fundingPage = {
      title: PageNames.FUNDING,
      icon: 'account_box',
      iconFontSet: 'material-icons-round',
      link: `/${pageRoutes.REWARDS.path}/${pageRoutes.REWARDS.children.FUNDING.path}`,
    };

    if (isTangoRewardEnabled) {
      rewardsPage.children = [overviewPage, fundingPage];
    }

    return rewardsPage;
  }
}
