import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { delay, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  /**
   * create custom wait method without triggering the change detection
   *
   * @param time in ms
   * @param callback
   */
  public customWait(time: number, callback: (...payload: any[]) => void): void {
    // we create an observable from the array [1] and then we pipe and delay it
    // after its finished we call the callback function
    from([1])
      .pipe(first(), delay(time))
      .subscribe(() => {
        callback();
      });
  }

  public convertStringFromCamelToKebap(value: string): string {
    // e.g. internalShuttle --> internal-shuttle
    return value.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
  }

  /**
   * Converts a string to an enum of a given type.
   * Careful: check if enum keys are uppercase or lowercase
   * @param enumType
   * @param value
   * @returns
   */
  public convertStringToEnum<T>(enumType: T, value: string, enumKeyUppercase = true): T[keyof T] | undefined {
    const enumValue = (enumType as any)[(enumKeyUppercase ? value.toUpperCase() : value) as keyof typeof enumType];

    return enumValue;
  }

  /**
   * Converts an enum to an enum array
   * @param enumObj
   * @returns
   */
  public enumToArray<T extends Record<string, keyof T>>(enumObj: T): T[keyof T][] {
    return Object.values(enumObj) as T[keyof T][];
  }
}
