/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildTangoRewardStyleConfigWithPurchaseInfo } from './childTangoRewardStyleConfigWithPurchaseInfo';
import { ChildExternalRewardStyleConfigWithPurchaseInfo } from './childExternalRewardStyleConfigWithPurchaseInfo';
import { ChildRaffleRewardStyleConfigWithPurchaseInfo } from './childRaffleRewardStyleConfigWithPurchaseInfo';


export interface ApiResponseRewardStylesWithPurchaseInfo { 
    externalRewardStyleConfigs: Array<ChildExternalRewardStyleConfigWithPurchaseInfo>;
    raffleRewardStyleConfigs: Array<ChildRaffleRewardStyleConfigWithPurchaseInfo>;
    tangoRewardStyleConfigs: Array<ChildTangoRewardStyleConfigWithPurchaseInfo>;
}

