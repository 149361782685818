import { Environment } from '@shared/enums/environment.enum';
import { EnvConfig } from './interface/environment.interface';

export const environment: EnvConfig = {
  production: false,
  name: Environment.dev,
  apiUrl: 'https://pave.develop.rideamigos.com',
  loginUrl:
    'https://paveappdev.auth.us-east-1.amazoncognito.com/login' +
    '?client_id=7723vgq6i5ljg7pl1e82nfssfv' +
    '&response_type=code' +
    '&scope=phone+email+openid+aws.cognito.signin.user.admin+profile' +
    '&redirect_uri=',
  googleMapsApiKey: 'AIzaSyAHM_4C8AwEOdgRob7wtyvKT0LBLrXYUis',
  region: 'us',
  applicationId: 'pave-admin-dashboard',
  sentianceAppId: '60914cf273a0400700000327',
  appDeeplinkBaseUrl: 'https://devpavecommute.page.link',
  androidPackageName: 'com.rideamigos.pave_app.dev',
  iosPackageName: 'com.rideamigos.pave.app.dev',
  sentryDns: 'https://9428b2ad2b1f44bda66c7d6f85f3723c@o4506320800972800.ingest.sentry.io/4506395435925508',
  awsS3PublicBucket: 'rideamigos-pave-dev-public-assets',
  googleTagManagerAuth: 'tcxWkzlofS1umoHN-l9Sng',
  googleTagManagerPreview: 'env-18',
  appWebUrl: 'https://dev.web.pavecommute.app',
  intercomId: 'u8zcj4x2',
  hotjarId: '4984595',
  kompassifyMonthlyNpsSurveyId: '2a53df3a-adc5-4565-b4a9-277f90db1efe',
  kompassifyCsatSurveyId: '27b3bfbc-95ae-4087-8596-38944c2ae558',
};
