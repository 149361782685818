/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum UserSearchTag {
    MATCHES_SCHEDULE = 'MATCHES_SCHEDULE',
    DOES_NOT_MATCH_SCHEDULE = 'DOES_NOT_MATCH_SCHEDULE',
    ALONG_ROUTE = 'ALONG_ROUTE',
    DETOUR_TOO_BIG = 'DETOUR_TOO_BIG',
    SIMILAR_COMMUTE_BEHAVIOR = 'SIMILAR_COMMUTE_BEHAVIOR'
}

