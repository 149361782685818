import { Component, Input } from '@angular/core';
import { ApiResponseTransitDetectionCheckTransport } from '../../../api';

@Component({
  selector: 'transit-validation-result',
  templateUrl: './transit-validation-result.component.html',
  styleUrls: ['./transit-validation-result.component.scss'],
})
export class TransitValidationResultComponent {
  @Input()
  public result!: ApiResponseTransitDetectionCheckTransport;

  public showRailFeaturesAlongLatLongs = false;

  public showRailMatchingLatLongs = false;

  public showTransitBusStopsFeatures = false;

  public showTransitMatchingStopSegments = false;

  public showTransitStopSegments = false;

  // trajectory should be shown on map load
  public showTrajectory = true;
}
