import { DOCUMENT } from '@angular/common';
import { DestroyRef, Inject, Injectable, inject } from '@angular/core';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { pageRoutes } from '@core/models/page-routes.model';
import { DashboardLanguageService } from '@core/services/dashboard-language.service';
import { DateHelperService } from '@core/services/date-helper/date-helper.service';
import { DisplayNameHelperService } from '@core/services/display-name-helper';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { KompassifyEvent } from '@core/services/kompassify/enums/kompassify-event.enum';
import { KompassifyTemplateVariable } from '@core/services/kompassify/enums/kompassify-template-variable.enum';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';

import { UserDataService } from '@core/services/user/user-data.service';
import { LocalStorageKey } from '@shared/enums/local-storage-key.enum';
import { ApiResponseEmployer, ApiResponseUserModel, SubscriptionStatusEnum } from '@swagger/index';
import { ReplaySubject, combineLatest, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KompassifyService {
  private kompassifyBootLoaderReady$: ReplaySubject<void> = new ReplaySubject(1);

  private kompassifyProgressBarReady$: ReplaySubject<void> = new ReplaySubject(1);

  private destroyRef = inject(DestroyRef);

  private readonly excludedPageRoutes = [pageRoutes.MANAGER_APP_PREVIEW.path];

  private readonly visibleClass = 'visible';

  private readonly elementClass = 'kompassify-progress-player-frame';

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly userDataService: UserDataService,
    private readonly employerWrapperService: EmployerWrapperService,
    private readonly displayNameHelperService: DisplayNameHelperService,
    private readonly router: Router,
    private readonly dashboardLanguageService: DashboardLanguageService,
    private readonly localStorageService: LocalStorageService,
    private readonly dateHelperService: DateHelperService
  ) {}

  public init(): void {
    this.exposeRouter();
    this.addEventListener();
    this.initIdentifySubscription();
    this.setLocale();
  }

  /**
   * Expose Angular Router globally, allowing us to define custom JS in kompassify that is run
   * used to do SPA navigation without window reloads
   * @param router
   */
  private exposeRouter(): void {
    (window as any).ngRouter = this.router;
  }

  private addEventListener(): void {
    window.addEventListener('message', (event) => {
      if (event.data.TYPE === KompassifyEvent.KOMPASSIFY_BOOT_LOADER_IS_READY) {
        this.kompassifyBootLoaderReady$.next();
      }

      if (event.data.TYPE === KompassifyEvent.KOMPASSIFY_PROGRESS_BAR_IS_READY) {
        this.kompassifyProgressBarReady$.next();
      }

      if (event.data.TYPE === KompassifyEvent.KOMPASSIFY_EXECUTE_JS_CODE) {
        // eslint-disable-next-line no-eval
        eval(event.data.jsCode);
      }

      if (event.data.TYPE === KompassifyEvent.KOMPASSIFY_DESTROY_PROGRESSBAR) {
        this.showCsatSurvey();
      }

      if (
        event.data.TYPE === KompassifyEvent.KOMPASSIFY_SKIP_NPS_SURVEY ||
        event.data.TYPE === KompassifyEvent.KOMPASSIFY_CLOSE_NPS_SURVEY
      ) {
        if (event.data.uuid === environment.kompassifyMonthlyNpsSurveyId) {
          this.localStorageService.setItem(LocalStorageKey.monthlyNpsSurveySeenDate, new Date().toISOString());
        }

        if (event.data.uuid === environment.kompassifyCsatSurveyId) {
          this.localStorageService.setItem(LocalStorageKey.csatSurveySeenDate, new Date().toISOString());
        }
      }
    });
  }

  private initIdentifySubscription(): void {
    combineLatest([this.userDataService.userStream(), this.employerWrapperService.getEmployerStream()])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([user, employer]) => {
        this.identify(user, employer);
      });
  }

  private async setLocale(): Promise<void> {
    await firstValueFrom(this.kompassifyBootLoaderReady$);
    const language = this.dashboardLanguageService.getCurrentDashboardLanguage();

    window.kompassifyMultiLanguage.setUserLanguage(language);
  }

  private async identify(user: ApiResponseUserModel, employer: ApiResponseEmployer): Promise<void> {
    await firstValueFrom(this.kompassifyBootLoaderReady$);
    await window.kompassifyIdUser(user.username);

    const firstName = this.displayNameHelperService.getFirstName(user.displayName);

    window.kompassifyTemplating.addTemplateVariable(KompassifyTemplateVariable.FIRST_NAME, firstName);
    window.kompassifyTemplating.addTemplateVariable(KompassifyTemplateVariable.EMPLOYER_NAME, employer.name);
  }

  public async toggleVisibleClass(lastRoutePath: string): Promise<void> {
    await firstValueFrom(this.kompassifyProgressBarReady$);
    const excluded = this.excludedPageRoutes.some((excludedPath) => lastRoutePath.includes(excludedPath));
    const element = this.document.querySelector(`.${this.elementClass}`) as HTMLElement;

    if (!element) {
      return;
    }

    if (excluded) {
      element.classList.remove(this.visibleClass);
    } else {
      element.classList.add(this.visibleClass);
    }
  }

  /**
   * NPS = Net Promoter Score
   */
  public showMonthlyNpsSurvey(): void {
    if (this.employerWrapperService.employer.subscription?.status === SubscriptionStatusEnum.trialing) {
      return;
    }

    const currentDate = new Date();
    const lastSeen = this.localStorageService.getItem(LocalStorageKey.monthlyNpsSurveySeenDate);

    if (lastSeen) {
      const lastSeenDate = new Date(lastSeen);

      const isSameMonth = this.dateHelperService.isSameMonth(lastSeenDate, currentDate);

      if (isSameMonth) {
        return;
      }
    }

    this.showSurvey(environment.kompassifyMonthlyNpsSurveyId);
  }

  private async showSurvey(id: string): Promise<void> {
    await firstValueFrom(this.kompassifyBootLoaderReady$);

    window.kompassifyNpsSurvey.startNpsSurvey(id);
  }

  /**
   * CSAT = Customer Satisfaction
   */
  public showCsatSurvey(): void {
    if (this.employerWrapperService.employer.subscription?.status !== SubscriptionStatusEnum.trialing) {
      return;
    }

    const csatSurveySeenDate = this.localStorageService.getItem(LocalStorageKey.csatSurveySeenDate);

    if (csatSurveySeenDate) {
      return;
    }

    this.showSurvey(environment.kompassifyCsatSurveyId);
  }
}
