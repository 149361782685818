/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum StripePriceRecurringAggregateUsage {
    last_during_period = 'last_during_period',
    last_ever = 'last_ever',
    max = 'max',
    sum = 'sum'
}

