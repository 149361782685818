import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { pageRoutes } from '@core/models/page-routes.model';
import { DisplayNameHelperService } from '@core/services/display-name-helper';
import { Observable, ReplaySubject, catchError, concatMap, from, retry, tap } from 'rxjs';
import { ApiResponseUserModel, ChildAddressModel, UserService } from '../../../api';
import { SentryService } from '../sentry.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private runtimeUser?: ApiResponseUserModel;

  private readonly user$: ReplaySubject<ApiResponseUserModel> = new ReplaySubject(1);

  constructor(
    private readonly userService: UserService,
    private readonly toastService: ToastService,
    private readonly sentryService: SentryService,
    private readonly router: Router,
    private readonly displayNameHelperService: DisplayNameHelperService
  ) {}

  public userStream(): Observable<ApiResponseUserModel> {
    return this.user$.asObservable();
  }

  public get user(): ApiResponseUserModel {
    if (!this.runtimeUser) {
      throw Error('No runtime user available');
    }

    return this.runtimeUser;
  }

  public get firstNameOfUserOrEmptyIfEmail(): string {
    return this.displayNameHelperService.getFirstName(this.runtimeUser?.displayName) ?? '';
  }

  public getMyUser(): Observable<ApiResponseUserModel> {
    return this.userService.userControllerGet().pipe(
      retry(3),
      catchError((error) => {
        this.toastService.show(true, 'user.loadingFailureMessage');

        return from(this.router.navigate([pageRoutes.CRITICAL_DATA_LOADING_FAILURE.path])).pipe(
          concatMap(() => {
            throw error;
          })
        );
      }),
      tap((user) => {
        this.runtimeUser = user;
        this.user$.next(user);
        this.sentryService.setSentryUser(user);
      })
    );
  }

  public getEmailDomain(): string {
    return this.user.email.split('@')[1];
  }

  public getWorkAddress(user: ApiResponseUserModel): ChildAddressModel | undefined {
    const addresses = user.addresses as Record<string, ChildAddressModel>;
    const workAddressEntry = Object.entries(addresses).find((entry) => entry[0] === 'work');

    return workAddressEntry?.[1];
  }
}
