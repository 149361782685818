<div [class.cdk-dialog--fill]="responsiveService.isMobileView()" class="cdk-dialog justify-center">
  <div class="cdk-dialog-header flex flex-col items-center">
    <img
      [src]="platformService.isMobileAppWebView() ? 'assets/images/emojis/1f389.svg' : 'assets/images/emojis/1f4f1.svg'"
      alt="smartphone emoji"
      class="w-16 h-16"
    />
    <h1 class="text-3xl mt-6 text-center">
      {{
        (platformService.isMobileAppWebView()
          ? 'mobileCheckoutDialog.titleMobileWebView'
          : 'mobileCheckoutDialog.title'
        ) | translate
      }}
    </h1>
  </div>

  <div class="cdk-dialog-body border-none py-0 h-auto max-w-full w-[600px] mx-auto">
    <p
      class="text-center"
      [innerHTML]="
        (platformService.isMobileAppWebView()
          ? 'mobileCheckoutDialog.descriptionMobileWebView'
          : !responsiveService.isMobileView()
            ? 'mobileCheckoutDialog.qrCodeDescription'
            : 'mobileCheckoutDialog.description'
        ) | translate
      "
    ></p>

    <download-qr-code
      *ngIf="!responsiveService.isMobileView()"
      (refresh)="getCode(false)"
      [deepLink]="deepLink"
    ></download-qr-code>
  </div>

  <div class="cdk-dialog-actions flex-col items-center gap-4">
    <ng-container *ngIf="responsiveService.isMobileView(); else qrCodeClose">
      <button (click)="this.onContinueInApp()" color="primary" mat-flat-button>
        {{ (platformService.isMobileAppWebView() ? 'general.continue' : 'general.goToApp') | translate }}
      </button>

      <button
        *ngIf="!this.platformService.isMobileAppWebView()"
        (click)="this.dialogRef.close()"
        mat-flat-button
        class="text-gray-dark underline"
      >
        {{ 'general.goToDashboard' | translate }}
      </button>
    </ng-container>

    <ng-template #qrCodeClose>
      <button (click)="this.dialogRef.close()" color="primary" mat-flat-button>
        {{ 'general.close' | translate }}
      </button>
    </ng-template>
  </div>
</div>
