/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestAddDashboardUserEmailModel } from '../model/models';
import { ApiRequestAddDashboardUserSocialModel } from '../model/models';
import { ApiResponseSignupUserModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface DashboardSignupServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestAddDashboardUserEmailModel 
     */
    dashboardSignupControllerSignUpEmail(apiRequestAddDashboardUserEmailModel: ApiRequestAddDashboardUserEmailModel, extraHttpRequestParams?: any): Observable<ApiResponseSignupUserModel>;

    /**
     * 
     * 
     * @param apiRequestAddDashboardUserEmailModel 
     */
    dashboardSignupControllerSignUpEmail_1(apiRequestAddDashboardUserEmailModel: ApiRequestAddDashboardUserEmailModel, extraHttpRequestParams?: any): Observable<ApiResponseSignupUserModel>;

    /**
     * 
     * 
     * @param apiRequestAddDashboardUserSocialModel 
     */
    dashboardSignupControllerSignUpSocial(apiRequestAddDashboardUserSocialModel: ApiRequestAddDashboardUserSocialModel, extraHttpRequestParams?: any): Observable<ApiResponseSignupUserModel>;

    /**
     * 
     * 
     * @param apiRequestAddDashboardUserSocialModel 
     */
    dashboardSignupControllerSignUpSocial_2(apiRequestAddDashboardUserSocialModel: ApiRequestAddDashboardUserSocialModel, extraHttpRequestParams?: any): Observable<ApiResponseSignupUserModel>;

}
