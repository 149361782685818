/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityDiscriminator } from './activityDiscriminator';
import { TripLogModel } from './tripLogModel';
import { OtherActivityModel } from './otherActivityModel';


export interface ApiRequestActivityManagementV2 { 
    oldActivityDiscriminator: ActivityDiscriminator;
    newActivityDiscriminator: ActivityDiscriminator;
    id: string;
    newOtherActivity?: OtherActivityModel;
    tripLog?: TripLogModel;
}



