<div [formGroup]="formGroup">
  <mat-form-field class="mt-0">
    <mat-select matInput [formControlName]="controlName">
      <ng-container *ngFor="let role of userRoles">
        <mat-option [value]="role">
          {{ 'user.role.' + role | translate }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
