import { isLocalhost } from '@core/helpers/host-name-check.helper';
import { Region } from './enum/region.enum';
import { AmplifyConfig } from './interfaces/amplify-config.interface';

export const amplifyConfigDev: AmplifyConfig = {
  Auth: {
    identityPoolId: 'us-east-1:1ea338e3-4fa3-4179-9769-f39ad549430e',
    region: Region.US,
    userPoolId: 'us-east-1_3e5dZqajt',
    userPoolWebClientId: '7723vgq6i5ljg7pl1e82nfssfv',
    authenticationFlowType: 'CUSTOM_AUTH',
    oauth: {
      domain: 'paveappdev.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: isLocalhost ? 'http://localhost:4200' : 'https://dev.dashboard.pavecommute.app',
      redirectSignOut: isLocalhost ? 'http://localhost:4200' : 'https://dev.dashboard.pavecommute.app',
      responseType: 'code',
    },
  },
  Storage: {
    customPrefix: { public: '', protected: '', private: '' },
    AWSS3: {
      bucket: 'rideamigos-pave-dev-private', // REQUIRED -  Amazon S3 bucket name
      region: Region.US, // OPTIONAL -  Amazon service region,
    },
  },
};
