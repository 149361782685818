import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AmplifyService } from '@core/services/amplify/amplify.service';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@shared/modules/material/material.module';

@Component({
  selector: 'critical-data-loading-error-component',
  standalone: true,
  imports: [TranslateModule, RouterModule, MaterialModule],
  templateUrl: './critical-data-loading-error-component.component.html',
  styleUrl: './critical-data-loading-error-component.component.scss',
})
export class CriticalDataLoadingErrorComponentComponent {
  constructor(
    private readonly location: Location,
    private readonly amplifyService: AmplifyService
  ) {}

  public onRetry(): void {
    this.location.back(); // Navigate back to the previous page so that the service can fetch the data again
  }

  public async onLogout(): Promise<void> {
    await this.amplifyService.logout();
  }
}
