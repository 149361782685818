import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { showDialogQueryParam } from '@core/constants/query-param.constant';
import { pageRoutes } from '@core/models/page-routes.model';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { LayoutService } from '@core/services/layout/layout.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { MixpanelEvent } from '@core/services/mixpanel/mixpanel-event.enum';
import { MixpanelWrapperService } from '@core/services/mixpanel/mixpanel-wrapper.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { TranslateModule } from '@ngx-translate/core';
import { DialogId } from '@shared/enums/dialog-id.enum';
import { LocalStorageKey } from '@shared/enums/local-storage-key.enum';
import { MaterialModule } from '@shared/modules/material/material.module';
import { AppPreviewComponent } from '../components/app-preview/app-preview.component';

@Component({
  selector: 'manager-app-preview-page',
  standalone: true,
  imports: [AppPreviewComponent, MaterialModule, TranslateModule],
  templateUrl: './manager-app-preview-page.component.html',
  styleUrl: './manager-app-preview-page.component.scss',
})
export class ManagerAppPreviewPageComponent implements OnInit, OnDestroy {
  public navigatedFromOnboarding: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly layoutService: LayoutService,
    private readonly localStorageService: LocalStorageService,
    private readonly mixpanelWrapperService: MixpanelWrapperService,
    private readonly employerWrapperService: EmployerWrapperService
  ) {
    this.trackMixpanelEventIfRequired();
    this.navigatedFromOnboarding = this.route.snapshot.queryParamMap.get('fromOnboarding') === 'true' ?? false;
  }

  public ngOnInit(): void {
    this.layoutService.toggleFullscreenPage(true);
  }

  public ngOnDestroy(): void {
    this.layoutService.toggleFullscreenPage(false);
  }

  public navigateBack(): void {
    if (this.navigatedFromOnboarding) {
      this.router.navigate([pageRoutes.STATISTICS.path], {
        queryParams: {
          [showDialogQueryParam]: DialogId.GET_STARTED_DIALOG,
        },
      });

      return;
    }

    this.navigationService.navigateBack();
  }

  public navigateToDashboard(): void {
    this.router.navigate([pageRoutes.STATISTICS.path]);
  }

  private async trackMixpanelEventIfRequired(): Promise<void> {
    const visitedAppPreview = this.readTrackedVisitedAppPreview();

    if (visitedAppPreview) {
      return;
    }

    this.mixpanelWrapperService.track(MixpanelEvent.VISITED_APP_PREVIEW, {
      employerId: this.employerWrapperService.employerId,
    });

    this.localStorageService.setItem(LocalStorageKey.visitedAppPreview, Boolean(true).toString());
  }

  private readTrackedVisitedAppPreview(): boolean | null {
    const value = this.localStorageService.getItem(LocalStorageKey.firstTrackedCommuteDialogShown);

    if (value) {
      return JSON.parse(value);
    }

    return null;
  }
}
