/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestMockFirehose } from '../model/models';
import { ApiResponseSuccessModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface SentianceServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * More Info: https://docs.sentiance.com/guide/firehose Needed for verification by sentiance
     */
    sentianceControllerFirehoseGet(extraHttpRequestParams?: any): Observable<object>;

    /**
     * 
     * More Info: https://docs.sentiance.com/guide/firehose Needed for verification by sentiance
     */
    sentianceControllerFirehoseGet_1(extraHttpRequestParams?: any): Observable<object>;

    /**
     * 
     * 
     */
    sentianceControllerFirehosePost(extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     */
    sentianceControllerFirehosePost_2(extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     */
    sentianceControllerGetAuthCode(extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     */
    sentianceControllerGetAuthCode_3(extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param apiRequestMockFirehose 
     */
    sentianceControllerMockFirehose(apiRequestMockFirehose: ApiRequestMockFirehose, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestMockFirehose 
     */
    sentianceControllerMockFirehose_4(apiRequestMockFirehose: ApiRequestMockFirehose, extraHttpRequestParams?: any): Observable<{}>;

}
