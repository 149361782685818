import { CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TimeFrameItemHelperService } from '@core/services/time-frame-item-helper/time-frame-item-helper.service';
import { LocalStorageKey } from '@shared/enums/local-storage-key.enum';
import { TimeFrameItem } from '@shared/interfaces/time-frame-item.interface';

@Component({
  selector: 'time-frame-selector',
  templateUrl: './time-frame-selector.component.html',
  styleUrls: ['./time-frame-selector.component.scss'],
})
export class TimeFrameSelectorComponent implements OnInit {
  // If provided, the value for the time frame will be loaded from and (if selection changes) saved to local storage
  @Input() public localStorageKey?: LocalStorageKey;

  // If provided, this value will be used initially and immediately stored in the localStorageKey if also provided
  @Input() public default?: TimeFrameItem;

  // If true, the initial emit after setting the initial value will be skipped
  @Input() public skipInitialEmit?: boolean;

  @ViewChild('cdkMenuTrigger', { static: true }) public cdkMenuTrigger!: CdkMenuTrigger;

  @Output()
  public timeFrameChange: EventEmitter<TimeFrameItem> = new EventEmitter<TimeFrameItem>();

  public selectedTimeFrameItem!: TimeFrameItem;

  constructor(private readonly timeFrameItemHelperService: TimeFrameItemHelperService) {}

  public ngOnInit(): void {
    this.initTimeFrameItem();
  }

  private setLocalStorageTimeFrame(timeFrameItem: TimeFrameItem): void {
    if (!this.localStorageKey) {
      return;
    }

    localStorage.setItem(this.localStorageKey, JSON.stringify(timeFrameItem));
  }

  private initTimeFrameItem(): void {
    if (this.default) {
      this.selectedTimeFrameItem = this.default!;
    }

    if (!this.default && this.localStorageKey) {
      this.selectedTimeFrameItem = this.timeFrameItemHelperService.getTimeFrameItemFromLocalStorage(
        this.localStorageKey!
      );
    }

    if (!this.default && !this.localStorageKey) {
      this.selectedTimeFrameItem = this.timeFrameItemHelperService.getDefaultTimeFrameItem();
    }

    if (!this.skipInitialEmit) {
      this.emit();
    }
  }

  private emit(): void {
    this.setLocalStorageTimeFrame(this.selectedTimeFrameItem);
    this.timeFrameChange.emit(this.selectedTimeFrameItem);
  }

  public onTimeFrameSelect(timeFrameItem: TimeFrameItem): void {
    this.selectedTimeFrameItem = timeFrameItem;
    this.emit();
    this.cdkMenuTrigger.close();
  }

  public onCancel(): void {
    this.cdkMenuTrigger.close();
  }
}
