import { Dialog } from '@angular/cdk/dialog';
import { Component, DestroyRef, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSidenavContent } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DashboardLanguageService } from '@core/services/dashboard-language.service';
import { DateHelperService } from '@core/services/date-helper/date-helper.service';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { IntercomService } from '@core/services/intercom.service';
import { KompassifyService } from '@core/services/kompassify/kompassify.service';
import { UserDataService } from '@core/services/user/user-data.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { filter } from 'rxjs';
import { AdminService } from '../../../core/services/admin/admin.service';
import { LayoutService } from '../../../core/services/layout/layout.service';

@Component({
  selector: 'app-logged-in-pages-layout',
  templateUrl: './logged-in-pages-layout.component.html',
  styleUrls: ['./logged-in-pages-layout.component.scss'],
})
export class LoggedInPagesLayoutComponent implements OnInit {
  private scrollPositions: { [routePath: string]: number } = {};

  private lastRoutePath = '';

  private destroyRef = inject(DestroyRef);

  private navigatedFromOnboarding: boolean;

  @ViewChild('sidenavContent', { static: true }) public sidenavContent!: MatSidenavContent;

  constructor(
    public readonly layoutService: LayoutService,
    public readonly adminService: AdminService,
    private readonly router: Router,
    private readonly utilsService: UtilsService,
    private readonly kompassifyService: KompassifyService,
    private readonly userDataService: UserDataService,
    private readonly intercomService: IntercomService,
    private readonly dashboardLanguageService: DashboardLanguageService,
    private readonly employerWrapperService: EmployerWrapperService,
    private readonly dateHelperService: DateHelperService,
    private readonly route: ActivatedRoute,
    private readonly dialog: Dialog
  ) {
    this.navigatedFromOnboarding = this.route.snapshot.queryParamMap.get('fromOnboarding') === 'true' ?? false;

    // store current scroll position on NavigationStart
    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe(() => {
        if (this.sidenavContent) {
          this.scrollPositions[this.lastRoutePath] = this.sidenavContent.measureScrollOffset('top');
        }
      });

    // reapply stored scroll position scroll position on NavigationEnd
    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        this.lastRoutePath = (event as NavigationEnd).urlAfterRedirects;

        const newPosition = this.scrollPositions[this.lastRoutePath] ?? 0;

        if (this.sidenavContent) {
          this.utilsService.customWait(0, () => this.sidenavContent.scrollTo({ top: newPosition }));
        }

        this.kompassifyService.toggleVisibleClass(this.lastRoutePath);
      });
  }

  public ngOnInit(): void {
    this.initUpdateIntercomUserSubscription();
    this.showMonthlyNpsSurvey();
    this.showCsatSurvey();
  }

  private showMonthlyNpsSurvey(): void {
    if (this.navigatedFromOnboarding) {
      return;
    }

    this.kompassifyService.showMonthlyNpsSurvey();
  }

  private showCsatSurvey(): void {
    const createdAtDate = new Date(this.employerWrapperService.employer.createdAt);
    const today = new Date();

    const diff = this.dateHelperService.getDayDifferenceBetweenDates(today, createdAtDate);

    if (diff > 14) {
      this.kompassifyService.showCsatSurvey();
    }
  }

  private async initUpdateIntercomUserSubscription(): Promise<void> {
    this.userDataService
      .userStream()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        const updateIntercomUser = (): void =>
          this.intercomService.update({
            user_id: user.username,
            name: user.displayName ?? undefined,
            language_override: this.dashboardLanguageService.getCurrentDashboardLanguage(),
          });

        updateIntercomUser();
      });
  }
}
