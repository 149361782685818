import { Injectable } from '@angular/core';
import { ExternalRewardStyle, RaffleRewardStyle, TangoRewardStyle, TripModeEnum } from '@swagger/index';
import { RewardStyle } from 'src/app/post-login/rewards/pages/rewards-overview-page/types/reward-style.type';

@Injectable({
  providedIn: 'root',
})
export class EnumImageHelperService {
  public getRewardStyleImage(style: string | RewardStyle): string {
    switch (style) {
      case RaffleRewardStyle.MANUAL:
        return 'assets/images/reward-styles/raffle.png';

      case ExternalRewardStyle.CHARITY:
        return 'assets/images/reward-styles/employer-charity.png';

      case ExternalRewardStyle.REWARDS:
        return 'assets/images/reward-styles/employer-rewards.png';

      case TangoRewardStyle.CHARITY:
        return 'assets/images/reward-styles/tango-charity.png';

      case TangoRewardStyle.REWARDS:
        return 'assets/images/reward-styles/tango-rewards.png';

      default:
        return '';
    }
  }

  public getRewardStyleEmoji(style: string | RewardStyle): string {
    switch (style) {
      case ExternalRewardStyle.CHARITY:
        return 'assets/images/emojis/1f91d.svg';

      case ExternalRewardStyle.REWARDS:
        return 'assets/images/emojis/1f381.svg';

      case TangoRewardStyle.CHARITY:
        return 'assets/images/emojis/1f91d.svg';

      case TangoRewardStyle.REWARDS:
        return 'assets/images/emojis/1f6d2.svg';

      case RaffleRewardStyle.MANUAL:
        return 'assets/images/emojis/1f39f.svg';

      default:
        return '';
    }
  }

  public getModeImageUrl(mode: string | TripModeEnum): string {
    switch (mode) {
      case TripModeEnum.bike:
        return 'assets/images/emojis/1f6b2.svg';

      case TripModeEnum.car:
        return 'assets/images/emojis/1f697.svg';

      case TripModeEnum.carpool:
        return 'assets/images/emojis/1f699.svg';

      case TripModeEnum.internalShuttle:
        return 'assets/images/emojis/1f68c.svg';

      case TripModeEnum.scooter:
        return 'assets/images/emojis/1f6f4.svg';

      case TripModeEnum.transit:
        return 'assets/images/emojis/1f68d.svg';

      case TripModeEnum.walk:
        return 'assets/images/emojis/1f45f.svg';

      default:
        return '';
    }
  }
}
