/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeoJsonFeatureCollection } from './geoJsonFeatureCollection';


export interface TransitStopDetectionResult { 
    /**
     * Value between 0.0 - 1.0
     */
    probability: number;
    stopSegments: GeoJsonFeatureCollection;
    transitStops: GeoJsonFeatureCollection;
    matchingStopSegments: GeoJsonFeatureCollection;
}

