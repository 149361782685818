import { Component, Input } from '@angular/core';

@Component({
  selector: 'error-state',
  templateUrl: './error-state.component.html',
  styleUrls: ['./error-state.component.scss'],
})
export class ErrorStateComponent {
  @Input() public titleKey?: string;

  @Input({ required: true }) public msgKey!: string;
}
