import { Component, Input } from '@angular/core';

@Component({
  selector: 'kpi-card',
  templateUrl: './kpi-card.component.html',
  styleUrls: ['./kpi-card.component.scss'],
})
export class KpiCardComponent {
  @Input() public value!: number;

  @Input() public unity?: string;

  @Input() public text!: string;

  @Input() public emoji!: string;

  @Input() public explanation?: string;
}
