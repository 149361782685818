import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[overflowMarqueeAnimation]',
})
export class OverflowMarqueeAnimationDirective {
  constructor(private el: ElementRef) {}

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.addCssClass();
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.removeCssClass();
  }

  private addCssClass(): void {
    if (this.isElementOverflowing()) {
      this.el.nativeElement.className += ' animate-marquee';
    }
  }

  public isElementOverflowing(): boolean {
    const overflowX = this.el.nativeElement.offsetWidth < this.el.nativeElement.scrollWidth;
    const overflowY = this.el.nativeElement.offsetHeight < this.el.nativeElement.scrollHeight;

    return overflowX || overflowY;
  }

  private removeCssClass(): void {
    if (this.el.nativeElement.className.includes(' animate-marquee')) {
      this.el.nativeElement.className = this.el.nativeElement.className.replace(' animate-marquee', '');
    }
  }
}
