import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DataLayerEvent } from '@core/services/google-analytics/data-layer-event.enum';
import { GoogleAnalyticsService } from '@core/services/google-analytics/google-analytics.service';
import freeEmailDomains from 'free-email-domains';

export function companyEmailValidator(googleAnalyticsService: GoogleAnalyticsService): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;

    if (email) {
      const domain = email.split('@')[1];

      if (domain && freeEmailDomains.includes(domain)) {
        googleAnalyticsService.trackEvent(DataLayerEvent.privateEmailEnteredForSignup);

        return { freeEmailDomain: true };
      }
    }

    return null;
  };
}
