import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { RegionDetectMethod } from '@core/enums/region-detect-method.enum';
import { Region } from '@core/enums/region.enum';
import { RegionService } from '@core/services/region.service';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'region-dialog',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './region-dialog.component.html',
  styleUrl: './region-dialog.component.scss',
})
export class RegionDialogComponent {
  public selectedRegion?: Region;

  public regions = Region;

  constructor(
    private readonly dialogRef: DialogRef<Region>,
    public readonly regionService: RegionService
  ) {
    this.initData();
  }

  private initData(): void {
    if (this.regionService.getRegionDetectMethod() === RegionDetectMethod.PARAM) {
      this.selectedRegion = this.regionService.getRegion();
    }
  }

  public onContinue(): void {
    this.regionService.switchRegion(this.selectedRegion!);
    this.dialogRef.close(this.selectedRegion!);
  }

  public onSelectRegion(region: Region): void {
    this.selectedRegion = region;
  }
}
