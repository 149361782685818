import { Dialog } from '@angular/cdk/dialog';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { DialogId } from '@shared/enums/dialog-id.enum';
import { AdminService } from '../../core/services/admin/admin.service';
import { RawDataDialogComponent } from '../dialogs/raw-data-dialog/raw-data-dialog.component';

@Directive({
  selector: '[rawData]',
})
export class RawDataDirective {
  @Input() public rawData!: unknown;

  constructor(
    private el: ElementRef,
    private readonly adminService: AdminService,
    private readonly dialog: Dialog
  ) {
    if (!this.adminService.isAdministrator()) {
      this.el.nativeElement.style.display = 'none';
    }
  }

  @HostListener('click')
  public onOpenRawDataDialog(): void {
    this.dialog.open(RawDataDialogComponent, {
      id: DialogId.RAW_DATA_DIALOG,
      data: {
        jsonData: this.rawData,
      },
    });
  }
}
