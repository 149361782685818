import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { pageRoutes } from '@core/models/page-routes.model';
import { AdminService } from '../services/admin/admin.service';

export const AdminGuard: CanMatchFn = () => {
  const adminService = inject(AdminService);
  const router = inject(Router);

  if (adminService.isAdministrator()) {
    return true;
  }

  return router.createUrlTree([pageRoutes.STATISTICS.path]);
};
