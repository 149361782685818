/* eslint-disable no-restricted-syntax */
import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';
import { APP_BASE_HREF, DecimalPipe, PlatformLocation } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, Router } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { AmplifyService } from '@core/services/amplify/amplify.service';
import { CurrencyService } from '@core/services/currency.service';
import { EnvironmentService } from '@core/services/environment.service';
import { LocaleService } from '@core/services/locale.service';
import { RegionService } from '@core/services/region.service';
import * as Sentry from '@sentry/angular';
import { LocalizeMeterPipe } from '@shared/pipes/localize-meter.pipe';

import Intercom from '@intercom/messenger-js-sdk';
import { AppIframeComponent } from '@shared/standalone/app-iframe/app-iframe.component';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { QuillModule } from 'ngx-quill';
import { provideToastr } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Configuration } from './api/configuration';

import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { defaultRequestTimeoutInMs } from './core/constants/timeout.constant';
import { TIMEOUT, TimeoutInterceptor } from './core/interceptors/timeout.interceptor';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { SharedModule } from './shared/shared.module';

export function initializeApp(
  regionService: RegionService,
  environmentService: EnvironmentService,
  amplifyService: AmplifyService
): Function {
  return (): void => {
    regionService.init();
    environmentService.init();
    amplifyService.init();
  };
}

Intercom({ app_id: environment.intercomId });

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    MatDatepickerModule, // this need to be in the app module and cannot be moved into the material module
    MatProgressSpinnerModule, // this need to be in the app module and cannot be moved into the material module
    MatSnackBarModule, // this need to be in the app module and cannot be moved into the material module
    BrowserAnimationsModule,
    ApiModule.forRoot(() => new Configuration({ basePath: `${environment.apiUrl}` })),
    QuillModule.forRoot(),
    AppIframeComponent,
  ],
  exports: [],
  providers: [
    provideCharts(withDefaultRegisterables()),
    {
      provide: APP_INITIALIZER,
      deps: [RegionService, EnvironmentService, AmplifyService, Sentry.TraceService], // the order of the dependencies matter (RegionService first) otherwise it won't initialize the app and throws
      useFactory: initializeApp,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (localeService: LocaleService): string => localeService.locale,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      deps: [CurrencyService],
      useFactory: (currencyService: CurrencyService): string => currencyService.currency,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: TIMEOUT, useValue: defaultRequestTimeoutInMs },
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: { autoFocus: false, hasBackdrop: true, maxHeight: '95vh', maxWidth: '95vw' },
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill', subscriptSizing: 'fixed' } },
    {
      provide: APP_BASE_HREF,
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    DecimalPipe,
    LocalizeMeterPipe,
    provideToastr({
      progressBar: true,
      timeOut: 5000,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
