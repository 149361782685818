import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { LocalStorageKey } from '@shared/enums/local-storage-key.enum';
import { AuthTokens } from '@shared/interfaces/auth-tokens.interface';
import { JwtToken } from '@shared/interfaces/jwt-token.interface';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationTokenService {
  private accessToken = '';

  private refreshToken = '';

  private idToken = '';

  constructor(private readonly localStorageService: LocalStorageService) {
    this.initLSData();
  }

  public setAccessToken(token: string): void {
    this.accessToken = token;
  }

  public setRefreshToken(token: string): void {
    this.refreshToken = token;
  }

  public setIdToken(token: string): void {
    this.idToken = token;
  }

  public getAccessToken(): string {
    return this.accessToken;
  }

  public getRefreshToken(): string {
    return this.refreshToken;
  }

  public getIdToken(): string {
    return this.idToken;
  }

  public setAllTokensAndUpdateLocalStorage(tokens: AuthTokens, isAdmin: boolean): void {
    this.setIdToken(tokens.idToken);
    this.setAccessToken(tokens.accessToken);
    this.setRefreshToken(tokens.refreshToken);

    this.setLSData(isAdmin);
  }

  private initLSData(): void {
    this.setAccessToken(this.localStorageService.getItem(LocalStorageKey.accessToken));
    this.setRefreshToken(this.localStorageService.getItem(LocalStorageKey.refreshToken));
    this.setIdToken(this.localStorageService.getItem(LocalStorageKey.idToken));
  }

  private setLSData(isAdministrator: boolean): void {
    this.localStorageService.setItem(LocalStorageKey.accessToken, this.getAccessToken());
    this.localStorageService.setItem(LocalStorageKey.refreshToken, this.getRefreshToken());
    this.localStorageService.setItem(LocalStorageKey.idToken, this.getIdToken());

    const { email } = jwtDecode(this.getIdToken()) as JwtToken;

    if (isAdministrator || email.endsWith('@rideamigos.com') || email.endsWith('@pavecommute.app')) {
      this.localStorageService.setItem(LocalStorageKey.traffic_type, 'internal');
    }
  }
}
