import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nothing-found-hint',
  templateUrl: './nothing-found-hint.component.html',
  styleUrls: ['./nothing-found-hint.component.scss'],
})
export class NothingFoundHintComponent {
  @Input() public hintMessageKey = 'general.nothingFound';
}
