import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { EnumImageHelperService } from '@core/services/enum-image-helper.service';
import { TripModeEnum } from '../../../api';
import { CommuteModeHelperService } from '../../../core/services/commute/commute-mode-helper/commute-mode-helper.service';

@Component({
  selector: 'mode-selector',
  templateUrl: './mode-selector.component.html',
  styleUrls: ['./mode-selector.component.scss'],
})
export class ModeSelectorComponent implements OnInit {
  // if provided, only those commute modes can be selected - otherwise all sustainable commute modes can be selected
  @Input() public selectableCommuteModes?: TripModeEnum[];

  @Input() public formGroup!: FormGroup;

  @Input() public controlName!: string;

  @Input() public multiple = true;

  public commuteModes!: TripModeEnum[];

  constructor(
    private readonly commuteModeHelperService: CommuteModeHelperService,
    public readonly enumImageHelperService: EnumImageHelperService
  ) {}

  public ngOnInit(): void {
    this.initCommuteModes();
  }

  private initCommuteModes(): void {
    this.commuteModes = this.selectableCommuteModes
      ? this.selectableCommuteModes
      : this.commuteModeHelperService.getSustainableCommuteModes();
  }
}
