export enum DataLayerCustomDimensions {
  authType = 'authType',
  employerId = 'employerId',
  employerName = 'employerName',
  event = 'event',
  region = 'region',
  username = 'username',
  virtualPageUrl = 'virtualPageUrl',
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  addressPostalCode = 'addressPostalCode',
}
