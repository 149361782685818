<div class="shadow-card flex h-full justify-between gap-2 items-center w-64">
  <div class="flex flex-col min-w-0">
    <div>
      <span class="text-dark text-3xl font-redHatDisplayBold"> {{ value | number: '1.0-2' }} </span>
      <span class="text-primary font-redHatTextSemibold" *ngIf="unity">{{ unity }}</span>
    </div>
    <span class="text-gray-dark">
      {{ text | translate }}

      <mat-icon
        *ngIf="explanation"
        class="cursor-pointer relative -bottom-[3px]"
        style="line-height: initial"
        inline="true"
        [matTooltip]="explanation | translate"
        >help_outline</mat-icon
      >
    </span>
  </div>
  <img class="w-11" src="assets/images/{{ emoji }}.svg" alt="emoji" />
</div>
