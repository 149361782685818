import { Dialog } from '@angular/cdk/dialog';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CdkDialogHelperService } from '@core/services/cdk-dialog-helper.service';
import { DialogId } from '@shared/enums/dialog-id.enum';
import { take } from 'rxjs';
import { CropperDialogComponent, CropperDialogData } from './components/cropper-dialog/cropper-dialog.component';

@Component({
  selector: 'edit-image-upload',
  templateUrl: './edit-image-upload.component.html',
  styleUrls: ['./edit-image-upload.component.scss'],
})
export class EditImageUploadComponent {
  @Input() public form!: FormGroup;

  @Input() public formFieldName!: string;

  @Input() public aspectRatio!: number;

  @Input() public imageUrl?: string;

  // if the edit-image component is used within a dialog, the id of the parent dialog can be passed in
  // to temporary hide the parent dialog when the crop dialog is displayed (to avoid showing a dialog over another dialog)
  @Input() public parentDialogId?: string;

  @Input() public cropperHint?: string;

  @Input({ required: false }) public allowDelete = true;

  @Output() public save: EventEmitter<File> = new EventEmitter<File>();

  @Output() public delete: EventEmitter<void> = new EventEmitter<void>();

  public croppedImage = '';

  constructor(
    private readonly dialog: Dialog,
    private readonly cdkDialogHelperService: CdkDialogHelperService
  ) {}

  public fileChangeEvent(event: any): void {
    if (event.target.value.length !== 0) {
      this.openCropperDialog(event);
    }
  }

  public openCropperDialog(event: any): void {
    const data: CropperDialogData = {
      imageEvent: event,
      aspectRatio: this.aspectRatio,
      showBackIcon: this.parentDialogId !== undefined,
      info: this.cropperHint,
    };
    const dialogRef = this.dialog.open<File>(CropperDialogComponent, {
      id: DialogId.EDIT_IMAGE_DIALOG,
      data,
    });

    this.hideParentDialog();

    dialogRef.closed.pipe(take(1)).subscribe((response) => {
      if (response) {
        this.saveCroppedImage(response);
      }

      this.showParentDialog();
    });
  }

  private hideParentDialog(): void {
    if (this.parentDialogId) {
      this.cdkDialogHelperService.hideDialog(this.parentDialogId);
    }
  }

  private showParentDialog(): void {
    if (this.parentDialogId) {
      this.cdkDialogHelperService.showDialog(this.parentDialogId);
    }
  }

  private saveCroppedImage(file: File): void {
    this.save.emit(file);
  }

  /**
   * Enforces the aspect ratio via a css padding trick
   */
  public get aspectRatioPadding(): string {
    return `${((1 / this.aspectRatio) * 100).toFixed(2)}%`;
  }

  public onDelete(): void {
    this.delete.emit();
  }
}
