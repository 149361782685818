import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { differenceInCalendarDays, isBefore, isSameMonth } from 'date-fns';
import { TimeFrame } from '../../../shared/interfaces/time-frame.interface';

@Injectable({
  providedIn: 'root',
})
export class DateHelperService {
  constructor(private readonly datePipe: DatePipe) {}

  public isBeforeDate(date: Date, dateToCompare: Date): boolean {
    return isBefore(date, dateToCompare);
  }

  public isOnSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  public isSameMonth(date1: Date, date2: Date): boolean {
    return isSameMonth(date1, date2);
  }

  public isDateInThePast(date: Date | string): boolean {
    const today = new Date();
    const comparisonDate = typeof date === 'string' ? new Date(date) : date;

    return comparisonDate < today;
  }

  public getStartOfYear(): Date {
    const startOfYear = new Date(new Date().setMonth(0, 1));
    const startOfYearAndDay = new Date(startOfYear.setHours(0, 0, 0));

    return startOfYearAndDay;
  }

  public getOneYearBackDate(): Date {
    const oneYearBack = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

    return new Date(oneYearBack.setHours(0, 0, 0));
  }

  public getDayDifferenceBetweenDates(date1: Date, date2: Date): number {
    return differenceInCalendarDays(date1, date2);
  }

  public getEndOfToday(): Date {
    return new Date(new Date().setHours(23, 59, 59));
  }

  public getEndOfDate(date: Date): Date {
    return new Date(date.setHours(23, 59, 59));
  }

  public getStartOfDate(date: Date): Date {
    return new Date(date.setHours(0, 0, 0));
  }

  public getStartOfMonth(): Date {
    const startOfMonth = new Date(new Date().setMonth(new Date().getMonth(), 1));

    const startOfMonthAndDay = new Date(startOfMonth.setHours(0, 0, 0));

    return startOfMonthAndDay;
  }

  public getDatesBetweenTimeFrame(timeFrame: TimeFrame): Date[] {
    const dates: Date[] = [];

    const oneDayInMs = 1000 * 60 * 60 * 24;

    const timeDifferenceInDays = (timeFrame.to.getTime() - timeFrame.from.getTime()) / oneDayInMs;

    for (let i = 0; i <= timeDifferenceInDays; i += 1) {
      const nextDay = new Date(timeFrame.from.getTime() + oneDayInMs * i);

      dates.push(nextDay);
    }

    return dates;
  }

  public getFormattedDate(date: Date, format = 'short'): string {
    return this.datePipe.transform(date, format) ?? '';
  }

  public toEndOfDay(date: Date): Date {
    const endOfDay = new Date(date);

    endOfDay.setHours(23, 59, 59, 999);

    return endOfDay;
  }
}
