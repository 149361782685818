<div
  class="user-card h-full flex flex-col justify-center rounded-xl border-gray-light border-[1.5px] cursor-pointer bg-light relative"
>
  <div matRipple (click)="change()" class="p-4 flex gap-x-4 rounded-[inherit]">
    <mat-checkbox
      class="row-span-2 justify-self-center self-center"
      [(ngModel)]="isChecked"
      (click)="onChange($event)"
    ></mat-checkbox>

    <div class="flex flex-col whitespace-nowrap overflow-hidden">
      <p *ngIf="displayName" overflowMarqueeAnimation class="font-redHatTextSemibold truncate hover:overflow-visible">
        {{ displayName }}
      </p>
      <p overflowMarqueeAnimation class="truncate hover:overflow-visible">{{ email }}</p>
    </div>
  </div>

  <ng-content select="#overlay-slot"></ng-content>
</div>
