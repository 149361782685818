import { AnimationBuilder, AnimationPlayer, animate, style } from '@angular/animations';
import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[collapseAnimation]',
})
export class CollapseAnimationDirective implements OnInit, OnChanges {
  @Input({ required: true }) public collapse!: boolean;

  @Input() public duration = 300;

  @Input() public style = 'ease-in-out';

  private player?: AnimationPlayer;

  constructor(
    private readonly ref: ElementRef,
    private readonly renderer: Renderer2,
    private readonly animationBuilder: AnimationBuilder
  ) {}

  public ngOnInit(): void {
    this.renderer.setStyle(this.ref.nativeElement, 'overflow', 'hidden');

    if (this.collapse) {
      this.animate(0);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.collapse && !changes.collapse.isFirstChange()) {
      this.animate();
    }
  }

  private animate(duration = this.duration): void {
    if (this.player) {
      this.player.destroy();
    }

    const animationMetadata = this.collapse
      ? [style({ height: '*', opacity: 1 }), animate(`${duration}ms ${this.style}`, style({ height: 0, opacity: 0 }))]
      : [
          style({ height: '0', opacity: 0 }),
          animate(`${duration}ms ${this.style}`, style({ height: '*', opacity: 1 })),
        ];

    const animation = this.animationBuilder.build(animationMetadata);

    this.player = animation.create(this.ref.nativeElement);
    this.player.play();
  }
}
