import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FirstNameForm } from '../../interfaces/first-name-form.interface';

@Component({
  selector: 'first-name-form',
  templateUrl: './first-name-form.component.html',
  styleUrls: ['./first-name-form.component.scss'],
})
export class FirstNameFormComponent {
  @Input({ required: true }) public form!: FormGroup<FirstNameForm>;

  @Input({ required: true }) public tabAnimationDuration!: number;
}
