<section class="p-12 flex flex-col justify-center items-center h-full">
  <img class="h-12 w-12 mb-8" src="/assets/images/emojis/1f44b.svg" alt="wave emoji" />
  <h1 class="text-3xl">{{ 'otp.title' | translate }},</h1>
  <p class="text-center my-8 max-w-screen-sm">
    {{
      (this.amplifyService.cognitoUser ? 'otp.dashboardLoginDescription' : 'otp.mobileAppLoginDescription') | translate
    }}
  </p>
  <div class="flex gap-3">
    <div *ngFor="let part of otp" class="bg-light shadow-2xl rounded-md flex items-center justify-center w-12 h-14">
      {{ part }}
    </div>
  </div>
</section>
