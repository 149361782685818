import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { EmployerGuard } from '@core/guards/employer.guard';
import { LoggedInGuard } from '@core/guards/logged-in.guard';
import { OnboardingGuard } from '@core/guards/onboarding.guard';
import { SelectExistingEmployerGuard } from '@core/guards/select-existing-employer.guard';
import { UserAppPreviewGuard } from '@core/guards/user-app-preview.guard';
import { UserAppPreviewResolver } from '@core/resolvers/user-app-preview.resolver';
import { NotfoundComponent } from '@shared/components/notfound/notfound.component';
import { SelectExistingEmployerComponent } from '@shared/components/select-existing-employer/select-existing-employer.component';
import { CriticalDataLoadingErrorComponentComponent } from '@shared/standalone/critical-data-loading-error-component/critical-data-loading-error-component.component';
import { ManagerAppPreviewPageComponent } from 'src/app/post-login/app-preview/manager-app-preview-page/manager-app-preview-page.component';
import { UserAppPreviewPageComponent } from 'src/app/post-login/app-preview/user-app-preview-page/user-app-preview-page.component';
import { AdminGuard } from './core/guards/admin.guard';
import { DashboardAccessGuard } from './core/guards/dashboard-access.guard';
import { pageRoutes } from './core/models/page-routes.model';
import { CustomTitleStrategy } from './custom-title-strategy';
import { OnboardingComponent } from './post-login/onboarding/onboarding.component';
import { OtpComponent } from './pre-login/otp/otp.component';
import { RegisterComponent } from './pre-login/register/register.component';

import { LoggedInPagesLayoutComponent } from './shared/components/logged-in-pages-layout/logged-in-pages-layout.component';

const routes: Routes = [
  // Public routes
  {
    path: pageRoutes.OTP.path,
    component: OtpComponent,
    title: pageRoutes.OTP.title,
    loadChildren: (): Promise<any> => import('./pre-login/otp/otp.module').then((m) => m.OtpModule),
  },
  {
    path: pageRoutes.CRITICAL_DATA_LOADING_FAILURE.path,
    pathMatch: 'full',
    title: pageRoutes.CRITICAL_DATA_LOADING_FAILURE.title,
    component: CriticalDataLoadingErrorComponentComponent,
  },
  {
    path: pageRoutes.AUTH_EMAIL_VERIFICATION.path,
    loadComponent: () =>
      import('./pre-login/auth-email-verification/auth-email-verification.component').then(
        (m) => m.AuthEmailVerificationComponent
      ),
    title: pageRoutes.AUTH_EMAIL_VERIFICATION.title,
  },

  /** the first entry is the '', the DashboardAccessGuard afterwards if not allowed it will start by the SelectExistingEmployerGuard -> OnboardingGuard -> PermissionMissingGuard  */
  // Routes accessible only for authenticated users
  {
    path: '',
    canMatch: [LoggedInGuard],
    children: [
      // Select existing Employer,
      {
        path: pageRoutes.SELECT_EXISTING_COMPANY.path,
        pathMatch: 'full',
        canMatch: [SelectExistingEmployerGuard],
        component: SelectExistingEmployerComponent,
      },

      // Onboarding routes
      {
        path: pageRoutes.ONBOARDING.path,
        pathMatch: 'full',
        canMatch: [OnboardingGuard],
        component: OnboardingComponent,
        loadChildren: (): Promise<any> =>
          import('./post-login/onboarding/onboarding.module').then((m) => m.OnboardingModule),
      },

      // User App Preview
      {
        path: pageRoutes.USER_APP_PREVIEW.path,
        pathMatch: 'full',
        canActivate: [UserAppPreviewGuard],
        title: pageRoutes.USER_APP_PREVIEW.title,
        component: UserAppPreviewPageComponent,
        resolve: {
          employerDetails: UserAppPreviewResolver,
        },
      },

      // Onboarded routes (=> accessible with a connected employer)
      {
        path: '',
        canMatch: [DashboardAccessGuard, EmployerGuard],
        component: LoggedInPagesLayoutComponent,
        children: [
          // Admin routes
          {
            path: pageRoutes.ADMIN.path,
            canMatch: [AdminGuard],
            children: [
              {
                path: pageRoutes.ADMIN.children.EMPLOYERS.path,
                loadChildren: (): Promise<any> =>
                  import('./post-login/employers/employers.module').then((m) => m.EmployersModule),
              },
              {
                path: pageRoutes.USERS.path,
                loadChildren: (): Promise<any> => import('./post-login/users/users.module').then((m) => m.UsersModule),
              },
              {
                path: pageRoutes.ADMIN.children.EMPLOYERS.children.EMPLOYER_NETWORKS.path,

                loadChildren: (): Promise<any> =>
                  import('./post-login/employers/employers.module').then((m) => m.EmployersModule),
              },
              {
                path: pageRoutes.ADMIN.children.TRIP_INSIGHT.path,
                loadChildren: (): Promise<any> => import('./post-login/trips/trips.module').then((m) => m.TripsModule),
              },
              {
                path: pageRoutes.ADMIN.children.CARPOOL_VALIDATION.path,
                loadChildren: (): Promise<any> =>
                  import('./post-login/carpool-validation/carpool-validation.module').then(
                    (m) => m.CarpoolValidationModule
                  ),
              },
              {
                path: pageRoutes.ADMIN.children.TRANSIT_VALIDATION.path,
                loadChildren: (): Promise<any> =>
                  import('./post-login/transit-validation/transit-validation.module').then(
                    (m) => m.TransitValidationModule
                  ),
              },
              {
                path: pageRoutes.REWARDS.path,
                loadChildren: (): Promise<any> =>
                  import('./post-login/rewards/rewards.module').then((m) => m.RewardsModule),
              },
              {
                path: '',
                redirectTo: pageRoutes.ADMIN.children.EMPLOYERS.path,
                pathMatch: 'full',
              },
            ],
          },

          // Manager routes
          {
            path: '',
            pathMatch: 'full',
            loadChildren: (): Promise<any> =>
              import('./post-login/dashboard/dashboard.module').then((m) => m.DashboardModule),
          },

          {
            path: pageRoutes.REWARDS.path,
            loadChildren: (): Promise<any> =>
              import('./post-login/rewards/rewards.module').then((mod) => mod.RewardsModule),
          },
          {
            path: pageRoutes.USERS.path,
            loadChildren: (): Promise<any> => import('./post-login/users/users.module').then((mod) => mod.UsersModule),
          },
          {
            path: pageRoutes.SETTINGS.path,
            loadChildren: (): Promise<any> =>
              import('./post-login/settings/settings.module').then((mod) => mod.SettingsModule),
          },
          {
            path: pageRoutes.MANAGER_APP_PREVIEW.path,
            component: ManagerAppPreviewPageComponent,
          },
        ],
      },
      // Error 404 route
      {
        path: pageRoutes[404].path,
        title: pageRoutes[404].title,
        component: NotfoundComponent,
      },
      { path: '**', redirectTo: pageRoutes[404].path },
    ],
  },

  // Register / Login routes
  {
    path: '',
    component: RegisterComponent,
    loadChildren: (): Promise<any> => import('./pre-login/register/register.module').then((m) => m.RegisterModule),
  },

  //   Redirect to register
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      // Enable for route debugging:
      // enableTracing: true
    }),
  ],
  exports: [RouterModule],

  providers: [
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy,
    },
  ],
})
export class AppRoutingModule {}
