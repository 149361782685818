/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityConfidence } from './activityConfidence';
import { ActivityType } from './activityType';


export interface ChildTrackingPointModel { 
    activityType?: ActivityType;
    activityConfidence?: ActivityConfidence;
    lat: number;
    long: number;
    speed: number;
    accuracy: number;
    speedAccuracy: number;
    altitude: number;
    heading: number;
    time: string;
}



