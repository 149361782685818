import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationStatusService } from '@core/services/authentication-status/authentication-status.service';
import { TabCommunicationService } from '@core/services/tab-communication.service';
import { take } from 'rxjs';
import { AmplifyService } from '../../core/services/amplify/amplify.service';

@Component({
  selector: 'otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent {
  public otp!: string[];

  constructor(
    private readonly route: ActivatedRoute,
    public readonly amplifyService: AmplifyService,
    private readonly router: Router,
    private readonly tabCommunicationService: TabCommunicationService,
    private readonly authStatusService: AuthenticationStatusService
  ) {
    const data = this.route.snapshot.paramMap.get('code');

    this.otp = data!.split('');

    this.handleOtpCode(data);
  }

  private async handleOtpCode(data: string | null): Promise<void> {
    if (this.amplifyService.cognitoUser && data) {
      await this.amplifyService.sendCustomChallengeAnswer(data);

      // NOTE:
      // The refresh is required to get the most recent jwt token
      // The token payload of the ID-Token otherwise contains email_verified = false (although it should be true) as it was set that way in the Lambda
      this.authStatusService
        .refresh()
        .pipe(take(1))
        .subscribe(() => {
          this.router.navigate(['/']);
          this.tabCommunicationService.otpCodeEntered.postMessage(true);
        });
    }
  }
}
