<section class="bg-light min-h-full w-full content-block py-8 flex flex-col gap-y-8 sm:gap-y-10">
  <div class="flex justify-between items-center">
    <img src="assets/images/brand/pave-logo-horizontal-color.svg" class="h-8 sm:h-10" alt="pave icon" />
    <language-switch></language-switch>
  </div>

  <div
    class="flex-grow grid items-center justify-center relative justify-items-center grid-cols-1"
    [ngClass]="{ 'lg:grid-cols-2 lg:gap-6': isSignup }"
  >
    <signup-trust *ngIf="isSignup" class="hidden lg:block"></signup-trust>

    <div class="relative max-w-[500px] w-full">
      <img class="absolute w-72 -bottom-4 -left-24" src="assets/images/shapes/corn-shape.svg" alt="shape" />
      <div #formWrapper class="shadow-card w-full p-5 sm:p-8 relative z-10 {{ this.animationClasses.join(' ') }}">
        <mat-tab-group class="no-header" [(selectedIndex)]="currentTabIndex">
          <mat-tab>
            <signup-form
              *ngIf="isSignup; else login"
              (signup)="signUpWithEmail($event)"
              [region]="region"
              (toggleLoginSignup)="toggleLoginSignup()"
              (setSessionStorage)="onSetSessionStorage($event)"
            ></signup-form>

            <ng-template #login>
              <login-form
                (login)="loginWithEmail($event)"
                [region]="region"
                (toggleLoginSignup)="toggleLoginSignup()"
                (setSessionStorage)="onSetSessionStorage($event)"
              ></login-form>
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template matTabContent>
              <enter-code
                [email]="lastUsedEmail"
                (enterCode)="onEnterCode($event)"
                (resendCode)="onResendCode()"
              ></enter-code>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>

      <switch-form
        (toggleLoginSignup)="toggleLoginSignup()"
        (backToLoginSignup)="onBack()"
        class="mt-8 block"
        [isSignup]="isSignup"
        [onEnterCodeStep]="lastUsedEmail !== ''"
      ></switch-form>
      <p class="text-center text-gray-dark text-base">© Pave Commute {{ currentDate | date: 'YYYY' }}</p>
    </div>
  </div>
</section>
