<div class="flex flex-col gap-y-10">
  <h2 class="text-xl">{{ 'signup.trustHeadline' | translate }}</h2>

  <div class="flex flex-col gap-y-5">
    <div class="item">
      <mat-icon class="item__icon" fontSet="material-icons-round">check_circle</mat-icon>
      <p class="item__text">{{ 'signup.trustElement1' | translate }}</p>
    </div>
    <div class="item">
      <mat-icon class="item__icon" fontSet="material-icons-round">check_circle</mat-icon>
      <p class="item__text">{{ 'signup.trustElement2' | translate }}</p>
    </div>
    <div class="item">
      <mat-icon class="item__icon" fontSet="material-icons-round">check_circle</mat-icon>
      <p class="item__text">{{ 'signup.trustElement3' | translate }}</p>
    </div>
    <div class="item">
      <mat-icon class="item__icon" fontSet="material-icons-round">check_circle</mat-icon>
      <p class="item__text">{{ 'signup.trustElement4' | translate }}</p>
    </div>
  </div>

  <div class="flex flex-col gap-3">
    <p>{{ 'signup.trustedBy' | translate }}</p>
    <div class="flex items-center gap-9">
      <img class="client" src="assets/images/brand/clients/loreal.svg" alt="loreal logo" />
      <img class="client" src="assets/images/brand/clients/toyota.svg" alt="toyota logo" />
      <img class="client" src="assets/images/brand/clients/wuerth.svg" alt="wuerth industries logo" />
    </div>
  </div>
</div>
