/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiResponseMaileroo } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface DefaultServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     */
    appControllerGetClientIp(extraHttpRequestParams?: any): Observable<object>;

    /**
     * 
     * 
     */
    appControllerGetClientIp_1(extraHttpRequestParams?: any): Observable<object>;

    /**
     * 
     * 
     * @param locale 
     */
    appControllerGetPrivacyUrl(locale: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param locale 
     */
    appControllerGetPrivacyUrl_2(locale: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * Defines status routes for checking up on the server Returns a custom pingdom xml response as defined here: https://www.pingdom.com/blog/new-pingdom-feature-custom-monitoring-type/  Where OK/NOT OK indicates our ability to reach the database.
     */
    appControllerGetStatus(extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * Defines status routes for checking up on the server Returns a custom pingdom xml response as defined here: https://www.pingdom.com/blog/new-pingdom-feature-custom-monitoring-type/  Where OK/NOT OK indicates our ability to reach the database.
     */
    appControllerGetStatus_3(extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param locale 
     */
    appControllerGetTermsUrl(locale: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param locale 
     */
    appControllerGetTermsUrl_4(locale: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param encryptedUsername 
     */
    appControllerUnsubscribeEmail(encryptedUsername: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param encryptedUsername 
     */
    appControllerUnsubscribeEmail_5(encryptedUsername: string, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param email 
     */
    mailerooControllerVerifyEmail(email: string, extraHttpRequestParams?: any): Observable<ApiResponseMaileroo>;

}
