<div class="cdk-dialog w-[600px]">
  <div class="cdk-dialog-header">
    <h1 class="cdk-dialog-header__title text-center">{{ 'signup.region.dialogTitle' | translate }}</h1>
  </div>
  <div class="cdk-dialog-body flex gap-5 justify-center">
    <ng-container *ngFor="let region of regions | keyvalue">
      <div
        mat-ripple
        [ngClass]="region.value === selectedRegion ? 'border-accent' : 'border-gray-light'"
        class="rounded-2xl border-[1.5px] w-[200px] hover:border-accent cursor-pointer flex justify-center items-center transition-animation"
        (click)="onSelectRegion(region.value)"
      >
        <div class="p-5 flex flex-col gap-y-2">
          <div class="flex flex-col items-center">
            <img class="w-12" [src]="regionService.getRegionImageUrl(region.value)" alt="emoji" />
          </div>
          <div class="font-redHatTextSemibold text-center">
            <span>{{ 'signup.region.' + region.value | translate }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="cdk-dialog-actions justify-center">
    <button mat-flat-button color="primary" (click)="onContinue()" [disabled]="selectedRegion === undefined">
      {{ 'signup.region.dialogButtonText' | translate }} <mat-icon iconPositionEnd>{{ 'arrow_forward' }}</mat-icon>
    </button>
  </div>
</div>
