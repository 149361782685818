import { Component } from '@angular/core';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { StripeSubscriptionManagementWrapperService } from '@core/services/stripe-subscription-management/stripe-subscription-management-wrapper.service';
import { SubscriptionStatusEnum } from '@swagger/index';

@Component({
  selector: 'payment-pending-banner',
  templateUrl: './payment-pending-banner.component.html',
  styleUrls: ['./payment-pending-banner.component.scss'],
})
export class PaymentPendingBannerComponent {
  public subscriptionStatus = SubscriptionStatusEnum;

  constructor(
    public readonly employerWrapperService: EmployerWrapperService,
    private readonly stripeSubscriptionManagementWrapperService: StripeSubscriptionManagementWrapperService
  ) {}

  public onUpgradeNow(): void {
    this.stripeSubscriptionManagementWrapperService.openCustomerPortal();
  }
}
