<div class="w-screen h-screen bg-light bg-opacity-95 flex items-center justify-center overflow-auto">
  <div class="max-w-3xl w-full p-6 text-center flex flex-col gap-y-10 items-center">
    <h1 class="text-4xl">{{ 'subscriptionCancelled.title' | translate }}</h1>
    <p class="text-base">
      {{ 'subscriptionCancelled.subtitle' | translate }}
    </p>

    <p class="font-redHatTextSemibold">{{ 'subscriptionCancelled.missingFeatures' | translate }}</p>

    <div class="max-w-md text-left flex flex-col gap-y-6">
      <div class="feature">
        <img class="feature__image" src="assets/images/emojis/1f4ca.svg" alt="emoji" />
        <p>{{ 'subscriptionCancelled.feature1' | translate }}</p>
      </div>
      <div class="feature">
        <img class="feature__image" src="assets/images/emojis/1f381.svg" alt="emoji" />
        <p>{{ 'subscriptionCancelled.feature2' | translate }}</p>
      </div>
      <div class="feature">
        <img class="feature__image" src="assets/images/emojis/1f680.svg" alt="emoji" />
        <p>{{ 'subscriptionCancelled.feature3' | translate }}</p>
      </div>
    </div>

    <p>
      {{ 'subscriptionCancelled.questionsLeft' | translate
      }}<a class="text-primary font-redHatTextSemibold" [href]="'mailto:' + contactEmail"
        >{{ 'general.contactUs' | translate }}.</a
      >
    </p>

    <div>
      <button class="block mx-auto" (click)="onUpgradeNow()" mat-flat-button color="primary">
        {{ 'general.upgradeNow' | translate }}
      </button>
    </div>
  </div>
</div>
