import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'backendTranslate',
})
export class BackendTranslatePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  public transform(input: any): string {
    const currLang = this.translateService.getBrowserLang();
    const defaultLang = this.translateService.getDefaultLang();
    const record = input as Record<string, string>;

    let value;

    // Priority:
    // 1) use translation of current browser language
    // 2) use translation of default language translation (=en)
    // 3) use translation of first entry of record as fallback

    if (currLang && currLang in record) {
      value = record[currLang];
    } else if (defaultLang in record) {
      value = record[defaultLang];
    } else {
      value = Object.values(record)[0] ?? '';
    }

    return value;
  }
}
