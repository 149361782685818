<div [formGroup]="formGroup">
  <mat-form-field class="mt-0">
    <mat-select
      placeholder="
          {{ 'commuteType.selectCommuteDirection.' + (multiple ? 'placeholder' : 'placeholderPlural') | translate }}
        "
      name="commuteType"
      matInput
      [formControlName]="controlName"
      [multiple]="multiple"
    >
      <ng-container *ngFor="let item of commuteTypes">
        <mat-option [value]="item">
          {{ 'commuteType.' + item | translate }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
