import { Region } from '@core/enums/region.enum';
import { mapStyles } from './map-styles';

function getCenter(region: Region): google.maps.LatLngLiteral {
  const center = {
    lat: region === Region.EU ? 48.208176 : 34.059761,
    lng: region === Region.EU ? 16.373819 : -118.276802,
  };

  return center;
}

export function defaultMapOptions(region: Region): google.maps.MapOptions {
  return {
    streetViewControl: false,
    mapTypeControl: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoom: 10,
    zoomControl: true,
    zoomControlOptions: {
      position: 9,
    },
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: 9,
    },
    styles: mapStyles,
    center: getCenter(region),
  };
}

export function defaultHybridMapOptions(region: Region): google.maps.MapOptions {
  return {
    streetViewControl: false,
    mapTypeControl: false,
    mapTypeId: google.maps.MapTypeId.HYBRID,
    zoom: 10,
    tilt: 0,
    zoomControl: true,
    zoomControlOptions: {
      position: 9,
    },
    rotateControl: false,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: 9,
    },
    center: getCenter(region),
  };
}
