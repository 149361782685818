import { Injectable } from '@angular/core';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Amplify, Hub } from '@aws-amplify/core';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Observable, Subject, filter } from 'rxjs';

import { MixpanelEvent } from '@core/services/mixpanel/mixpanel-event.enum';
import { MixpanelWrapperService } from '@core/services/mixpanel/mixpanel-wrapper.service';
import { PaveWebAppEventService } from '@core/services/pave-web-app-event/pave-web-app-event.service';
import { DeepLinkAction } from '@shared/enums/deep-link-action.enum';
import { environment } from 'src/environments/environment';
import { amplifyConfigDev } from '../../../amplify/config/amplify-config.dev';
import { amplifyConfigLocal } from '../../../amplify/config/amplify-config.local';
import { amplifyConfigProd } from '../../../amplify/config/amplify-config.prod';
import { amplifyConfigProdEu } from '../../../amplify/config/amplify-config.prod_eu';
import { amplifyConfigQa } from '../../../amplify/config/amplify-config.qa';
import { Region } from '../../../amplify/config/enum/region.enum';
import { AmplifyConfig } from '../../../amplify/config/interfaces/amplify-config.interface';
import { Environment } from '../../../shared/enums/environment.enum';
import { LocalStorageKey } from '../../../shared/enums/local-storage-key.enum';
import { AuthenticationStatusService } from '../authentication-status/authentication-status.service';
import { LoadingService } from '../loading/loading.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class AmplifyService {
  public cognitoUser?: CognitoUser;

  private hubEvent$ = new Subject<{ event: string; data: any }>();

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly toastService: ToastService,
    private readonly loadingService: LoadingService,
    private readonly authStatusService: AuthenticationStatusService,
    private readonly mixpanelWrapperService: MixpanelWrapperService,
    private readonly paveWebAppEventsService: PaveWebAppEventService
  ) {}

  public init(): void {
    Amplify.configure(this.getAmplifyConfig());
    this.listenToHub();
    this.rehydrateCognitoUser();
  }

  private getAmplifyConfig(): AmplifyConfig {
    if (environment.name === Environment.local && environment.region === 'us') {
      return amplifyConfigLocal;
    }

    if (environment.name === Environment.dev && environment.region === 'us') {
      return amplifyConfigDev;
    }

    if (environment.name === Environment.qa && environment.region === 'us') {
      return amplifyConfigQa;
    }

    if (environment.name === Environment.prod && environment.region === 'us') {
      return amplifyConfigProd;
    }

    if (environment.name === Environment.prod && environment.region === 'eu') {
      return amplifyConfigProdEu;
    }

    throw Error(`no amplify config found for ${environment.name} and ${environment.region}`);
  }

  public getAwsRegion(): Region {
    return this.getAmplifyConfig().Auth.region;
  }

  private listenToHub(): void {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      this.hubEvent$.next({ event, data });

      switch (event) {
        case 'signIn':
          this.authStatusService.onLogin({
            accessToken: data.signInUserSession.accessToken.jwtToken,
            idToken: data.signInUserSession.idToken.jwtToken,
            refreshToken: data.signInUserSession.refreshToken.token,
          });
          break;

        case 'signOut':
          this.authStatusService.onLogout();
          break;

        case 'oAuthSignOut':
          // federated sign outs do not trigger the auth sign out hub event because of the default page reload for federated sign ins / outs
          // this means storage data is not cleared correctly
          // we have manually clean the values here, otherwise the the logged-in-guard will try to log the user back in
          this.authStatusService.onLogout();
          break;

        default:
          break;
      }
    });
  }

  public onHubEvent(eventType: string): Observable<{
    event: string;
    data: any;
  }> {
    return this.hubEvent$.asObservable().pipe(filter(({ event }) => event === eventType));
  }

  /**
   * We need to rehydrate the cognito user to enable deeplink functionality.
   * Without rehydrating the cognito user we don't have access to the user session
   * in the new tab when the user clicks on a deeplink.
   */
  private rehydrateCognitoUser(): void {
    const cognitoUserFromSession = this.localStorageService.getObjectItem<any>(LocalStorageKey.cognitoUser);

    if (!cognitoUserFromSession) {
      return;
    }

    const poolData = {
      UserPoolId: this.getAmplifyConfig().Auth.userPoolId,
      ClientId: this.getAmplifyConfig().Auth.userPoolWebClientId,
    };

    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: cognitoUserFromSession.username,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    (cognitoUser as any).Session = cognitoUserFromSession.session;

    cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

    this.cognitoUser = cognitoUser;
  }

  public async signInWithEmail(email: string): Promise<void> {
    try {
      this.loadingService.startLoading();
      const cognitoUser = await Auth.signIn(email);

      this.saveCognitoUserResponse(cognitoUser);
    } catch (error) {
      this.toastService.show(true, 'login.signInWithEmailError');
    } finally {
      this.loadingService.endLoading();
    }
  }

  private saveCognitoUserResponse(user: CognitoUser): void {
    this.cognitoUser = user;
    this.localStorageService.setNonPrimitiveItem(LocalStorageKey.cognitoUser, {
      username: user.getUsername(),
      session: (user as any).Session,
    });
  }

  public async sendCustomChallengeAnswer(otp: string): Promise<void> {
    try {
      this.loadingService.startLoading();

      const cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, otp);

      if (cognitoUser.signInUserSession === null) {
        // wrong code was sent and user was not signed in
        this.saveCognitoUserResponse(cognitoUser);
        throw Error();
      }

      this.mixpanelWrapperService.track(MixpanelEvent.AUTHENTICATED);
    } catch (error) {
      this.toastService.show(true, 'login.signInWithCodeError');
      throw error;
    } finally {
      this.loadingService.endLoading();
      this.localStorageService.removeItem(LocalStorageKey.cognitoUser);
    }
  }

  public async socialSignIn(provider: CognitoHostedUIIdentityProvider, customState?: string): Promise<void> {
    await this.logoutCognitoUser();

    Auth.federatedSignIn({ provider, customState });
  }

  public async logoutCognitoUser(): Promise<void> {
    try {
      // needed to have all the sign in options again (e.g. several google accounts)
      const user: CognitoUser = await Auth.currentAuthenticatedUser();

      user.signOut();
    } catch {
      // nothing to do here
    }
  }

  public async logout(logoutReloadPath?: string): Promise<void> {
    this.authStatusService.logoutReloadPath = logoutReloadPath;
    this.paveWebAppEventsService.sendEvent(DeepLinkAction.logout, '');
    await Auth.signOut();
  }

  /**
   * Given existing credentials this will log a user into Amplify
   * https://github.com/aws-amplify/amplify-js/issues/8632#issuecomment-1491513762
   *
   * @param authData
   * @param userConfig
   * @returns
   */
  public async authorizeByToken(authData: {
    idToken: string;
    accessToken: string;
    refreshToken: string;
  }): Promise<CognitoUser | any> {
    // create a CognitoAccessToken using the response accessToken
    const AccessToken = new CognitoAccessToken({
      AccessToken: authData.accessToken,
    });

    // create a CognitoIdToken using the response idToken
    const IdToken = new CognitoIdToken({
      IdToken: authData.idToken,
    });

    // create a RefreshToken using the response refreshToken
    const RefreshToken = new CognitoRefreshToken({
      RefreshToken: authData.refreshToken,
    });

    // create a session object with all the tokens
    const sessionData = {
      IdToken,
      AccessToken,
      RefreshToken,
    };

    // create the CognitoUserSession using the sessionData
    const session = new CognitoUserSession(sessionData);

    // create an object with the UserPoolId and ClientId
    const poolData = {
      UserPoolId: this.getAmplifyConfig().Auth.userPoolId,
      ClientId: this.getAmplifyConfig().Auth.userPoolWebClientId,
    };

    // pass the poolData object to CognitoUserPool
    const userPool = new CognitoUserPool(poolData);

    // create an object containing the username and user pool.
    // You can get the username from CognitoAccessToken object
    // we created above.
    const userData = {
      Username: AccessToken.payload.username,
      Pool: userPool,
    };

    // create a cognito user using the userData object
    const cognitoUser = new CognitoUser(userData);

    // set the cognito user session w/ the CognitoUserSession
    cognitoUser.setSignInUserSession(session);

    // get the Amplify authenticated user
    return Auth.currentAuthenticatedUser();
  }
}
