import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { InitialsHelperService } from '@core/services/initials-helper.service';
import { SelectableEmployerDetails } from '@shared/interfaces/selectable-employer-details.interface';
import { SharedModule } from '@shared/shared.module';
import { ApiResponseSelectableForDashboardEmployer } from '@swagger/index';

@Component({
  selector: 'request-access-employer-list',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './request-access-employer-list.component.html',
  styleUrl: './request-access-employer-list.component.scss',
})
export class RequestAccessEmployerListComponent implements OnChanges {
  @Input({ required: true }) public employerDetailsResponse!: ApiResponseSelectableForDashboardEmployer[];

  @Output()
  public requestAccessEvent = new EventEmitter<string>();

  public employerDetails: SelectableEmployerDetails[] = [];

  constructor(private readonly initialsHelperService: InitialsHelperService) {}

  public onRequestAccess(employerId: string): void {
    this.requestAccessEvent.emit(employerId);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.employerDetailsResponse) {
      this.initDetails();
    }
  }

  private initDetails(): void {
    this.employerDetailsResponse.forEach((detail) => {
      this.employerDetails.push({
        employerLogoUrl: detail.logoUrl ?? undefined,
        employerInitials: this.initialsHelperService.createInitials(detail.name),
        employerName: detail.name,
        employerId: detail.id,
        connectedUsers: detail.amountOfConnectedUsers,
      });
    });
  }
}
