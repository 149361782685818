import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarMenuItem } from '../../interfaces/sidebar-menu-item.interface';

@Component({
  selector: 'sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent {
  @Input() public menuTitle!: string;

  @Input() public menuItems!: SidebarMenuItem[];

  @Input() public currentUrl!: string;

  @Input() public showDownloadAppHint = false;

  constructor(private readonly router: Router) {}

  public onSidebarMenuClick(menuItem: SidebarMenuItem): void {
    if (menuItem.link !== undefined) {
      this.router.navigate([menuItem.link]);

      return;
    }

    if (menuItem.url !== undefined) {
      window.open(menuItem.url, menuItem.target ?? '');
    }
  }
}
