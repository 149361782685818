<div class="cdk-dialog w-[600px]">
  <img src="assets/images/emojis/1f4e8.svg" class="w-12 h-12 mx-auto" alt="warn emoji" />

  <div class="cdk-dialog-header">
    <h1 class="cdk-dialog-header__title text-center">
      {{ 'verifyEmail.blockingDialog.title' | translate }}
    </h1>
  </div>

  <div class="cdk-dialog-body px-8 text-center">
    <p>
      {{ 'verifyEmail.blockingDialog.description' | translate }} <mark>{{ data.email }}</mark>
    </p>
  </div>

  <div class="cdk-dialog-actions justify-center">
    <button mat-flat-button (click)="onResendEmail()">
      {{ 'verifyEmail.blockingDialog.resend' | translate }}
    </button>
    <button mat-flat-button color="primary" (click)="onClose()">{{ 'general.close' | translate }}</button>
  </div>
  <div class="cdk-dialog-actions justify-center" *ngIf="isAuthenticated">
    <button class="text-gray-dark text-sm underline" mat-flat-button (click)="onUpdateEmail()">
      {{ 'verifyEmail.blockingDialog.updateEmail' | translate }}
    </button>
  </div>
</div>
