/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestAllUsersListPagination } from '../model/models';
import { ApiRequestCountUsersByWorkAddress } from '../model/models';
import { ApiRequestDeleteUsers } from '../model/models';
import { ApiRequestRegenerateRoutesByEmployerId } from '../model/models';
import { ApiRequestUserUpdateModel } from '../model/models';
import { ApiRequestUsersListByEmployerPagination } from '../model/models';
import { ApiResponseDisplayUser } from '../model/models';
import { ApiResponseSuccessModel } from '../model/models';
import { ApiResponseUserModel } from '../model/models';
import { ApiResponseUserStatistics } from '../model/models';
import { ApiResponseUsersListPagination } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UserServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestCountUsersByWorkAddress 
     */
    userControllerCountUsersByWorkAddress(apiRequestCountUsersByWorkAddress: ApiRequestCountUsersByWorkAddress, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestCountUsersByWorkAddress 
     */
    userControllerCountUsersByWorkAddress_1(apiRequestCountUsersByWorkAddress: ApiRequestCountUsersByWorkAddress, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     */
    userControllerDelete(extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param username 
     */
    userControllerDeleteAsAdmin(username: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param username 
     */
    userControllerDeleteAsAdmin_2(username: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * returns the usernames where the deletion failed
     * @param apiRequestDeleteUsers 
     */
    userControllerDeleteByUsernames(apiRequestDeleteUsers: ApiRequestDeleteUsers, extraHttpRequestParams?: any): Observable<Array<string>>;

    /**
     * 
     * returns the usernames where the deletion failed
     * @param apiRequestDeleteUsers 
     */
    userControllerDeleteByUsernames_3(apiRequestDeleteUsers: ApiRequestDeleteUsers, extraHttpRequestParams?: any): Observable<Array<string>>;

    /**
     * 
     * 
     */
    userControllerDelete_4(extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     */
    userControllerGet(extraHttpRequestParams?: any): Observable<ApiResponseUserModel>;

    /**
     * 
     * 
     * @param employerId 
     */
    userControllerGetAllUsersByEmployerWithStatistics(employerId: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserStatistics>>;

    /**
     * 
     * 
     * @param employerId 
     */
    userControllerGetAllUsersByEmployerWithStatistics_5(employerId: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserStatistics>>;

    /**
     * 
     * 
     * @param apiRequestAllUsersListPagination 
     */
    userControllerGetAllUsersWithPagination(apiRequestAllUsersListPagination: ApiRequestAllUsersListPagination, extraHttpRequestParams?: any): Observable<ApiResponseUsersListPagination>;

    /**
     * 
     * 
     * @param apiRequestAllUsersListPagination 
     */
    userControllerGetAllUsersWithPagination_6(apiRequestAllUsersListPagination: ApiRequestAllUsersListPagination, extraHttpRequestParams?: any): Observable<ApiResponseUsersListPagination>;

    /**
     * 
     * 
     * @param username 
     */
    userControllerGetOne(username: string, extraHttpRequestParams?: any): Observable<ApiResponseUserModel>;

    /**
     * 
     * 
     * @param username 
     */
    userControllerGetOne_7(username: string, extraHttpRequestParams?: any): Observable<ApiResponseUserModel>;

    /**
     * 
     * 
     * @param username 
     * @param employerId 
     */
    userControllerGetUserByUsernameWithStatistics(username: string, employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseUserStatistics>;

    /**
     * 
     * 
     * @param username 
     * @param employerId 
     */
    userControllerGetUserByUsernameWithStatistics_8(username: string, employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseUserStatistics>;

    /**
     * 
     * 
     * @param apiRequestUsersListByEmployerPagination 
     */
    userControllerGetUsersByEmployerWithPagination(apiRequestUsersListByEmployerPagination: ApiRequestUsersListByEmployerPagination, extraHttpRequestParams?: any): Observable<ApiResponseUsersListPagination>;

    /**
     * 
     * 
     * @param apiRequestUsersListByEmployerPagination 
     */
    userControllerGetUsersByEmployerWithPagination_9(apiRequestUsersListByEmployerPagination: ApiRequestUsersListByEmployerPagination, extraHttpRequestParams?: any): Observable<ApiResponseUsersListPagination>;

    /**
     * 
     * 
     */
    userControllerGetUsersConnectedToSameNetworks(extraHttpRequestParams?: any): Observable<Array<ApiResponseDisplayUser>>;

    /**
     * 
     * 
     */
    userControllerGetUsersConnectedToSameNetworks_10(extraHttpRequestParams?: any): Observable<Array<ApiResponseDisplayUser>>;

    /**
     * 
     * 
     */
    userControllerGet_11(extraHttpRequestParams?: any): Observable<ApiResponseUserModel>;

    /**
     * 
     * 
     */
    userControllerGet_12(extraHttpRequestParams?: any): Observable<ApiResponseUserModel>;

    /**
     * 
     * 
     */
    userControllerGet_13(extraHttpRequestParams?: any): Observable<ApiResponseUserModel>;

    /**
     * 
     * 
     * @param email 
     */
    userControllerIsEmailRegistered(email: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param email 
     */
    userControllerIsEmailRegistered_14(email: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param domain 
     */
    userControllerIsPrivateDomainAlreadyUsed(domain: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param domain 
     */
    userControllerIsPrivateDomainAlreadyUsed_15(domain: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param apiRequestRegenerateRoutesByEmployerId 
     */
    userControllerRegenerateRoutesByEmployerId(apiRequestRegenerateRoutesByEmployerId: ApiRequestRegenerateRoutesByEmployerId, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestRegenerateRoutesByEmployerId 
     */
    userControllerRegenerateRoutesByEmployerId_16(apiRequestRegenerateRoutesByEmployerId: ApiRequestRegenerateRoutesByEmployerId, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestUserUpdateModel 
     */
    userControllerUpdate(apiRequestUserUpdateModel: ApiRequestUserUpdateModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestUserUpdateModel 
     */
    userControllerUpdate_17(apiRequestUserUpdateModel: ApiRequestUserUpdateModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

}
