export enum DialogId {
  REWARD_DIALOG = 'reward-dialog',
  RAFFLE_REWARD_DIALOG = 'raffle-reward-dialog',
  TANGO_REWARD_DIALOG = 'tango-reward-dialog',
  EMPLOYER_REWARD_DIALOG = 'employer-reward-dialog',
  EDIT_EMPLOYER_DIALOG = 'edit-employer-dialog',
  DESKTOP_OPTIMIZED_DIALOG = 'desktop-optimized-dialog',
  CHECKOUT_MOBILE_APP_DIALOG = 'checkout-mobile-app-dialog',
  SUBSCRIPTION_EXPIRED_DIALOG = 'subscription-expired-dialog',
  UPDATE_COINS_DIALOG = 'update-coins-dialog',
  DESTINATION_ADDRESS_DIALOG = 'destination-address-dialog',
  CONFIRM_DIALOG = 'confirm-dialog',
  AUTH_EMAIL_VERIFICATION_SUCCESS_DIALOG = 'auth-email-verification-success-dialog',
  AUTH_EMAIL_VERIFICATION_EXPIRED_DIALOG = 'auth-email-verification-expired-dialog',
  AUTH_EMAIL_VERIFICATION_BLOCK_DIALOG = 'auth-email-verification-block-dialog',
  AUTH_EMAIL_VERIFICATION_UPDATE_EMAIL_DIALOG = 'auth-email-verification-update-email-dialog',
  WORK_LOCATION_ADDRESS_HEATMAP_DIALOG = 'work-location-address-heatmap-dialog',
  FIRST_TRACKED_COMMUTE_DIALOG = 'first-tracked-commute-dialog',
  EDIT_EMPLOYER_NETWORK_DIALOG = 'edit-employer-network-dialog',
  GLOBAL_REWARDS_EXPLANATION_DIALOG = 'global-rewards-explanation-dialog',
  ADD_NEW_BENEFIT_DIALOG = 'add-new-benefit-dialog',
  ADD_TANGO_REWARD_STYLE_CTA_DIALOG = 'add-tango-reward-style-cta-dialog',
  RAW_DATA_DIALOG = 'raw-data-dialog',
  EDIT_IMAGE_DIALOG = 'edit-image-dialog',
  REGION_DIALOG = 'region-dialog',
  IMPORT_USER_DIALOG = 'import-user-dialog',
  EDIT_USER_DIALOG = 'edit-user-dialog',
  SELECT_USERS_DIALOG = 'select-users-dialog',
  EXPORT_BENEFIT_DIALOG = 'export-benefit-dialog',
  SELECT_EXISTING_EMPLOYER_DIALOG = 'select-existing-employer-dialog',
  GET_STARTED_DIALOG = 'get-started-dialog',
  INVITE_USERS_DIALOG = 'invite-users-dialog',
  DELETE_EMPLOYER_DIALOG = 'delete-employer-dialog',
  SUCCESS_REQUEST_EMPLOYER_DELETION_DIALOG = 'success-request-employer-deletion-dialog',
}
