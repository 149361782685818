import { Component, Input, OnInit } from '@angular/core';
import { Region } from '@core/enums/region.enum';
import { RegionService } from '@core/services/region.service';

@Component({
  selector: 'region-switch',
  templateUrl: './region-switch.component.html',
  styleUrls: ['./region-switch.component.scss'],
})
export class RegionSwitchComponent implements OnInit {
  public regions = Region;

  @Input() public selectedRegion!: Region;

  constructor(public readonly regionService: RegionService) {}

  public ngOnInit(): void {
    this.selectedRegion = this.regionService.getRegion();
  }

  public selectRegion(region: Region): void {
    this.selectedRegion = region;
    this.regionService.switchRegion(region);
  }
}
