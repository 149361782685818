import { Injectable } from '@angular/core';
import { TripModeEnum } from '../../../../api';

@Injectable({
  providedIn: 'root',
})
export class CommuteModeHelperService {
  public getSustainableCommuteModes(): TripModeEnum[] {
    const sustainableCommuteModes: TripModeEnum[] = [];

    Object.values(TripModeEnum).forEach((commuteMode) => {
      if (commuteMode === TripModeEnum.car) {
        return;
      }

      sustainableCommuteModes.push(commuteMode);
    });

    return sustainableCommuteModes;
  }

  public getTripModeEnumSortedByAlphabeticalOrder(): TripModeEnum[] {
    return Object.values(TripModeEnum).sort((modeA, modeB) =>
      TripModeEnum[modeA as keyof typeof TripModeEnum].localeCompare(TripModeEnum[modeB as keyof typeof TripModeEnum])
    );
  }
}
