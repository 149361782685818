import { Injectable } from '@angular/core';
import { catchError, map, Observable, ReplaySubject, tap } from 'rxjs';

import {
  ApiRequestApproveLinkRequestModel,
  ApiRequestDisconnectUser,
  ApiRequestInviteUsers,
  ApiRequestUpdateUserRole,
  ApiResponseNotInvitedUsers,
  ApiResponseSuccessModel,
  ChildConnectedUser,
  ConnectedUserRoleEnum,
  EmployerConnectService,
  EmployerInviteService,
  EmployerUserManagementService,
} from '../../../api';
import { EmployerWrapperService } from '../employer/employer-wrapper.service';
import { HttpHelperService } from '../http-helper.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class EmployerConnectWrapperService {
  private pendingJoinRequests$: ReplaySubject<ChildConnectedUser[]> = new ReplaySubject();

  constructor(
    private readonly employerWrapperService: EmployerWrapperService,
    private readonly employerUserManagementService: EmployerUserManagementService,
    private readonly employerInviteService: EmployerInviteService,
    private readonly toastService: ToastService,
    private readonly httpHelperService: HttpHelperService,
    private readonly employerConnectService: EmployerConnectService
  ) {}

  public updateUserRole(role: ConnectedUserRoleEnum, username: string, employerId?: string): Observable<boolean> {
    const request: ApiRequestUpdateUserRole = {
      employerId: employerId || this.employerWrapperService.employerId,
      role,
      username,
    };

    return this.employerUserManagementService.employerUserManagementControllerUpdateUserRole(request).pipe(
      catchError((error) => {
        this.toastService.show(true, 'user.editUserFailure');
        throw error;
      }),
      map((result) => {
        if (result) {
          this.toastService.show(false, 'user.editUserSuccess');
        } else {
          this.toastService.show(true, 'user.editUserFailure');
        }

        return result;
      })
    );
  }

  public disconnectUser(username: string, employerId?: string): Observable<ApiResponseSuccessModel> {
    const request: ApiRequestDisconnectUser = {
      employerId: employerId || this.employerWrapperService.employerId,
      username,
    };

    return this.employerUserManagementService.employerUserManagementControllerDisconnectUser(request).pipe(
      catchError((error) => {
        this.toastService.show(true, 'user.disconnectUserFailure');
        throw error;
      }),
      map((result) => {
        if (result.success) {
          this.toastService.show(false, 'user.disconnectUserSuccess');
        } else {
          this.toastService.show(true, 'user.disconnectUserFailure');
        }

        return result;
      })
    );
  }

  public getPendingJoinRequestStream(): Observable<ChildConnectedUser[]> {
    return this.pendingJoinRequests$.asObservable();
  }

  public getPendingJoinRequests(silentLoading = false): Observable<ChildConnectedUser[]> {
    const options = this.httpHelperService.getDefaultBypassLoadingOptions(silentLoading);

    return this.employerUserManagementService
      .employerUserManagementControllerGetPendingJoinRequests(
        this.employerWrapperService.employerId,
        'body',
        undefined,
        options
      )
      .pipe(
        catchError((error) => {
          this.toastService.show(true, 'user.getPendingJoinRequestsFailure');
          throw error;
        }),
        tap((pendingJoinRequests: ChildConnectedUser[]) => this.pendingJoinRequests$.next(pendingJoinRequests))
      );
  }

  public approveUserConnection(email: string, employerId: string): Observable<ApiResponseSuccessModel> {
    const request: ApiRequestApproveLinkRequestModel = {
      email,
      employerId,
    };

    return this.employerUserManagementService.employerUserManagementControllerApproveUserConnection(request).pipe(
      catchError((error) => {
        this.toastService.show(true, 'user.approveUserConnectionFailure');
        throw error;
      }),
      map((result) => {
        if (result.success) {
          this.toastService.show(false, 'user.approveUserConnectionSuccess');
        } else {
          this.toastService.show(true, 'user.approveUserConnectionFailure');
        }

        return result;
      })
    );
  }

  public denyUserConnection(email: string, employerId: string): Observable<ApiResponseSuccessModel> {
    const request: ApiRequestApproveLinkRequestModel = {
      email,
      employerId,
    };

    return this.employerUserManagementService.employerUserManagementControllerRejectUserConnection(request).pipe(
      catchError((error) => {
        this.toastService.show(true, 'user.denyUserConnectionFailure');
        throw error;
      }),
      map((result) => {
        if (result.success) {
          this.toastService.show(false, 'user.denyUserConnectionSuccess');
        } else {
          this.toastService.show(true, 'user.denyUserConnectionFailure');
        }

        return result;
      })
    );
  }

  public importUsers(file: Blob): Observable<boolean> {
    return this.employerInviteService
      .employerInviteControllerImportUsers(this.employerWrapperService.employerId, file)
      .pipe(
        catchError((error) => {
          this.toastService.show(true, 'user.importUsers.importFailure');
          throw error;
        }),
        map((result) => {
          if (result) {
            this.toastService.show(false, 'user.importUsers.importSuccess');
          } else {
            this.toastService.show(true, 'user.importUsers.importFailure');
          }

          return result;
        })
      );
  }

  public inviteUsers(emails: Array<string>, role: ConnectedUserRoleEnum): Observable<ApiResponseNotInvitedUsers> {
    const request: ApiRequestInviteUsers = {
      employerId: this.employerWrapperService.employerId,
      usersToInvite: emails.map((email) => ({
        email,
        role,
      })),
    };

    return this.employerInviteService.employerInviteControllerInviteUsers(request).pipe(
      catchError((error) => {
        this.toastService.show(true, 'user.inviteUsersFailure');
        throw error;
      })
    );
  }

  public joinCompanyViaDashboard(employerId: string): Observable<any> {
    return this.employerConnectService.employerConnectControllerJoinCompanyViaDashboard(employerId).pipe(
      catchError((error) => {
        this.toastService.show(true, 'user.joinEmployerFailure');
        throw error;
      })
    );
  }
}
