/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestManuallyReward } from '../model/models';
import { ApiRequestPointHistoryWithPagination } from '../model/models';
import { ApiRequestRedeemModel } from '../model/models';
import { ApiRequestResetAccountBalance } from '../model/models';
import { ApiRequestUserReferredModel } from '../model/models';
import { ApiResponseCSVReward } from '../model/models';
import { ApiResponsePointHistoryWithPagination } from '../model/models';
import { ApiResponseRedeemModel } from '../model/models';
import { ApiResponseSuccessModel } from '../model/models';
import { ApiResponseUserAccountBalanceModel } from '../model/models';
import { ApiResponseUserTransactionsExport } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface RewardsServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     */
    rewardsControllerGetAccountBalance(extraHttpRequestParams?: any): Observable<ApiResponseUserAccountBalanceModel>;

    /**
     * 
     * 
     */
    rewardsControllerGetAccountBalance_1(extraHttpRequestParams?: any): Observable<ApiResponseUserAccountBalanceModel>;

    /**
     * 
     * 
     * @param apiRequestPointHistoryWithPagination 
     */
    rewardsControllerGetPointsHistoryWithPagination(apiRequestPointHistoryWithPagination: ApiRequestPointHistoryWithPagination, extraHttpRequestParams?: any): Observable<ApiResponsePointHistoryWithPagination>;

    /**
     * 
     * 
     * @param apiRequestPointHistoryWithPagination 
     */
    rewardsControllerGetPointsHistoryWithPagination_2(apiRequestPointHistoryWithPagination: ApiRequestPointHistoryWithPagination, extraHttpRequestParams?: any): Observable<ApiResponsePointHistoryWithPagination>;

    /**
     * 
     * 
     * @param username 
     */
    rewardsControllerGetSpecificUserAccountBalance(username: string, extraHttpRequestParams?: any): Observable<ApiResponseUserAccountBalanceModel>;

    /**
     * 
     * 
     * @param username 
     */
    rewardsControllerGetSpecificUserAccountBalance_3(username: string, extraHttpRequestParams?: any): Observable<ApiResponseUserAccountBalanceModel>;

    /**
     * 
     * 
     * @param employerId 
     * @param rewardStyleConfigId 
     * @param from 
     * @param to 
     */
    rewardsControllerGetTransactions(employerId: string, rewardStyleConfigId: string, from: string, to: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserTransactionsExport>>;

    /**
     * 
     * 
     * @param employerId 
     * @param rewardStyleConfigId 
     * @param from 
     * @param to 
     */
    rewardsControllerGetTransactions_4(employerId: string, rewardStyleConfigId: string, from: string, to: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseUserTransactionsExport>>;

    /**
     * 
     * 
     * @param username 
     * @param apiRequestManuallyReward 
     */
    rewardsControllerManuallyReward(username: string, apiRequestManuallyReward: ApiRequestManuallyReward, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param username 
     * @param apiRequestManuallyReward 
     */
    rewardsControllerManuallyReward_5(username: string, apiRequestManuallyReward: ApiRequestManuallyReward, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestRedeemModel 
     */
    rewardsControllerRedeem(apiRequestRedeemModel: ApiRequestRedeemModel, extraHttpRequestParams?: any): Observable<ApiResponseRedeemModel>;

    /**
     * 
     * 
     * @param apiRequestRedeemModel 
     */
    rewardsControllerRedeem_6(apiRequestRedeemModel: ApiRequestRedeemModel, extraHttpRequestParams?: any): Observable<ApiResponseRedeemModel>;

    /**
     * 
     * 
     * @param apiRequestResetAccountBalance 
     */
    rewardsControllerResetAccountBalanceByEmployerId(apiRequestResetAccountBalance: ApiRequestResetAccountBalance, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestResetAccountBalance 
     */
    rewardsControllerResetAccountBalanceByEmployerId_7(apiRequestResetAccountBalance: ApiRequestResetAccountBalance, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestUserReferredModel 
     */
    rewardsControllerRewardForUserReferred(apiRequestUserReferredModel: ApiRequestUserReferredModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestUserReferredModel 
     */
    rewardsControllerRewardForUserReferred_8(apiRequestUserReferredModel: ApiRequestUserReferredModel, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param file 
     */
    rewardsControllerRewardMultipleViaCSV(file: Blob, extraHttpRequestParams?: any): Observable<ApiResponseCSVReward>;

    /**
     * 
     * 
     * @param file 
     */
    rewardsControllerRewardMultipleViaCSV_9(file: Blob, extraHttpRequestParams?: any): Observable<ApiResponseCSVReward>;

}
