<div [formGroup]="formGroup">
  <mat-form-field class="mt-0">
    <mat-select matInput [formControlName]="controlName">
      <ng-container *ngFor="let code of supportedLanguageCodes">
        <mat-option [disabled]="isSelectionDisabled(code.alpha2)" [value]="code.alpha2">
          {{ code.English }} ({{ code.alpha2 }})
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
