export enum LocalStorageKey {
  accessToken = 'access_token',
  refreshToken = 'refresh_token',
  idToken = 'id_token',
  cognitoUser = 'cognito_user',
  actAsEmployerId = 'act_as_employer_id',
  preferredStatisticsTimeFrame = 'preferred_statistics_time_frame',
  preferredStatisticsAddresses = 'preferredStatisticsAddresses',
  preferredCommuteHistoryTimeFrame = 'preferred_commute_history_time_frame',
  preferredBenefitExportTimeFrame = 'preferred_benefit_export_time_frame',
  preferredDashboardLanguage = 'preferred_dashboard_language',
  region = 'region',
  enabledSampleData = 'enabledSampleData',
  firstTrackedCommuteDialogShown = 'firstTrackedCommuteDialogShown',
  checkoutSuccessTracked = 'checkoutSuccessTracked',
  unitSystem = 'unitSystem',
  traffic_type = 'traffic_type',
  visitedAppPreview = 'visitedAppPreview',
  userInviteHotspotRead = 'userInviteHotspotRead',
  monthlyNpsSurveySeenDate = 'monthlyNpsSurveySeenDate',
  csatSurveySeenDate = 'csatSurveySeenDate',
  hasRequestedManagerAccess = 'hasRequestedManagerAccess',
  trackedCommutesChartGrouping = 'trackedCommutesChartGrouping',
  distanceTraveledChartGrouping = 'distanceTraveledChartGrouping',
}
