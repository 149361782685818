import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { qrCodeAuthValidInSeconds } from '@core/constants/app-auth.constant';
import { TranslateModule } from '@ngx-translate/core';
import { SecondsCounterDirective } from '@shared/directives/seconds-counter.directive';
import { InfoExplanationComponent } from '@shared/standalone/info-explanation/info-explanation.component';
import { QRCodeModule } from 'angularx-qrcode';

export type CounterSeconds = 0 | typeof qrCodeAuthValidInSeconds;

@Component({
  selector: 'download-qr-code',
  standalone: true,
  imports: [InfoExplanationComponent, TranslateModule, CommonModule, QRCodeModule, SecondsCounterDirective],
  templateUrl: './download-qr-code.component.html',
  styleUrl: './download-qr-code.component.scss',
})
export class DownloadQrCodeComponent implements OnChanges {
  @Input() public deepLink?: string;

  @Output() public refresh: EventEmitter<void> = new EventEmitter();

  public countdown: CounterSeconds = 0;

  public currCountdown = 0;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.deepLink) {
      this.startCounter();
    }
  }

  private startCounter(): void {
    this.countdown = qrCodeAuthValidInSeconds;
  }

  private stopCounter(): void {
    this.countdown = 0;
  }

  public onCounterUpdate(newValue: number): void {
    this.currCountdown = newValue;

    if (this.currCountdown === 0) {
      this.stopCounter();
    }
  }

  public get minutes(): number {
    return Math.floor(this.currCountdown / 60);
  }

  public get seconds(): number {
    return this.currCountdown % 60;
  }
}
