/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestActivityManagementV2 } from '../model/models';
import { ApiRequestConfirmModeForPossibleCommute } from '../model/models';
import { ApiRequestTripLogModel } from '../model/models';
import { ApiResponseUnseenActivityTripAndTransactionModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface TripManagementServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestActivityManagementV2 
     */
    tripManagementControllerChangeModeV2(apiRequestActivityManagementV2: ApiRequestActivityManagementV2, extraHttpRequestParams?: any): Observable<ApiResponseUnseenActivityTripAndTransactionModel>;

    /**
     * 
     * 
     * @param apiRequestActivityManagementV2 
     */
    tripManagementControllerChangeModeV2_1(apiRequestActivityManagementV2: ApiRequestActivityManagementV2, extraHttpRequestParams?: any): Observable<ApiResponseUnseenActivityTripAndTransactionModel>;

    /**
     * 
     * 
     * @param apiRequestConfirmModeForPossibleCommute 
     */
    tripManagementControllerConfirmMode(apiRequestConfirmModeForPossibleCommute: ApiRequestConfirmModeForPossibleCommute, extraHttpRequestParams?: any): Observable<ApiResponseUnseenActivityTripAndTransactionModel>;

    /**
     * 
     * 
     * @param apiRequestConfirmModeForPossibleCommute 
     */
    tripManagementControllerConfirmMode_2(apiRequestConfirmModeForPossibleCommute: ApiRequestConfirmModeForPossibleCommute, extraHttpRequestParams?: any): Observable<ApiResponseUnseenActivityTripAndTransactionModel>;

    /**
     * 
     * 
     * @param apiRequestTripLogModel 
     */
    tripManagementControllerLogTrips(apiRequestTripLogModel: ApiRequestTripLogModel, extraHttpRequestParams?: any): Observable<ApiResponseUnseenActivityTripAndTransactionModel>;

    /**
     * 
     * 
     * @param apiRequestTripLogModel 
     */
    tripManagementControllerLogTrips_3(apiRequestTripLogModel: ApiRequestTripLogModel, extraHttpRequestParams?: any): Observable<ApiResponseUnseenActivityTripAndTransactionModel>;

}
