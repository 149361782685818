/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ActivityDiscriminator {
    trip = 'trip',
    transaction = 'transaction',
    home_office = 'home-office',
    vacation = 'vacation',
    sick_leave = 'sick-leave',
    weekend = 'weekend',
    trip_free_day = 'trip-free-day'
}

