<!-- image cropper -->
<form [formGroup]="form" id="edit-image-upload-form">
  <div class="relative outline-gray-light outline rounded-xl" [ngStyle]="{ 'padding-top': aspectRatioPadding }">
    <skeleton-image class="absolute top-0 left-0 w-full h-full" [imageSrc]="imageUrl" [imageAlt]="'benefit image'">
      <ng-content fallback select="[fallback]"> </ng-content>
    </skeleton-image>

    @if (!imageUrl) {
      <div
        matRipple
        (click)="fileInput.click()"
        class="z-50 rounded-[inherit] absolute inset-0 flex flex-col items-center justify-center bg-gray-ultralight cursor-pointer"
      >
        <mat-icon fontSet="material-icons-round" class="text-dark transform scale-125">add</mat-icon>
        <ng-content select="[addImageExtension]"></ng-content>
      </div>
    } @else {
      <div
        class="z-50 rounded-[inherit] absolute inset-0 justify-center items-center bg-gray-ultralight opacity-0 flex hover:opacity-100 bg-opacity-70 transition-animation"
      >
        <button (click)="fileInput.click()" mat-icon-button>
          <mat-icon fontSet="material-icons-round">upload</mat-icon>
        </button>
        <button *ngIf="allowDelete" (click)="onDelete()" mat-icon-button>
          <mat-icon fontSet="material-icons-round">delete</mat-icon>
        </button>
      </div>
    }
  </div>

  <input hidden type="file" accept="image/png,image/jpeg,image/webp" #fileInput (change)="fileChangeEvent($event)" />
  <input hidden type="text" [formControlName]="formFieldName" />
</form>
