import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TripType } from '../../../api';

@Component({
  selector: 'commute-direction-selector',
  templateUrl: './commute-direction-selector.component.html',
  styleUrls: ['./commute-direction-selector.component.scss'],
})
export class CommuteDirectionSelectorComponent {
  @Input() public formGroup!: FormGroup;

  @Input() public controlName!: string;

  @Input() public multiple = false;

  public commuteTypes = Object.values(TripType);
}
