import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { pageRoutes } from '@core/models/page-routes.model';
import { EmployerConnectWrapperService } from '@core/services/employer-connect/employer-connect-wrapper.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { DialogId } from '@shared/enums/dialog-id.enum';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { SharedModule } from '@shared/shared.module';
import { RequestAccessEmployerListComponent } from '@shared/standalone/request-access-employer-list/request-access-employer-list.component';
import { ApiResponseSelectableEmployer, ApiResponseSelectableForDashboardEmployer } from '@swagger/index';

@Component({
  selector: 'select-existing-employer-dialog',
  standalone: true,
  imports: [SharedModule, CommonModule, RequestAccessEmployerListComponent, SafeHtmlPipe],
  templateUrl: './select-existing-employer-dialog.component.html',
  styleUrl: './select-existing-employer-dialog.component.scss',
})
export class SelectExistingEmployerDialogComponent {
  public employers: ApiResponseSelectableForDashboardEmployer[] = [];

  constructor(
    @Inject(DIALOG_DATA) public dialogData: { employers: ApiResponseSelectableForDashboardEmployer[] },
    public readonly dialogRef: DialogRef<boolean>,
    private readonly employerConnectWrapperService: EmployerConnectWrapperService,
    private readonly router: Router,
    public readonly responsiveService: ResponsiveService
  ) {
    this.employers = dialogData.employers;
  }

  public onRequestAccess(employerId: string): void {
    this.employerConnectWrapperService.joinCompanyViaDashboard(employerId).subscribe(() => {
      this.router.navigate([pageRoutes.USER_APP_PREVIEW.path]);
      this.dialogRef.close(false);
    });
  }

  public onContinueWithOnboarding(): void {
    this.dialogRef.close(true);
  }

  public static open(
    dialog: Dialog,
    showFullscreen: boolean,
    employers: ApiResponseSelectableEmployer[]
  ): DialogRef<boolean, unknown> {
    const config = {
      id: DialogId.SELECT_EXISTING_EMPLOYER_DIALOG,
      data: {
        employers,
      },
      disableClose: true,
    };

    if (showFullscreen) {
      Object.assign(config, {
        height: '100vh',
        width: '100vw',
        maxHeight: '100vh',
        maxWidth: '100vw',
      });
    }

    return dialog.open<boolean>(SelectExistingEmployerDialogComponent, config);
  }
}
