const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim();
const lightColor = getComputedStyle(document.documentElement).getPropertyValue('--light-color').trim();

export const defaultCircleOptions: google.maps.CircleOptions = {
  strokeColor: primaryColor,
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: primaryColor,
  fillOpacity: 0.35,
};

export const defaultHybridMapCircleOptions: google.maps.CircleOptions = {
  strokeColor: lightColor,
  fillColor: lightColor,
};
