import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Only allows A-Z, special ASCI characters (e.g. Ö, Ä, ø), space and hyphen
 */
export function customCharacterValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = /^(?!.*[ -]{2})[A-Za-zÀ-ÖØ-öø-ÿ -]*$/.test(control.value);

    return forbidden ? null : { invalidCharacters: { value: control.value } };
  };
}
