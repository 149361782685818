/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildTransportLocation } from './childTransportLocation';


export interface ApiRequestMockFirehose { 
    startLocation: ChildTransportLocation;
    endLocation: ChildTransportLocation;
    username: string;
    sentianceUserId: string;
    startTime: string;
    endTime: string;
    transportLength: number;
    tripMode: ApiRequestMockFirehoseTripModeEnum;
    employerId: string;
}
export enum ApiRequestMockFirehoseTripModeEnum {
    car = 'car',
    bike = 'bike',
    walk = 'walk',
    scooter = 'scooter',
    transit = 'transit',
    carpool = 'carpool',
    internalShuttle = 'internalShuttle'
};



