import { CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TimeFrameItemHelperService } from '@core/services/time-frame-item-helper/time-frame-item-helper.service';
import { LocalStorageKey } from '@shared/enums/local-storage-key.enum';
import { TimeFrameItem } from '@shared/interfaces/time-frame-item.interface';

@Component({
  selector: 'time-frame-selector-card',
  templateUrl: './time-frame-selector-card.component.html',
  styleUrls: ['./time-frame-selector-card.component.scss'],
})
export class TimeFrameSelectorCardComponent implements OnInit {
  @Input()
  public disabled = false;

  @Input()
  public localStorageKey?: LocalStorageKey;

  @ViewChild('cdkMenuTrigger', { static: true })
  public cdkMenuTrigger!: CdkMenuTrigger;

  @Output()
  public timeFrameChange: EventEmitter<TimeFrameItem> = new EventEmitter<TimeFrameItem>();

  public selectedTimeFrameItem?: TimeFrameItem;

  constructor(private readonly timeFrameItemHelperService: TimeFrameItemHelperService) {}

  public ngOnInit(): void {
    this.initTimeFrameItem();
  }

  private initTimeFrameItem(): void {
    this.selectedTimeFrameItem = this.localStorageKey
      ? this.timeFrameItemHelperService.getTimeFrameItemFromLocalStorage(this.localStorageKey!)
      : this.timeFrameItemHelperService.getDefaultTimeFrameItem();

    this.emit();
  }

  private setLocalStorageTimeFrame(timeFrameItem: TimeFrameItem): void {
    if (!this.localStorageKey) {
      return;
    }

    localStorage.setItem(this.localStorageKey, JSON.stringify(timeFrameItem));
  }

  private emit(): void {
    this.setLocalStorageTimeFrame(this.selectedTimeFrameItem!);
    this.timeFrameChange.emit(this.selectedTimeFrameItem);
  }

  public onTimeFrameSelect(timeFrameItem: TimeFrameItem): void {
    this.selectedTimeFrameItem = timeFrameItem;
    this.emit();
    this.cdkMenuTrigger.close();
  }

  public onCancel(): void {
    this.cdkMenuTrigger.close();
  }

  /**
   * Can be accessed via ViewChild in the parent component
   */
  public resetToDefaultValue(): void {
    this.selectedTimeFrameItem = this.timeFrameItemHelperService.getDefaultTimeFrameItem();
    this.emit();
  }
}
