<div class="cdk-dialog h-full">
  <div class="cdk-dialog-header">
    <div class="flex justify-between items-center">
      <h1 class="cdk-dialog-header__title">{{ 'general.manage' | translate }} {{ 'general.users' | translate }}</h1>
      <mat-form-field appearance="outline" class="hide-bottom-padding w-60">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput #search type="text" placeholder="{{ 'general.search' | translate }}" />
      </mat-form-field>
    </div>
  </div>

  <div class="cdk-dialog-body cdk-dialog-body--separated">
    <p class="mb-4 font-redHatTextSemibold" *ngIf="selectedUsers.length > 0">
      {{ 'general.selectedUsers' | translate }}:
    </p>
    <div *ngIf="selectedUsers.length > 0" class="select-users-grid mb-4">
      <ng-container *ngFor="let value of selectedUsers; let index = index">
        <user-checkbox-card
          [displayName]="value.displayName"
          [email]="value.email"
          [isChecked]="true"
          (changeEvent)="onDeSelect(value, index)"
        ></user-checkbox-card>
      </ng-container>
    </div>
    <p *ngIf="filteredUsers.length > 0" class="mb-4 font-redHatTextSemibold">
      {{ 'general.searchResult' | translate }}:
    </p>
    <div *ngIf="filteredUsers.length > 0; else nothingFound" class="select-users-grid">
      <ng-container *ngFor="let value of filteredUsers; let index = index">
        <user-checkbox-card
          [displayName]="value.displayName"
          [email]="value.email"
          [isChecked]="false"
          (changeEvent)="onSelect(value, index)"
        ></user-checkbox-card>
      </ng-container>
    </div>
    <ng-template #nothingFound>
      <div class="h-full flex items-center justify-center">
        <empty-state
          emojiPathUrl="assets/images/emojis/1f440.svg"
          [msg]="'user.noUsersFound' | translate"
        ></empty-state>
      </div>
    </ng-template>
  </div>

  <div class="cdk-dialog-actions">
    <button mat-flat-button (click)="onClose()">{{ 'general.cancel' | translate }}</button>
    <button mat-flat-button (click)="onRemoveAll()">{{ 'general.removeAll' | translate }}</button>
    <button color="primary" mat-flat-button (click)="onSave()">
      {{ 'user.saveUsers' | translate }} ({{ this.selectedUsers.length }})
    </button>
  </div>
</div>
