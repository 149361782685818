/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Price } from './price';
import { StripeInvoiceLineItemType } from './stripeInvoiceLineItemType';


export interface InvoiceLineItem { 
    type: StripeInvoiceLineItemType;
    id: string;
    amount: number;
    currency: string;
    description?: string;
    periodStart: string;
    periodEnd: string;
    price?: Price;
    proration: boolean;
    quantity?: number;
}



