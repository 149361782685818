/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TripModeEnum } from './tripModeEnum';


export interface ApiResponseSuggestedGroups { 
    modeForSuggestions: TripModeEnum;
    groups: Array<object>;
}



