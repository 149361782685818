/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiResponseChallenge } from '../model/models';
import { ApiResponseChallengeSummary } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ChallengeServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     */
    challengeControllerFindActive(extraHttpRequestParams?: any): Observable<Array<ApiResponseChallenge>>;

    /**
     * 
     * 
     */
    challengeControllerFindActive_1(extraHttpRequestParams?: any): Observable<Array<ApiResponseChallenge>>;

    /**
     * 
     * 
     */
    challengeControllerFindLastFinishedForUser(extraHttpRequestParams?: any): Observable<object>;

    /**
     * 
     * 
     */
    challengeControllerFindLastFinishedForUser_2(extraHttpRequestParams?: any): Observable<object>;

    /**
     * 
     * 
     */
    challengeControllerFindPast(extraHttpRequestParams?: any): Observable<Array<ApiResponseChallenge>>;

    /**
     * 
     * 
     */
    challengeControllerFindPast_3(extraHttpRequestParams?: any): Observable<Array<ApiResponseChallenge>>;

    /**
     * 
     * 
     */
    challengeControllerGetSummaryByChallengeId(extraHttpRequestParams?: any): Observable<ApiResponseChallengeSummary>;

    /**
     * 
     * 
     */
    challengeControllerGetSummaryByChallengeId_4(extraHttpRequestParams?: any): Observable<ApiResponseChallengeSummary>;

}
