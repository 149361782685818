/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AppUpdateInformation { 
    appVersion: number;
    platform: AppUpdateInformationPlatformEnum;
    updateType: AppUpdateInformationUpdateTypeEnum;
    necessaryUntil: string | null;
}
export enum AppUpdateInformationPlatformEnum {
    ANY = 'ANY',
    ANDROID = 'ANDROID',
    IOS = 'IOS'
};
export enum AppUpdateInformationUpdateTypeEnum {
    IMMEDIATELY_NECESSARY = 'IMMEDIATELY_NECESSARY',
    NECESSARY_WITH_DELAY = 'NECESSARY_WITH_DELAY',
    OPTIONAL = 'OPTIONAL'
};



