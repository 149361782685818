import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { Redirect } from 'src/redirect';
import packageInfo from '../package.json';

import { AppModule } from './app/app.module';
import { isLocalhost } from './app/core/helpers/host-name-check.helper';
import { Environment } from './app/shared/enums/environment.enum';
import { EnvBodyClass } from './env-body-class';
import { environment } from './environments/environment';

import { ScriptLoader } from './script-loader';

if (environment.production) {
  enableProdMode();
}

if (environment.name !== Environment.local && isLocalhost === false) {
  Sentry.init({
    environment: environment.name,
    release: packageInfo.version,
    dsn: environment.sentryDns,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: environment.production ? 0.2 : 1,
    tracePropagationTargets: [environment.apiUrl, /^\//],
  });
}

const bootstrap = async (): Promise<void> => {
  Redirect.check();

  EnvBodyClass.init();

  // Load scripts before bootstrapping the application
  await ScriptLoader.init();

  // Bootstrap the Angular application
  await platformBrowserDynamic().bootstrapModule(AppModule);
};

bootstrap().catch((err) => {
  throw new Error(err);
});
