import { Injectable } from '@angular/core';
import { dataLayerConstantDimensions } from '@core/services/google-analytics/data-layer-constant-dimensions.constant';
import {
  FullCustomDimensionsSet,
  PartialDimensionsSet,
} from '@core/services/google-analytics/data-layer-custom-dimension.type';
import { DataLayerCustomDimensions } from '@core/services/google-analytics/data-layer-custom-dimensions.enum';

@Injectable({ providedIn: 'root' })
export class DataLayerCustomDimensionsService {
  private currentSet!: FullCustomDimensionsSet;

  private constantDimensions: PartialDimensionsSet = {};

  constructor() {
    this.generateEmptyDimensionsSet();
  }

  public setDimensions(someDimensions: PartialDimensionsSet): void {
    const newSet = JSON.parse(JSON.stringify(this.currentSet));
    const newConstantDimensions = JSON.parse(JSON.stringify(this.constantDimensions));

    Object.keys(DataLayerCustomDimensions)
      .map((key) => DataLayerCustomDimensions[key as keyof typeof DataLayerCustomDimensions])
      .forEach((key) => {
        const newDimValue = someDimensions[key];

        if (newDimValue && dataLayerConstantDimensions.includes(key)) {
          newConstantDimensions[key] = someDimensions[key];
        }

        newSet[key] = newDimValue || this.constantDimensions[key];
      });

    this.currentSet = JSON.parse(JSON.stringify(newSet));
    this.constantDimensions = JSON.parse(JSON.stringify(newConstantDimensions));
    (<any>window).dataLayer.push(newSet);
  }

  public removeIdentityConstantDimensions(): void {
    this.constantDimensions = {
      ...this.constantDimensions,
      employerId: undefined,
      employerName: undefined,
      username: undefined,
    };
  }

  private generateEmptyDimensionsSet(): void {
    this.currentSet = {
      [DataLayerCustomDimensions.authType]: undefined,
      [DataLayerCustomDimensions.employerId]: undefined,
      [DataLayerCustomDimensions.employerName]: undefined,
      [DataLayerCustomDimensions.event]: undefined,
      [DataLayerCustomDimensions.region]: undefined,
      [DataLayerCustomDimensions.username]: undefined,
      [DataLayerCustomDimensions.virtualPageUrl]: undefined,
      [DataLayerCustomDimensions.email]: undefined,
      [DataLayerCustomDimensions.firstName]: undefined,
      [DataLayerCustomDimensions.lastName]: undefined,
      [DataLayerCustomDimensions.addressPostalCode]: undefined,
    };
  }
}
