/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildTripLocationModel } from './childTripLocationModel';
import { CommuteOrigin } from './commuteOrigin';
import { TripType } from './tripType';
import { ChildModeDetails } from './childModeDetails';
import { ChildSelfReportDetails } from './childSelfReportDetails';
import { ChildTripUserDetailsModel } from './childTripUserDetailsModel';


export interface ChildTripModel { 
    origin: CommuteOrigin;
    type: TripType;
    id: string;
    userDetails: ChildTripUserDetailsModel;
    startTime: string;
    endTime: string;
    transportIds: Array<string>;
    startLocation: ChildTripLocationModel;
    endLocation: ChildTripLocationModel;
    lengthInMeters: number;
    modeDetails: ChildModeDetails;
    selfReportDetails?: ChildSelfReportDetails;
}



