import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, DestroyRef, ElementRef, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { ApiResponseUserWithLimitedDetails } from '../../../api';
import { ToastService } from '../../../core/services/toast/toast.service';
import { UserStatisticsWrapperService } from '../../../core/services/user/user-statistics-wrapper.service';

@Component({
  selector: 'select-users-dialog',
  templateUrl: './select-users-dialog.component.html',
  styleUrls: ['./select-users-dialog.component.scss'],
})
export class SelectUsersDialogComponent implements OnInit {
  public allUsers: ApiResponseUserWithLimitedDetails[] = [];

  public filteredUsers: ApiResponseUserWithLimitedDetails[] = [];

  public selectedUsers: ApiResponseUserWithLimitedDetails[] = [];

  @ViewChild('search', { static: true }) public search!: ElementRef;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(DIALOG_DATA)
    private readonly data: {
      selectedUsers: ApiResponseUserWithLimitedDetails[];
    },
    public readonly dialogRef: DialogRef<ApiResponseUserWithLimitedDetails[]>,
    private readonly userStatisticsWrapperService: UserStatisticsWrapperService,
    private toastService: ToastService
  ) {
    this.selectedUsers = this.data.selectedUsers;
  }

  public ngOnInit(): void {
    this.initSearchListener();
  }

  private loadUsers(searchValue: string): void {
    // skip loading all users
    if (searchValue === '') {
      this.toastService.show(true, 'general.provideSearchText');

      return;
    }

    this.userStatisticsWrapperService.getAllBySearchWithLimitedDetails(searchValue).subscribe((users) => {
      this.onNewUsersLoaded(users);
    });
  }

  private initSearchListener(): void {
    fromEvent(this.search.nativeElement, 'keyup')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((event: any) => event.target.value.trim()),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((searchValue) => this.loadUsers(searchValue));
  }

  public onNewUsersLoaded(users: ApiResponseUserWithLimitedDetails[]): void {
    this.sortUsers(users);
    this.allUsers = users;
    this.initUserStatus(this.allUsers);
  }

  private initUserStatus(users: ApiResponseUserWithLimitedDetails[]): void {
    this.filteredUsers = [];

    users.forEach((user) => {
      const isChecked = this.selectedUsers.some((selectedUser) => selectedUser.username === user.username);

      if (isChecked) {
        return;
      }

      this.filteredUsers.push(user);
    });
  }

  public onSave(): void {
    this.dialogRef.close(this.selectedUsers);
  }

  public onSelect(user: ApiResponseUserWithLimitedDetails, index: number): void {
    this.filteredUsers.splice(index, 1);

    this.selectedUsers.push(user);
  }

  public onDeSelect(user: ApiResponseUserWithLimitedDetails, index: number): void {
    this.selectedUsers.splice(index, 1);

    this.filteredUsers.push(user);
  }

  public sortUsers(users: ApiResponseUserWithLimitedDetails[]): void {
    users.sort((a, b) => {
      if ((a.displayName ?? '').toLowerCase() > (b.displayName ?? '').toLowerCase()) {
        return 1;
      }

      return -1;
    });
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onRemoveAll(): void {
    this.filteredUsers.push(...this.selectedUsers);
    this.selectedUsers = [];
  }
}
