import { Injectable } from '@angular/core';
import { LocalStorageKey } from '../../../shared/enums/local-storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private readonly nonClearableKeys: Set<LocalStorageKey> = new Set([
    LocalStorageKey.region,
    LocalStorageKey.visitedAppPreview,
    LocalStorageKey.userInviteHotspotRead,
    LocalStorageKey.traffic_type,
    LocalStorageKey.monthlyNpsSurveySeenDate,
    LocalStorageKey.csatSurveySeenDate,
    LocalStorageKey.hasRequestedManagerAccess,
    LocalStorageKey.preferredStatisticsTimeFrame,
    LocalStorageKey.preferredStatisticsAddresses,
    LocalStorageKey.trackedCommutesChartGrouping,
    LocalStorageKey.distanceTraveledChartGrouping,
  ]);

  private readonly nonClearableKeysCache: Map<LocalStorageKey, any> = new Map();

  public setItem(key: LocalStorageKey, value: string): void {
    localStorage.setItem(key, value);
  }

  public getItem(key: LocalStorageKey): string {
    return localStorage.getItem(key) || '';
  }

  public setNonPrimitiveItem(key: LocalStorageKey, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getObjectItem<T>(key: LocalStorageKey): T | null {
    const item = localStorage.getItem(key);

    return item ? (JSON.parse(item) as T) : null;
  }

  public getArrayItem<T>(key: LocalStorageKey): T[] | null {
    const item = localStorage.getItem(key);
    const parsedItem = item ? JSON.parse(item) : null;

    if (Array.isArray(parsedItem)) {
      return parsedItem as T[];
    }

    return null;
  }

  public removeItem(key: LocalStorageKey): void {
    localStorage.removeItem(key);
  }

  public clear(): void {
    this.cacheNonClearableKeys();
    localStorage.clear();
    this.reapplyCachedNonClearableKeys();
  }

  private cacheNonClearableKeys(): void {
    this.nonClearableKeysCache.clear();

    this.nonClearableKeys.forEach((key) => {
      const value = localStorage.getItem(key);

      if (value !== null) {
        this.nonClearableKeysCache.set(key, value);
      }
    });
  }

  private reapplyCachedNonClearableKeys(): void {
    this.nonClearableKeysCache.forEach((value, key) => {
      localStorage.setItem(key, value);
    });
  }
}
