<div [formGroup]="formGroup">
  <mat-form-field class="mt-0">
    <mat-select
      placeholder="
          {{
        'pointTypes.' + (multiple ? 'selectPointTypePlaceholderPlural' : 'selectPointTypePlaceholder') | translate
      }}
        "
      name="pointType"
      matInput
      [formControlName]="controlName"
      [multiple]="multiple"
    >
      <ng-container *ngFor="let item of pointTypes">
        <mat-option [value]="item">
          {{ 'pointTypes.' + item | translate }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
