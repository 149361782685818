import { Injectable } from '@angular/core';
import { Region } from '@core/enums/region.enum';
import { LocalStorageKey } from '@shared/enums/local-storage-key.enum';

import { RegionDetectMethod } from '@core/enums/region-detect-method.enum';
import { ToastService } from '@core/services/toast/toast.service';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilsService } from './utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  private region = Region.US;

  private regionDetected = false;

  private regionDetectMethod?: RegionDetectMethod;

  private region$ = new ReplaySubject<Region>(1);

  public get regionStream(): Observable<Region> {
    return this.region$.asObservable();
  }

  constructor(
    private readonly utilService: UtilsService,
    private readonly toastService: ToastService
  ) {}

  public init(): void {
    this.determineRegion();
  }

  private determineRegion(): void {
    if (!environment.production) {
      this.setRegion(Region.REST_OF_WORLD);

      return;
    }

    const localStorageRegion = this.getFromLocalStorage();

    if (localStorageRegion) {
      this.setRegion(localStorageRegion);
      this.regionDetectMethod = RegionDetectMethod.STORAGE;

      return;
    }

    const regionFromParam = this.getRegionFromParam();

    if (regionFromParam) {
      this.setRegion(regionFromParam);
      this.regionDetectMethod = RegionDetectMethod.PARAM;
    }
  }

  private couldDetectRegion(): boolean {
    return this.regionDetected;
  }

  private getRegionFromParam(): Region | null {
    const regionParam = new URLSearchParams(window.location.search).get('region');

    if (regionParam) {
      // TODO: remove after everyone is on 2.0.1
      const newRegion = this.migrateRegionIfNecessary(regionParam);

      const region = this.utilService.convertStringToEnum(Region, newRegion);

      if (region) {
        return region;
      }
    }

    return null;
  }

  private saveInLocalStorage(region: Region): void {
    localStorage.setItem(LocalStorageKey.region, region);
  }

  private getFromLocalStorage(): Region | null {
    const regionString = localStorage.getItem(LocalStorageKey.region);

    if (regionString) {
      // TODO: remove after everyone is on 2.0.1
      const newRegion = this.migrateRegionIfNecessary(regionString);

      const region = this.utilService.convertStringToEnum(Region, newRegion);

      if (region) {
        return region;
      }
    }

    return null;
  }

  private setRegion(region: Region): void {
    this.region = region;
    this.saveInLocalStorage(region);
    this.regionDetected = true;
    this.region$.next(region);
  }

  public getRegion(): Region {
    return this.region;
  }

  public switchRegion(region: Region): void {
    if (!environment.production) {
      this.toastService.show(true, 'Region switch only works on the production environment');

      return;
    }

    this.setRegion(region);
    this.reloadAngularApp();
  }

  /**
   * Reloads the angular app to make sure all modules
   * are initialized with the correct environment
   */
  private reloadAngularApp(): void {
    (window as any).location = window.location.pathname;
  }

  public getRegionImageUrl(region: Region = this.region): string {
    switch (region) {
      case Region.EU:
        return 'assets/images/emojis/1f1ea-1f1fa.svg';

      case Region.REST_OF_WORLD:
        return 'assets/images/emojis/1f30e.svg';

      case Region.US:
        return 'assets/images/emojis/1f1fa-1f1f8.svg';

      default:
        return 'assets/images/emojis/1f1fa-1f1f8.svg';
    }
  }

  public get isRegionEU(): boolean {
    return this.region === Region.EU;
  }

  public getRegionDetectMethod(): RegionDetectMethod | undefined {
    return this.regionDetectMethod;
  }

  public shouldOpenRegionDialog(): boolean {
    if (!environment.production) {
      return false;
    }

    return !this.couldDetectRegion() || this.getRegionDetectMethod() === RegionDetectMethod.PARAM;
  }

  private migrateRegionIfNecessary(region: string): string {
    switch (region) {
      case 'EU':
        return `${Region.EU}`;

      case 'RESTOFWORLD':
        return `${Region.US}`;

      default:
        break;
    }

    return region;
  }
}
