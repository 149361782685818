<div [class.cdk-dialog--fill]="responsiveService.isMobileView()" class="cdk-dialog sm:max-h-[95vh] w-[55rem] gap-6">
  <h1
    class="text-3xl sm:text-4xl mb-4 px-4 text-center"
    [innerHTML]="'selectExistingCompanyDialog.title' | translate | safeHtml"
  ></h1>
  <p class="text-center sm:px-16 px-4 mb-4">
    {{ 'selectExistingCompanyDialog.subtitle' | translate }}
  </p>

  <div class="w-full sm:px-[7rem] px-4 max-h-[35vh] overflow-y-scroll">
    <request-access-employer-list
      [employerDetailsResponse]="employers"
      (requestAccessEvent)="onRequestAccess($event)"
    ></request-access-employer-list>
  </div>
  <div class="flex justify-center">
    <button mat-button (click)="onContinueWithOnboarding()">
      {{ 'selectExistingCompanyDialog.buttonText' | translate }}
    </button>
  </div>
</div>
