<div class="cdk-dialog w-[600px]">
  <img src="assets/images/emojis/1f4e8.svg" class="w-12 h-12 mx-auto" alt="warn emoji" />

  <div class="cdk-dialog-header">
    <h1 class="cdk-dialog-header__title text-center">
      {{ 'verifyEmail.updateEmailDialog.title' | translate }}
    </h1>
  </div>

  <div class="cdk-dialog-body px-8 text-center">
    <p class="mb-5">{{ 'verifyEmail.updateEmailDialog.description' | translate }}</p>
    <form novalidate [formGroup]="formGroup" class="dialog-form" id="auth-email-update">
      <mat-form-field class="mt-0" subscriptSizing="dynamic" appearance="outline">
        <input
          type="email"
          matInput
          placeholder="{{ 'verifyEmail.updateEmailDialog.placeholder' | translate }}"
          formControlName="email"
          autofocus
          autocomplete="email"
          name="email"
        />
      </mat-form-field>

      <mat-error *ngIf="formGroup.controls.email.invalid">
        <span *ngIf="formGroup.controls.email.hasError('required')">{{
          'formValidation.requiredError' | translate: { field: 'general.email' | translate }
        }}</span>
        <span *ngIf="formGroup.controls.email.hasError('email')"
          >{{ 'formValidation.emailError' | translate }}&nbsp;</span
        >
        <span *ngIf="formGroup.controls.email.hasError('emailExists')"
          >{{ 'formValidation.emailExists' | translate }}&nbsp;</span
        >
        <span
          *ngIf="
            formGroup.controls.email.hasError('disposable') || formGroup.controls.email.hasError('freeEmailDomain')
          "
          [innerHtml]="'formValidation.onlyWorkEmailAllowed' | translate"
        ></span>

        <mat-hint class="text-gray-dark" *ngIf="emailDomainSuggestionWarning && formGroup.controls.email.valid">
          <domain-suggestion
            [domainSuggestion]="emailDomainSuggestionWarning"
            [email]="formGroup.controls.email.value!"
            (suggestionUse)="onSuggestionUse($event)"
          ></domain-suggestion>
        </mat-hint>
      </mat-error>
    </form>
  </div>

  <div class="cdk-dialog-actions justify-center">
    <button mat-flat-button (click)="onClose()">{{ 'general.close' | translate }}</button>
    <button mat-flat-button color="primary" (click)="onUpdateEmail()">
      {{ 'verifyEmail.updateEmailDialog.updateEmail' | translate }}
    </button>
  </div>
</div>
