import { Injectable } from '@angular/core';
import { SessionStorageKey } from '@shared/enums/session-storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  public isMobileAppWebView(): boolean {
    return sessionStorage.getItem(SessionStorageKey.isMobileAppWebView) ? true : false;
  }
}
