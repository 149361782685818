import { Component } from '@angular/core';
import { supportedDashboardLanguageCodes } from '@core/constants/language-codes.constant';
import { DashboardLanguageService } from '@core/services/dashboard-language.service';
import { LanguageCode } from '@shared/interfaces/language-code.interface';

@Component({
  selector: 'language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent {
  public readonly supportedDashboardLanguageCodes = supportedDashboardLanguageCodes;

  public selectedLanguage: LanguageCode;

  constructor(private readonly dashboardLanguageService: DashboardLanguageService) {
    this.selectedLanguage = supportedDashboardLanguageCodes.find(
      (language) => language.alpha2 === this.dashboardLanguageService.getCurrentDashboardLanguage()
    )!;
  }

  public selectLanguage(language: LanguageCode): void {
    this.selectedLanguage = language;

    this.dashboardLanguageService.changeLanguage(language.alpha2);
  }
}
