/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildAddressModel } from './childAddressModel';
import { ChildGroupUser } from './childGroupUser';
import { GroupSearchTag } from './groupSearchTag';
import { TripModeEnum } from './tripModeEnum';


export interface ApiResponseGroupSearch { 
    commuteMode: TripModeEnum;
    tags: Array<GroupSearchTag>;
    id: string;
    name: string;
    destinationAddresses: Array<ChildAddressModel>;
    color?: string;
    users: Array<ChildGroupUser>;
    distanceAwayInMeters: number;
    foundByAddress: ChildAddressModel;
}



