<div class="cdk-dialog w-[600px]">
  <div class="cdk-dialog-header cdk-dialog-header--back">
    <button *ngIf="showBackIcon" mat-icon-button (click)="cancelCroppedImage()">
      <mat-icon class="cdk-dialog-header__back-button-icon">arrow_back</mat-icon>
    </button>
    <h1 class="cdk-dialog-header__title">{{ 'general.cropImage' | translate }}</h1>
  </div>

  <div class="cdk-dialog-body">
    <info-explanation
      *ngIf="dialogData.info"
      class="mb-8 block"
      type="info"
      [msg]="dialogData.info"
      prefixImageUrl="assets/images/emojis/1f4a1.svg"
    ></info-explanation>

    <image-cropper
      class="bg-gray-ultralight"
      [imageChangedEvent]="uploadedImageEvent"
      [containWithinAspectRatio]="true"
      [aspectRatio]="aspectRatio"
      [alignImage]="'center'"
      [format]="this.imageFormat"
      [imageQuality]="50"
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="loadImageFailed()"
    >
    </image-cropper>
  </div>
  <div class="cdk-dialog-actions">
    <button mat-flat-button (click)="cancelCroppedImage()">{{ 'general.cancel' | translate }}</button>
    <button color="primary" mat-flat-button (click)="saveCroppedImage()">
      {{ 'general.save' | translate }}
    </button>
  </div>
</div>
