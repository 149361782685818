import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PointTypeHelperService } from '@core/services/point-type-helper/point-type-helper.service';

@Component({
  selector: 'point-type-selector',
  templateUrl: './point-type-selector.component.html',
  styleUrls: ['./point-type-selector.component.scss'],
})
export class PointTypeSelectorComponent implements OnInit {
  @Input() public formGroup!: FormGroup;

  @Input() public controlName!: string;

  @Input() public multiple = false;

  public pointTypes: string[] = [];

  constructor(private readonly pointTypeHelperService: PointTypeHelperService) {}

  public ngOnInit(): void {
    this.initPointTypes();
  }

  private initPointTypes(): void {
    this.pointTypes = this.pointTypeHelperService.getManualPointTypes();
  }
}
