/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestLinkShiftWork } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UserShiftWorkServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestLinkShiftWork 
     */
    shiftWorkControllerLinkViaApp(apiRequestLinkShiftWork: ApiRequestLinkShiftWork, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param apiRequestLinkShiftWork 
     */
    shiftWorkControllerLinkViaApp_1(apiRequestLinkShiftWork: ApiRequestLinkShiftWork, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param employerId 
     * @param apiRequestLinkShiftWork 
     */
    shiftWorkControllerLinkViaDashboard(employerId: string, apiRequestLinkShiftWork: ApiRequestLinkShiftWork, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param employerId 
     * @param apiRequestLinkShiftWork 
     */
    shiftWorkControllerLinkViaDashboard_2(employerId: string, apiRequestLinkShiftWork: ApiRequestLinkShiftWork, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param file 
     */
    shiftWorkControllerUpdatePlan(file: Blob, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param file 
     */
    shiftWorkControllerUpdatePlan_3(file: Blob, extraHttpRequestParams?: any): Observable<{}>;

}
