import { AfterViewInit, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { GeoJsonObject } from 'geojson';
import * as L from 'leaflet';
import { ApiResponseTransitDetectionCheckTransport } from '../../../../api';

@Component({
  selector: 'map-visualization',
  templateUrl: './map-visualization.component.html',
  styleUrls: ['./map-visualization.component.scss'],
})
export class MapVisualizationComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  public result!: ApiResponseTransitDetectionCheckTransport;

  @Input()
  public showRailFeaturesAlongLatLongs = false;

  @Input()
  public showRailMatchingLatLongs = false;

  @Input()
  public showTransitBusStopsFeatures = false;

  @Input()
  public showTransitMatchingStopSegments = false;

  @Input()
  public showTransitStopSegments = false;

  @Input()
  public showTrajectory = true;

  private layers = new Map<string, L.GeoJSON>();

  private map!: L.Map;

  public ngAfterViewInit(): void {
    if (!this.map) {
      this.initMap();
    }
  }

  public ngOnChanges(): void {
    if (this.map) {
      this.updateLayers();
    }
  }

  public initMap(): void {
    this.map = L.map('transit-validation-map', {
      center: L.latLng(43, 6),
      zoom: 10,
    });

    const openStreetLayer = new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      minZoom: 3,
      maxZoom: 18,
      attribution: "<a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>",
    });

    openStreetLayer.addTo(this.map);

    if (this.result) {
      this.initLayers();
    }
  }

  private addGeoJsonLayer(geojsonData: GeoJsonObject, options?: L.PathOptions): L.GeoJSON {
    const geoJsonLayer = L.geoJSON(geojsonData, {
      pointToLayer: (_feature, latLng) =>
        L.circleMarker(latLng, {
          radius: 8,
          fillColor: options?.fillColor,
          color: options?.color,
          weight: 5,
          opacity: 1,
          fillOpacity: 0.8,
        }),
      style: () => ({
        color: options?.color,
        weight: 8,
      }),
      onEachFeature: (feature, layer) => {
        // You can add interactions like popups here
        if (feature.properties && feature.properties.name) {
          layer.bindPopup(feature.properties.name);
        }
      },
    });

    return geoJsonLayer;
  }

  private updateLayers(): void {
    // rail layers
    this.updateLayer(this.showRailFeaturesAlongLatLongs, 'railFeaturesAlongLatLongs');
    this.updateLayer(this.showRailMatchingLatLongs, 'railMatchingLatLongs');

    // transit stop layers
    this.updateLayer(this.showTransitBusStopsFeatures, 'transitBusStopsFeatures');
    this.updateLayer(this.showTransitMatchingStopSegments, 'transitMatchingStopSegments');
    this.updateLayer(this.showTransitStopSegments, 'transitStopSegments');
    this.updateLayer(this.showTrajectory, 'trajectory');
  }

  private updateLayer(shouldShowLayer: boolean, layerKey: string): void {
    const layer = this.layers.get(layerKey);

    if (shouldShowLayer) {
      if (layer && !this.map.hasLayer(layer)) {
        layer.addTo(this.map);
        const bounds = layer.getBounds();

        this.map.fitBounds(bounds);
      }
    } else if (layer && this.map.hasLayer(layer)) {
      this.map.removeLayer(layer);
    }
  }

  private initLayers(): void {
    if (!this.result) {
      return;
    }

    const red = '#FF0000';
    const orange = '#FFA500';
    const green = '#0AFC12';
    const black = '#000000';
    const purple = '#83008b';

    this.layers.set(
      'railMatchingLatLongs',
      this.addGeoJsonLayer(this.result.result.railResult?.matchingLatLongs as GeoJsonObject, {
        fillColor: red,
      })
    );

    this.layers.set(
      'railFeaturesAlongLatLongs',
      this.addGeoJsonLayer(this.result.result.railResult?.railFeaturesAlongLatLongs as GeoJsonObject, {
        color: orange,
      })
    );

    this.layers.set(
      'transitMatchingStopSegments',
      this.addGeoJsonLayer(this.result.result.transitStopDetectionResult?.matchingStopSegments as GeoJsonObject, {
        color: green,
      })
    );

    this.layers.set(
      'transitStopSegments',
      this.addGeoJsonLayer(this.result.result.transitStopDetectionResult?.stopSegments as GeoJsonObject, {
        color: orange,
      })
    );

    this.layers.set(
      'transitBusStopsFeatures',
      this.addGeoJsonLayer(this.result.result.transitStopDetectionResult?.transitStops as GeoJsonObject, {
        color: purple,
      })
    );

    this.layers.set(
      'trajectory',
      this.addGeoJsonLayer(this.result.trajectory as GeoJsonObject, {
        color: black,
      })
    );

    this.updateLayers();
  }

  public ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
  }
}
