import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PaveWebAppEventService } from '@core/services/pave-web-app-event/pave-web-app-event.service';
import { QrCodeAuthWrapperService } from '@core/services/qr-code-auth-wrapper.service';
import { UserDataService } from '@core/services/user/user-data.service';
import { TranslateModule } from '@ngx-translate/core';
import { DeepLinkAction } from '@shared/enums/deep-link-action.enum';
import { MaterialModule } from '@shared/modules/material/material.module';
import { SafeResourceUrlPipe } from '@shared/pipes/safe-resource-url.pipe';
import { filter, take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-iframe',
  standalone: true,
  imports: [CommonModule, SafeResourceUrlPipe, MaterialModule, TranslateModule],
  templateUrl: './app-iframe.component.html',
  styleUrl: './app-iframe.component.scss',
})
export class AppIframeComponent implements OnInit {
  public webUrl!: string;

  @ViewChild('iframe') public iframe?: ElementRef<HTMLIFrameElement>;

  constructor(
    private readonly qrCodeAuthWrapperService: QrCodeAuthWrapperService,
    private readonly userDataService: UserDataService,
    private readonly paveWebAppEventService: PaveWebAppEventService
  ) {}

  public ngOnInit(): void {
    this.userDataService
      .userStream()
      .pipe(
        filter(
          (user) =>
            user.connectedToEmployer !== '' ||
            (user.pendingJoinRequestAtEmployer !== undefined && user.pendingJoinRequestAtEmployer !== '')
        ),
        take(1)
      )
      .subscribe(() => {
        this.getCode();
      });
  }

  private getCode(): void {
    this.qrCodeAuthWrapperService.getCode().subscribe((code) => {
      let region = environment.region as String;

      /// TODO: remove once app also migrates to "US" from "restOfWorld"
      if (region === 'us') {
        region = 'restOfWorld';
      }

      this.webUrl = `${environment.appWebUrl}/?${DeepLinkAction.qrCodeAutoAuth}=${code}&region=${region}`;
    });
  }

  public iFrameLoaded(): void {
    if (this.iframe) {
      this.paveWebAppEventService.registerIframe(this.iframe);
    }
  }
}
