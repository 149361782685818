<div [formGroup]="formGroup">
  <mat-form-field>
    <input matInput [min]="minDate" [formControlName]="controlName" [matDatepicker]="picker" />
    <button
      (click)="formGroup.controls[controlName].setValue(null)"
      *ngIf="formGroup.controls[controlName].value"
      matSuffix
      mat-icon-button
      type="button"
    >
      <mat-icon>close</mat-icon>
    </button>

    <mat-error *ngIf="formGroup.controls[controlName].hasError('dateRangeInvalid')">
      {{ 'dateRangeValidationError' | translate }}
    </mat-error>

    <mat-hint>
      <ng-content select="mat-hint"></ng-content>
    </mat-hint>

    <mat-datepicker-toggle matIconSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon>edit_calendar</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker [startView]="'month'" [startAt]="startAt"></mat-datepicker>
  </mat-form-field>
</div>
