/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestCreateApiKey } from '../model/models';
import { ApiRequestRevokeApiKey } from '../model/models';
import { ApiResponseApiKey } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ApiKeyServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestCreateApiKey 
     */
    apiKeyControllerCreate(apiRequestCreateApiKey: ApiRequestCreateApiKey, extraHttpRequestParams?: any): Observable<ApiResponseApiKey>;

    /**
     * 
     * 
     * @param apiRequestCreateApiKey 
     */
    apiKeyControllerCreate_1(apiRequestCreateApiKey: ApiRequestCreateApiKey, extraHttpRequestParams?: any): Observable<ApiResponseApiKey>;

    /**
     * 
     * 
     * @param apiKey 
     */
    apiKeyControllerDecode(apiKey: string, extraHttpRequestParams?: any): Observable<ApiResponseApiKey>;

    /**
     * 
     * 
     * @param apiKey 
     */
    apiKeyControllerDecode_2(apiKey: string, extraHttpRequestParams?: any): Observable<ApiResponseApiKey>;

    /**
     * 
     * 
     * @param employerId 
     */
    apiKeyControllerFindActiveApiKey(employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseApiKey>;

    /**
     * 
     * 
     * @param employerId 
     */
    apiKeyControllerFindActiveApiKey_3(employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseApiKey>;

    /**
     * 
     * 
     * @param apiRequestRevokeApiKey 
     */
    apiKeyControllerRevokeApiKey(apiRequestRevokeApiKey: ApiRequestRevokeApiKey, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param apiRequestRevokeApiKey 
     */
    apiKeyControllerRevokeApiKey_4(apiRequestRevokeApiKey: ApiRequestRevokeApiKey, extraHttpRequestParams?: any): Observable<boolean>;

}
