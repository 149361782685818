<div class="flex items-center justify-center">
  <button
    (click)="onEnterCodeStep ? backToLoginSignup.emit() : toggleLoginSignup.emit()"
    mat-button
    type="button"
    class="form-switch"
  >
    <ng-container *ngIf="!onEnterCodeStep">
      {{ (isSignup ? 'signup.loginHint' : 'login.signupHint') | translate }}
    </ng-container>
    <strong>
      <ng-container *ngIf="!onEnterCodeStep; else enterCodeCTA">
        {{ (isSignup ? 'login.logInNow' : 'signup.signupNow') | translate }}
      </ng-container>
      <ng-template #enterCodeCTA>
        {{ (isSignup ? 'signup.back' : 'login.back') | translate }}
      </ng-template>
    </strong>
  </button>
</div>
