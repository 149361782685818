import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class DisplayNameHelperService {
  /**
   * By default we set the displayName to the email so we need to make sure we return undefined if that is the case
   *
   * @param displayName
   * @returns
   */
  public getFirstName(displayName?: string | null): string | undefined {
    if (this.isEmail(displayName)) {
      return undefined;
    }

    const firstName = displayName?.split(' ')[0];

    return firstName;
  }

  /**
   * By default we set the displayName to the email so we need to make sure we return undefined if that is the case
   *
   * @param displayName
   * @returns
   */
  public getLastName(displayName?: string | null): string | undefined {
    if (this.isEmail(displayName)) {
      return undefined;
    }

    const lastName = displayName?.split(' ').slice(1).join(' ');

    return lastName;
  }

  private isEmail(displayName?: string | null): boolean {
    const emailControl = new FormControl(displayName, Validators.email);

    const isEmail = emailControl.errors?.email == null;

    return isEmail;
  }
}
