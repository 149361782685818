import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private loadingCounter = 0;

  /**
   * Method to inform other other components about the loading status
   * @method loading
   */
  public getLoadingStream(): Observable<boolean> {
    return this.loading$.pipe(
      delay(0) // this is needed to avoid expression has changed after it has been checked error -> delay causes a new check
      // see full explanation: https://blog.angular-university.io/angular-debugging/
    );
  }

  /**
   * Method to start the loading
   * @method startLoading
   */
  public startLoading(): void {
    this.setLoadingCounter(this.loadingCounter + 1);
  }

  /**
   * Method to end the loading
   * @method endLoading
   */
  public endLoading(): void {
    this.setLoadingCounter(this.loadingCounter - 1);
  }

  /**
   * Set loading counter
   * @param value
   */
  private setLoadingCounter(value: number): void {
    if (value >= 0) {
      this.loadingCounter = value;
    }

    if (this.loadingCounter > 0) {
      this.loading$.next(true);
    } else {
      this.loading$.next(false);
    }
  }
}
