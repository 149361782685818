/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildRaffleRewardStyleConfig } from './childRaffleRewardStyleConfig';
import { ChildTangoRewardStyleConfig } from './childTangoRewardStyleConfig';
import { ChildExternalRewardStyleConfig } from './childExternalRewardStyleConfig';


export interface ChildRewardStyles { 
    externalRewardStyleConfigs: Array<ChildExternalRewardStyleConfig>;
    tangoRewardStyleConfigs: Array<ChildTangoRewardStyleConfig>;
    raffleRewardStyleConfigs: Array<ChildRaffleRewardStyleConfig>;
}

