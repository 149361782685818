/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Price } from './price';


export interface SubscriptionItem { 
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Time at which the object was created.
     */
    created: string;
    price: Price;
    /**
     * The [quantity](https://stripe.com/docs/subscriptions/quantities) of the plan to which the customer should be subscribed.
     */
    quantity: number;
}

