<div
  class="rounded-xl relative border-gray-light border-[1.5px] bg-light py-1 justify-around items-center custom-grid grid gap-x-3 px-3"
>
  <mat-checkbox [(ngModel)]="isChecked" (change)="change($event)"></mat-checkbox>

  <div class="whitespace-nowrap overflow-hidden">
    <p overflowMarqueeAnimation class="truncate hover:overflow-visible">{{ user.displayName }}</p>
  </div>

  <div class="whitespace-nowrap overflow-hidden">
    <p overflowMarqueeAnimation class="truncate hover:overflow-visible">{{ user.email }}</p>
  </div>

  <div class="flex justify-center items-center">
    <button mat-button [color]="permissionsAreOk ? 'primary' : 'warn'" [rawData]="userPermissions">
      {{ 'commuteInsight.permissions' | translate }}
      <mat-icon inline="true">{{ permissionsAreOk ? 'check_circle' : 'do_not_disturb' }}</mat-icon>
    </button>
  </div>

  <div class="flex justify-center items-center">
    <button (click)="onRewardCoins()" color="primary" mat-button>
      {{ 'user.updateCoins' | translate }}
    </button>
  </div>
</div>
