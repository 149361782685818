import { Component, Input } from '@angular/core';

@Component({
  selector: 'form-intro',
  templateUrl: './form-intro.component.html',
  styleUrls: ['./form-intro.component.scss'],
})
export class FormIntroComponent {
  @Input({ required: true }) public title!: string;

  @Input({ required: true }) public imagePath!: string;
}
