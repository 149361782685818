import { Injectable } from '@angular/core';
import { HttpHelperService } from '@core/services/http-helper.service';
import { ApiResponseMaileroo, DefaultService } from '@swagger/index';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MailerooService {
  constructor(
    private readonly defaultService: DefaultService,
    private readonly httpHelperService: HttpHelperService
  ) {}

  public verifyEmailAddress(email: string): Observable<ApiResponseMaileroo> {
    const options = this.httpHelperService.getDefaultBypassLoadingOptions(true);

    return this.defaultService.mailerooControllerVerifyEmail(email, undefined, undefined, options);
  }
}
