/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum SelfReportAction {
    FLAGGED_AS_COMMUTE = 'FLAGGED_AS_COMMUTE',
    MODE_CHANGED = 'MODE_CHANGED',
    TRIP_CREATED = 'TRIP_CREATED',
    MODE_CHANGED_AUTOMATIC = 'MODE_CHANGED_AUTOMATIC',
    AUTOMATIC_UPGRADE_TO_COMMUTE = 'AUTOMATIC_UPGRADE_TO_COMMUTE'
}

