/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildTrackingInfo } from './childTrackingInfo';
import { ChildInternalPropertiesUpdateModel } from './childInternalPropertiesUpdateModel';
import { ChildNotificationOptionsModel } from './childNotificationOptionsModel';
import { ChildShiftWorkProperties } from './childShiftWorkProperties';
import { ChildCommuteDataModel } from './childCommuteDataModel';


export interface ApiRequestUserUpdateModel { 
    displayName?: string | null;
    phoneNumber?: string | null;
    pushNotificationToken: string;
    internalProperties: ChildInternalPropertiesUpdateModel;
    notificationOptions: ChildNotificationOptionsModel;
    addresses: object;
    commuteData: ChildCommuteDataModel;
    trackingInfo?: ChildTrackingInfo | null;
    shiftWorkProperties: ChildShiftWorkProperties | null;
}

