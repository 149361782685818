import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AuthenticationStatusService } from '@core/services/authentication-status/authentication-status.service';
import { AuthenticationWrapperService } from '@core/services/authentication/authentication-wrapper.service';
import { ToastService } from '@core/services/toast/toast.service';
import { TranslateModule } from '@ngx-translate/core';
import { DialogId } from '@shared/enums/dialog-id.enum';
import { MaterialModule } from '@shared/modules/material/material.module';
import { take } from 'rxjs';
import { AuthEmailVerificationUpdateEmailDialogComponent } from 'src/app/pre-login/auth-email-verification/components/auth-email-verification-update-email-dialog/auth-email-verification-update-email-dialog.component';

export interface AuthEmailVerificationBlockDialogData {
  email: string;
}

@Component({
  selector: 'auth-email-verification-block-dialog',
  standalone: true,
  imports: [TranslateModule, MaterialModule, CommonModule],
  templateUrl: './auth-email-verification-block-dialog.component.html',
  styleUrls: ['./auth-email-verification-block-dialog.component.scss'],
})
export class AuthEmailVerificationBlockDialogComponent {
  public isAuthenticated: boolean;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: AuthEmailVerificationBlockDialogData,
    private readonly dialogService: Dialog,
    private readonly dialogRef: DialogRef<void>,
    private readonly authWrapperService: AuthenticationWrapperService,
    private readonly authStatusService: AuthenticationStatusService,
    private readonly toastService: ToastService
  ) {
    this.isAuthenticated = this.authStatusService.isAuthenticated();
  }

  public async onResendEmail(): Promise<void> {
    this.authWrapperService
      .resendEmailVerification(this.data.email)
      .pipe(take(1))
      .subscribe(() => {
        this.closeDialog();
        const isError = false;

        this.toastService.show(isError, 'verifyEmail.email.sent');
      });
  }

  public onClose(): void {
    this.closeDialog();
  }

  public onUpdateEmail(): void {
    this.dialogRef.close();
    AuthEmailVerificationUpdateEmailDialogComponent.open(this.dialogService, { email: this.data.email });
  }

  private closeDialog(): void {
    this.dialogRef.close();
  }

  public static open(
    dialog: Dialog,
    email: string
  ): DialogRef<AuthEmailVerificationBlockDialogData, AuthEmailVerificationBlockDialogComponent> {
    const config = {
      id: DialogId.AUTH_EMAIL_VERIFICATION_BLOCK_DIALOG,
      data: { email },
    };

    return dialog.open(AuthEmailVerificationBlockDialogComponent, config);
  }
}
