/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModeSegment } from './modeSegment';


export interface ModeDetails { 
    mainMode: ModeDetailsMainModeEnum | null;
    detectedMainMode: ModeDetailsDetectedMainModeEnum | null;
    selectedMainMode?: ModeDetailsSelectedMainModeEnum;
    usedModes: Array<ModeSegment>;
}
export enum ModeDetailsMainModeEnum {
    car = 'car',
    bike = 'bike',
    walk = 'walk',
    scooter = 'scooter',
    transit = 'transit',
    carpool = 'carpool',
    internalShuttle = 'internalShuttle'
};
export enum ModeDetailsDetectedMainModeEnum {
    car = 'car',
    bike = 'bike',
    walk = 'walk',
    scooter = 'scooter',
    transit = 'transit',
    carpool = 'carpool',
    internalShuttle = 'internalShuttle'
};
export enum ModeDetailsSelectedMainModeEnum {
    car = 'car',
    bike = 'bike',
    walk = 'walk',
    scooter = 'scooter',
    transit = 'transit',
    carpool = 'carpool',
    internalShuttle = 'internalShuttle'
};



