/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StripeInvoiceStatus } from './stripeInvoiceStatus';
import { InvoiceLineItem } from './invoiceLineItem';
import { StripeInvoiceCollectionMethod } from './stripeInvoiceCollectionMethod';


export interface Invoice { 
    collectionMethod: StripeInvoiceCollectionMethod;
    status?: StripeInvoiceStatus;
    stripeId: string;
    customerId: string;
    employerId: object;
    currency: string;
    description?: string;
    hostedInvoiceUrl?: string;
    lines: Array<InvoiceLineItem>;
    periodEnd: string;
    periodStart: string;
    subscriptionId?: string;
    total: number;
    subtotalExcludingTax?: number;
    tax?: number;
    invoicePdf?: string;
    paid: boolean;
    paidOutOfBand: boolean;
}



