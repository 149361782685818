/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildAddressModel } from './childAddressModel';
import { ChildEmployerGroupsConfig } from './childEmployerGroupsConfig';
import { ChildEmployerWorkPlaceModel } from './childEmployerWorkPlaceModel';
import { ChildEmployerEmailDomain } from './childEmployerEmailDomain';
import { Customer } from './customer';
import { Subscription } from './subscription';
import { TripModeEnum } from './tripModeEnum';
import { ChildConnectedUser } from './childConnectedUser';
import { ChildEmployerTripLogConfig } from './childEmployerTripLogConfig';


export interface ApiResponseEmployer { 
    possibleCommuteModes: Array<TripModeEnum>;
    id: string;
    name: string;
    code: string;
    phoneNumber?: string;
    email: string;
    address: ChildAddressModel | null;
    connectedUsers: Array<ChildConnectedUser>;
    logoFileName: string | null;
    workPlaces: Array<ChildEmployerWorkPlaceModel>;
    employerGroupsConfig: ChildEmployerGroupsConfig;
    emailDomains: Array<ChildEmployerEmailDomain>;
    isPublic: boolean;
    createdAt: string;
    subscription?: Subscription;
    customer?: Customer;
    connectCode?: string;
    tripLogConfig: ChildEmployerTripLogConfig;
    locale: string;
}

