import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { pageRoutes } from '@core/models/page-routes.model';
import { PermissionService } from '@core/services/permission/permission.service';
import { UserDataService } from '@core/services/user/user-data.service';
import { firstValueFrom, take } from 'rxjs';

export const DashboardAccessGuard: CanMatchFn = async () => {
  const userDataService = inject(UserDataService);
  const permissionService = inject(PermissionService);
  const router = inject(Router);

  await firstValueFrom(userDataService.userStream().pipe(take(1)));

  if (permissionService.isAllowedToAccessDashboard()) {
    return true;
  }

  return router.createUrlTree([pageRoutes.SELECT_EXISTING_COMPANY.path]);
};
