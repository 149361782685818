import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { pageRoutes } from '@core/models/page-routes.model';
import { PermissionService } from '@core/services/permission/permission.service';
import { UserDataService } from '@core/services/user/user-data.service';
import { firstValueFrom } from 'rxjs';

export const UserAppPreviewGuard: CanActivateFn = async () => {
  const permissionService = inject(PermissionService);
  const router = inject(Router);
  const userDataService = inject(UserDataService);

  let { connectedToEmployer, pendingJoinRequestAtEmployer } = userDataService.user;

  if (!connectedToEmployer && !pendingJoinRequestAtEmployer) {
    const refreshedUser = await firstValueFrom(userDataService.getMyUser());

    connectedToEmployer = refreshedUser.connectedToEmployer;
    pendingJoinRequestAtEmployer = refreshedUser.pendingJoinRequestAtEmployer;
  }

  if (!connectedToEmployer && !pendingJoinRequestAtEmployer) {
    return router.createUrlTree([pageRoutes.ONBOARDING.path]);
  }

  if (permissionService.isAllowedToAccessDashboard() && !permissionService.isAllowedToAccessOnboarding()) {
    return router.createUrlTree([pageRoutes.STATISTICS.path]);
  }

  return true;
};
