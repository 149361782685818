/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StripePriceRecurringInterval } from './stripePriceRecurringInterval';
import { StripePriceTaxBehavior } from './stripePriceTaxBehavior';
import { StripePriceRecurringAggregateUsage } from './stripePriceRecurringAggregateUsage';
import { PriceTier } from './priceTier';
import { StripePriceRecurringUsageType } from './stripePriceRecurringUsageType';
import { StripePriceBillingScheme } from './stripePriceBillingScheme';
import { StripePriceTiersMode } from './stripePriceTiersMode';


export interface Price { 
    billingScheme: StripePriceBillingScheme;
    taxBehavior?: StripePriceTaxBehavior;
    tiersMode?: StripePriceTiersMode;
    recurringAggregateUsage?: StripePriceRecurringAggregateUsage;
    recurringInterval?: StripePriceRecurringInterval;
    recurringUsageType?: StripePriceRecurringUsageType;
    stripeId: string;
    created: string;
    currency: string;
    lookupKey?: string;
    nickname?: string;
    productId: string;
    tiers?: Array<PriceTier>;
    type: object;
    unitAmount?: number;
    unitAmountDecimal?: string;
    recurringIntervalCount?: number;
}



