import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { ResponsiveService } from '@core/services/responsive.service';
import { CheckoutMobileAppDialogComponent } from '@shared/standalone/checkout-mobile-app-dialog/checkout-mobile-app-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CdkDialogHelperService {
  constructor(
    private readonly dialog: Dialog,
    private readonly responsiveService: ResponsiveService
  ) {}

  /**
   * Manually hide dialog
   * Useful when swapping between two dialogs
   * @param id
   */
  public hideDialog(id: string): void {
    const element = document.getElementById(id);

    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  /**
   * Manually show dialog
   * Useful when swapping between two dialogs
   * @param id
   */
  public showDialog(id: string): void {
    const element = document.getElementById(id);

    if (element) {
      element.style.visibility = 'visible';
    }
  }

  public openDialogViaQueryParam(param: string): void {
    switch (param) {
      case 'get-the-app':
        CheckoutMobileAppDialogComponent.open(this.dialog, this.responsiveService.isMobileView());
        break;

      default:
        break;
    }
  }

  /**
   * Moves a dialog based on it's id to the top by applying a higher zIndex
   * Useful if multiple overlapping dialogs are shown
   * @param id
   */
  public moveDialogToTheTop(id: string): void {
    const targetDiv = document.getElementById(id);
    const parentDiv = targetDiv?.parentElement;
    const grandParentDiv = parentDiv?.parentElement;
    const grandParentSiblingDiv = grandParentDiv?.previousElementSibling as HTMLElement;

    if (grandParentDiv && grandParentSiblingDiv) {
      grandParentSiblingDiv.style.zIndex = '9999999';
      grandParentDiv.style.zIndex = '9999999';
    }
  }
}
