<section class="px-4 py-8 md:p-10 flex flex-col justify-center items-center min-h-full md:h-full bg-light gap-6">
  <h1
    class="text-3xl sm:text-4xl mb-4 text-center max-w-[35rem]"
    [innerHTML]="'selectExistingCompany.title' | translate | safeHtml"
  ></h1>
  <p class="text-center px-16 mb-4 max-w-[45rem]">
    {{ 'selectExistingCompany.subtitle' | translate }}
  </p>

  <div class="max-w-[60rem] sm:px-[7rem] max-h-[35vh] w-full overflow-y-scroll">
    <request-access-employer-list
      [employerDetailsResponse]="employerDetails"
      (requestAccessEvent)="onRequestAccess($event)"
    ></request-access-employer-list>
  </div>

  <div class="flex flex-wrap items-center text-center justify-center gap-y-2">
    <span>{{ 'selectExistingCompany.createEmployerHint' | translate }}</span>
    <button color="primary" mat-button (click)="onContinueWithOnboarding()">
      {{ 'selectExistingCompany.buttonText' | translate }}
    </button>
  </div>

  <div>
    <span>
      <a [href]="helpCenterLink" target="_blank" class="text-gray-dark underline">
        {{ 'permissionMissing.helpCenterLinking' | translate }}</a
      >
    </span>
    <span class="underline ml-4 cursor-pointer text-gray-dark" (click)="onLogout()">{{
      'general.logout' | translate
    }}</span>
  </div>
</section>
