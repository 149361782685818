/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BillingAddress } from './billingAddress';
import { Payment } from './payment';
import { TaxId } from './taxId';


export interface Customer { 
    stripeCustomerId: string;
    name: string;
    description?: string;
    email: string;
    address?: BillingAddress;
    payment?: Payment;
    /**
     * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase.
     */
    currency?: string;
    phone?: string;
    locale?: string;
    taxIds: Array<TaxId>;
}

