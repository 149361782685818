import { CommonModule } from '@angular/common';
import {
  AfterRenderPhase,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  afterRender,
} from '@angular/core';
import { DeeplinkService } from '@core/services/deeplink.service';
import { QrCodeAuthWrapperService } from '@core/services/qr-code-auth-wrapper.service';
import { TranslateModule } from '@ngx-translate/core';
import { BackNavigationTitleComponent } from '@shared/components/back-navigation-title/back-navigation-title.component';
import { DeepLinkAction } from '@shared/enums/deep-link-action.enum';
import { MaterialModule } from '@shared/modules/material/material.module';
import { DownloadQrCodeComponent } from '@shared/standalone/download-qr-code/download-qr-code.component';

@Component({
  selector: 'app-preview',
  standalone: true,
  imports: [CommonModule, BackNavigationTitleComponent, MaterialModule, TranslateModule, DownloadQrCodeComponent],
  templateUrl: './app-preview.component.html',
  styleUrl: './app-preview.component.scss',
})
export class AppPreviewComponent implements OnInit, OnDestroy {
  public deepLink?: string;

  private iframeRef: HTMLElement | null = null;

  @ViewChild('iframeParentElement', { static: false }) public iframeParentElement!: ElementRef<HTMLElement>;

  constructor(
    private readonly qrCodeAuthWrapperService: QrCodeAuthWrapperService,
    private readonly deeplinkService: DeeplinkService
  ) {
    afterRender(
      () => {
        this.showIframe();
      },
      { phase: AfterRenderPhase.Read }
    );
  }

  public ngOnInit(): void {
    this.getCode();
  }

  public ngOnDestroy(): void {
    this.hideIframe();
  }

  public getCode(silentLoading = true): void {
    this.qrCodeAuthWrapperService.getCode(silentLoading).subscribe((code) => {
      this.deepLink = this.deeplinkService.createAppDeeplink(DeepLinkAction.qrCodeAuth, code);
    });
  }

  private hideIframe(): void {
    if (this.iframeRef) {
      this.iframeRef.classList.add('hidden');
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.showIframe();
  }

  private showIframe(): void {
    this.iframeRef = document.getElementById('app-preview-iframe');

    if (!this.iframeParentElement.nativeElement || !this.iframeRef) {
      return;
    }

    const iframeParentRect = this.iframeParentElement.nativeElement.getBoundingClientRect();
    const iframeRect = this.iframeRef.getBoundingClientRect();

    const left = iframeParentRect.left + (iframeParentRect.width - iframeRect.width) / 2;
    const top = iframeParentRect.top + (iframeParentRect.height - iframeRect.height) / 2;

    if (this.iframeRef) {
      this.iframeRef.classList.remove('hidden');
      this.iframeRef.style.left = `${left}px`;
      this.iframeRef.style.top = `${top}px`;
      this.iframeRef.style.height = `${iframeParentRect.height}px`;
    }
  }
}
