import { Region } from './enum/region.enum';
import { AmplifyConfig } from './interfaces/amplify-config.interface';

export const amplifyConfigProd: AmplifyConfig = {
  Auth: {
    identityPoolId: 'us-east-1:a2577634-8350-450a-a909-8b6ba65cc2fc',
    region: Region.US,
    userPoolId: 'us-east-1_aYrJiygpe',
    userPoolWebClientId: '25s5tlf73qmm9p8beft98npbtl',
    authenticationFlowType: 'CUSTOM_AUTH',
    oauth: {
      domain: 'paveapp.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://dashboard.pavecommute.app',
      redirectSignOut: 'https://dashboard.pavecommute.app',
      responseType: 'code',
    },
  },
  Storage: {
    customPrefix: { public: '', protected: '', private: '' },
    AWSS3: {
      bucket: 'rideamigos-pave-prod-private', // REQUIRED -  Amazon S3 bucket name
      region: Region.US, // OPTIONAL -  Amazon service region,
    },
  },
};
