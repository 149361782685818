import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { billingEmailAddress } from '@core/constants/contact.constants';
import { pageRoutes } from '@core/models/page-routes.model';

@Component({
  selector: 'subscription-expired-dialog',
  templateUrl: './subscription-expired-dialog.component.html',
  styleUrls: ['./subscription-expired-dialog.component.scss'],
})
export class SubscriptionExpiredDialogComponent {
  public readonly contactEmail = billingEmailAddress;

  constructor(
    private readonly router: Router,
    private readonly dialogRef: DialogRef<void>
  ) {}

  public onUpgradeNow(): void {
    this.router
      .navigate([pageRoutes.SETTINGS.path, pageRoutes.SETTINGS.children.BILLING.path])
      .then(() => this.dialogRef.close());
  }
}
