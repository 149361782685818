<mat-form-field
  [appearance]="appearance"
  [matTooltip]="tooltipContent"
  matTooltipPosition="above"
  class="w-full relative"
  [class.invalid]="isInvalid"
  #addressField
>
  <mat-icon *ngIf="showPrefixSearchIcon" matPrefix>search</mat-icon>
  <input
    #addressText
    timedAutofocus
    [enable]="autofocus"
    [delay]="autofocusDelay"
    [disabled]="isDisabled"
    [value]="defaultValue"
    matInput
    placeholder="{{ placeholder ?? ('employerSettings.workLocations.searchAddress' | translate) }}"
    required
    (input)="onInput()"
    (focus)="onFocus()"
    name="addressText"
  />

  @if (isLoadingEmployers()) {
    <mat-spinner matSuffix diameter="18" style="display: inline-flex; position: relative; top: -3px"></mat-spinner>
  }

  <button *ngIf="this.addressText.value" matSuffix mat-icon-button type="button" (click)="clearInput()">
    <mat-icon>close</mat-icon>
  </button>

  <mat-hint class="text-danger">
    <ng-content select="[mat-error-content]"></ng-content>
  </mat-hint>
</mat-form-field>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="addressText"
  [cdkConnectedOverlayOpen]="showOverlay()"
  [cdkConnectedOverlayWidth]="overlayWidth()"
  [cdkConnectedOverlayHasBackdrop]="false"
>
  <div
    *ngIf="employers.length > 0 || autocompleteItems.length > 0"
    class="custom-overlay p-4 bg-light border border-gray-light rounded-lg shadow-lg max-h-96 overflow-y-auto box-border w-full"
  >
    <div *ngIf="employers.length > 0" class="pb-4">
      <h3 class="font-semibold text-base mb-2">{{ 'general.employers' | translate }}</h3>
      <span class="text-gray-600 mb-4 flex"
        ><img class="h-6 w-6 mr-1" src="assets/images/emojis/1f4a1.svg" alt="info image" />{{
          'destinationOrEmployerPicker.hint' | translate
        }}</span
      >
      <div
        *ngFor="let employerDetail of employers"
        class="py-1 hover:bg-gray-ultralight rounded cursor-pointer"
        (click)="onSelectEmployer(employerDetail)"
      >
        <div class="flex items-start gap-2 pl-2">
          <div class="h-6 w-6 sm:w-8 sm:h-8 bg-gray-light rounded-xl shrink-0">
            <skeleton-image [imageSrc]="employerDetail.employerLogoUrl" [imageAlt]="'benefit image'">
              <div
                fallback
                class="rounded-[inherit] w-full h-full flex items-center justify-center text-xl text-gray-dark font-proximaNovaBold bg-gray-ultralight"
              >
                {{ employerDetail.employerInitials }}
              </div>
            </skeleton-image>
          </div>
          <div>
            <h4 class="font-semibold text-sm">{{ employerDetail.employerName }}</h4>
            <p>{{ employerDetail.address }}</p>
          </div>
        </div>
      </div>
      <hr />
    </div>

    <div *ngIf="autocompleteItems.length > 0">
      <h3 class="font-semibold text-base mb-2" *ngIf="employers.length > 0">{{ 'general.addresses' | translate }}</h3>
      <div
        *ngFor="let item of autocompleteItems"
        (click)="onSelectPlace(item.place_id)"
        class="cursor-pointer p-2 hover:bg-gray-ultralight rounded"
      >
        {{ item.description }}
      </div>
    </div>
  </div>
</ng-template>
