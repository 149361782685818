import { Region } from './enum/region.enum';
import { AmplifyConfig } from './interfaces/amplify-config.interface';

export const amplifyConfigQa: AmplifyConfig = {
  Auth: {
    identityPoolId: 'us-east-1:62bb9f0f-3321-472c-b33a-6432f0b8d8fc',
    region: Region.US,
    userPoolId: 'us-east-1_rMKMhynyv',
    userPoolWebClientId: '11heeqkoc37u39lqv886ge4c0n',
    authenticationFlowType: 'CUSTOM_AUTH',
    oauth: {
      domain: 'paveappqa.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://qa.dashboard.pavecommute.app',
      redirectSignOut: 'https://qa.dashboard.pavecommute.app',
      responseType: 'code',
    },
  },
  Storage: {
    customPrefix: { public: '', protected: '', private: '' },
    AWSS3: {
      bucket: 'rideamigos-pave-qa-private', // REQUIRED -  Amazon S3 bucket name
      region: Region.US, // OPTIONAL -  Amazon service region,
    },
  },
};
