/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildChallengeRanking } from './childChallengeRanking';
import { ChildChallengeGoal } from './childChallengeGoal';


export interface ApiResponseChallenge { 
    id: string;
    title: object;
    startDate: string;
    endDate: string;
    goal: ChildChallengeGoal;
    rankings: Array<ChildChallengeRanking>;
}

