import { Injectable } from '@angular/core';
import { defaultAddressRadiusInMeter } from '@core/constants/address.constant';
import { Address } from '@swagger/index';

@Injectable({
  providedIn: 'root',
})
export class AddressHelperService {
  public googlePlacesResultToAddress(placeResult: google.maps.places.PlaceResult, displayName?: string): Address {
    const address: Address = {
      lat: placeResult.geometry!.location?.lat()!,
      long: placeResult.geometry!.location?.lng()!,
      geofenceRadius: defaultAddressRadiusInMeter,
      text: displayName ?? placeResult.formatted_address,
      fullAddress: placeResult.formatted_address,
      zipCode: placeResult.address_components!.find((component) => component.types.includes('postal_code'))?.long_name!,
    };

    return address;
  }
}
