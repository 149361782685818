import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  ApiResponseUserWithLimitedDetails,
  ChildUserPermission,
  LocationPermissionGrantTypeEnum,
  LocationPermissionPrecisionEnum,
  SentianceStateEnum,
  TrackingMode,
} from '../../../api';
import { RewardCoinsService } from '../../../core/services/reward-coins/reward-coins.service';

@Component({
  selector: 'user-insight-checkbox-card',
  templateUrl: './user-insight-checkbox-card.component.html',
  styleUrls: ['./user-insight-checkbox-card.component.scss'],
})
export class UserInsightCheckboxCardComponent implements OnChanges {
  @Input() public user!: ApiResponseUserWithLimitedDetails;

  @Input() public userPermissions?: ChildUserPermission;

  @Output() public changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public isChecked!: boolean;

  public permissionsAreOk = false;

  constructor(private readonly rewardCoinsService: RewardCoinsService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.userPermissions) {
      this.checkPermissions();
    }
  }

  public onChange(event: MouseEvent): void {
    event.stopPropagation();
    this.changeEvent.next(this.isChecked);
  }

  public change(event: MatCheckboxChange): void {
    this.changeEvent.next(event.checked);
  }

  public onRewardCoins(): void {
    this.rewardCoinsService.openRewardCoinsDialog(this.user);
  }

  public checkPermissions(): void {
    if (!this.userPermissions) {
      this.permissionsAreOk = false;

      return;
    }

    const isBatteryOptimizationTurnedOff = this.userPermissions.permissions.batteryOptimizationTurnedOffDate
      ? true
      : false;
    const locationPermissionGrantTypeIsSet =
      this.userPermissions.permissions.locationPermissionGrantType === LocationPermissionGrantTypeEnum.always ||
      (this.userPermissions.permissions.locationPermissionGrantType === LocationPermissionGrantTypeEnum.whenInUse &&
        this.userPermissions.permissions.osName === 'iOS');
    const locationPermissionPrecisionIsSet =
      this.userPermissions.permissions.locationPermissionPrecision === LocationPermissionPrecisionEnum.fine;
    const isMotionPermissionGranted = this.userPermissions.permissions.motionPermissionGrantedDate ? true : false;
    const isSentianceStateOk =
      this.userPermissions.permissions.sentianceState === SentianceStateEnum.started ||
      (this.userPermissions.permissions.sentianceState === SentianceStateEnum.notStarted &&
        this.userPermissions.permissions.trackingMode === TrackingMode.MANUAL);

    this.permissionsAreOk =
      isBatteryOptimizationTurnedOff &&
      locationPermissionGrantTypeIsSet &&
      locationPermissionPrecisionIsSet &&
      isMotionPermissionGranted &&
      isSentianceStateOk;
  }
}
