<div class="cdk-dialog w-[920px]">
  <div class="cdk-dialog-header cdk-dialog-header--back">
    <button *ngIf="showBackButton" mat-icon-button (click)="onCancel()">
      <mat-icon class="cdk-dialog-header__back-button-icon">arrow_back</mat-icon>
    </button>

    <h1 class="cdk-dialog-header__title">
      {{
        (editMode
          ? 'employerSettings.workLocations.editDestinationAddresses'
          : 'employerSettings.workLocations.addDestinationAddresses'
        ) | translate
      }}
    </h1>
  </div>

  <div class="cdk-dialog-body cdk-dialog-body--separated">
    <form [formGroup]="form" id="destination-address-form">
      <div class="settings-input-block mt-0">
        <!-- google places input -->
        <div class="settings-input-row">
          <div class="settings-input-row__label-wrap">
            <h3 class="settings-input-row__label">{{ 'general.address' | translate }}</h3>
          </div>
          <div class="settings-input-row__input-wrap">
            <google-places-input
              class="w-full"
              [placeholder]="
                editMode
                  ? this.dialogData.selectedAddress!.fullAddress
                  : ('employerSettings.workLocations.searchAddress' | translate)
              "
              [tooltipContent]="
                editMode ? ('employerSettings.workLocations.editModeSearchAddressLockHint' | translate) : ''
              "
              [isDisabled]="editMode"
              [clearValueAfterSelection]="false"
              [isInvalid]="this.form.controls.place.dirty && this.form.controls.place.invalid"
              (placeSelected)="onSelectedAddress($event)"
            ></google-places-input>
          </div>
        </div>

        <!-- display name input -->
        <div class="settings-input-row">
          <div class="settings-input-row__label-wrap">
            <h3 class="settings-input-row__label">
              {{ 'employerSettings.workLocations.displayName' | translate }}
            </h3>
            <mat-icon
              [matTooltip]="'employerSettings.workLocations.displayNameExplanation' | translate"
              matTooltipPosition="above"
              [inline]="true"
              >help_outline</mat-icon
            >
          </div>
          <div class="settings-input-row__input-wrap">
            <mat-form-field>
              <input formControlName="displayName" matInput />
              <button
                *ngIf="form.controls.displayName.value !== null"
                matSuffix
                mat-icon-button
                type="button"
                (click)="onClearDisplayName()"
              >
                <mat-icon class="transform">close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>

        <!-- destination area -->
        <div>
          <div class="flex justify-between items-center">
            <div class="settings-input-row__label-wrap">
              <h3 class="settings-input-row__label">
                {{ 'employerSettings.workLocations.defineDestinationAreaLabel' | translate }}
              </h3>
              <mat-icon
                [matTooltip]="'employerSettings.workLocations.destinationAreaExplanation' | translate"
                [inline]="true"
                matTooltipPosition="above"
                >help_outline</mat-icon
              >
            </div>
            <button (click)="toggleSimpleMode()" mat-button color="primary">
              <mat-icon fontSet="material-icons-round">{{ simpleMode ? 'settings' : 'restore' }}</mat-icon>
              {{
                (simpleMode
                  ? 'employerSettings.workLocations.defineAreaManually'
                  : 'employerSettings.workLocations.useBasicRadius'
                ) | translate
              }}
            </button>
          </div>
          <div class="mt-5 flex flex-col gap-y-3">
            <ng-container *ngFor="let step of destinationAreaExplanationSteps">
              <div class="explanation">
                <img class="explanation__emoji" [src]="step.image" alt="computer mouse" />
                <p class="explanation__text">
                  {{ step.explanation }}
                </p>
              </div>
            </ng-container>
          </div>

          <div
            *ngIf="
              this.form.controls.place.valid && this.form.controls.polygons.dirty && this.form.controls.polygons.invalid
            "
            class="mt-5"
          >
            <info-explanation
              type="error"
              prefixIcon="info"
              [msg]="'employerSettings.workLocations.workLocationsError' | translate"
            ></info-explanation>
          </div>
          <div
            [ngClass]="
              this.form.controls.place.valid && this.form.controls.polygons.dirty && this.form.controls.polygons.invalid
                ? 'border-4 border-rose-misty'
                : ''
            "
            class="rounded-2xl overflow-hidden mt-5 relative"
          >
            <div class="h-[350px]">
              <draw-territory-map
                *ngIf="!simpleMode; else simpleMap"
                [polygonCoordinates]="this.polygonCoordinates"
                [markerAddress]="this.markerAddress"
                (deleteSelectedPolygon)="this.onDeleteSelectedPolygon($event)"
                (createdPolygon)="this.onCreatePolygon($event)"
                (deleteAllPolygons)="this.onDeleteAllPolygons()"
              ></draw-territory-map>

              <ng-template #simpleMap>
                <google-places-input-map-preview
                  [showRadius]="true"
                  [address]="markerAddress"
                  [mapOptions]="mapOptions"
                ></google-places-input-map-preview>
              </ng-template>
            </div>

            <div
              *ngIf="!this.editMode ? this.form.controls.place.value === null : false"
              class="absolute top-0 left-0 z-50 w-full h-full bg-light bg-opacity-95 flex flex-col items-center justify-center gap-y-2"
            >
              <mat-icon class="">search</mat-icon>
              {{ 'employerSettings.workLocations.emptyMapHint' | translate }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="cdk-dialog-actions">
    <button (click)="onCancel()" mat-flat-button>{{ 'general.cancel' | translate }}</button>
    <button (click)="onSave()" color="primary" mat-flat-button>
      {{ 'general.save' | translate }}
    </button>
  </div>
</div>
