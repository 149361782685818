<ng-template uploadAreaWrapper (fileDropped)="onFileUpload($event)">
  <div style="height: 100%" class="flex items-center justify-center">
    <div class="inner-container" *ngIf="!file">
      <img class="w-16" src="assets/images/emojis/1f5c2.svg" alt="emoji" />
      <h3>{{ 'user.importUsers.dragFile' | translate }}</h3>
      <p>{{ 'general.or' | translate }}</p>
      <upload-button (uploadFile)="onFileUploadButton()"></upload-button>
    </div>
    <div *ngIf="file" class="uploaded-file">
      <button mat-icon-button aria-label="Close Icon" (click)="removeFile()">
        <mat-icon>close</mat-icon>
      </button>
      <p class="file-name">{{ this.file.name }}</p>
      <button mat-flat-button color="primary" (click)="onImport()">
        <mat-icon fontSet="material-icons-round">upload</mat-icon>
        {{ 'user.importUsers.importNow' | translate }}
      </button>
    </div>
  </div>

  <input #fileInput (change)="onFileUpload($event!)" type="file" accept=".csv" hidden />
</ng-template>
