<section class="max-content-width min-h-full mx-auto flex flex-col gap-y-10">
  <div>
    <ng-content select="[header]"></ng-content>
  </div>

  <div class="grid grid-cols-2 gap-10 flex-grow">
    <div class="flex flex-col gap-y-6">
      <div class="flex-grow flex flex-col justify-center gap-y-4 max-w-[600px]">
        <h1 class="text-3xl mb-6">
          <ng-content select="[title]"></ng-content>
        </h1>
        <p>
          <ng-content select="[description]"></ng-content>
        </p>
        <div class="my-6">
          <download-qr-code [deepLink]="deepLink" (refresh)="getCode(false)">
            <h3 class="text-xl text-center" title>{{ 'appPreview.readyToDownload' | translate }}</h3>
          </download-qr-code>
        </div>
        <ng-content select="[cta]"></ng-content>
      </div>
    </div>
    <div #iframeParentElement></div>
  </div>
</section>
