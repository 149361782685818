<div class="sidebar-menu">
  <div class="sidebar-menu__title">
    {{ menuTitle }}
    <ng-content select="[titleSuffix]"></ng-content>
  </div>

  <sidebar-download-app-hint *ngIf="showDownloadAppHint"></sidebar-download-app-hint>

  <div *ngFor="let menuItem of menuItems">
    <mat-expansion-panel
      id="sidebar-expansion-panel"
      [class]="'sidebar-expansion-panel-' + menuItem.title"
      [disabled]="menuItem.children ? false : true"
      [expanded]="menuItem.selected"
    >
      <mat-expansion-panel-header
        [class.highlighted]="menuItem.selected"
        [class.selected]="menuItem.link === this.currentUrl && !menuItem.children"
        (click)="menuItem.children ? '' : onSidebarMenuClick(menuItem)"
      >
        <mat-panel-title>
          <div class="sidebar-menu__item">
            <mat-icon [fontSet]="menuItem.iconFontSet">{{ menuItem.icon }}</mat-icon>
            <p class="sidebar-menu__item-title">
              <span class="sidebar-menu__item-title-text">{{ 'sidebar.' + menuItem.title | translate }}</span>
              <span *ngIf="menuItem.notificationBadge" class="sidebar-menu__notification">{{
                menuItem.notificationBadge
              }}</span>
            </p>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!-- submenu -->
      <div *ngIf="menuItem.children" class="sidebar-menu__submenu">
        <mat-expansion-panel
          *ngFor="let subMenuItem of menuItem.children"
          id="sidebar-expansion-panel"
          [class]="'sidebar-expansion-panel-' + subMenuItem.title"
          [disabled]="true"
        >
          <mat-expansion-panel-header
            [class.selected]="subMenuItem.selected"
            [class.highlighted]="subMenuItem.selected"
            (click)="onSidebarMenuClick(subMenuItem)"
          >
            <mat-panel-title>
              <div class="sidebar-menu__item">
                <mat-icon [fontSet]="subMenuItem.iconFontSet">{{ subMenuItem.icon }}</mat-icon>
                <p class="sidebar-menu__item-title">
                  <span class="sidebar-menu__item-title-text">{{ 'sidebar.' + subMenuItem.title | translate }}</span>
                  <span *ngIf="subMenuItem.notificationBadge" class="sidebar-menu__notification">{{
                    subMenuItem.notificationBadge
                  }}</span>
                </p>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <!-- we do not show more than 1 submenu for now - a second layer of submenus would come here -->
        </mat-expansion-panel>
      </div>
    </mat-expansion-panel>
  </div>
</div>
