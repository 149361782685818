import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MaterialModule } from '@shared/modules/material/material.module';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialModule],
  selector: 'info-explanation',
  templateUrl: './info-explanation.component.html',
  styleUrls: ['./info-explanation.component.scss'],
})
export class InfoExplanationComponent {
  @Input() public type: 'info' | 'error' | 'smart-hint' | 'warn' | 'accent' = 'info';

  @Input({ required: true }) public msg!: string;

  @Input() public prefixIcon?: string;

  @Input() public prefixImageUrl?: string;
}
