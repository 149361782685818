<div
  class="statistics-filter-card-wrap"
  [class.disabled]="disabled"
  [matTooltip]="'dashboard.disableSampleDataToEnableFilter' | translate"
  [matTooltipDisabled]="!disabled"
>
  <div matRipple class="statistics-filter-card" #cdkMenuTrigger="cdkMenuTriggerFor" [cdkMenuTriggerFor]="menu">
    <mat-icon fontSet="material-icons-round">schedule</mat-icon>
    <span class="statistics-filter-card__text">
      <span class="font-redHatTextSemibold">{{ 'general.timeFrame' | translate }}: </span>
      <span class="font-redHatTextRegular">{{ 'timeFrame.' + selectedTimeFrameItem!.option | translate }}</span>
    </span>
    <mat-icon fontSet="material-icons-round">expand_more</mat-icon>

    <div class="statistics-filter-card__disabled-overlay"></div>
  </div>
  <ng-template #menu>
    <div cdkMenu>
      <calendar-range-selector
        [selectedTimeFrameItem]="selectedTimeFrameItem!"
        (timeFrameSelect)="onTimeFrameSelect($event)"
        (cancel)="onCancel()"
      ></calendar-range-selector>
    </div>
  </ng-template>
</div>
