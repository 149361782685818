<ng-container *ngFor="let employerDetail of employerDetails">
  <div
    class="flex flex-col sm:flex-row p-4 rounded-xl border-gray-light border-[1px] items-center gap-4 justify-between mb-4 last:mb-0"
  >
    <div class="flex items-center gap-4">
      <div class="h-12 w-12 sm:w-16 sm:h-16 bg-gray-light rounded-xl shrink-0">
        <skeleton-image [imageSrc]="employerDetail.employerLogoUrl" [imageAlt]="'benefit image'">
          <div
            fallback
            class="rounded-[inherit] w-full h-full flex items-center justify-center text-4xl text-gray-dark font-proximaNovaBold bg-gray-ultralight"
          >
            {{ employerDetail.employerInitials }}
          </div>
        </skeleton-image>
      </div>
      <div>
        <p class="font-redHatDisplayBold">
          {{ employerDetail.employerName }}
        </p>
        <p>{{ 'general.membersCount' | translate: { value: employerDetail.connectedUsers } }}</p>
      </div>
    </div>
    <button class="min-w-28" mat-flat-button color="primary" (click)="onRequestAccess(employerDetail.employerId)">
      {{ 'general.join' | translate }}
    </button>
  </div>
</ng-container>
