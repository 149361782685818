/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StripeSubscriptionCollectionMethod } from './stripeSubscriptionCollectionMethod';
import { SubscriptionItem } from './subscriptionItem';
import { StripeSubscriptionTrialSettingsEndBehaviorMissingPaymentMethod } from './stripeSubscriptionTrialSettingsEndBehaviorMissingPaymentMethod';


export interface Subscription { 
    collectionMethod: StripeSubscriptionCollectionMethod;
    trialSettingEndBehaviorMissingPaymentMethod?: StripeSubscriptionTrialSettingsEndBehaviorMissingPaymentMethod;
    stripeSubscriptionId: string;
    status: SubscriptionStatusEnum;
    cancelAt?: string;
    cancelAtPeriodEnd: boolean;
    canceledAt?: string;
    currency?: string;
    currentPeriodEnd: string;
    currentPeriodStart: string;
    /**
     * ID of the default payment method for the subscription. It must belong to the customer associated with the subscription. This takes precedence over `default_source`. If neither are set, invoices will use the customer\'s [invoice_settings.default_payment_method](https://stripe.com/docs/api/customers/object#customer_object-invoice_settings-default_payment_method) or [default_source](https://stripe.com/docs/api/customers/object#customer_object-default_source).
     */
    default_payment_method?: string;
    description?: string;
    items: Array<SubscriptionItem>;
    trialEnd?: string;
    trialStart?: string;
}
export enum SubscriptionStatusEnum {
    active = 'active',
    canceled = 'canceled',
    incomplete = 'incomplete',
    incomplete_expired = 'incomplete_expired',
    past_due = 'past_due',
    paused = 'paused',
    trialing = 'trialing',
    unpaid = 'unpaid'
};



