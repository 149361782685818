/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestEmployerStatistic } from '../model/models';
import { ApiResponseTangoOrders } from '../model/models';
import { ApiResponseTangoSummary } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface EmployerStatisticsServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    employerStatisticsControllerFindTotalMessagesSent(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    employerStatisticsControllerFindTotalMessagesSent_1(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    employerStatisticsControllerGetCurrentCoinBalance(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    employerStatisticsControllerGetCurrentCoinBalance_2(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param employerId 
     * @param page 
     */
    employerStatisticsControllerGetTangoOrders(employerId: string, page: number, extraHttpRequestParams?: any): Observable<ApiResponseTangoOrders>;

    /**
     * 
     * 
     * @param employerId 
     * @param page 
     */
    employerStatisticsControllerGetTangoOrders_3(employerId: string, page: number, extraHttpRequestParams?: any): Observable<ApiResponseTangoOrders>;

    /**
     * 
     * 
     * @param employerCode 
     */
    employerStatisticsControllerGetTangoSummary(employerCode: string, extraHttpRequestParams?: any): Observable<ApiResponseTangoSummary>;

    /**
     * 
     * 
     * @param employerCode 
     */
    employerStatisticsControllerGetTangoSummary_4(employerCode: string, extraHttpRequestParams?: any): Observable<ApiResponseTangoSummary>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    employerStatisticsControllerGetTotalRedeemedCoins(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

    /**
     * 
     * 
     * @param apiRequestEmployerStatistic 
     */
    employerStatisticsControllerGetTotalRedeemedCoins_5(apiRequestEmployerStatistic: ApiRequestEmployerStatistic, extraHttpRequestParams?: any): Observable<number>;

}
