<mat-toolbar
  *ngIf="
    (employerWrapperService.getEmployerStream() | async)?.subscription?.status === subscriptionStatus.past_due &&
    (employerWrapperService.getEmployerStream() | async)?.customer?.payment?.card
  "
  class="banner bg-corn"
>
  <div class="banner__content" [innerHTML]="'paymentPending.bannerNotice' | translate"></div>

  <button verifiedEmailRequired [clickVerifiedEmailFunc]="onUpgradeNow.bind(this)" mat-flat-button class="banner__cta">
    {{ 'paymentPending.updatePaymentMethod' | translate }}
  </button>
</mat-toolbar>
