/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildAddressModel } from './childAddressModel';
import { ChildEmployerDetails } from './childEmployerDetails';
import { UserSearchTag } from './userSearchTag';
import { TripModeEnum } from './tripModeEnum';


export interface ApiResponseUserSearchModel { 
    usedCommuteModes: Array<TripModeEnum>;
    tags: Array<UserSearchTag>;
    username: string;
    employer: ChildEmployerDetails;
    displayName: string;
    origin: ChildAddressModel;
    destination: ChildAddressModel;
    distanceAwayInMeters: number;
    commuteDayPref: object;
}

