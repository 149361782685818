/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeoJsonFeatureCollection } from './geoJsonFeatureCollection';


export interface RailDetectionResult { 
    /**
     * Value between 0.0 - 1.0 that indicates the likelihood being a rail transit
     */
    probability: number;
    matchingLatLongs: GeoJsonFeatureCollection;
    railFeaturesAlongLatLongs: GeoJsonFeatureCollection;
}

