/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildSocialFeedEntryComment } from './childSocialFeedEntryComment';
import { ChildButtonDetails } from './childButtonDetails';
import { ChildFeedEntryActor } from './childFeedEntryActor';
import { ChildSocialEventBadge } from './childSocialEventBadge';
import { SocialEventType } from './socialEventType';
import { ChildIconImgDetails } from './childIconImgDetails';


export interface ChildSocialFeedEntry { 
    type: SocialEventType;
    id: string;
    title: object;
    text: object;
    actor?: ChildFeedEntryActor;
    iconImage?: ChildIconImgDetails;
    reactions: object;
    comments: Array<ChildSocialFeedEntryComment>;
    buttonDetails?: ChildButtonDetails;
    badge?: ChildSocialEventBadge;
    createdAt: string;
}



