<div class="h-full flex flex-col pt-8 px-5 gap-y-6">
  <div class="flex flex-col items-stretch gap-3">
    <div class="flex justify-between">
      <img class="h-9" src="assets/images/brand/pave-icon-color.svg" alt="brand logo full" />

      <div class="w-10 h-10 absolute right-0 flex justify-end">
        <button
          mat-icon-button
          type="button"
          class="w-8 h-8 rounded-l-full rounded-w-none bg-gray-ultralight p-0 flex items-center justify-center z-50"
          (click)="layoutService.toggleSidebar(true)"
        >
          <mat-icon class="text-dark">first_page</mat-icon>
        </button>
      </div>
    </div>

    <p *ngIf="adminService.isAdministrator()" class="text-gray-dark text-sm leading-none flex items-center mt-2">
      <img [src]="regionService.getRegionImageUrl()" class="h-3.5" alt="region emoji" />
      <span> &nbsp;{{ envName | uppercase }} {{ region | uppercase }}&nbsp;</span>
      <span> {{ 'sidebar.DASHBOARD' | translate }} </span>
    </p>
  </div>

  <ng-container *ngIf="adminService.isAdministrator()">
    <hr />

    <sidebar-menu
      menuTitle="{{ 'sidebar.ADMIN' | translate }}"
      [menuItems]="adminMenuItems"
      [currentUrl]="currentUrl"
    ></sidebar-menu>

    <hr />
  </ng-container>

  <sidebar-menu
    menuTitle="{{ (employerWrapperService.getEmployerStream() | async)?.name }}"
    [menuItems]="managerMenuItems"
    [currentUrl]="currentUrl"
    [showDownloadAppHint]="true"
  >
    <span *ngIf="adminService.isAdministrator()" titleSuffix class="custom-label--accent-light">
      <ng-container
        *ngIf="
          (employerWrapperService.getEmployerStream() | async)?.subscription?.status !== subscriptionStatus.trialing &&
            !(employerWrapperService.getEmployerStream() | async)?.customer?.payment?.card;
          else stripeSubscription
        "
      >
        INVOICE
      </ng-container>
      <ng-template #stripeSubscription>{{
        (employerWrapperService.getEmployerStream() | async)?.subscription?.status | uppercase
      }}</ng-template>
    </span>
  </sidebar-menu>

  <!-- logout -->
  <div
    class="text-center absolute bottom-0 left-0 w-full flex justify-center items-center border-t border-gray-light h-16 bg-light"
  >
    <button (click)="amplifyService.logout()" color="warn" mat-button class="text-sm">
      <mat-icon fontSet="material-icons-round">logout</mat-icon>
      {{ 'general.logout' | translate }}
    </button>
  </div>
</div>
