import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { defaultAddressRadiusInMeter } from '@core/constants/address.constant';
import { RegionService } from '@core/services/region.service';
import { defaultHybridMapCircleOptions } from '@shared/constants/default-circle-options.constant';
import { defaultMapOptions } from '@shared/constants/default-map-option.constant';
import { defaultMarkerOptions } from '@shared/constants/default-marker-options.constant';

@Component({
  selector: 'google-places-input-map-preview',
  templateUrl: './google-places-input-map-preview.component.html',
  styleUrls: ['./google-places-input-map-preview.component.scss'],
})
export class GooglePlacesInputMapPreviewComponent implements OnChanges, OnInit {
  @Input()
  public address?: google.maps.LatLngLiteral;

  @Input()
  public showRadius = false;

  @Input({ required: false })
  public mapOptions?: google.maps.MapOptions;

  @ViewChild(GoogleMap, { static: true }) public googleMap!: GoogleMap;

  public readonly radius = defaultAddressRadiusInMeter;

  public circleOptions: google.maps.CircleOptions = {
    ...defaultHybridMapCircleOptions,
  };

  constructor(private readonly regionService: RegionService) {}

  public ngOnInit(): void {
    if (!this.mapOptions) {
      this.mapOptions = {
        ...defaultMapOptions(this.regionService.getRegion()),
        fullscreenControl: false,
        zoomControl: false,
      };
    }
  }

  public markerOptions: google.maps.MarkerOptions = defaultMarkerOptions();

  public onMapReady(): void {
    this.zoomToMarker();
  }

  private zoomToMarker(): void {
    if (this.address) {
      this.googleMap.googleMap?.setCenter(this.address);

      if (this.showRadius) {
        this.setCircleBounds();

        return;
      }

      this.googleMap.googleMap?.setZoom(17);
    }
  }

  private setCircleBounds(): void {
    const circle = new google.maps.Circle({
      center: this.address,
      radius: this.radius,
    });

    const bounds = circle.getBounds();

    if (bounds) {
      this.googleMap.googleMap?.fitBounds(bounds);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.address.isFirstChange() && changes.address.currentValue) {
      this.zoomToMarker();
    }
  }
}
