<div class="content">
  <h1 class="content__heading" [innerHTML]="'signup.getStartedHeading' | translate"></h1>
  <div class="region-switch">
    <p>{{ 'signup.accountRegionHint' | translate }}&nbsp;</p>
    <region-switch [selectedRegion]="region"></region-switch>
  </div>

  <form novalidate [formGroup]="form" class="form" id="signup">
    <mat-form-field subscriptSizing="dynamic" class="w-full" appearance="outline">
      <input
        (ngModelChange)="onChange()"
        matInput
        type="email"
        formControlName="email"
        placeholder="{{ 'general.emailAddress' | translate }}"
        autofocus
        autocomplete="email"
        name="email"
      />

      <mat-spinner
        *ngIf="form.controls.email.value && form.pending"
        matSuffix
        diameter="18"
        style="display: inline-flex; position: relative; top: -3px"
      ></mat-spinner>

      <button type="button" (click)="onClearEmail()" *ngIf="form.controls.email.value" mat-icon-button matSuffix>
        <mat-icon>{{ 'close' }}</mat-icon>
      </button>

      <mat-error *ngIf="form.controls.email.invalid">
        <span *ngIf="form.controls.email.hasError('required')">{{
          'formValidation.requiredError' | translate: { field: 'general.email' | translate }
        }}</span>
        <span *ngIf="form.controls.email.hasError('email')">{{ 'formValidation.emailError' | translate }}&nbsp;</span>
        <span
          *ngIf="form.controls.email.hasError('disposable') || form.controls.email.hasError('freeEmailDomain')"
          [innerHtml]="'formValidation.onlyWorkEmailAllowed' | translate"
        ></span>
      </mat-error>

      <mat-hint class="text-gray-dark" *ngIf="form.controls.email.valid && emailDomainSuggestionWarning">
        <domain-suggestion
          [domainSuggestion]="emailDomainSuggestionWarning"
          [email]="form.controls.email.value!"
          (suggestionUse)="onSuggestionUse($event)"
        ></domain-suggestion>
      </mat-hint>
    </mat-form-field>

    <div class="text-left w-full mt-2">
      <mat-checkbox
        color="primary"
        formControlName="legal"
        [class.invalid]="form.controls.legal.invalid && form.controls.legal.touched"
      >
        <span
          (click)="onLegalClick($event)"
          class="termsAndConditions block text-left"
          [innerHTML]="
            'signup.termsAndConditions'
              | translate
                : {
                    termsAndConditions: termsAndConditionsLink,
                    privacyPolicy: privacyPolicyLink
                  }
              | safeHtml
          "
        ></span>
      </mat-checkbox>
      <mat-error *ngIf="form.controls.legal.invalid && form.controls.legal.touched" class="text-xs border-t pt-1 ml-11">
        <span *ngIf="form.controls.legal.hasError('required')"
          >{{ 'formValidation.acceptLegalError' | translate }}
        </span>
      </mat-error>
    </div>

    <div>
      <button (click)="onSubmit()" class="form__submit" type="submit" mat-flat-button color="primary">
        {{ 'signup.getStartedButton' | translate }}
      </button>
    </div>
  </form>

  <social-options class="w-full" [isSignup]="true"></social-options>
</div>
