import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TabCommunicationService } from '@core/services/tab-communication.service';
import { ToastService } from '@core/services/toast/toast.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { distinctUntilChanged, filter, throttleTime } from 'rxjs';
import { EnterCodeForm } from '../../interfaces/enter-code-form.interface';

@Component({
  selector: 'enter-code',
  templateUrl: './enter-code.component.html',
  styleUrls: ['./enter-code.component.scss'],
})
export class EnterCodeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('pinInput') public pinInputFields!: QueryList<ElementRef>;

  @ViewChild(NgOtpInputComponent, { static: true }) public otpInput!: NgOtpInputComponent;

  @Input({ required: true }) public email!: string;

  @Output() public enterCode: EventEmitter<string> = new EventEmitter();

  @Output() public resendCode: EventEmitter<void> = new EventEmitter();

  public form!: FormGroup<EnterCodeForm>;

  private readonly pinLength = 6;

  public countDown: 60 | 0 = 0;

  public currCountDown = 0;

  public readonly config: NgOtpInputConfig = {
    length: this.pinLength,
    allowNumbersOnly: true,
    isPasswordInput: false,
    inputClass: 'custom-otp-input',
    containerClass: 'custom-otp-container',
  };

  public otpFormControl = new FormControl('', [Validators.required, Validators.minLength(this.pinLength)]);

  constructor(
    private readonly toastService: ToastService,
    private readonly tabCommunicationService: TabCommunicationService,
    private readonly utilsService: UtilsService
  ) {
    this.initSubscriptions();
  }

  public ngOnInit(): void {
    this.startCounter();
    this.initTabListener();
  }

  private initOtpListener(): void {
    this.otpFormControl.valueChanges
      .pipe(
        takeUntilDestroyed(),
        filter((value) => value?.length === 6),
        distinctUntilChanged(),

        // temporary fix as safari otp autocomplete fires twice
        throttleTime(500)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  public ngAfterViewInit(): void {
    const timeInMs = 300;

    this.utilsService.customWait(timeInMs, () => {
      const eleId = this.otpInput.getBoxId(0);

      this.otpInput.focusTo(eleId);
    });
  }

  public ngOnDestroy(): void {
    this.tabCommunicationService.otpCodeEntered.close();
  }

  private initTabListener(): void {
    this.tabCommunicationService.otpCodeEntered.onmessage = (message): void => {
      if (message.data === true) {
        window.location.reload();
      }
    };
  }

  private initSubscriptions(): void {
    this.initOtpListener();
  }

  public async onPaste(): Promise<void> {
    try {
      const otp = (await navigator.clipboard.readText()).trim();

      this.otpInput.setValue(otp);
    } catch {
      this.toastService.show(true, 'login.pasteNotSupported');
    }
  }

  public onSubmit(): void {
    if (this.otpFormControl.valid) {
      this.enterCode.emit(this.otpFormControl.value!);
    }
  }

  public onResendCode(): void {
    this.resendCode.emit();
  }

  private startCounter(): void {
    this.countDown = 60;
  }

  private stopCounter(): void {
    this.countDown = 0;
  }

  public onCounterUpdate(newValue: number): void {
    this.currCountDown = newValue;

    if (this.currCountDown === 0) {
      this.stopCounter();
    }
  }
}
