/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { RouteSection } from './routeSection';


export interface Route { 
    origin: Address;
    destination: Address;
    startTime: string;
    endTime: string;
    transportMode: RouteTransportModeEnum;
    durationInSeconds: number;
    lengthInMeters: number;
    sections: Array<RouteSection>;
}
export enum RouteTransportModeEnum {
    car = 'car',
    bike = 'bike',
    walk = 'walk',
    scooter = 'scooter',
    transit = 'transit',
    carpool = 'carpool',
    internalShuttle = 'internalShuttle'
};



