import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { environment as prodEuEnvironment } from 'src/environments/environment.prod-eu';
import { Configuration } from '../../api';
import { RegionService } from './region.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(
    private readonly regionService: RegionService,
    private readonly apiConfiguration: Configuration
  ) {}

  public init(): void {
    if (!environment.production) {
      // Angular already has loaded the correct environment
      return;
    }

    if (this.regionService.isRegionEU) {
      // Angular by default loads the prodUsEnvironment
      // Let's replace it with the prodEuEnvironment
      Object.assign(environment, prodEuEnvironment);

      // Angular loads Modules before the App Initializer correctly sets the region and environment
      // This ensures we override the basePath for the generated ApiModule
      this.apiConfiguration.basePath = prodEuEnvironment.apiUrl;
    }
  }
}
