export enum KompassifyEvent {
  KOMPASSIFY_BOOT_LOADER_IS_READY = 'KOMPASSIFY_BOOT_LOADER_IS_READY',
  KOMPASSIFY_TOUR_STARTED = 'KOMPASSIFY_TOUR_STARTED',
  KOMPASSIFY_TOUR_ENDED = 'KOMPASSIFY_TOUR_ENDED',
  KOMPASSIFY_TOUR_STEP = 'KOMPASSIFY_TOUR_STEP',
  KOMPASSIFY_TOUR_SKIPPED = 'KOMPASSIFY_TOUR_SKIPPED',
  KOMPASSIFY_PROGRESS_BAR_IS_READY = 'KOMPASSIFY_PROGRESS_BAR_IS_READY',
  KOMPASSIFY_PROGRESS_NEXT_CLICKED = 'KOMPASSIFY_PROGRESS_NEXT_CLICKED',
  KOMPASSIFY_PROGRESS_PREVIOUS_CLICKED = 'KOMPASSIFY_PROGRESS_PREVIOUS_CLICKED',
  KOMPASSIFY_CHECKLIST_V2_TASK_COMPLETED = 'KOMPASSIFY_CHECKLIST_V2_TASK_COMPLETED',
  KOMPASSIFY_CHECKLIST_V2_TASK_STARTED = 'KOMPASSIFY_CHECKLIST_V2_TASK_STARTED',
  KOMPASSIFY_EXECUTE_JS_CODE = 'KOMPASSIFY_EXECUTE_JS_CODE',
  KOMPASSIFY_DESTROY_PROGRESSBAR = 'KOMPASSIFY_DESTROY_PROGRESSBAR',
  KOMPASSIFY_SKIP_NPS_SURVEY = 'KOMPASSIFY_SKIP_NPS_SURVEY',
  KOMPASSIFY_CLOSE_NPS_SURVEY = 'KOMPASSIFY_CLOSE_NPS_SURVEY',
}
