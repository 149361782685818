/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PriceTier { 
    /**
     * Price for the entire tier.
     */
    flat_amount?: number;
    /**
     * Same as `flat_amount`, but contains a decimal value with at most 12 decimal places.
     */
    flat_amount_decimal?: string;
    /**
     * Per unit price for units relevant to the tier.
     */
    unit_amount?: number;
    /**
     * Same as `unit_amount`, but contains a decimal value with at most 12 decimal places.
     */
    unit_amount_decimal?: string;
    /**
     * Up to and including to this quantity will be contained in the tier.
     */
    up_to?: number;
}

