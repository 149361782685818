/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestSearchV2Groups } from '../model/models';
import { ApiRequestSendMessageToGroupsByMode } from '../model/models';
import { ApiRequestSendMessageToGroupsByModeForAdmins } from '../model/models';
import { ApiRequestUpsertGroup } from '../model/models';
import { ApiResponseGroupSearch } from '../model/models';
import { ApiResponseGroupV2 } from '../model/models';
import { ApiResponseSentMessageResponse } from '../model/models';
import { ApiResponseSuccessModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface GroupV2ServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param employerId 
     */
    groupV2ControllerDeleteAllByEmployerId(employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param employerId 
     */
    groupV2ControllerDeleteAllByEmployerId_1(employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     */
    groupV2ControllerFindMyGroups(extraHttpRequestParams?: any): Observable<Array<ApiResponseGroupV2>>;

    /**
     * 
     * 
     */
    groupV2ControllerFindMyGroups_2(extraHttpRequestParams?: any): Observable<Array<ApiResponseGroupV2>>;

    /**
     * 
     * 
     * @param groupId 
     */
    groupV2ControllerJoinGroup(groupId: string, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param groupId 
     */
    groupV2ControllerJoinGroup_3(groupId: string, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param groupId 
     */
    groupV2ControllerLeaveGroup(groupId: string, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param groupId 
     */
    groupV2ControllerLeaveGroup_4(groupId: string, extraHttpRequestParams?: any): Observable<ApiResponseSuccessModel>;

    /**
     * 
     * 
     * @param apiRequestSearchV2Groups 
     */
    groupV2ControllerSearchGroupsV2(apiRequestSearchV2Groups: ApiRequestSearchV2Groups, extraHttpRequestParams?: any): Observable<Array<ApiResponseGroupSearch>>;

    /**
     * 
     * 
     * @param apiRequestSearchV2Groups 
     */
    groupV2ControllerSearchGroupsV2_5(apiRequestSearchV2Groups: ApiRequestSearchV2Groups, extraHttpRequestParams?: any): Observable<Array<ApiResponseGroupSearch>>;

    /**
     * 
     * 
     * @param apiRequestSendMessageToGroupsByModeForAdmins 
     */
    groupV2ControllerSendGroupV2MessageByMode(apiRequestSendMessageToGroupsByModeForAdmins: ApiRequestSendMessageToGroupsByModeForAdmins, extraHttpRequestParams?: any): Observable<ApiResponseSentMessageResponse>;

    /**
     * 
     * 
     * @param apiRequestSendMessageToGroupsByMode 
     */
    groupV2ControllerSendGroupV2MessageByModeAndKey(apiRequestSendMessageToGroupsByMode: ApiRequestSendMessageToGroupsByMode, extraHttpRequestParams?: any): Observable<ApiResponseSentMessageResponse>;

    /**
     * 
     * 
     * @param apiRequestSendMessageToGroupsByMode 
     */
    groupV2ControllerSendGroupV2MessageByModeAndKey_6(apiRequestSendMessageToGroupsByMode: ApiRequestSendMessageToGroupsByMode, extraHttpRequestParams?: any): Observable<ApiResponseSentMessageResponse>;

    /**
     * 
     * 
     * @param apiRequestSendMessageToGroupsByModeForAdmins 
     */
    groupV2ControllerSendGroupV2MessageByMode_7(apiRequestSendMessageToGroupsByModeForAdmins: ApiRequestSendMessageToGroupsByModeForAdmins, extraHttpRequestParams?: any): Observable<ApiResponseSentMessageResponse>;

    /**
     * 
     * 
     * @param apiRequestUpsertGroup 
     */
    groupV2ControllerUpsertGroup(apiRequestUpsertGroup: ApiRequestUpsertGroup, extraHttpRequestParams?: any): Observable<ApiResponseGroupV2>;

    /**
     * 
     * 
     * @param apiRequestUpsertGroup 
     */
    groupV2ControllerUpsertGroup_8(apiRequestUpsertGroup: ApiRequestUpsertGroup, extraHttpRequestParams?: any): Observable<ApiResponseGroupV2>;

}
