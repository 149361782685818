import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'user-checkbox-card',
  templateUrl: './user-checkbox-card.component.html',
  styleUrls: ['./user-checkbox-card.component.scss'],
})
export class UserCheckboxCardComponent {
  @Input() public displayName?: string | null;

  @Input() public email!: string;

  @Output() public changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public isChecked!: boolean;

  public onChange(event: MouseEvent): void {
    this.changeEvent.next(this.isChecked);
    event.preventDefault();
  }

  public change(): void {
    this.isChecked = !this.isChecked;
    this.changeEvent.next(this.isChecked);
  }
}
