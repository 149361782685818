import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { ApiResponseSignupUserModel, AuthTypeEnum, DashboardSignupService, UtmChildModel } from '../../../api';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardSignupWrapperService {
  constructor(
    private readonly dashboardSignupService: DashboardSignupService,
    private readonly toastService: ToastService
  ) {}

  public signupEmail(email: string, locale: string, utm?: UtmChildModel): Observable<ApiResponseSignupUserModel> {
    return this.dashboardSignupService
      .dashboardSignupControllerSignUpEmail({
        email,
        locale,
        utm,
      })
      .pipe(
        catchError((error) => {
          this.toastService.show(true, 'user.loadingFailureMessage');
          throw error;
        })
      );
  }

  public signupSocial(
    email: string,
    locale: string,
    authType: AuthTypeEnum,
    firstName?: string,
    lastName?: string,
    utm?: UtmChildModel
  ): Observable<ApiResponseSignupUserModel> {
    return this.dashboardSignupService
      .dashboardSignupControllerSignUpSocial({
        authType,
        email,
        locale,
        firstName,
        lastName,
        utm,
      })
      .pipe(
        catchError((error) => {
          this.toastService.show(true, 'user.loadingFailureMessage');
          throw error;
        })
      );
  }
}
