import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class SourceInterceptor implements HttpInterceptor {
  private readonly headerField: string = 'source';

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newReq: HttpRequest<any> = this.setSourceHeader(req);

    return next.handle(newReq);
  }

  /**
   * Sets a "source" header field which allows the backend to determine from which application the request is coming from
   * @param req HttpRequest
   * @returns HttpRequest
   */
  private setSourceHeader(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      headers: req.headers.set(this.headerField, environment.applicationId),
    });
  }
}
