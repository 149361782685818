import { Injectable } from '@angular/core';
import { ToastService } from '@core/services/toast/toast.service';
import { Observable, catchError } from 'rxjs';
import { NotificationService } from '../../../api';

@Injectable({
  providedIn: 'root',
})
export class NotificationWrapperService {
  constructor(
    private readonly notificationService: NotificationService,
    private readonly toastService: ToastService
  ) {}

  public sendForManagerAccess(): Observable<void> {
    return this.notificationService.notificationControllerSendRequestManagerAccess().pipe(
      catchError((error) => {
        this.toastService.show(true, 'general.tryAgainAndContactSupportErrorMsg');
        throw error;
      })
    );
  }
}
