import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@shared/modules/material/material.module';

@Component({
  selector: 'signup-trust',
  standalone: true,
  imports: [MaterialModule, TranslateModule],
  templateUrl: './signup-trust.component.html',
  styleUrl: './signup-trust.component.scss',
})
export class SignupTrustComponent {}
