<div class="cdk-dialog w-[600px]">
  <img *ngIf="!hideIcon" src="assets/images/emojis/26a0.svg" class="w-12 h-12 mx-auto" alt="warn emoji" />

  <div class="cdk-dialog-header">
    <h1 class="cdk-dialog-header__title text-center">
      {{ title }}
    </h1>
  </div>

  <div class="cdk-dialog-body px-8 text-center">
    <p>{{ description }}</p>
  </div>

  <div class="cdk-dialog-actions justify-center">
    <button mat-flat-button (click)="onCancel()">{{ cancel | translate }}</button>
    <button [color]="color" mat-flat-button (click)="onConfirm()">
      {{ confirm }}
      <mat-icon>{{ 'checkmark' }}</mat-icon>
    </button>
  </div>
</div>
