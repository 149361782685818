import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { RegionService } from '@core/services/region.service';
import { ToastService } from '@core/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { defaultDrawingManagerOptions } from '@shared/constants/default-drawing-manager-options.constant';
import { defaultMarkerOptions } from '@shared/constants/default-marker-options.constant';
import { defaultHybridMapPolygonOptions } from '@shared/constants/default-polygon-option.constant';
import { firstValueFrom } from 'rxjs';
import { defaultHybridMapOptions } from '../../constants/default-map-option.constant';

export type Coordinates = [number, number][][][];

@Component({
  selector: 'draw-territory-map',
  templateUrl: './draw-territory-map.component.html',
  styleUrls: ['./draw-territory-map.component.scss'],
})
export class DrawTerritoryMapComponent implements OnChanges, OnInit {
  @Input() public markerAddress?: google.maps.LatLngLiteral;

  @Input() public polygonCoordinates?: number[][][][];

  @ViewChild(GoogleMap, { static: true }) public googleMap!: GoogleMap;

  public mapOptions!: google.maps.MapOptions;

  public markerOptions: google.maps.MarkerOptions = defaultMarkerOptions();

  public drawingManagerOptions: google.maps.drawing.DrawingManagerOptions = defaultDrawingManagerOptions();

  public polygonOptions: google.maps.PolygonOptions = defaultHybridMapPolygonOptions;

  private drawingManager?: google.maps.drawing.DrawingManager;

  private deleteSelectedPolygonButton!: HTMLDivElement;

  private deleteAllPolygonButton!: HTMLDivElement;

  private selectedPolygon?: google.maps.Polygon;

  private polygons: google.maps.Polygon[] = [];

  private readonly minPolygonPoints = 4;

  @Output() public deleteAllPolygons: EventEmitter<void> = new EventEmitter<void>();

  @Output() public deleteSelectedPolygon: EventEmitter<google.maps.Polygon> = new EventEmitter<google.maps.Polygon>();

  @Output() public createdPolygon: EventEmitter<google.maps.Polygon> = new EventEmitter<google.maps.Polygon>();

  constructor(
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
    private readonly regionService: RegionService
  ) {}

  public ngOnInit(): void {
    this.mapOptions = defaultHybridMapOptions(this.regionService.getRegion());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.markerAddress.firstChange && changes.markerAddress && changes.markerAddress.currentValue) {
      this.zoomToMarker();
    }
  }

  public onMapReady(): void {
    this.initControls();
    this.initDrawingManager();
    this.registerDrawingListener();
    this.zoomToMarker();
    this.addExistingPolygonsToMap();
  }

  public initControls(): void {
    this.createDeleteAllPolygonButton();
    this.initDeleteSelectedPolygonButton();
  }

  private zoomToMarker(): void {
    if (this.markerAddress) {
      this.googleMap.googleMap?.setCenter(this.markerAddress);
      this.googleMap.googleMap?.setZoom(17);
      this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].clear();
    }
  }

  private addExistingPolygonsToMap(): void {
    if (this.polygonCoordinates && this.polygonCoordinates.length > 0) {
      this.coordinatesToGoogleMapsPolygon();
      this.googleMap.fitBounds(this.getBounds());
    }
  }

  private coordinatesToGoogleMapsPolygon(): void {
    this.polygonCoordinates!.forEach((value) => {
      const latLngs: google.maps.LatLngLiteral[] = value
        .map((arr) => arr.map((latlng) => ({ lat: latlng[1], lng: latlng[0] })))
        .flat();

      const polygon = new google.maps.Polygon({
        paths: latLngs,
        ...this.polygonOptions,
        map: this.googleMap.googleMap!,
      });

      this.onCreatePolygon(polygon);
    });
  }

  private getBounds(): google.maps.LatLngBounds {
    const bounds = new google.maps.LatLngBounds();

    this.polygons.forEach((polygon) => {
      polygon.getPaths().forEach((path) => {
        path.getArray().forEach((point) => {
          bounds.extend(point);
        });
      });
    });

    return bounds;
  }

  private async createDeleteAllPolygonButton(): Promise<void> {
    const button = document.createElement('div');
    const controlUI = this.getControlUi();

    button.appendChild(controlUI);

    const controlText = await this.getControlTextUi('employer.edit.deleteAllPolygons');

    controlUI.appendChild(controlText);

    controlUI.addEventListener('click', () => {
      this.onDeleteAllPolygons();
    });

    this.deleteAllPolygonButton = button;

    if (this.polygonCoordinates && this.polygonCoordinates.length > 0) {
      this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.deleteAllPolygonButton);
    }
  }

  private async initDeleteSelectedPolygonButton(): Promise<void> {
    const button = document.createElement('div');
    const controlUI = this.getControlUi();

    button.appendChild(controlUI);

    const controlText = await this.getControlTextUi('employer.edit.deleteSelectedPolygon');

    controlUI.appendChild(controlText);

    controlUI.addEventListener('click', () => {
      this.onDeleteSelectedPolygon();
    });

    this.deleteSelectedPolygonButton = button;
  }

  private onDeleteAllPolygons(): void {
    this.polygons.forEach((polygon) => polygon.setMap(null));
    this.polygons = [];
    this.selectedPolygon = undefined;
    this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].clear();
    this.deleteAllPolygons.emit();
  }

  private onDeleteSelectedPolygon(): void {
    this.polygons = this.polygons.filter((polygon) => polygon !== this.selectedPolygon);

    this.deleteSelectedPolygon.emit(this.selectedPolygon);
    this.selectedPolygon?.setMap(null);
    this.selectedPolygon = undefined;

    if (this.polygons.length === 0) {
      this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].clear();

      return;
    }

    this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].pop();
  }

  private getControlUi(): HTMLDivElement {
    // Set CSS for the control border.
    const controlUI = document.createElement('div');

    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '2px solid #fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginTop = '8px';
    controlUI.style.marginRight = '8px';
    controlUI.style.marginBottom = '22px';
    controlUI.style.textAlign = 'center';

    return controlUI;
  }

  private async getControlTextUi(translateKey: string): Promise<HTMLDivElement> {
    // Set CSS for the control interior.
    const controlText = document.createElement('div');

    controlText.style.color = 'rgb(25,25,25)';
    controlText.style.fontFamily = 'RedHatText_SemiBold,Roboto,Arial,sans-serif';
    controlText.style.fontSize = '16px';
    controlText.style.lineHeight = '38px';
    controlText.style.paddingLeft = '10px';
    controlText.style.paddingRight = '10px';
    controlText.innerHTML = await firstValueFrom(this.translateService.get(translateKey));

    return controlText;
  }

  private initDrawingManager(): void {
    this.drawingManager = new google.maps.drawing.DrawingManager(this.drawingManagerOptions);
    this.drawingManager.setMap(this.googleMap.googleMap!);
  }

  private registerDrawingListener(): void {
    google.maps.event.addListener(
      this.drawingManager!,
      'overlaycomplete',
      (event: google.maps.drawing.OverlayCompleteEvent) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          if (event.overlay) {
            if (this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].getLength() === 0) {
              this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.deleteAllPolygonButton);
            }

            const polygon = event.overlay as google.maps.Polygon;

            const paths = polygon.getPaths();
            const numberOfPoints = paths.getAt(0).getLength();

            if (numberOfPoints < this.minPolygonPoints) {
              polygon.setMap(null);

              this.toastService.show(true, 'employerSettings.workLocations.polygonMinPointAmountError');

              return;
            }

            this.onCreatePolygon(polygon);
          }
        }
      }
    );
  }

  private onCreatePolygon(polygon: google.maps.Polygon): void {
    polygon.addListener('click', () => this.onClickPolygon(polygon));
    this.polygons.push(polygon);
    this.createdPolygon.emit(polygon);
  }

  private onClickPolygon(polygon: google.maps.Polygon): void {
    if (!this.selectedPolygon) {
      this.selectedPolygon = polygon;
      this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.deleteSelectedPolygonButton);
      polygon.setOptions({
        strokeColor: getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim(),
        fillColor: getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim(),
      });

      return;
    }

    this.selectedPolygon.setOptions({
      strokeColor: this.polygonOptions.strokeColor,
      fillColor: this.polygonOptions.fillColor,
    });
    this.selectedPolygon = undefined;
    this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].pop();
  }
}
