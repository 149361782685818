import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BYPASS_LOADING } from '@core/interceptors/loading-interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class HttpHelperService {
  public getDefaultBypassLoadingOptions(silentLoading: boolean): { context: HttpContext } {
    const options: { context: HttpContext } = {
      context: new HttpContext().set(BYPASS_LOADING, silentLoading),
    };

    return options;
  }
}
