import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { ApiResponseUserModel } from '@swagger/index';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  public setSentryUser(user: ApiResponseUserModel): void {
    Sentry.setUser({
      email: user.email,
      username: user.username,
    });
  }

  public captureException(e: any): void {
    Sentry.captureException(e);
  }
}
