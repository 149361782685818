import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Component, HostListener, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTab } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { pageRoutes } from '@core/models/page-routes.model';
import { AddressHelperService } from '@core/services/address-helper.service';
import { AmplifyService } from '@core/services/amplify/amplify.service';
import { DashboardLanguageService } from '@core/services/dashboard-language.service';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { MixpanelEvent } from '@core/services/mixpanel/mixpanel-event.enum';
import { MixpanelWrapperService } from '@core/services/mixpanel/mixpanel-wrapper.service';
import { PlatformService } from '@core/services/platform.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { SelfOnboardingWrapperServiceService } from '@core/services/self-onboarding-wrapper-service.service';
import { UserDataService } from '@core/services/user/user-data.service';
import { SelectExistingEmployerDialogComponent } from '@shared/standalone/select-existing-employer-dialog/select-existing-employer-dialog.component';
import { customCharacterValidator } from '@shared/validators/custom-character.validator';
import { ApiRequestSelfOnboarding } from '@swagger/index';
import { firstValueFrom, switchMap, take } from 'rxjs';
import { FirstNameForm } from '../../interfaces/first-name-form.interface';
import { LastNameForm } from '../../interfaces/last-name-form.interface';
import { OrganizationDestinationForm } from '../../interfaces/organization-destination-form.interface';
import { OrganizationNameForm } from '../../interfaces/organization-name-form.interface';

@Component({
  selector: 'onboarding-layout',
  templateUrl: './onboarding-layout.component.html',
  styleUrls: ['./onboarding-layout.component.scss'],
})
export class OnboardingLayoutComponent implements OnInit {
  public readonly tabAnimationDuration = 500;

  public readonly initialFocusDelay = 200;

  public backNavigationTriggered = false;

  public tabIndex = 0;

  public firstNameForm!: FormGroup<FirstNameForm>;

  public lastNameForm!: FormGroup<LastNameForm>;

  public organizationNameForm!: FormGroup<OrganizationNameForm>;

  public organizationDestinationForm!: FormGroup<OrganizationDestinationForm>;

  @ViewChildren(MatTab) public tabs?: QueryList<MatTab>;

  constructor(
    public readonly amplifyService: AmplifyService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly selfOnboardingWrapperService: SelfOnboardingWrapperServiceService,
    private readonly addressHelperService: AddressHelperService,
    private readonly userDataService: UserDataService,
    private readonly route: ActivatedRoute,
    private readonly employerWrapperService: EmployerWrapperService,
    private readonly dialog: Dialog,
    public readonly platformService: PlatformService,
    private readonly responsiveService: ResponsiveService,
    private readonly dashboardLanguageService: DashboardLanguageService,
    private readonly mixpanelWrapperService: MixpanelWrapperService
  ) {}

  public ngOnInit(): void {
    this.initForms();
    this.setCurrentTabAsQueryParam();
  }

  private initForms(): void {
    this.firstNameForm = this.formBuilder.group<FirstNameForm>({
      name: this.formBuilder.control(null, [Validators.required, Validators.minLength(2), customCharacterValidator()]),
    });

    this.lastNameForm = this.formBuilder.group<LastNameForm>({
      name: this.formBuilder.control(null, [Validators.required, Validators.minLength(2), customCharacterValidator()]),
    });

    this.organizationNameForm = this.formBuilder.group<OrganizationNameForm>({
      name: this.formBuilder.control(null, [Validators.required, Validators.minLength(2)]),
    });

    this.organizationDestinationForm = this.formBuilder.group<OrganizationDestinationForm>({
      place: this.formBuilder.control(null, [Validators.required]),
    });
  }

  @HostListener('document:keyup.enter', ['$event'])
  public onEnter(): void {
    this.onNext();
  }

  public onTabChange(): void {
    this.setCurrentTabAsQueryParam();
  }

  private setCurrentTabAsQueryParam(): void {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { tab: this.tabIndex + 1 },
      queryParamsHandling: 'merge',
    });
  }

  public onBack(): void {
    this.backNavigationTriggered = true;

    this.tabIndex -= 1;
  }

  public onNext(): void {
    switch (this.tabIndex) {
      case 0:
        this.submitFirstNameForm();
        break;

      case 1:
        this.submitLastNameForm();
        break;

      case 2:
        this.submitOrganizationNameForm();
        break;

      case 3:
        this.submitOrganizationDestinationForm();
        break;

      default:
        break;
    }
  }

  private submitFirstNameForm(): void {
    this.firstNameForm.markAllAsTouched();

    if (this.firstNameForm.valid) {
      this.tabIndex += 1;
      this.mixpanelWrapperService.track(MixpanelEvent.ONBOARDING_FIRST_NAME_COMPLETED);
    }
  }

  private submitLastNameForm(): void {
    this.lastNameForm.markAllAsTouched();

    if (this.lastNameForm.valid) {
      this.tabIndex += 1;
      this.mixpanelWrapperService.track(MixpanelEvent.ONBOARDING_LAST_NAME_COMPLETED);
    }
  }

  private submitOrganizationNameForm(): void {
    this.organizationNameForm.markAllAsTouched();

    if (this.organizationNameForm.valid) {
      this.checkForExistingEmployersByName();
      this.mixpanelWrapperService.track(MixpanelEvent.ONBOARDING_COMPANY_NAME_COMPLETED);
    }
  }

  private async checkForExistingEmployersByName(): Promise<void> {
    const name = this.organizationNameForm.controls.name.value!;

    const employers = await firstValueFrom(this.employerWrapperService.findByFuzzySearch(name));

    if (employers.length > 0) {
      const ref: DialogRef<boolean> = SelectExistingEmployerDialogComponent.open(
        this.dialog,
        this.responsiveService.isMobileView(),
        employers
      );

      ref.closed.pipe(take(1)).subscribe((continueOnboarding?: boolean) => {
        if (continueOnboarding) {
          this.tabIndex += 1;
        }
      });
    } else {
      this.tabIndex += 1;
    }
  }

  private submitOrganizationDestinationForm(): void {
    this.organizationDestinationForm.markAllAsTouched();

    if (this.organizationDestinationForm.valid) {
      this.mixpanelWrapperService.track(MixpanelEvent.ONBOARDING_COMPANY_LOCATION_COMPLETED);
      this.onFinishOnboarding();
    }
  }

  private onFinishOnboarding(): void {
    const request: ApiRequestSelfOnboarding = {
      locale: this.dashboardLanguageService.getCurrentDashboardLanguage(),
      firstName: this.firstNameForm.value.name!,
      lastName: this.lastNameForm.value.name!,
      organizationName: this.organizationNameForm.value.name!,
      organizationDestination: this.addressHelperService.googlePlacesResultToAddress(
        this.organizationDestinationForm.value.place!
      ),
    };

    this.selfOnboardingWrapperService
      .selfOnboarding(request)
      .pipe(switchMap(() => this.userDataService.getMyUser()))
      .subscribe(() => {
        this.router.navigate([pageRoutes.STATISTICS.path], {
          queryParams: { fromOnboarding: true },
        });
      });
  }

  public get illustrationPath(): string {
    switch (this.tabIndex) {
      case 0:
        return '/assets/images/illustrations/onboarding-slide-1.svg';

      case 1:
        return '/assets/images/illustrations/onboarding-slide-2.svg';

      case 2:
        return '/assets/images/illustrations/onboarding-slide-3.svg';

      case 3:
        return '/assets/images/illustrations/onboarding-slide-4.svg';

      default:
        return '/assets/images/illustrations/onboarding-slide-1.svg';
    }
  }
}
