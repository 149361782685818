/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PublicRecommendationConfig } from './publicRecommendationConfig';
import { PublicCarpoolConfig } from './publicCarpoolConfig';
import { PublicShuttleConfig } from './publicShuttleConfig';


export interface PublicGroupsConfig { 
    recommendationMode: PublicGroupsConfigRecommendationModeEnum;
    recommendationConfig: PublicRecommendationConfig;
    carpoolConfig: PublicCarpoolConfig;
    shuttleConfig?: PublicShuttleConfig;
}
export enum PublicGroupsConfigRecommendationModeEnum {
    SIMPLE_RECOMMENDATION = 'SIMPLE_RECOMMENDATION',
    GROUPS_V2_RECOMMENDATION = 'GROUPS_V2_RECOMMENDATION'
};



