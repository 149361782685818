import { Dialog } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { pageRoutes } from '@core/models/page-routes.model';
import { ResponsiveService } from '@core/services/responsive.service';
import { CheckoutMobileAppDialogComponent } from '@shared/standalone/checkout-mobile-app-dialog/checkout-mobile-app-dialog.component';

@Component({
  selector: 'sidebar-download-app-hint',
  templateUrl: './sidebar-download-app-hint.component.html',
  styleUrls: ['./sidebar-download-app-hint.component.scss'],
})
export class SidebarDownloadAppHintComponent {
  constructor(
    private readonly dialog: Dialog,
    private readonly responsiveService: ResponsiveService,
    private readonly router: Router
  ) {}

  public onOpenAppPreview(): void {
    if (this.responsiveService.isMobileView()) {
      CheckoutMobileAppDialogComponent.open(this.dialog, this.responsiveService.isMobileView());

      return;
    }

    this.router.navigate([pageRoutes.MANAGER_APP_PREVIEW.path]);
  }
}
