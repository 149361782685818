import { Pipe, PipeTransform } from '@angular/core';
import { LengthFormatterService } from '@core/services/length-formatter.service';

@Pipe({
  name: 'localizeMeter',
})
export class LocalizeMeterPipe implements PipeTransform {
  constructor(private readonly lengthFormatterService: LengthFormatterService) {}

  public transform(value: number): string | null {
    return `${this.lengthFormatterService.getMeterInLocaleValue(
      value
    )} ${this.lengthFormatterService.getMeterInLocaleAbbreviation()}`;
  }
}
