/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum SocialEventType {
    CHALLENGE_STARTED = 'CHALLENGE_STARTED',
    CHALLENGE_REACHED = 'CHALLENGE_REACHED',
    CHALLENGE_HALF_REACHED = 'CHALLENGE_HALF_REACHED',
    CHALLENGE_ENDED = 'CHALLENGE_ENDED',
    CHALLENGE_REACHED_WITH_STREAK = 'CHALLENGE_REACHED_WITH_STREAK',
    USER_REACHED_SUSTAINABLE_TRIP_MILESTONE = 'USER_REACHED_SUSTAINABLE_TRIP_MILESTONE',
    USER_TRACKED_FIRST_SUSTAINABLE_TRIP = 'USER_TRACKED_FIRST_SUSTAINABLE_TRIP',
    COMPANY_REACHED_DISTANCE_MILESTONE = 'COMPANY_REACHED_DISTANCE_MILESTONE',
    NEW_REWARD_AVAILABLE = 'NEW_REWARD_AVAILABLE',
    REACTION_TO_FEED = 'REACTION_TO_FEED',
    COMMENT_TO_FEED = 'COMMENT_TO_FEED',
    CUSTOM_MESSAGE = 'CUSTOM_MESSAGE',
    EMPLOYER_CREATED = 'EMPLOYER_CREATED'
}

