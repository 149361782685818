const colors = require('tailwindcss/colors');

/** @type {import('tailwindcss').Config} */
module.exports = {
  important: true,
  content: ['./src/**/*.{html,ts,scss,json}'],
  theme: {
    colors: {
      // some colors are also variables in styles/variables.css
      primary: '#00a89c',
      accent: '#2d2974',
      gray: {
        dark: '#9aa0af',
        medium: '#c1c8db',
        DEFAULT: '#c1c8db',
        light: '#ECEEF4',
        ultralight: '#F7F8FB',
      },
      'light-cyan': '#E3F8F6',
      danger: colors.rose[500],
      success: colors.green[500],
      warning: '#ffb01f',
      dark: '#000',
      light: {
        DEFAULT: '#ffffff',
      },
      transparent: '#fff0',
      bike: '#6064d2',
      walk: '#0e8dfb',
      car: '#e73668',
      carpool: '#ffb01f',
      transit: '#257e70',
      scooter: '#ff674d',
      internalShuttle: colors.cyan[500],
      periwinkle: {
        light: '#ebebff',
      },
      rose: {
        misty: '#ffdcd6',
        dark: '#A52830',
      },
      jonquil: '#f9c80e',
      corn: '#ffe47d',
      cornsilk: '#fef6d7',
      tomato: '#ff674d',
    },
    fontFamily: {
      redHatTextRegular: 'RedHatText_Regular',
      redHatTextSemibold: 'RedHatText_SemiBold',
      redHatDisplayBold: 'RedHatDisplay_Bold',
    },
    extend: {
      width: {
        sidebar: '260px',
      },
      boxShadow: {
        sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
        md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
        DEFAULT: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
        lg: ' 0 0px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
        xl: ' 0 0px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
        '2xl': '0 0px 50px -12px rgb(0 0 0 / 0.25)',
        inner: 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
        none: '0 0 #0000;',
      },
      animation: {
        marquee: 'marquee 7s linear infinite forwards',
      },
      keyframes: {
        marquee: {
          '0%': { transform: 'translate(0, 0)' },
          '100%': { transform: 'translate(-100%, 0)' },
        },
      },
    },
  },
  /*
    https://tailwindcss.com/docs/configuring-variants
    configure which utility variants are enabled (state variants like hover, responsive variants, ...)
    */
  variants: {},
  plugins: [],
};
