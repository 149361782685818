import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import localeEN_GB from '@angular/common/locales/en-GB';

import { Injectable } from '@angular/core';
import { europeanCountryCodes } from '@core/constants/european-country-codes.constant';
import { defaultLocale, supportedLocales } from '@core/constants/locale.constant';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  public readonly locale: string;

  constructor(private readonly translateService: TranslateService) {
    this.registerLocaleData();
    this.locale = this.init();
  }

  public get isLocaleFromEuropeanCountry(): boolean {
    const countryCode = this.locale.split('-')[1];

    return europeanCountryCodes.includes(countryCode.toUpperCase());
  }

  /**
   * All supported locales need to be registered here
   * Needed for angular localization (pipes) to automatically use the
   * correct locale.
   * @method registerLocalData
   */
  private registerLocaleData(): void {
    registerLocaleData(localeEN, 'en-US');
    registerLocaleData(localeDE, 'de-DE');
    registerLocaleData(localeEN_GB, 'en-GB');
  }

  private init(): string {
    const browserLocale = this.translateService.getBrowserCultureLang();

    let matchedLocale: string | undefined;

    if (browserLocale) {
      matchedLocale = supportedLocales.find((locale) => locale.startsWith(browserLocale));
    }

    if (matchedLocale) {
      return matchedLocale;
    }

    return defaultLocale;
  }
}
