import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface ConfirmDialogData {
  title: string;
  description: string;
  color?: 'warn' | 'primary' | 'accent';
  hideIcon?: boolean;
  cancelButton?: string;
  confirmButton?: string;
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  public title: string;

  public description: string;

  public color = 'warn';

  public hideIcon = false;

  public cancel: string;

  public confirm: string;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: ConfirmDialogData,
    public readonly dialogRef: DialogRef<boolean>,
    private readonly translateService: TranslateService
  ) {
    this.title = data.title;
    this.description = data.description;

    if (data.color) {
      this.color = data.color;
    }

    if (data.cancelButton) {
      this.cancel = data.cancelButton;
    } else {
      this.cancel = this.translateService.instant('general.cancel');
    }

    if (data.confirmButton) {
      this.confirm = data.confirmButton;
    } else {
      this.confirm = this.translateService.instant('general.continue');
    }

    if (data.hideIcon !== undefined) {
      this.hideIcon = data.hideIcon;
    }
  }

  public onConfirm(): void {
    this.closeDialog(true);
  }

  public onCancel(): void {
    this.closeDialog(false);
  }

  private closeDialog(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }
}
