import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'raw-data-dialog',
  templateUrl: './raw-data-dialog.component.html',
  styleUrls: ['./raw-data-dialog.component.scss'],
})
export class RawDataDialogComponent {
  public jsonData: unknown;

  constructor(
    @Inject(DIALOG_DATA) public readonly data: { jsonData: unknown },
    private dialogRef: DialogRef
  ) {
    this.jsonData = data.jsonData;
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
