<section class="flex flex-col h-full max-h-[100%]">
  <div class="card">
    <h2>Transport</h2>
    <mat-slide-toggle color="primary" [(ngModel)]="showTrajectory">Show Trajectory</mat-slide-toggle>
  </div>
  <br />
  <div class="card">
    <h2>Rail Detection</h2>
    <p>Probability: {{ result.result.railResult.probability }}</p>
    <mat-slide-toggle color="primary" [(ngModel)]="showRailMatchingLatLongs">Show Matching LatLongs</mat-slide-toggle
    ><br />
    <mat-slide-toggle color="primary" [(ngModel)]="showRailFeaturesAlongLatLongs">
      Show Rail Features Along LatLongs
    </mat-slide-toggle>
  </div>
  <br />
  <div class="card">
    <h2>Transit Stop Detection</h2>
    <p>Probability: {{ result.result.transitStopDetectionResult?.probability }}</p>
    <p>
      Matching Stop Segments: {{ result.result.transitStopDetectionResult?.matchingStopSegments?.features?.length }}
    </p>
    <p>Total Stop Segments: {{ result.result.transitStopDetectionResult?.stopSegments?.features?.length }}</p>
    <p>
      Found Transit Stops for Stop Segments:
      {{ result.result.transitStopDetectionResult?.transitStops?.features?.length }}
    </p>
    <mat-slide-toggle color="primary" [(ngModel)]="showTransitBusStopsFeatures">
      Show Bus Stop Features
    </mat-slide-toggle>
    <br />
    <mat-slide-toggle color="primary" [(ngModel)]="showTransitStopSegments">
      Show Detected Transit Stops Segments</mat-slide-toggle
    >
    <br />
    <mat-slide-toggle color="primary" [(ngModel)]="showTransitMatchingStopSegments">
      Show Matching Stop Segments
    </mat-slide-toggle>
  </div>

  <div class="h-full mt-5 mb-10">
    <map-visualization
      [showRailFeaturesAlongLatLongs]="showRailFeaturesAlongLatLongs"
      [showRailMatchingLatLongs]="showRailMatchingLatLongs"
      [showTransitBusStopsFeatures]="showTransitBusStopsFeatures"
      [showTransitMatchingStopSegments]="showTransitMatchingStopSegments"
      [showTransitStopSegments]="showTransitStopSegments"
      [showTrajectory]="showTrajectory"
      [result]="result"
    ></map-visualization>
  </div>
</section>
