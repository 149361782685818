import { LocalStorageKey } from '@shared/enums/local-storage-key.enum';
import { environment } from './environments/environment';

export class ScriptLoader {
  public static init(): Promise<[void]> {
    this.addGTMScript();
    this.addHotjarHeadScript();

    return Promise.all([this.loadGoogleMapsScript()]);
  }

  private static loadGoogleMapsScript(): Promise<void> {
    return this.loadScriptToHead(
      `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=geometry,places,drawing&callback=Function.prototype`
    );
  }

  private static addGTMScript(): void {
    this.addGTMDataLayerInitHeadScript();
    this.addGTMHeadScript();
    this.addGTMBodyScript();
  }

  private static addHotjarHeadScript(): void {
    const trafficType = localStorage.getItem(LocalStorageKey.traffic_type);

    if (trafficType === 'internal') {
      return;
    }

    const script = document.createElement('script');

    script.text = `<!-- Hotjar Tracking Code -->
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${environment.hotjarId},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
`;

    document.getElementsByTagName('head')[0].appendChild(script);
  }

  private static addGTMHeadScript(): void {
    const script = document.createElement('script');

    script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${environment.googleTagManagerAuth}&gtm_preview=${environment.googleTagManagerPreview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5CDRPPRJ');`;

    document.getElementsByTagName('head')[0].appendChild(script);
  }

  private static addGTMDataLayerInitHeadScript(): void {
    const script = document.createElement('script');

    script.text = `
    window.dataLayer = window.dataLayer || [];`;

    document.getElementsByTagName('head')[0].appendChild(script);
  }

  private static addGTMBodyScript(): void {
    const script = document.createElement('noscript');

    script.textContent = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5CDRPPRJ&gtm_auth=${environment.googleTagManagerAuth}&gtm_preview=${environment.googleTagManagerPreview}&gtm_cookies_win=x"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    document.getElementsByTagName('body')[0].appendChild(script);
  }

  private static loadScriptToHead(src: string, id?: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = src;

      if (id) {
        script.id = id;
      }

      script.onload = (): void => {
        resolve();
      };

      script.onerror = (): void => {
        reject();
      };

      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }
}
