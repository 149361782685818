<div class="flex flex-col justify-center gap-y-5">
  <hr class="w-full" />
  <div class="text-center">{{ (isSignup ? 'signup.socialOptionHint' : 'login.socialOptionHint') | translate }}</div>
  <div class="flex justify-center items-center gap-4">
    <div matRipple class="social-login-card" (click)="onSignInWithGoogle()">
      <img src="/assets/images/icons/login-with-google.svg" alt="google logo" />
    </div>
    <div *ngIf="!isSignup" matRipple class="social-login-card" (click)="onSignInWithApple()">
      <img src="/assets/images/icons/login-with-apple.svg" alt="apple logo" />
    </div>
  </div>
</div>
