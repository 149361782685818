<div class="w-full h-full relative">
  <!-- remote image -->
  <img
    *ngIf="imageSrc && !errorLoadingImage"
    (load)="onImageLoaded()"
    (error)="onImageError()"
    class="w-full h-full absolute z-30 top-0 left-0 object-contain"
    [alt]="imageAlt"
    [src]="imageSrc"
  />

  <!-- fallback widget (if provided will be shown instead of fallback image) -->
  <div #fallback>
    <div *ngIf="!imageSrc || errorLoadingImage" class="w-full h-full absolute top-0 left-0 z-20">
      <ng-content select="[fallback]"></ng-content>
    </div>
  </div>

  <!-- fallback image -->
  <img
    *ngIf="errorLoadingImage && fallback.children.length === 0"
    class="w-full h-full absolute z-10 top-0 left-0"
    [alt]="'fallback image'"
    [src]="fallbackImageSrc"
  />

  <!-- skeleton loading effect -->
  <div [hidden]="!isImageLoading" class="absolute top-0 left-0 w-full h-full skeleton-loading"></div>
</div>
