import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { ApiRequestManuallyReward, ApiResponseUserTransactionsExport, RewardsService } from '../../../api';

import { EmployerWrapperService } from '../employer/employer-wrapper.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class RewardsWrapperService {
  constructor(
    private readonly rewardsService: RewardsService,
    private readonly toastService: ToastService,
    private readonly employerWrapperService: EmployerWrapperService
  ) {}

  public manuallyRewardUser(username: string, request: ApiRequestManuallyReward): Observable<void> {
    return this.rewardsService.rewardsControllerManuallyReward(username, request).pipe(
      catchError((e) => {
        this.toastService.show(true, 'user.updateCoinsFailure');
        throw e;
      })
    );
  }

  public exportTransactions(
    rewardStyleConfigId: string,
    from: string,
    to: string
  ): Observable<ApiResponseUserTransactionsExport[]> {
    return this.rewardsService
      .rewardsControllerGetTransactions(this.employerWrapperService.employerId, rewardStyleConfigId, from, to)
      .pipe(
        catchError((error) => {
          this.toastService.show(true, 'incentiveNetworks.export.error');
          throw error;
        })
      );
  }
}
