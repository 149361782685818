/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Currency } from './currency';
import { UnitSystem } from './unitSystem';
import { AnalyticsCookie } from './analyticsCookie';


export interface ChildInternalPropertiesUpdateModel { 
    enabledCookies?: Array<AnalyticsCookie>;
    unitSystem?: UnitSystem;
    currency?: Currency;
    onboardingCompletedDate?: string | null;
    utcOffset: number;
    locale?: string;
    groupsOnboardingCompletedAt?: string;
    sentianceID?: string;
}



