import { Dialog } from '@angular/cdk/dialog';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { pageRoutes } from '@core/models/page-routes.model';
import { CdkDialogHelperService } from '@core/services/cdk-dialog-helper.service';
import { DashboardLanguageService } from '@core/services/dashboard-language.service';
import { IntercomService } from '@core/services/intercom.service';
import { LastDashboardOpenService } from '@core/services/last-dashboard-open.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { SubscriptionExpiredDialogComponent } from '@shared/components/subscription-expired-dialog/subscription-expired-dialog.component';
import { filter, take, withLatestFrom } from 'rxjs';

import { showToastQueryParam } from '@core/constants/query-param.constant';
import { GoogleAnalyticsService } from '@core/services/google-analytics/google-analytics.service';
import { KompassifyService } from '@core/services/kompassify/kompassify.service';
import { PaveWebAppEventService } from '@core/services/pave-web-app-event/pave-web-app-event.service';
import { ToastService } from '@core/services/toast/toast.service';
import { DialogId } from '@shared/enums/dialog-id.enum';
import { SubscriptionStatusEnum } from './api';
import { AdminService } from './core/services/admin/admin.service';
import { AuthenticationStatusService } from './core/services/authentication-status/authentication-status.service';
import { EmployerWrapperService } from './core/services/employer/employer-wrapper.service';
import { LoadingService } from './core/services/loading/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public loading = false;

  public isDialogOpen = false;

  public isEmployerLoaded = false;

  public isLoggedIn = false;

  public blockingDialogOpen = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly loadingService: LoadingService,
    public readonly adminService: AdminService,
    public readonly authStatusService: AuthenticationStatusService,
    public readonly employerWrapperService: EmployerWrapperService,
    private readonly dialog: Dialog,
    private readonly navigationService: NavigationService,
    private readonly dashboardLanguageService: DashboardLanguageService,
    private readonly lastDashboardOpenService: LastDashboardOpenService,
    private readonly router: Router,
    private readonly intercomService: IntercomService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cdkDialogHelperService: CdkDialogHelperService,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly toastService: ToastService,
    private readonly kompassifyService: KompassifyService,
    private readonly paveWebEventService: PaveWebAppEventService
  ) {
    this.kompassifyService.init();
    this.lastDashboardOpenService.init();
    this.navigationService.init();
    this.dashboardLanguageService.init();
    this.googleAnalyticsService.listenToEvents();
    this.paveWebEventService.init();
  }

  public ngOnInit(): void {
    this.initCDKDialogSubscriptions();
    this.initSubscriptionExpiredSubscription();
    this.initRemoveIntercomUserSubscription();
    this.initOpenDialogViaQueryParamSubscription();
    this.initShowToastViaQueryParamSubscription();

    this.loadingService
      .getLoadingStream()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => {
        this.loading = value;
      });

    this.employerWrapperService
      .getEmployerStream()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.isEmployerLoaded = true;
      });

    this.authStatusService
      .isLoggedInStream()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });
  }

  private initShowToastViaQueryParamSubscription(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        const param = this.activatedRoute.snapshot.queryParamMap.get(showToastQueryParam);

        if (!param) {
          return;
        }

        this.toastService.show(true, param);
        this.router.navigate([], {
          queryParams: { [showToastQueryParam]: null },
          queryParamsHandling: 'merge',
        });
      });
  }

  private initSubscriptionExpiredSubscription(): void {
    if (this.adminService.isAdministrator()) {
      return;
    }

    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        withLatestFrom(this.employerWrapperService.getEmployerStream()),
        filter(
          ([event, employer]) =>
            event instanceof NavigationEnd && employer.subscription?.status === SubscriptionStatusEnum.canceled
        )
      )
      .subscribe(([event]) => {
        const navigationEvent = event as NavigationEnd;

        if (
          !navigationEvent.url.includes(`/${pageRoutes.SETTINGS.path}/${pageRoutes.SETTINGS.children.BILLING.path}`)
        ) {
          this.showSubscriptionExpiredDialog();
        }
      });
  }

  private showSubscriptionExpiredDialog(): void {
    this.blockingDialogOpen = true;

    const dialogRef = this.dialog.open<void>(SubscriptionExpiredDialogComponent, {
      disableClose: true,
      maxHeight: '100vh',
      maxWidth: '100vw',
      hasBackdrop: false,
      id: DialogId.SUBSCRIPTION_EXPIRED_DIALOG,
    });

    dialogRef.closed.pipe(take(1)).subscribe(() => {
      this.blockingDialogOpen = false;
    });
  }

  private initCDKDialogSubscriptions(): void {
    this.dialog.afterOpened.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isDialogOpen = true;
    });
    this.dialog.afterAllClosed.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isDialogOpen = false;
    });
  }

  private initRemoveIntercomUserSubscription(): void {
    this.authStatusService
      .isLoggedInStream()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLoggedIn) => {
        if (!isLoggedIn) {
          this.intercomService.update({});
        }
      });
  }

  private initOpenDialogViaQueryParamSubscription(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        const param = this.activatedRoute.snapshot.queryParamMap.get('show-dialog');

        if (!param) {
          return;
        }

        this.cdkDialogHelperService.openDialogViaQueryParam(param);
      });
  }
}
