import { getLocaleCurrencyCode, getLocaleCurrencyName, getLocaleCurrencySymbol } from '@angular/common';
import { Injectable } from '@angular/core';
import { SupportedCurrencies } from '@shared/enums/supported-currencies.enum';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  public readonly currency: string;

  constructor(private readonly localeService: LocaleService) {
    this.currency = this.init();
  }

  private init(): string {
    if (this.localeService.isLocaleFromEuropeanCountry) {
      // for simplicity if current locale is from european country we use EUR
      return SupportedCurrencies.EUR;
    }

    return SupportedCurrencies.USD;
  }

  public get currencySymbol(): string | null {
    return getLocaleCurrencySymbol(this.localeService.locale);
  }

  public get currencyCode(): string | null {
    return getLocaleCurrencyCode(this.localeService.locale);
  }

  public get currencyName(): string | null {
    return getLocaleCurrencyName(this.localeService.locale);
  }
}
