import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { defaultAddressRadiusInMeter } from '@core/constants/address.constant';
import { AddressHelperService } from '@core/services/address-helper.service';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { RegionService } from '@core/services/region.service';
import { ToastService } from '@core/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { defaultHybridMapOptions } from '@shared/constants/default-map-option.constant';
import { LocalizeMeterPipe } from '@shared/pipes/localize-meter.pipe';
import {
  ChildAddressModel,
  ChildEmployerWorkPlaceModel,
  ChildPolygonModel,
  ChildPolygonModelTypeEnum,
} from '@swagger/index';
import { Observable } from 'rxjs';
import { GooglePlacesInputComponent } from '../google-places-input/google-places-input.component';

export interface DestinationAddressDialogData<T> {
  // if provided, the method is called before closing the dialog
  onSave?: (val: ChildEmployerWorkPlaceModel) => Observable<T>;
  selectedAddress?: ChildAddressModel;
  workplaceCoordinates?: ChildPolygonModel[];
  showBackButton?: boolean;
}

export interface DestinationAddressForm {
  place: FormControl<google.maps.places.PlaceResult | null>;
  displayName: FormControl<string | null>;
  polygons: FormControl<google.maps.Polygon[] | null>;
}

@Component({
  selector: 'destination-address-dialog',
  templateUrl: './destination-address-dialog.component.html',
  styleUrls: ['./destination-address-dialog.component.scss'],
})
export class DestinationAddressDialogComponent<T> implements OnInit {
  @ViewChild(GooglePlacesInputComponent, { static: true })
  public googlePlaceInputRef!: GooglePlacesInputComponent;

  public form!: FormGroup<DestinationAddressForm>;

  public markerAddress?: google.maps.LatLngLiteral;

  public polygonCoordinates?: number[][][][];

  public editMode = false;

  public simpleMode = true;

  public showBackButton = false;

  public destinationAreaExplanationSteps!: Array<{ image: string; explanation: string }>;

  public mapOptions?: google.maps.MapOptions;

  constructor(
    @Inject(DIALOG_DATA) public dialogData: DestinationAddressDialogData<T>,
    private readonly dialogRef: DialogRef,
    private readonly formBuilder: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly addressHelperService: AddressHelperService,
    private readonly translateService: TranslateService,
    private readonly localizeMeterPipe: LocalizeMeterPipe,
    private readonly employerWrapperService: EmployerWrapperService,
    private readonly toastService: ToastService,
    private readonly regionService: RegionService
  ) {
    if (this.dialogData?.selectedAddress && this.dialogData?.workplaceCoordinates) {
      this.editMode = true;
      this.simpleMode = this.dialogData?.workplaceCoordinates.length === 0;
      this.setMarkerAddress(this.dialogData?.selectedAddress.lat, this.dialogData?.selectedAddress.long);
      this.setPolygonCoordinates();
    }

    if (this.dialogData?.showBackButton) {
      this.showBackButton = this.dialogData.showBackButton;
    }
  }

  public ngOnInit(): void {
    this.mapOptions = {
      ...defaultHybridMapOptions(this.regionService.getRegion()),
      fullscreenControl: false,
      zoomControl: false,
    };
    this.initForm();
    this.initDestinationAreaExplanationSteps();
  }

  private initForm(): void {
    this.form = this.formBuilder.group<DestinationAddressForm>({
      place: this.formBuilder.control(null, this.editMode ? null : [Validators.required]),
      displayName: this.formBuilder.control(
        {
          value: this.dialogData?.selectedAddress ? this.dialogData.selectedAddress.text! : null,
          disabled: this.dialogData?.selectedAddress ? false : true,
        },
        [Validators.required]
      ),
      polygons: this.formBuilder.control([]),
    });

    this.updatePolygonsFormControlValidators();
  }

  private updatePolygonsFormControlValidators(): void {
    if (this.simpleMode) {
      this.form.controls.polygons.clearValidators();
    } else {
      this.form.controls.polygons.setValidators(Validators.required);
    }

    this.form.controls.polygons.updateValueAndValidity();
  }

  private initDestinationAreaExplanationSteps(): void {
    if (this.simpleMode) {
      this.destinationAreaExplanationSteps = [
        {
          image: 'assets/images/icons/pave-coin.svg',
          explanation: this.translateService.instant(
            'employerSettings.workLocations.destinationAreaBasicExplanation1',
            {
              radius: this.localizeMeterPipe.transform(defaultAddressRadiusInMeter),
            }
          ),
        },
        {
          image: 'assets/images/emojis/1f17f.svg',
          explanation: this.translateService.instant('employerSettings.workLocations.destinationAreaBasicExplanation2'),
        },
        {
          image: 'assets/images/emojis/1f4a1.svg',
          explanation: this.translateService.instant('employerSettings.workLocations.destinationAreaBasicExplanation3'),
        },
      ];

      return;
    }

    this.destinationAreaExplanationSteps = [
      {
        image: 'assets/images/emojis/1f5b1.svg',
        explanation: this.translateService.instant('employerSettings.workLocations.destinationAreaExplanation1'),
      },
      {
        image: 'assets/images/emojis/1f17f.svg',
        explanation: this.translateService.instant('employerSettings.workLocations.destinationAreaExplanation2'),
      },
      {
        image: 'assets/images/icons/pave-coin.svg',
        explanation: this.translateService.instant('employerSettings.workLocations.destinationAreaExplanation3'),
      },
    ];
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSave(): void {
    if (this.form.valid) {
      this.onFormSuccess();

      return;
    }

    this.onFormError();
  }

  private onFormSuccess(): void {
    const address = this.editMode ? this.updateAddress() : this.createAddress();

    if (this.dialogData?.onSave) {
      this.dialogData.onSave(address).subscribe((value) => {
        this.dialogRef.close(value);
      });

      return;
    }

    this.dialogRef.close(address);
  }

  private updateAddress(): ChildEmployerWorkPlaceModel {
    const displayName = this.form.value.displayName!;
    const polygons = this.form.value.polygons!;

    return {
      name: displayName,
      workAddresses: [
        {
          ...this.dialogData.selectedAddress!,
          text: displayName,
          geofenceRadius: defaultAddressRadiusInMeter,
        },
      ],
      territories: this.simpleMode
        ? []
        : polygons.map((polygon) => ({
            type: ChildPolygonModelTypeEnum.Polygon,
            coordinates: this.googleMapsPolygonToCoordinates(polygon),
          })),
    };
  }

  private createAddress(): ChildEmployerWorkPlaceModel {
    const displayName = this.form.value.displayName!;
    const place = this.form.value.place!;
    const polygons = this.form.value.polygons!;

    return {
      name: displayName,
      workAddresses: [this.addressHelperService.googlePlacesResultToAddress(place, displayName)],
      territories: this.simpleMode
        ? []
        : polygons.map((polygon) => ({
            type: ChildPolygonModelTypeEnum.Polygon,
            coordinates: this.googleMapsPolygonToCoordinates(polygon),
          })),
    };
  }

  private googleMapsPolygonToCoordinates(polygon: google.maps.Polygon): [number, number][][] {
    const paths: any[] = polygon.getPaths().getArray();
    const coordinates: [number, number][][] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const path of paths) {
      const pathArray: [number, number][] = [];
      const points = path.getArray();
      let firstPoint;

      // eslint-disable-next-line no-restricted-syntax
      for (const point of points) {
        if (!firstPoint) {
          firstPoint = point;
        }

        pathArray.push([point.lng(), point.lat()]);
      }

      pathArray.push([firstPoint.lng(), firstPoint.lat()]);

      coordinates.push(pathArray);
    }

    return coordinates;
  }

  private onFormError(): void {
    if (this.form.controls.place.invalid) {
      this.form.controls.place.markAsDirty();

      return;
    }

    if (this.form.controls.polygons.invalid) {
      this.form.controls.polygons.markAsDirty();
    }
  }

  public onClearDisplayName(): void {
    this.form.controls.displayName.patchValue(null);
  }

  private isExistingAddress(lat?: number, lng?: number): boolean {
    if (lat && lng) {
      return this.employerWrapperService.employer.workPlaces.some(
        (workPlace) => workPlace.workAddresses[0]?.lat === lat && workPlace.workAddresses[0]?.long === lng
      );
    }

    return false;
  }

  public onSelectedAddress(result: google.maps.places.PlaceResult | null): void {
    if (this.isExistingAddress(result?.geometry?.location?.lat(), result?.geometry?.location?.lng())) {
      this.googlePlaceInputRef.clearInput();
      this.toastService.show(true, 'employerSettings.workLocations.existingAddressError');

      return;
    }

    this.form.controls.place.patchValue(result);
    this.form.controls.place.markAsDirty();
    this.form.controls.displayName.patchValue(result?.formatted_address ?? null);
    this.form.controls.polygons.markAsPristine();

    this.onDeleteAllPolygons();

    if (result === null) {
      this.form.controls.displayName.disable();
      this.form.controls.displayName.markAsPristine();
      this.setMarkerAddress();

      return;
    }

    this.form.controls.displayName.enable();
    this.form.controls.displayName.markAsDirty();

    this.setMarkerAddress(result.geometry?.location?.lat(), result.geometry?.location?.lng());
  }

  private setMarkerAddress(lat?: number, lng?: number): void {
    if (lat && lng) {
      this.markerAddress = { lat, lng };

      return;
    }

    this.markerAddress = undefined;
  }

  private setPolygonCoordinates(): void {
    this.polygonCoordinates = this.dialogData.workplaceCoordinates!.map((value) => value.coordinates);
  }

  public onDeleteAllPolygons(): void {
    this.form.controls.polygons.patchValue([]);
  }

  public onDeleteSelectedPolygon(polygon: google.maps.Polygon): void {
    const polygons = [...(this.form.controls.polygons.value ?? [])];

    this.form.controls.polygons.patchValue(polygons.filter((e) => e !== polygon));
  }

  public onCreatePolygon(polygon: google.maps.Polygon): void {
    const polygons = [...(this.form.controls.polygons.value ?? []), polygon];

    this.form.controls.polygons.patchValue(polygons);
    this.form.controls.polygons.markAsDirty();

    // existing polygons might be created after the view was already rendered (because we have to wait for google maps to render)
    // therefore we manually need to trigger the change detection
    this.cd.detectChanges();
  }

  public toggleSimpleMode(): void {
    this.simpleMode = !this.simpleMode;
    this.updatePolygonsFormControlValidators();
    this.initDestinationAreaExplanationSteps();
    this.reApplyUnsavedPolygonCoordinates();
  }

  private reApplyUnsavedPolygonCoordinates(): void {
    if (!this.simpleMode && this.form.value.polygons) {
      this.polygonCoordinates = this.form.value.polygons.map((polygon) => this.googleMapsPolygonToCoordinates(polygon));
      this.form.controls.polygons.patchValue(null);
    }
  }
}
