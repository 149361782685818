export enum MixpanelEvent {
  AUTHENTICATED = 'AUTHENTICATED',
  ONBOARDING_FIRST_NAME_COMPLETED = 'ONBOARDING_FIRST_NAME_COMPLETED',
  ONBOARDING_LAST_NAME_COMPLETED = 'ONBOARDING_LAST_NAME_COMPLETED',
  ONBOARDING_COMPANY_NAME_COMPLETED = 'ONBOARDING_COMPANY_NAME_COMPLETED',
  ONBOARDING_COMPANY_SIZE_COMPLETED = 'ONBOARDING_COMPANY_SIZE_COMPLETED',
  ONBOARDING_COMPANY_LOCATION_COMPLETED = 'ONBOARDING_COMPANY_LOCATION_COMPLETED',
  GET_STARTED_PLATFORM_SELECTED = 'GET_STARTED_PLATFORM_SELECTED',
  VISITED_APP_PREVIEW = 'VISITED_APP_PREVIEW',
  COPY_INVITE_LINK = 'COPY_INVITE_LINK',
}
