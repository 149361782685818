import { Injectable } from '@angular/core';
import { AchievementPointTypeWithManualDeduction } from '../../../api';

@Injectable({
  providedIn: 'root',
})
export class PointTypeHelperService {
  /**
   * Returns only manual point types (reward manual points / deduct manual points)
   * @method getManualPointTypes
   */
  public getManualPointTypes(): AchievementPointTypeWithManualDeduction[] {
    const pointTypes: AchievementPointTypeWithManualDeduction[] = [
      AchievementPointTypeWithManualDeduction.manual,
      AchievementPointTypeWithManualDeduction.manualDeduction,
    ];

    return pointTypes;
  }
}
