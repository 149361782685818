import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeCast',
})
export class TypeCastPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public transform<T>(input: unknown, baseItem: T | undefined): T {
    return input as unknown as T;
  }
}
