/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AnalyticsCookie } from './analyticsCookie';
import { AuthTypeEnum } from './authTypeEnum';
import { UtmChildModel } from './utmChildModel';


export interface ApiRequestAuthSocialLoginModel { 
    authType: AuthTypeEnum;
    enabledCookies: Array<AnalyticsCookie>;
    displayName: string;
    email: string;
    refreshToken: string;
    locale: string;
    utm?: UtmChildModel;
}



