<div class="flex flex-col justify-center items-center gap-y-5 border border-gray-light rounded-xl p-8 text-center">
  <ng-content select="[title]"></ng-content>

  <div class="h-40 w-40 relative">
    <div class="absolute top-0 left-0 w-full h-full skeleton-loading"></div>
    <qrcode
      class="absolute left-0 top-0 z-50"
      *ngIf="this.deepLink"
      [qrdata]="this.deepLink"
      [width]="160"
      [errorCorrectionLevel]="'L'"
    ></qrcode>
  </div>

  <div>
    <h5 class="font-redHatTextSemibold text-base flex items-center gap-x-1.5">
      <img class="h-4 w-4" src="assets/images/icons/scan.svg" alt="scan icon" />
      {{ 'loginWithApp.scanToDownload' | translate }}
    </h5>
    <p secondsCounter [count]="countdown" (valueUpdate)="onCounterUpdate($event)" class="text-sm h-5">
      <span class="text-gray-dark" *ngIf="deepLink && (minutes > 0 || seconds > 0)">
        ({{ 'loginWithApp.qrCodeValidHint' | translate }}
        <span>
          <ng-container *ngIf="minutes > 0"> {{ minutes }}m </ng-container>
          {{ seconds }}s</span
        >)
      </span>
      <span *ngIf="deepLink && minutes === 0 && seconds === 0" class="text-danger">
        <span class="text-danger">
          {{ 'loginWithApp.qrCodeExpired' | translate }}
        </span>
        <span (click)="refresh.emit()" class="text-dark underline cursor-pointer">Refresh</span>
      </span>
    </p>
  </div>

  <info-explanation
    type="smart-hint"
    [msg]="'loginWithApp.qrCodeShareHint' | translate"
    prefixImageUrl="assets/images/emojis/1f4a1.svg"
  ></info-explanation>
</div>
