/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildAddressModel } from './childAddressModel';


export interface ApiRequestSuggestedGroups { 
    TripModeEnum?: ApiRequestSuggestedGroupsTripModeEnumEnum;
    useRecommendation: boolean;
    schedule?: object;
    destinations?: Array<ChildAddressModel>;
}
export enum ApiRequestSuggestedGroupsTripModeEnumEnum {
    car = 'car',
    bike = 'bike',
    walk = 'walk',
    scooter = 'scooter',
    transit = 'transit',
    carpool = 'carpool',
    internalShuttle = 'internalShuttle'
};



