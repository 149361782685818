import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DateHelperService } from '@core/services/date-helper/date-helper.service';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { StripeSubscriptionManagementWrapperService } from '@core/services/stripe-subscription-management/stripe-subscription-management-wrapper.service';
import { SubscriptionStatusEnum } from '@swagger/index';

@Component({
  selector: 'subscription-expires-soon-banner',
  templateUrl: './subscription-expires-soon-banner.component.html',
  styleUrls: ['./subscription-expires-soon-banner.component.scss'],
})
export class SubscriptionExpiresSoonBannerComponent {
  public remainingDaysInSubscription?: number;

  constructor(
    public readonly employerWrapperService: EmployerWrapperService,
    private readonly dateHelperService: DateHelperService,
    private readonly stripeSubscriptionManagementWrapperService: StripeSubscriptionManagementWrapperService
  ) {
    this.employerWrapperService
      .getEmployerStream()
      .pipe(takeUntilDestroyed())
      .subscribe((employer) => {
        if (employer.subscription?.status === SubscriptionStatusEnum.active && employer.subscription.cancelAt) {
          const today = this.dateHelperService.getEndOfToday();
          const subscriptionEnd = this.dateHelperService.getEndOfDate(new Date(employer.subscription.cancelAt));

          this.remainingDaysInSubscription = this.dateHelperService.getDayDifferenceBetweenDates(
            subscriptionEnd,
            today
          );
        } else {
          this.remainingDaysInSubscription = undefined;
        }
      });
  }

  public onUpgradeNow(): void {
    this.stripeSubscriptionManagementWrapperService.openCustomerPortal();
  }
}
