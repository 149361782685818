<div class="cdk-dialog w-[50rem] h-screen">
  <div class="cdk-dialog-header">
    <div class="flex justify-between items-center">
      <h1 mat-dialog-title class="cdk-dialog-header__title">
        {{ 'general.rawData' | translate }}
      </h1>
      <copy-to-clipboard [data]="jsonData"></copy-to-clipboard>
    </div>
  </div>

  <div class="cdk-dialog-body h-full">
    <div>
      <ngx-json-viewer [json]="jsonData"></ngx-json-viewer>
    </div>
  </div>

  <div class="cdk-dialog-actions">
    <button mat-flat-button (click)="onClose()">{{ 'general.close' | translate }}</button>
  </div>
</div>
