import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageKey } from '@shared/enums/local-storage-key.enum';

import { UnitSystem } from '@swagger/index';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root',
})
export class UnitSystemService {
  private unitSystem: UnitSystem;

  constructor(
    private readonly localeService: LocaleService,
    private readonly translateService: TranslateService,
    private readonly decimalPipe: DecimalPipe,
    private readonly localStorageService: LocalStorageService,
    private readonly utilsService: UtilsService
  ) {
    this.unitSystem = this.init();
  }

  private init(): UnitSystem {
    const localUnitSystem = this.getUnitSystemFromLocalStorage();

    if (localUnitSystem) {
      return localUnitSystem;
    }

    if (this.localeService.locale.toUpperCase().includes('US')) {
      return UnitSystem.imperial;
    }

    return UnitSystem.metric;
  }

  private getUnitSystemFromLocalStorage(): UnitSystem | null {
    const unitSystem = this.localStorageService.getItem(LocalStorageKey.unitSystem);

    if (unitSystem) {
      const enumUnitSystem = this.utilsService.convertStringToEnum(UnitSystem, unitSystem, false);

      if (enumUnitSystem) {
        return enumUnitSystem;
      }
    }

    return null;
  }

  public getUnitSystem(): UnitSystem {
    return this.unitSystem;
  }

  public setUnitSystem(unitSystem: UnitSystem): void {
    this.unitSystem = unitSystem;
    this.saveUnitSystemInLocalStorage(unitSystem);
  }

  private saveUnitSystemInLocalStorage(unitSystem: UnitSystem): void {
    this.localStorageService.setItem(LocalStorageKey.unitSystem, unitSystem);
  }
}
