<section class="min-h-full h-full flex flex-col">
  <div
    class="flex-grow bg-light flex flex-col md:grid md:grid-cols-2 gap-10 items-center px-4 py-8 md:p-10 justify-center"
  >
    <div class="w-full max-w-lg mx-auto">
      <mat-tab-group
        (selectedTabChange)="onTabChange()"
        animationDuration="{{ this.tabAnimationDuration }}ms"
        class="no-header"
        [(selectedIndex)]="tabIndex"
      >
        <mat-tab>
          <ng-template matTabContent>
            <first-name-form
              [form]="firstNameForm"
              [tabAnimationDuration]="backNavigationTriggered ? tabAnimationDuration : initialFocusDelay"
            ></first-name-form>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template matTabContent>
            <last-name-form
              [form]="lastNameForm"
              [firstName]="firstNameForm.value.name ?? ''"
              [tabAnimationDuration]="tabAnimationDuration"
            ></last-name-form>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template matTabContent>
            <organization-name-form
              [form]="organizationNameForm"
              [tabAnimationDuration]="tabAnimationDuration"
            ></organization-name-form>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template matTabContent>
            <organization-destination-form
              [form]="organizationDestinationForm"
              [tabAnimationDuration]="tabAnimationDuration"
            ></organization-destination-form>
          </ng-template>
        </mat-tab>
      </mat-tab-group>

      <div class="flex gap-x-4 mt-4 md:mt-8 items-center">
        <button *ngIf="this.tabIndex !== 0" type="button" (click)="onBack()" mat-button color="primary">
          {{ 'general.back' | translate }}
        </button>
        <button type="button" (click)="onNext()" mat-flat-button color="primary">
          {{ (this.tabs && this.tabs.length - 1 === this.tabIndex ? 'general.save' : 'general.next') | translate }}
        </button>
        <div class="hidden xl:flex font-redHatTextSemibold text-sm items-center gap-1">
          {{ 'onboarding.orPressEnter' | translate }}
          <mat-icon inline="true" fontSet="material-icons-round">keyboard_return</mat-icon>
        </div>
        <div class="ml-auto text-sm text-gray-dark">
          {{ 'onboarding.steps' | translate: { current: tabIndex + 1, max: tabs?.length } }}
        </div>
      </div>
    </div>

    <img class="hidden md:block max-w-lg mx-auto" [src]="illustrationPath" alt="illustration" />
  </div>

  <div *ngIf="!this.platformService.isMobileAppWebView()" class="content-block flex items-center justify-center py-4">
    <button (click)="amplifyService.logout()" mat-button class="text-gray-dark text-sm underline">
      <mat-icon fontSet="material-icons-round">logout</mat-icon>
      {{ 'general.logout' | translate }}
    </button>
  </div>
</section>
