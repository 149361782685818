import { Component } from '@angular/core';
import { AmplifyService } from '../../core/services/amplify/amplify.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent {
  constructor(public readonly amplifyService: AmplifyService) {}
}
