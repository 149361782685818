import { Injectable } from '@angular/core';
import { AuthenticationTokenService } from '@core/services/authentication-token.service';
import { AWSUserRole } from '@shared/enums/aws-user-role.enum';
import { JwtToken } from '@shared/interfaces/jwt-token.interface';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private readonly authenticationTokenService: AuthenticationTokenService) {}

  public isAdministrator(): boolean {
    const idToken = this.authenticationTokenService.getIdToken();

    if (idToken.length > 0) {
      const decoded = jwtDecode(idToken) as JwtToken;

      return decoded['cognito:groups']?.includes(AWSUserRole.administrator) ?? false;
    }

    return false;
  }
}
