import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { pageRoutes } from '@core/models/page-routes.model';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { PermissionService } from '@core/services/permission/permission.service';
import { UserDataService } from '@core/services/user/user-data.service';
import { UserWrapperService } from '@core/services/user/user-wrapper.service';
import { firstValueFrom } from 'rxjs';

export const SelectExistingEmployerGuard: CanActivateFn = async () => {
  const permissionService = inject(PermissionService);
  const employerWrapperService = inject(EmployerWrapperService);
  const userWrapperService = inject(UserWrapperService);
  const userDataService = inject(UserDataService);

  const router = inject(Router);
  const domain = userDataService.getEmailDomain();

  const domainExistsOrCanConnect =
    (await firstValueFrom(employerWrapperService.canDomainConnect(domain))) ||
    (await firstValueFrom(userWrapperService.detectedAutomaticEmailDomain(domain)));

  if (permissionService.isAllowedToAccessOnboarding() && domainExistsOrCanConnect) {
    return true;
  }

  return router.createUrlTree([pageRoutes.ONBOARDING.path]);
};
