import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@shared/modules/material/material.module';

@Component({
  selector: 'switch-form',
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
  templateUrl: './switch-form.component.html',
  styleUrl: './switch-form.component.scss',
})
export class SwitchFormComponent {
  @Input({ required: true }) public isSignup!: boolean;

  @Input({ required: true }) public onEnterCodeStep!: boolean;

  @Output() public toggleLoginSignup: EventEmitter<void> = new EventEmitter();

  @Output() public backToLoginSignup: EventEmitter<void> = new EventEmitter();
}
