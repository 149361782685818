/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiResponseChallengeDetails } from '../model/models';
import { ApiResponseUserDetailsStats } from '../model/models';
import { UserDetailsTimeFrame } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UserDetailsServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param username 
     */
    userDetailsControllerGet(username: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseChallengeDetails>>;

    /**
     * 
     * 
     * @param timeFrame 
     * @param username 
     */
    userDetailsControllerGetProfileStats(timeFrame: UserDetailsTimeFrame, username: string, extraHttpRequestParams?: any): Observable<ApiResponseUserDetailsStats>;

    /**
     * 
     * 
     * @param timeFrame 
     * @param username 
     */
    userDetailsControllerGetProfileStats_1(timeFrame: UserDetailsTimeFrame, username: string, extraHttpRequestParams?: any): Observable<ApiResponseUserDetailsStats>;

    /**
     * 
     * 
     * @param username 
     */
    userDetailsControllerGet_2(username: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseChallengeDetails>>;

}
