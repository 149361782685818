/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum SelectableEmployerFoundByReason {
    EMAIL_DOMAIN = 'EMAIL_DOMAIN',
    CONNECT_CODE = 'CONNECT_CODE',
    FUZZY_SEARCH = 'FUZZY_SEARCH'
}

