import { Component, Input } from '@angular/core';
import { ToastService } from '../../../core/services/toast/toast.service';

@Component({
  selector: 'copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
})
export class CopyToClipboardComponent {
  @Input()
  public data: unknown;

  constructor(private readonly toastService: ToastService) {}

  public copyToClipboard(): void {
    if (navigator.clipboard) {
      const trajectoryAsJson = JSON.stringify(this.data);

      navigator.clipboard.writeText(trajectoryAsJson).then(
        () => {
          this.toastService.show(false, 'Copied to Clipboard');
        },
        () => {
          this.toastService.show(true, 'Could not copy to Clipboard');
        }
      );
    } else {
      this.toastService.show(true, 'Browser does not support Clipboard API');
    }
  }
}
