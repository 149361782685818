import { Component, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { EmployerConnectWrapperService } from '@core/services/employer-connect/employer-connect-wrapper.service';

import { pageRoutes } from '@core/models/page-routes.model';
import { ApiResponseSelectableForDashboardEmployerWithAddress } from '@swagger/index';
import { OrganizationDestinationForm } from '../../interfaces/organization-destination-form.interface';

@Component({
  selector: 'organization-destination-form',
  templateUrl: './organization-destination-form.component.html',
  styleUrls: ['./organization-destination-form.component.scss'],
})
export class OrganizationDestinationFormComponent implements OnInit {
  @Input({ required: true }) public form!: FormGroup<OrganizationDestinationForm>;

  @Input({ required: true }) public tabAnimationDuration!: number;

  public address: WritableSignal<google.maps.LatLngLiteral | undefined> = signal(undefined);

  constructor(
    private readonly employerConnectWrapperService: EmployerConnectWrapperService,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    if (this.form.value.place) {
      this.address.set({
        lat: this.form.value.place.geometry!.location!.lat()!,
        lng: this.form.value.place.geometry!.location!.lng()!,
      });
    }
  }

  public onPlaceSelected(result: google.maps.places.PlaceResult | null): void {
    this.form.controls.place.patchValue(result);
    this.form.controls.place.markAsDirty();

    this.address.set(
      result
        ? {
            lat: result.geometry?.location?.lat()!,
            lng: result.geometry?.location?.lng()!,
          }
        : undefined
    );
  }

  public onEmployerSelected(employerDetails: ApiResponseSelectableForDashboardEmployerWithAddress): void {
    this.employerConnectWrapperService.joinCompanyViaDashboard(employerDetails.id).subscribe(() => {
      this.router.navigate([pageRoutes.USER_APP_PREVIEW.path]);
    });
  }
}
