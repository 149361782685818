import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { positiveIntValidation } from '@shared/constants/positive-int-validation.regex';
import { ApiResponseUserWithIdModel } from '@swagger/model/apiResponseUserWithIdModel';
import { AchievementPointTypeWithManualDeduction, ApiRequestManuallyReward } from '../../../../api';
import { ApiResponseBaseIncentiveNetwork } from '../../../../api/model/apiResponseBaseIncentiveNetwork';
import { RewardsWrapperService } from '../../../../core/services/rewards/rewards-wrapper.service';
import { ToastService } from '../../../../core/services/toast/toast.service';

export interface ManualRewardForm {
  amount: FormControl<number | null>;
  pointType: FormControl<AchievementPointTypeWithManualDeduction>;
  description: FormControl<string | null>;
}

@Component({
  selector: 'update-coins-dialog',
  templateUrl: './update-coins-dialog.component.html',
  styleUrls: ['./update-coins-dialog.component.scss'],
})
export class UpdateCoinsDialogComponent implements OnInit {
  public user: ApiResponseUserWithIdModel;

  public incentiveNetwork?: ApiResponseBaseIncentiveNetwork;

  public formGroup!: FormGroup<ManualRewardForm>;

  public readonly maxDescriptionCharacters = 100;

  constructor(
    @Inject(DIALOG_DATA)
    private readonly data: {
      user: ApiResponseUserWithIdModel;
      network?: ApiResponseBaseIncentiveNetwork;
    },
    private readonly dialogRef: DialogRef<number>,
    private readonly rewardsWrapperService: RewardsWrapperService,
    private readonly toastService: ToastService,
    private readonly formBuilder: FormBuilder
  ) {
    this.user = this.data.user;
    this.incentiveNetwork = this.data.network;
  }

  public ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.formGroup = this.formBuilder.group<ManualRewardForm>({
      amount: new FormControl(null, [Validators.required, Validators.pattern(positiveIntValidation)]),
      pointType: new FormControl(AchievementPointTypeWithManualDeduction.manual, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      description: new FormControl(null, [Validators.maxLength(this.maxDescriptionCharacters)]),
    });
  }

  public onSave(): void {
    const amount =
      this.formGroup.value.pointType! === AchievementPointTypeWithManualDeduction.manualDeduction
        ? this.formGroup.value.amount! * -1
        : this.formGroup.value.amount!;

    const request: ApiRequestManuallyReward = {
      amount,
      type: this.formGroup.value.pointType!,
      description: this.formGroup.value.description,
    };

    this.rewardsWrapperService.manuallyRewardUser(this.user.username, request).subscribe(() => {
      this.dialogRef.close(amount);
      this.toastService.show(false, 'user.updateCoinsSuccess');
    });
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
