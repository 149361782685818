<div *ngIf="this.webUrl" id="app-preview-iframe" class="hidden fixed z-50">
  <div class="relative flex flex-col items-center justify-center h-full">
    <img
      src="assets/images/shapes/teal-shape.svg"
      alt="teal shape"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-[550px]"
    />
    <div style="aspect-ratio: 0.4906771344" class="max-h-[75vh] flex-grow relative z-10 min-h-0">
      <img
        src="assets/images/mockups/iphone-14-pro-cropped.png"
        alt="phone mockup"
        class="relative z-10 pointer-events-none w-full h-full"
      />

      <div
        class="h-full w-full rounded-[75px] bg-light absolute inset-0 m-auto flex flex-col items-center justify-center"
      >
        <div
          class="flex flex-col items-center gap-y-6 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full px-[10%]"
        >
          <img class="w-16 h-16" src="assets/images/animated-emojis/popcorn.webp" alt="popcorn emoji" />
          <div>
            <h6>{{ 'appPreview.loadingApp' | translate }}</h6>
            <p>{{ 'appPreview.loadingAppDescription' | translate }}</p>
          </div>
        </div>
        <iframe
          #iframe
          (load)="iFrameLoaded()"
          [src]="this.webUrl | safeResourceUrl"
          allow="clipboard-write"
          class="w-[calc(100%_-_10%)] h-[calc(100%_-_7%)] animate__animated animate__fadeIn animate__fast"
        ></iframe>
      </div>
    </div>

    <div class="flex items-center gap-2 pt-5">
      <svg
        data-v-7d7df0e4=""
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="256"
        height="256"
        viewBox="0 0 256 256"
        xml:space="preserve"
        class="h-7 w-7 relative -top-2"
        alt="svg arrow"
      >
        <g
          data-v-7d7df0e4=""
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          style="
            stroke: none;
            stroke-width: 0;
            stroke-dasharray: none;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 10;
            fill: none;
            fill-rule: nonzero;
            opacity: 1;
          "
        >
          <path
            data-v-7d7df0e4=""
            d="M 17.418 0.016 c 0.036 0.004 0.071 0.005 0.107 0.011 c 0.979 0.13 1.738 0.959 1.738 1.973 v 9.704 c 0 1.104 -0.895 2 -2 2 s -2 -0.895 -2 -2 V 6.385 c -4.224 4.317 -6.945 9.85 -7.78 15.943 c -1.029 7.506 0.929 14.955 5.508 20.987 c 3.769 4.966 8.869 8.338 14.429 10.001 c 1.111 -8.229 5.362 -16.031 12.483 -21.437 c 10.258 -7.788 18.323 -6.538 22.112 -3.101 c 3.889 3.527 4.116 9.518 0.581 15.262 c -1.712 2.782 -4.223 5.44 -7.464 7.9 c -6.883 5.225 -15.387 7.457 -23.949 6.285 c -0.019 -0.002 -0.036 -0.005 -0.055 -0.007 c 0.11 5.796 1.988 11.61 5.754 16.57 c 4.579 6.031 11.232 9.919 18.734 10.946 c 7.503 1.027 14.956 -0.929 20.987 -5.507 c 0 0 0 0 0.001 -0.001 c 2.845 -2.159 5.024 -4.451 6.476 -6.811 c 0.579 -0.94 1.81 -1.234 2.751 -0.655 c 0.94 0.578 1.235 1.81 0.655 2.751 c -1.712 2.783 -4.223 5.441 -7.464 7.901 c -0.001 0.001 -0.003 0.002 -0.004 0.003 c -6.882 5.223 -15.385 7.453 -23.944 6.281 c -8.561 -1.172 -16.153 -5.608 -21.377 -12.49 c -4.491 -5.915 -6.659 -12.896 -6.57 -19.798 c -6.889 -1.848 -12.939 -5.9 -17.321 -11.673 c -4.316 -5.685 -6.59 -12.477 -6.59 -19.492 c 0 -1.478 0.101 -2.967 0.305 -4.456 C 4.447 15.02 7.419 8.865 12.019 4 H 7.559 c -1.104 0 -2 -0.895 -2 -2 s 0.895 -2 2 -2 h 9.704 C 17.316 0 17.366 0.012 17.418 0.016 z M 52.713 48.754 c 2.845 -2.16 5.024 -4.451 6.476 -6.81 c 1.283 -2.085 1.925 -4.122 1.925 -5.911 c 0 -1.721 -0.595 -3.212 -1.787 -4.293 c -2.864 -2.598 -9.233 -2.577 -17.008 3.324 c -6.339 4.812 -10.082 11.791 -10.983 19.128 C 38.646 55.295 46.375 53.565 52.713 48.754 z"
            transform=" matrix(1 0 0 1 0 0) "
            stroke-linecap="round"
            style="
              stroke: none;
              stroke-width: 1;
              stroke-dasharray: none;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 10;
              fill: currentcolor;
              fill-rule: nonzero;
              opacity: 1;
            "
          ></path>
        </g>
      </svg>

      <h5 class="text-lg flex items-center gap-x-2">
        {{ 'appPreview.tryMe' | translate }}

        <mat-icon [matTooltip]="'appPreview.tooltip' | translate">info_outline</mat-icon>
      </h5>
    </div>
  </div>
</div>
