import { environment as prodUSEnvironment } from './environment.prod';
import { EnvConfig } from './interface/environment.interface';

export const environment: EnvConfig = {
  ...prodUSEnvironment,
  apiUrl: 'https://api.pavecommute.eu',
  loginUrl:
    'https://paveapp.auth.eu-central-1.amazoncognito.com/login' +
    '?client_id=m4sjpf6ei6485ihgfv9k4qat1' +
    '&response_type=code' +
    '&scope=phone+email+openid+aws.cognito.signin.user.admin+profile' +
    '&redirect_uri=',
  region: 'eu',
  sentianceAppId: '62445208ff766507000004e1',
  awsS3PublicBucket: 'ra-eu-pave-prod-public-assets',
  intercomId: 'u8zcj4x2',
  hotjarId: '4983023',
};
