/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestFindAllSimpleByPagination } from '../model/models';
import { ApiRequestFindEmployerByCodeAndDomain } from '../model/models';
import { ApiRequestUpdateEmployer } from '../model/models';
import { ApiResponseEmployer } from '../model/models';
import { ApiResponseEmployerAppModel } from '../model/models';
import { ApiResponseEmployerListWithPagination } from '../model/models';
import { ApiResponseManagerOnboardingAppStatus } from '../model/models';
import { ApiResponseSelectableEmployer } from '../model/models';
import { ApiResponseSelectableForDashboardEmployer } from '../model/models';
import { ApiResponseSelectableForDashboardEmployerWithAddress } from '../model/models';
import { ApiResponseSimpleEmployer } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface EmployerServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param domain 
     */
    employerControllerCanDomainConnect(domain: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param domain 
     */
    employerControllerCanDomainConnect_1(domain: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * 
     * 
     * @param employerId 
     * @param file 
     */
    employerControllerChangeLogo(employerId: string, file: Blob, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param employerId 
     * @param file 
     */
    employerControllerChangeLogo_2(employerId: string, file: Blob, extraHttpRequestParams?: any): Observable<string>;

    /**
     * 
     * 
     * @param apiRequestFindEmployerByCodeAndDomain 
     */
    employerControllerEmployerByConnectCodeAndDomainForDashboard(apiRequestFindEmployerByCodeAndDomain: ApiRequestFindEmployerByCodeAndDomain, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployer>>;

    /**
     * 
     * 
     * @param apiRequestFindEmployerByCodeAndDomain 
     */
    employerControllerEmployerByConnectCodeAndDomainForDashboard_3(apiRequestFindEmployerByCodeAndDomain: ApiRequestFindEmployerByCodeAndDomain, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployer>>;

    /**
     * 
     * 
     * @param apiRequestFindEmployerByCodeAndDomain 
     */
    employerControllerEmployerByConnectCodeAndDomainPost(apiRequestFindEmployerByCodeAndDomain: ApiRequestFindEmployerByCodeAndDomain, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableEmployer>>;

    /**
     * 
     * 
     * @param apiRequestFindEmployerByCodeAndDomain 
     */
    employerControllerEmployerByConnectCodeAndDomainPost_4(apiRequestFindEmployerByCodeAndDomain: ApiRequestFindEmployerByCodeAndDomain, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableEmployer>>;

    /**
     * 
     * 
     * @param domain 
     */
    employerControllerEmployerByPrivateDomain(domain: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployer>>;

    /**
     * 
     * 
     * @param domain 
     */
    employerControllerEmployerByPrivateDomain_5(domain: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployer>>;

    /**
     * 
     * 
     * @param apiRequestFindAllSimpleByPagination 
     */
    employerControllerFindAllSimpleEmployerByPagination(apiRequestFindAllSimpleByPagination: ApiRequestFindAllSimpleByPagination, extraHttpRequestParams?: any): Observable<ApiResponseEmployerListWithPagination>;

    /**
     * 
     * 
     * @param apiRequestFindAllSimpleByPagination 
     */
    employerControllerFindAllSimpleEmployerByPagination_6(apiRequestFindAllSimpleByPagination: ApiRequestFindAllSimpleByPagination, extraHttpRequestParams?: any): Observable<ApiResponseEmployerListWithPagination>;

    /**
     * 
     * 
     * @param addressSearchText 
     */
    employerControllerFindSelectableByAddressFuzzySearch(addressSearchText: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployerWithAddress>>;

    /**
     * 
     * 
     * @param addressSearchText 
     */
    employerControllerFindSelectableByAddressFuzzySearch_7(addressSearchText: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployerWithAddress>>;

    /**
     * 
     * 
     * @param searchText 
     */
    employerControllerFindSelectableByFuzzySearch(searchText: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployer>>;

    /**
     * 
     * 
     * @param searchText 
     */
    employerControllerFindSelectableByFuzzySearch_8(searchText: string, extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableForDashboardEmployer>>;

    /**
     * 
     * 
     */
    employerControllerGetAllSimpleEmployer(extraHttpRequestParams?: any): Observable<Array<ApiResponseSimpleEmployer>>;

    /**
     * 
     * 
     */
    employerControllerGetAllSimpleEmployer_9(extraHttpRequestParams?: any): Observable<Array<ApiResponseSimpleEmployer>>;

    /**
     * 
     * 
     */
    employerControllerGetForUser(extraHttpRequestParams?: any): Observable<ApiResponseEmployerAppModel>;

    /**
     * 
     * 
     */
    employerControllerGetForUser_10(extraHttpRequestParams?: any): Observable<ApiResponseEmployerAppModel>;

    /**
     * 
     * 
     */
    employerControllerGetForUser_11(extraHttpRequestParams?: any): Observable<ApiResponseEmployerAppModel>;

    /**
     * 
     * 
     */
    employerControllerGetForUser_12(extraHttpRequestParams?: any): Observable<ApiResponseEmployerAppModel>;

    /**
     * 
     * 
     * @param employerCode 
     */
    employerControllerGetFullEmployer(employerCode: string, extraHttpRequestParams?: any): Observable<ApiResponseEmployer>;

    /**
     * 
     * 
     * @param employerCode 
     */
    employerControllerGetFullEmployer_13(employerCode: string, extraHttpRequestParams?: any): Observable<ApiResponseEmployer>;

    /**
     * 
     * 
     * @param employerId 
     */
    employerControllerGetManagerOnboardingAppStatus(employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseManagerOnboardingAppStatus>;

    /**
     * 
     * 
     * @param employerId 
     */
    employerControllerGetManagerOnboardingAppStatus_14(employerId: string, extraHttpRequestParams?: any): Observable<ApiResponseManagerOnboardingAppStatus>;

    /**
     * 
     * 
     */
    employerControllerList(extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableEmployer>>;

    /**
     * 
     * 
     */
    employerControllerList_15(extraHttpRequestParams?: any): Observable<Array<ApiResponseSelectableEmployer>>;

    /**
     * 
     * 
     * @param employerId 
     * @param apiRequestUpdateEmployer 
     */
    employerControllerUpdateEmployer(employerId: string, apiRequestUpdateEmployer: ApiRequestUpdateEmployer, extraHttpRequestParams?: any): Observable<ApiResponseEmployer>;

    /**
     * 
     * 
     * @param employerId 
     * @param apiRequestUpdateEmployer 
     */
    employerControllerUpdateEmployer_16(employerId: string, apiRequestUpdateEmployer: ApiRequestUpdateEmployer, extraHttpRequestParams?: any): Observable<ApiResponseEmployer>;

    /**
     * 
     * 
     * @param employerId 
     * @param apiRequestUpdateEmployer 
     */
    employerControllerUpdateEmployer_17(employerId: string, apiRequestUpdateEmployer: ApiRequestUpdateEmployer, extraHttpRequestParams?: any): Observable<ApiResponseEmployer>;

    /**
     * 
     * 
     * @param employerId 
     * @param apiRequestUpdateEmployer 
     */
    employerControllerUpdateEmployer_18(employerId: string, apiRequestUpdateEmployer: ApiRequestUpdateEmployer, extraHttpRequestParams?: any): Observable<ApiResponseEmployer>;

}
