<div (click)="stopEventPropagation($event)">
  <div class="text-center border-b border-gray-light p-3 text-sm font-redHatTextSemibold">
    <p>{{ selectedTimeFrameItem.timeFrame.from | date }} - {{ selectedTimeFrameItem.timeFrame.to | date }}</p>
  </div>
  <div class="flex">
    <div class="border-r border-r-gray-light py-2 flex-col flex">
      <ng-container *ngFor="let option of selectableTimeFrameOptions">
        <button
          matRipple
          [class.active]="option === selectedTimeFrameItem.option"
          class="px-6 py-4 hover:bg-gray-ultralight text-left text-sm"
          (click)="onOptionSelected(option)"
        >
          {{ 'timeFrame.' + option | translate }}
        </button>
      </ng-container>
    </div>

    <div>
      <mat-calendar
        [dateFilter]="dateFilter"
        (selectedChange)="onCalendarRangeSelected($event)"
        [selected]="selectedCalendarRange"
        [startAt]="latestSelectableDate"
        [startView]="'month'"
        class="w-64"
      ></mat-calendar>
    </div>
  </div>

  <div class="border-t border-gray-light flex justify-end gap-2 p-3">
    <button (click)="cancel.emit()" mat-button>{{ 'general.cancel' | translate }}</button>
    <button mat-button color="primary" (click)="onSelect()">
      {{ 'general.select' | translate }}
    </button>
  </div>
</div>
