import { Component, Signal, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { usingTheAppArticleLink } from '@core/constants/help-center-constant';
import { AmplifyService } from '@core/services/amplify/amplify.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { NotificationWrapperService } from '@core/services/notification/notification-wrapper.service';
import { TranslateModule } from '@ngx-translate/core';
import { LocalStorageKey } from '@shared/enums/local-storage-key.enum';
import { MaterialModule } from '@shared/modules/material/material.module';
import { ApiResponseEmployerAppModel } from '@swagger/index';
import { AppPreviewComponent } from 'src/app/post-login/app-preview/components/app-preview/app-preview.component';

@Component({
  selector: 'user-app-preview-page',
  standalone: true,
  imports: [AppPreviewComponent, TranslateModule, MaterialModule],
  templateUrl: './user-app-preview-page.component.html',
  styleUrl: './user-app-preview-page.component.scss',
})
export class UserAppPreviewPageComponent {
  public hasRequestedManagerAccess: WritableSignal<boolean>;

  public employer: Signal<ApiResponseEmployerAppModel>;

  constructor(
    private readonly amplifyService: AmplifyService,
    private readonly localStorageService: LocalStorageService,
    private readonly route: ActivatedRoute,
    private readonly notificationWrapperService: NotificationWrapperService
  ) {
    this.employer = signal(this.route.snapshot.data.employerDetails);

    this.hasRequestedManagerAccess = signal(
      this.localStorageService.getItem(LocalStorageKey.hasRequestedManagerAccess) === 'true' ?? false
    );
  }

  public onLogout(): void {
    this.amplifyService.logout();
  }

  public onOpenHelpCenter(): void {
    window.open(usingTheAppArticleLink, '_blank');
  }

  public onRequestAccess(): void {
    this.notificationWrapperService.sendForManagerAccess().subscribe(() => {
      this.localStorageService.setItem(LocalStorageKey.hasRequestedManagerAccess, 'true');
      this.hasRequestedManagerAccess.set(true);
    });
  }
}
