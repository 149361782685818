/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiResponseChallengeContributor } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ChallengeContributorServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param challengeId 
     * @param contributorUsername 
     */
    challengeContributorControllerFindPast(challengeId: string, contributorUsername: string, extraHttpRequestParams?: any): Observable<ApiResponseChallengeContributor>;

    /**
     * 
     * 
     * @param challengeId 
     * @param contributorUsername 
     */
    challengeContributorControllerFindPast_1(challengeId: string, contributorUsername: string, extraHttpRequestParams?: any): Observable<ApiResponseChallengeContributor>;

}
