<div class="h-full w-full">
  <div
    *ngIf="loading && !blockingDialogOpen"
    class="bg-accent bg-opacity-10 rounded-2xl fixed z-[999999999999999999999999999] left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 h-24 w-24 flex items-center justify-center"
  >
    <mat-spinner color="accent" [diameter]="50"></mat-spinner>
  </div>

  <router-outlet></router-outlet>

  <!-- app-wide iFrames -->
  <app-iframe></app-iframe>
</div>
