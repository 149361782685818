import { Injectable } from '@angular/core';
import { AdminService } from '@core/services/admin/admin.service';
import { UserDataService } from '@core/services/user/user-data.service';
import { ConnectedUserRoleEnum } from '@swagger/index';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    private readonly userDataService: UserDataService,
    private readonly adminService: AdminService
  ) {}

  public isAllowedToAccessDashboard(): boolean {
    return (
      this.adminService.isAdministrator() ||
      this.userDataService.user.connectedEmployerRole === ConnectedUserRoleEnum.manager
    );
  }

  public isAllowedToAccessOnboarding(): boolean {
    return (
      this.userDataService.user.connectedToEmployer === '' &&
      this.userDataService.user.connectedEmployerRole === ConnectedUserRoleEnum.commuter &&
      !this.userDataService.user.pendingJoinRequestAtEmployer
    );
  }
}
