export enum PageNames {
  STATISTICS = 'STATISTICS',
  REWARDS = 'REWARDS',
  REWARDS_OVERVIEW = 'REWARDS_OVERVIEW',
  TANGO_ORDERS = 'TANGO_ORDERS',
  USERS = 'USERS',
  USER_LIST = 'USER_LIST',
  ALL_USER_LIST = 'ALL_USER_LIST',
  JOIN_REQUESTS = 'JOIN_REQUESTS',
  SETTINGS = 'SETTINGS',
  EMPLOYER_SETTINGS = 'EMPLOYER_SETTINGS',
  API = 'API',
  HELP_AND_SUPPORT = 'HELP_AND_SUPPORT',
  FUNDING = 'FUNDING',
  EMPLOYERS = 'EMPLOYERS',
  EMPLOYER_NETWORKS = 'EMPLOYER_NETWORKS',
  EMPLOYER_LIST = 'EMPLOYER_LIST',
  COMMUTES = 'COMMUTES',
  SCAN_TO_EARN_QR_CODES = 'SCAN_TO_EARN_QR_CODES',
  COMMUTE_HISTORY = 'COMMUTE_HISTORY',
  COMMUTE_INSIGHT = 'COMMUTE_INSIGHT',
  CARPOOL_VALIDATION = 'CARPOOL_VALIDATION',
  TRANSIT_VALIDATION = 'TRANSIT_VALIDATION',
  GLOBAL_REWARDS = 'GLOBAL_REWARDS',
  ORGANIZATION = 'ORGANIZATION',
  BILLING = 'BILLING',
  APP_SETTINGS = 'APP_SETTINGS',
  GENERAL = 'GENERAL',
  MY_PROFILE = 'MY_PROFILE',
  HELP_CENTER = 'HELP_CENTER',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  SCHEDULE_A_CALL = 'SCHEDULE_A_CALL',
  MARKETING_ASSETS = 'MARKETING_ASSETS',
  DATA_EXPORT = 'DATA_EXPORT',
}
