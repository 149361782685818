import { Dialog } from '@angular/cdk/dialog';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth, CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth';
import { showToastQueryParam } from '@core/constants/query-param.constant';
import { Region } from '@core/enums/region.enum';
import { AuthenticationStatusService } from '@core/services/authentication-status/authentication-status.service';
import { DataLayerEvent } from '@core/services/google-analytics/data-layer-event.enum';
import { GoogleAnalyticsService } from '@core/services/google-analytics/google-analytics.service';
import { MixpanelEvent } from '@core/services/mixpanel/mixpanel-event.enum';
import { MixpanelWrapperService } from '@core/services/mixpanel/mixpanel-wrapper.service';
import { RegionService } from '@core/services/region.service';
import { UserDataService } from '@core/services/user/user-data.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { DialogId } from '@shared/enums/dialog-id.enum';
import { SessionStorageKey } from '@shared/enums/session-storage-key.enum';
import { companyEmailValidator } from '@shared/validators/company-email-verification.validator';
import { filter, lastValueFrom, take } from 'rxjs';
import { RegionDialogComponent } from 'src/app/pre-login/register/components/region-dialog/region-dialog.component';
import { ApiResponseSignupUserModel, AuthTypeEnum } from '../../api';
import { AmplifyService } from '../../core/services/amplify/amplify.service';
import { DashboardSignupWrapperService } from '../../core/services/dashboard-signup/dashboard-signup-wrapper.service';
import { LoadingService } from '../../core/services/loading/loading.service';
import { LocaleService } from '../../core/services/locale.service';
import { ToastService } from '../../core/services/toast/toast.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements AfterViewInit {
  private readonly emailInputTabIndex = 0;

  private readonly otpInputTabIndex = 1;

  public currentTabIndex = this.emailInputTabIndex;

  public emailInput = '';

  public otpInput = '';

  public isSignup = true;

  public lastUsedEmail = '';

  public region: Region = Region.US;

  public readonly currentDate = new Date();

  public readonly animationClasses = ['animate__animated', 'animate__zoomIn', 'animate__faster'];

  @ViewChild('formWrapper', { static: true }) public formWrapper!: ElementRef<HTMLElement>;

  constructor(
    private readonly toastService: ToastService,
    private readonly amplifyService: AmplifyService,
    private readonly dashboardSignupWrapperService: DashboardSignupWrapperService,
    private readonly localeService: LocaleService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly regionService: RegionService,
    private readonly dialog: Dialog,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly mixpanelWrapperService: MixpanelWrapperService,
    private readonly utilService: UtilsService,
    private readonly userDataService: UserDataService,
    private readonly authStatusService: AuthenticationStatusService
  ) {
    this.subscribeToHubEvents();
  }

  public ngAfterViewInit(): void {
    if (this.regionService.shouldOpenRegionDialog()) {
      this.openRegionDialog();
    }
  }

  private subscribeToHubEvents(): void {
    this.amplifyService
      .onHubEvent('customOAuthState')
      .pipe(filter(({ data }) => data === 'signup-Apple' || data === 'signup-Google' || data === 'login'))
      .subscribe(async ({ data }: { data: string }) => {
        this.loadingService.startLoading();

        try {
          if (data.startsWith('signup')) {
            const providerString = data.split('-')[1].toLowerCase();
            const provider = AuthTypeEnum[providerString as keyof typeof AuthTypeEnum];

            const user = await this.completeSocialSignup(provider);

            if (await this.isInvalidSocialSignup()) {
              this.handleInvalidSocialLogin();

              return;
            }

            this.completeValidSocialSignup(user);
          }

          if (data === 'login') {
            this.navigateAfterSuccessfulLogin();
          }
        } finally {
          this.loadingService.endLoading();
        }
      });
  }

  private async handleInvalidSocialLogin(): Promise<void> {
    await this.amplifyService.logout(`/?${showToastQueryParam}=formValidation.onlyWorkEmailAllowed`);
  }

  /**
   * This is temporary solution to check if a user signed up with a social login
   * using an invalid email address (non-work-address)
   * In the long run this check should move to a Lambda function and attached as Cognito Trigger.
   */
  private async isInvalidSocialSignup(): Promise<boolean> {
    const user = await lastValueFrom(this.userDataService.getMyUser());

    const hasJustRegistered = await this.hasJustRegistered(new Date(user.internalProperties.registeredDate));

    if (hasJustRegistered) {
      const hasUsedInvalidSocialEmailSignup = this.hasUsedInvalidSocialEmailSignup(user.email);

      return hasUsedInvalidSocialEmailSignup;
    }

    return false;
  }

  private async hasJustRegistered(registeredDate: Date): Promise<boolean> {
    const now = new Date();
    const oneMinuteAgo = new Date(now.getTime() - 60000);

    return registeredDate >= oneMinuteAgo && registeredDate <= now;
  }

  private hasUsedInvalidSocialEmailSignup(email: string): boolean {
    const tempEmailControl = new FormControl(email, companyEmailValidator(this.googleAnalyticsService));

    return tempEmailControl.errors?.freeEmailDomain;
  }

  private async completeSocialSignup(provider: AuthTypeEnum): Promise<ApiResponseSignupUserModel> {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();

    const attributes = await Auth.userAttributes(user);
    // Extract the needed attributes
    const email = attributes.find((attr) => attr.Name === 'email')!.Value;
    const firstName = attributes.find((attr) => attr.Name === 'given_name')?.Value;
    const lastName = attributes.find((attr) => attr.Name === 'family_name')?.Value;

    return lastValueFrom(
      this.dashboardSignupWrapperService.signupSocial(email, this.localeService.locale, provider, firstName, lastName)
    );
  }

  private completeValidSocialSignup(signedUpUser: ApiResponseSignupUserModel): void {
    this.mixpanelWrapperService.track(MixpanelEvent.AUTHENTICATED);
    this.trackEvents(signedUpUser);
    this.navigateAfterSuccessfulLogin();
  }

  public signUpWithEmail(email: string): void {
    this.dashboardSignupWrapperService.signupEmail(email, this.localeService.locale).subscribe((signedUpUser) => {
      this.trackEvents(signedUpUser);
      const { authResponse } = signedUpUser;

      if (authResponse) {
        // user has already been authenticated, verification email has been sent to the account
        this.authStatusService.onLogin({
          accessToken: authResponse.accessToken,
          idToken: authResponse.idToken,
          refreshToken: authResponse.refreshToken,
        });
        this.amplifyService.authorizeByToken({
          idToken: authResponse.idToken,
          accessToken: authResponse.accessToken,
          refreshToken: authResponse.refreshToken,
        });
        this.navigateAfterSuccessfulLogin();

        return;
      }

      this.loginWithEmail(email);
    });
  }

  public async loginWithEmail(email: string): Promise<void> {
    await this.amplifyService.signInWithEmail(email);

    if (this.amplifyService.cognitoUser) {
      this.lastUsedEmail = email;
      this.currentTabIndex = this.otpInputTabIndex;
    }
  }

  public async onPaste(): Promise<void> {
    try {
      this.otpInput = await navigator.clipboard.readText();
    } catch {
      this.toastService.show(true, 'login.pasteNotSupported');
    }
  }

  public onEnterCode(code: string): void {
    this.sendCustomChallengeResponse(code);
  }

  public async onResendCode(): Promise<void> {
    await this.loginWithEmail(this.lastUsedEmail);
    this.toastService.show(false, 'login.resendCodeSuccess');
  }

  private navigateAfterSuccessfulLogin(): void {
    const initialPath = sessionStorage.getItem(SessionStorageKey.pathBeforeAuthRedirect);
    const queryParams: Record<string, string> = {};
    let path = '';

    if (initialPath) {
      const [pathString, queryString] = initialPath.split('?');

      path = pathString;

      if (queryString) {
        queryString.split('&').forEach((param) => {
          const [key, value] = param.split('=');

          queryParams[key] = value;
        });
      }
    }

    this.router
      .navigate([path], {
        onSameUrlNavigation: initialPath ? undefined : 'reload',
        queryParams,
      })
      .finally(() => sessionStorage.removeItem(SessionStorageKey.pathBeforeAuthRedirect));
  }

  private async sendCustomChallengeResponse(code: string): Promise<void> {
    await this.amplifyService.sendCustomChallengeAnswer(code);

    // NOTE:
    // The refresh is required to get the most recent jwt token
    // The token payload of the ID-Token otherwise contains email_verified = false (although it should be true) as it was set that way in the Lambda
    await this.authStatusService
      .refresh()
      .pipe(take(1))
      .subscribe(() => {
        this.navigateAfterSuccessfulLogin();
      });
  }

  public async onSignInWithApple(): Promise<void> {
    this.amplifyService.socialSignIn(CognitoHostedUIIdentityProvider.Apple, this.isSignup ? 'signup-Apple' : 'login');
  }

  public async onSignInWithGoogle(): Promise<void> {
    this.amplifyService.socialSignIn(CognitoHostedUIIdentityProvider.Google, this.isSignup ? 'signup-Google' : 'login');
  }

  public toggleLoginSignup(): void {
    this.formWrapper.nativeElement.classList.remove(...this.animationClasses);
    this.isSignup = !this.isSignup;

    this.utilService.customWait(0, () => {
      this.formWrapper.nativeElement.classList.add(...this.animationClasses);
    });
  }

  public onBack(): void {
    this.currentTabIndex = this.emailInputTabIndex;
    this.lastUsedEmail = '';
  }

  public onSetSessionStorage(email: string | null): void {
    if (!email) {
      sessionStorage.removeItem(SessionStorageKey.registerEmail);

      return;
    }

    sessionStorage.setItem(SessionStorageKey.registerEmail, email);
  }

  private openRegionDialog(): void {
    const ref = this.dialog.open<Region>(RegionDialogComponent, {
      id: DialogId.REGION_DIALOG,
      disableClose: true,
    });

    ref.closed.pipe(take(1)).subscribe((newRegion) => {
      if (newRegion) {
        this.region = newRegion;
      }
    });
  }

  private trackEvents(signedUpUser: ApiResponseSignupUserModel): void {
    this.googleAnalyticsService.identify({ username: signedUpUser.username, email: signedUpUser.email });

    if (signedUpUser.isNewUser) {
      this.googleAnalyticsService.trackEvent(DataLayerEvent.signUp, { authType: signedUpUser.authType });
    } else {
      this.googleAnalyticsService.trackEvent(DataLayerEvent.login, { authType: signedUpUser.authType });
    }
  }
}
