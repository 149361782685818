import { Injectable } from '@angular/core';
import { appFallbackUrl, iosStoreId, websiteUrl } from '@core/constants/deeplink.constant';
import { DeepLinkAction } from '@shared/enums/deep-link-action.enum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeeplinkService {
  public createAppDeeplink(action: DeepLinkAction, value: string): string {
    // firebase requires double encoding for a part of the link
    // therefore we encode the value with encodeURI (encodeURIComponent does not work)
    // the whole link then needs to be encode with encodeURIComponent (encodeURI does not work)

    const link = `${websiteUrl}?${action}=${encodeURI(value)}`;
    const encodedLink = encodeURIComponent(link);
    const deeplink = `${environment.appDeeplinkBaseUrl}/?link=${encodedLink}&apn=${environment.androidPackageName}&ibi=${environment.iosPackageName}&isi=${iosStoreId}&ofl=${appFallbackUrl}`;

    return deeplink;
  }
}
