import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  private tailwindConfig = resolveConfig(tailwindConfig);

  constructor(private readonly meta: Meta) {}

  public isDesktopView(): boolean {
    const breakpoint = +this.tailwindConfig.theme.screens.lg.replace('px', '');
    const currentWidth = window.innerWidth;

    return currentWidth >= breakpoint;
  }

  public isMobileView(): boolean {
    const breakpoint = +this.tailwindConfig.theme.screens.sm.replace('px', '');
    const currentWidth = window.innerWidth;

    return currentWidth < breakpoint;
  }

  /**
   * This method enforces desktop view on mobile / tablet devices by removing
   * the responsive viewport meta tag from index.html
   */
  public removeResponsiveMetaTag(): void {
    this.meta.updateTag({ name: 'viewport', content: 'width=1024' });
  }
}
