<section class="p-12 flex flex-col justify-center items-center h-full">
  <img class="h-12 w-12 mb-8" src="/assets/images/emojis/1f433.svg" alt="fish emoji" />
  <h1 class="text-3xl">{{ 'criticalDataLoadingError.title' | translate }}</h1>
  <p class="text-center my-8 max-w-screen-sm">
    {{ 'criticalDataLoadingError.description' | translate }}
  </p>
  <div class="flex gap-4">
    <button (click)="onRetry()" mat-flat-button color="primary">
      {{ 'criticalDataLoadingError.buttonText' | translate }}
    </button>
    <button (click)="onLogout()" mat-button color="warn">
      {{ 'general.logout' | translate }}
    </button>
  </div>
</section>
