<mat-form-field
  [appearance]="appearance"
  [matTooltip]="tooltipContent"
  matTooltipPosition="above"
  class="w-full relative"
  [class.invalid]="isInvalid"
>
  <mat-icon *ngIf="showPrefixSearchIcon" matPrefix>search</mat-icon>
  <input
    #addressText
    timedAutofocus
    [enable]="autofocus"
    [delay]="autofocusDelay"
    [disabled]="isDisabled"
    [value]="defaultValue"
    matInput
    placeholder="{{ placeholder ?? ('employerSettings.workLocations.searchAddress' | translate) }}"
    required
    name="addressText"
  />
  <button *ngIf="this.addressText.value" matSuffix mat-icon-button type="button" (click)="clearInput()">
    <mat-icon>close</mat-icon>
  </button>

  <mat-hint class="text-danger">
    <ng-content select="[mat-error-content]"></ng-content>
  </mat-hint>
</mat-form-field>
