/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildTransactionCommuteModel } from './childTransactionCommuteModel';


export interface ChildTransactionWithCommuteModel { 
    commuteMode?: ChildTransactionWithCommuteModelCommuteModeEnum | null;
    id: string;
    type: object;
    date: string;
    amount: number;
    amountInMoney?: number;
    raffleAmount?: number;
    accountBalance: number;
    rewardStyle?: object;
    description?: string;
    commute?: ChildTransactionCommuteModel | null;
    username: string;
}
export enum ChildTransactionWithCommuteModelCommuteModeEnum {
    car = 'car',
    bike = 'bike',
    walk = 'walk',
    scooter = 'scooter',
    transit = 'transit',
    carpool = 'carpool',
    internalShuttle = 'internalShuttle'
};



