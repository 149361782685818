<form-intro
  title="{{ 'onboarding.organizationLocationFormGreeting' | translate }}"
  imagePath="assets/images/icons/pave-coin.svg"
></form-intro>

<info-explanation
  type="smart-hint"
  [msg]="'onboarding.organizationLocationFormHint' | translate"
  prefixImageUrl="assets/images/emojis/1f4a1.svg"
></info-explanation>

<form [formGroup]="form" class="mt-8" novalidate id="organization-destination-form">
  <destination-picker
    appearance="outline"
    [isInvalid]="form.controls.place.invalid && form.controls.place.touched"
    [showPrefixSearchIcon]="false"
    [autofocus]="!form.value.place"
    [autofocusDelay]="tabAnimationDuration"
    [defaultValue]="form.value.place?.formatted_address"
    [placeholder]="'employerSettings.workLocations.searchAddress' | translate"
    [clearValueAfterSelection]="false"
    (placeSelected)="onPlaceSelected($event)"
    (requestAccess)="onEmployerSelected($event)"
  >
    <ng-container *ngIf="form.controls.place.invalid && form.controls.place.touched" mat-error-content>
      <span *ngIf="form.controls.place.hasError('required')">{{
        'formValidation.requiredError' | translate: { field: 'onboarding.organizationDestination' | translate }
      }}</span>
    </ng-container>
  </destination-picker>

  <div class="h-44 rounded-xl overflow-hidden mt-2">
    <google-places-input-map-preview [address]="address()"></google-places-input-map-preview>
  </div>
</form>
