/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TrackingStatus } from './trackingStatus';
import { TrackingMode } from './trackingMode';
import { ChildTrackingPermissions } from './childTrackingPermissions';


export interface ApiRequestCreateTrackingLogModel { 
    trackingMode: TrackingMode;
    trackingStatus: TrackingStatus;
    deviceName: string;
    osVersion: string;
    appVersion: string;
    trackingPermissions: ChildTrackingPermissions;
}



