<div>
  <button
    mat-flat-button
    class="justify-start rounded-md w-[18rem] shadow-xl min-h-[3rem] bg-light"
    #cdkMenuTrigger="cdkMenuTriggerFor"
    [cdkMenuTriggerFor]="menu"
  >
    <mat-icon fontSet="material-icons-round" class="mr-3">date_range</mat-icon>
    <span class="font-redHatTextSemibold">{{ 'general.timeFrame' | translate }}: </span>
    <span class="font-redHatTextRegular">{{ 'timeFrame.' + selectedTimeFrameItem.option | translate }}</span>
    <mat-icon fontSet="material-icons-round" class="absolute right-2">expand_more</mat-icon>
  </button>
  <ng-template #menu>
    <div cdkMenu>
      <calendar-range-selector
        [selectedTimeFrameItem]="selectedTimeFrameItem"
        (timeFrameSelect)="onTimeFrameSelect($event)"
        (cancel)="onCancel()"
      ></calendar-range-selector>
    </div>
  </ng-template>
</div>
