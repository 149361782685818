import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConnectedUserRoleEnum } from '@swagger/model/models';

@Component({
  selector: 'role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss'],
})
export class RoleSelectorComponent {
  @Input() public formGroup!: FormGroup;

  @Input() public controlName!: string;

  public userRoles = Object.values(ConnectedUserRoleEnum);
}
