/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';



import { Configuration }                                     from '../configuration';



export interface StreamChatServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param employerId 
     */
    streamChatControllerDeleteAllStreamChatChannels(employerId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param employerId 
     */
    streamChatControllerDeleteAllStreamChatChannels_1(employerId: string, extraHttpRequestParams?: any): Observable<{}>;

}
