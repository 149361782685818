import { Injectable } from '@angular/core';

import { DefaultService } from '@swagger/index';
import { Observable } from 'rxjs';
import { DashboardLanguageService } from './dashboard-language.service';
import { HttpHelperService } from './http-helper.service';

@Injectable({
  providedIn: 'root',
})
export class LegalLinksWrapperService {
  constructor(
    private readonly dashboardLanguageService: DashboardLanguageService,
    private readonly defaultService: DefaultService,
    private readonly httpHelperService: HttpHelperService
  ) {}

  public getTermsAndConditionsLink(silentLoading = true): Observable<string> {
    const options = this.httpHelperService.getDefaultBypassLoadingOptions(silentLoading);

    return this.defaultService.appControllerGetTermsUrl(
      this.dashboardLanguageService.getCurrentDashboardLanguage(),
      undefined,
      undefined,
      options
    );
  }

  public getPrivacyPolicyLink(silentLoading = true): Observable<string> {
    const options = this.httpHelperService.getDefaultBypassLoadingOptions(silentLoading);

    return this.defaultService.appControllerGetPrivacyUrl(
      this.dashboardLanguageService.getCurrentDashboardLanguage(),
      undefined,
      undefined,
      options
    );
  }
}
