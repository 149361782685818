<div matRipple [matMenuTriggerFor]="menu" class="switch">
  <div class="switch__option">
    {{ selectedLanguage.alpha2 | uppercase }}
    <mat-icon fontSet="material-icons-round">expand_more</mat-icon>
  </div>
</div>
<mat-menu #menu="matMenu">
  <button *ngFor="let language of supportedDashboardLanguageCodes" mat-menu-item (click)="selectLanguage(language)">
    <div class="switch__option">
      {{ language.alpha2 | uppercase }}
    </div>
  </button>
</mat-menu>
