import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DateHelperService } from '@core/services/date-helper/date-helper.service';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { StripeSubscriptionManagementWrapperService } from '@core/services/stripe-subscription-management/stripe-subscription-management-wrapper.service';
import { SubscriptionStatusEnum } from '@swagger/index';

@Component({
  selector: 'free-trial-banner',
  templateUrl: './free-trial-banner.component.html',
  styleUrls: ['./free-trial-banner.component.scss'],
})
export class FreeTrialBannerComponent {
  public remainingDaysInFreeTrial?: number;

  constructor(
    private readonly employerWrapperService: EmployerWrapperService,
    private readonly dateHelperService: DateHelperService,
    private readonly stripeSubscriptionManagementWrapperService: StripeSubscriptionManagementWrapperService
  ) {
    this.employerWrapperService
      .getEmployerStream()
      .pipe(takeUntilDestroyed())
      .subscribe((employer) => {
        if (employer.subscription?.status === SubscriptionStatusEnum.trialing && employer.subscription.trialEnd) {
          const today = this.dateHelperService.getEndOfToday();
          const trialEnd = this.dateHelperService.getEndOfDate(new Date(employer.subscription?.trialEnd));

          this.remainingDaysInFreeTrial = this.dateHelperService.getDayDifferenceBetweenDates(trialEnd, today);
        } else {
          this.remainingDaysInFreeTrial = undefined;
        }
      });
  }

  public onUpgradeNow(): void {
    this.stripeSubscriptionManagementWrapperService.openCheckoutUrl();
  }
}
