/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestCreateByOptionsModel } from '../model/models';
import { ApiRequestCreateForAllEmployersForMonthModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ChallengeCreationServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * Creates the challenges by options
     * @param apiRequestCreateByOptionsModel 
     */
    challengeCreationControllerCreateForAllEmployersByOptions(apiRequestCreateByOptionsModel: ApiRequestCreateByOptionsModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * Creates the challenges by options
     * @param apiRequestCreateByOptionsModel 
     */
    challengeCreationControllerCreateForAllEmployersByOptions_1(apiRequestCreateByOptionsModel: ApiRequestCreateByOptionsModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * Creates the next challenge for all employers
     * @param apiRequestCreateForAllEmployersForMonthModel 
     */
    challengeCreationControllerCreateForAllEmployersForMonth(apiRequestCreateForAllEmployersForMonthModel: ApiRequestCreateForAllEmployersForMonthModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * Creates the next challenge for all employers
     * @param apiRequestCreateForAllEmployersForMonthModel 
     */
    challengeCreationControllerCreateForAllEmployersForMonth_2(apiRequestCreateForAllEmployersForMonthModel: ApiRequestCreateForAllEmployersForMonthModel, extraHttpRequestParams?: any): Observable<{}>;

}
