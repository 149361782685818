import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { DateHelperService } from '@core/services/date-helper/date-helper.service';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { TimeFrameItemHelperService } from '@core/services/time-frame-item-helper/time-frame-item-helper.service';
import { TimeFrameOption } from '../../enums/time-frame-option.enum';
import { TimeFrameItem } from '../../interfaces/time-frame-item.interface';

@Component({
  selector: 'calendar-range-selector',
  templateUrl: './calendar-range-selector.component.html',
  styleUrls: ['./calendar-range-selector.component.scss'],
})
export class CalendarRangeSelectorComponent implements OnInit {
  @Input() public selectedTimeFrameItem!: TimeFrameItem;

  @Output()
  public timeFrameSelect: EventEmitter<TimeFrameItem> = new EventEmitter<TimeFrameItem>();

  @Output()
  public cancel: EventEmitter<void> = new EventEmitter<void>();

  public readonly selectableTimeFrameOptions = Object.keys(TimeFrameOption);

  public selectedCalendarRange?: DateRange<Date>;

  public readonly latestSelectableDate = new Date();

  public readonly earliestSelectableDate!: Date;

  public dateFilter!: (date: any) => boolean;

  constructor(
    private readonly timeFrameItemHelperService: TimeFrameItemHelperService,
    private readonly employerWrapperService: EmployerWrapperService,
    private readonly dateHelperService: DateHelperService
  ) {
    this.earliestSelectableDate = new Date(this.employerWrapperService.employer.createdAt);
    this.dateFilter = this.createDateFilter();
  }

  public ngOnInit(): void {
    this.setSelectedCalendarRange(this.selectedTimeFrameItem.timeFrame.from, this.selectedTimeFrameItem.timeFrame.to);
  }

  private setSelectedCalendarRange(from: Date, to: Date | null): void {
    this.selectedCalendarRange = new DateRange<Date>(from, to);
  }

  public onSelect(): void {
    this.timeFrameSelect.emit(this.selectedTimeFrameItem);
  }

  public onOptionSelected(option: string): void {
    switch (option) {
      case TimeFrameOption.allTime:
        this.selectedTimeFrameItem = this.timeFrameItemHelperService.getAllTimeTimeFrameItem();
        break;

      case TimeFrameOption.thisYear:
        this.selectedTimeFrameItem = this.timeFrameItemHelperService.getThisYearTimeFrameItem();
        break;

      case TimeFrameOption.lastTwelveMonth:
        this.selectedTimeFrameItem = this.timeFrameItemHelperService.getLast12MonthsTimeFrameItem();
        break;

      case TimeFrameOption.thisMonth:
        this.selectedTimeFrameItem = this.timeFrameItemHelperService.getThisMonthTimeFrameItem();
        break;

      case TimeFrameOption.individual:
        this.setIndividualTimeFrameItem(
          this.selectedTimeFrameItem.timeFrame.from,
          this.selectedTimeFrameItem.timeFrame.to
        );
        break;

      default:
        break;
    }

    this.setSelectedCalendarRange(this.selectedTimeFrameItem.timeFrame.from, this.selectedTimeFrameItem.timeFrame.to);
  }

  public stopEventPropagation(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  private setIndividualTimeFrameItem(from: Date, to: Date): void {
    this.selectedTimeFrameItem = this.timeFrameItemHelperService.getIndividualTimeFrameItem({
      from,
      to: this.dateHelperService.toEndOfDay(to),
    });
  }

  public onCalendarRangeSelected(date: Date): void {
    if (!this.selectedCalendarRange?.start || this.selectedCalendarRange?.end) {
      this.setIndividualTimeFrameItem(date, date);
      this.setSelectedCalendarRange(date, null);

      return;
    }

    const { start } = this.selectedCalendarRange;
    const end = date;

    if (end < start) {
      this.setIndividualTimeFrameItem(end, start);
      this.setSelectedCalendarRange(end, start);
    } else {
      this.setIndividualTimeFrameItem(start, end);
      this.setSelectedCalendarRange(start, end);
    }
  }

  private createDateFilter(): (date: any) => boolean {
    return (date: any): boolean => {
      if (date > this.latestSelectableDate) {
        return false;
      }

      if (date < this.earliestSelectableDate) {
        return false;
      }

      return true;
    };
  }
}
