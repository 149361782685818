/* eslint-disable no-plusplus */
import { Directive, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, switchMap, take, tap, timer } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[secondsCounter]',
})
export class SecondsCounterDirective implements OnChanges {
  private counterSource$ = new Subject<{ count: number; interval: number }>();

  private interval = 1000;

  @Input() public count!: number;

  @Output() public valueUpdate: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
    this.counterSource$

      .pipe(
        takeUntilDestroyed(),
        switchMap(({ interval, count }) =>
          timer(0, interval).pipe(
            take(count),
            tap(() => {
              // eslint-disable-next-line no-param-reassign
              this.valueUpdate.emit(--count);
            })
          )
        )
      )
      .subscribe();
  }

  public ngOnChanges(): void {
    this.counterSource$.next({ count: this.count, interval: this.interval });
  }
}
