import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { UtilsService } from '@core/services/utils/utils.service';

@Directive({
  selector: '[timedAutofocus]',
})
export class TimedAutofocusDirective implements AfterViewInit {
  @Input() public enable = true;

  @Input() public delay = 0;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly utilsService: UtilsService
  ) {}

  public ngAfterViewInit(): void {
    if (this.enable) {
      this.utilsService.customWait(this.delay, () => {
        this.elementRef.nativeElement.focus();
      });
    }
  }
}
