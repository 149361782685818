import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { kilometerInMileConversion, meterInYardConversion } from '@core/constants/unit-system.constant';
import { UnitSystemService } from '@core/services/unit-system.service';
import { TranslateService } from '@ngx-translate/core';
import { UnitSystem } from '@swagger/index';

@Injectable({
  providedIn: 'root',
})
export class LengthFormatterService {
  constructor(
    private readonly unitSystemService: UnitSystemService,
    private readonly translateService: TranslateService,
    private readonly decimalPipe: DecimalPipe
  ) {}

  public getMeterInLocaleValue(meterValue: number, digitsInfo = '1.0-0'): string | null {
    if (this.unitSystemService.getUnitSystem() === UnitSystem.imperial) {
      return this.decimalPipe.transform(meterValue * meterInYardConversion, digitsInfo);
    }

    return this.decimalPipe.transform(meterValue, digitsInfo);
  }

  public getMeterInLocaleAbbreviation(): string {
    if (this.unitSystemService.getUnitSystem() === UnitSystem.imperial) {
      return this.translateService.instant('lengthUnits.imperialSystem.meters');
    }

    return this.translateService.instant('lengthUnits.metricSystem.meters');
  }

  public geKilometerInLocaleAbbreviation(): string {
    if (this.unitSystemService.getUnitSystem() === UnitSystem.imperial) {
      return this.translateService.instant('lengthUnits.imperialSystem.kilometers');
    }

    return this.translateService.instant('lengthUnits.metricSystem.kilometers');
  }

  public getKilometerInLocaleValue(distanceInKilometers: number): number {
    if (this.unitSystemService.getUnitSystem() === UnitSystem.imperial) {
      return distanceInKilometers * kilometerInMileConversion;
    }

    return distanceInKilometers;
  }
}
