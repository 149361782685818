export function defaultMarkerOptions(): google.maps.MarkerOptions {
  return {
    draggable: false,
    icon: {
      url: 'assets/images/map/map-pin-destination.png',
      scaledSize: new google.maps.Size(40, 45.6, 'px', 'px'),
      anchor: new google.maps.Point(20, 41),
    },
  };
}
