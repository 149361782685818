<div class="content">
  <h1 class="content__heading" [innerHTML]="'login.enterCodeHeading' | translate"></h1>
  <p [innerHTML]="'login.enterCodeMessage' | translate: { email: email }"></p>

  <div class="flex flex-col justify-center items-center gap-y-6 w-full">
    <ng-otp-input [config]="config" [formCtrl]="otpFormControl"></ng-otp-input>

    <div class="flex justify-center">
      <button (click)="onPaste()" mat-button color="primary">
        <mat-icon fontSet="material-icons-round">content_copy</mat-icon>
        {{ 'login.pasteFromClipboard' | translate }}
      </button>
    </div>
  </div>

  <div class="flex items-center justify-center self-center">
    <button
      [disabled]="countDown !== 0"
      class="mx-auto block"
      matSuffix
      color="primary"
      mat-button
      (click)="onResendCode()"
    >
      <span class="text-dark font-redHatTextRegular">{{ 'login.codeMissingHint' | translate }} </span>
      <span>{{ 'login.resendCode' | translate }} </span>
      <span [class.w-10]="currCountDown !== 0" class="inline-block text-left">
        <span secondsCounter [count]="countDown" (valueUpdate)="onCounterUpdate($event)">
          <span class="font-redHatTextRegular" *ngIf="currCountDown !== 0">({{ currCountDown }}s)</span>
        </span>
      </span>
    </button>
  </div>
</div>
