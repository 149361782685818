/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildAddressModel } from './childAddressModel';
import { ChildGroupUser } from './childGroupUser';
import { ChildChat } from './childChat';
import { ChildGroupCalendarEntry } from './childGroupCalendarEntry';
import { TripModeEnum } from './tripModeEnum';


export interface ApiResponseGroupV2 { 
    commuteMode: TripModeEnum;
    id: string;
    name: string;
    destinationAddresses: Array<ChildAddressModel>;
    color?: string;
    chat: ChildChat;
    users: Array<ChildGroupUser>;
    calendar: Array<ChildGroupCalendarEntry>;
}



