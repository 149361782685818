<div
  matRipple
  [matMenuTriggerFor]="menu"
  class="border border-gray-light cursor-pointer rounded-full px-2 py-1 pr-1 inline-flex items-center"
>
  <div class="region">
    <img class="region__emoji" [src]="regionService.getRegionImageUrl(selectedRegion)" alt="region emoji" />
    <span>
      {{ 'signup.region.' + selectedRegion | translate }}
    </span>
    <mat-icon fontSet="material-icons-round">expand_more</mat-icon>
  </div>
</div>
<mat-menu #menu="matMenu">
  <button *ngFor="let region of regions | keyvalue" mat-menu-item (click)="selectRegion(region.value)">
    <div class="region">
      <img class="region__emoji" [src]="regionService.getRegionImageUrl(region.value)" alt="region emoji" />
      <span>
        {{ 'signup.region.' + region.value | translate }}
      </span>
    </div>
  </button>
</mat-menu>
