/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildLastTripDetails } from './childLastTripDetails';


export interface ChildChallengeRanking { 
    username: string;
    displayName: string;
    currentRank: number;
    rankChangedAt: string;
    previousRank: number | null;
    contribution: number;
    lastTripDetails: ChildLastTripDetails;
}

