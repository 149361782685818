<form-intro
  title="{{ 'onboarding.organizationNameFormGreeting' | translate }}"
  imagePath="assets/images/emojis/1f3e2.svg"
></form-intro>
<form [formGroup]="form" novalidate id="organization-name-form">
  <mat-form-field class="w-full" appearance="outline">
    <input
      timedAutofocus
      [delay]="tabAnimationDuration"
      matInput
      formControlName="name"
      placeholder="{{ 'onboarding.organizationName' | translate }}"
    />

    <button
      type="button"
      (click)="form.controls.name.reset()"
      *ngIf="form.controls.name.value"
      mat-icon-button
      matSuffix
    >
      <mat-icon>{{ 'close' }}</mat-icon>
    </button>

    <mat-error *ngIf="form.controls.name.invalid">
      <span *ngIf="form.controls.name.hasError('required')">{{
        'formValidation.requiredError' | translate: { field: 'onboarding.organizationName' | translate }
      }}</span>
      <span *ngIf="form.controls.name.hasError('minlength')">
        {{
          'formValidation.minLengthError'
            | translate
              : {
                  field: 'onboarding.organizationName' | translate,
                  value: form.controls.name.errors?.minlength.requiredLength
                }
        }}
      </span>
    </mat-error>
  </mat-form-field>
</form>
