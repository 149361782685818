import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { pageRoutes } from '@core/models/page-routes.model';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(private readonly router: Router) {}

  public init(): void {
    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const urlWithoutParams = event.urlAfterRedirects.split(/[?#]/)[0];

        const isURLDifferentFromLastEntry =
          this.history.length === 0 || this.history[this.history.length - 1] !== urlWithoutParams;

        if (isURLDifferentFromLastEntry) {
          this.history.push(urlWithoutParams);
        }
      }
    });
  }

  /**
   * @method navigateBack
   * Uses either location or router depending on availability
   * to navigate back to previous page
   * Inspired by: https://nils-mehlhorn.de/posts/angular-navigate-back-previous-page/
   */
  public navigateBack(extras?: NavigationExtras, fallbackRoute: any[] = [pageRoutes.STATISTICS.path]): void {
    this.history.pop();

    if (this.history.length > 0) {
      this.router.navigate([this.history[this.history.length - 1]], extras);
    } else {
      this.router.navigate(fallbackRoute, extras);
    }
  }
}
