/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildChallengeRanking } from './childChallengeRanking';
import { ApiResponseChallenge } from './apiResponseChallenge';


export interface ApiResponseChallengeSummary { 
    challenge: ApiResponseChallenge;
    distributionByMode: object;
    totalCo2SavedInGrams: number;
    totalDistanceInMeters: number;
    co2ContributorRanking: Array<ChildChallengeRanking>;
    distanceContributorRanking: Array<ChildChallengeRanking>;
}

