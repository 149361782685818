import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrganizationNameForm } from '../../interfaces/organization-name-form.interface';

@Component({
  selector: 'organization-name-form',
  templateUrl: './organization-name-form.component.html',
  styleUrls: ['./organization-name-form.component.scss'],
})
export class OrganizationNameFormComponent {
  @Input({ required: true }) public form!: FormGroup<OrganizationNameForm>;

  @Input({ required: true }) public tabAnimationDuration!: number;
}
