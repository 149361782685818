import { Dialog } from '@angular/cdk/dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { InitialsHelperService } from '@core/services/initials-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent, ConfirmDialogData } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { DialogId } from '@shared/enums/dialog-id.enum';
import { SelectableEmployerDetailsWithMatchingAddress } from '@shared/interfaces/selectable-employer-details-with-matching-address.interface';
import { SharedModule } from '@shared/shared.module';
import { ApiResponseSelectableForDashboardEmployerWithAddress } from '@swagger/index';

import { take } from 'rxjs';

@Component({
  selector: 'destination-picker',
  standalone: true,
  imports: [SharedModule, OverlayModule],
  templateUrl: './destination-picker.component.html',
  styleUrl: './destination-picker.component.scss',
})
export class DestinationPickerComponent implements AfterViewInit {
  @ViewChild('addressText', { static: false }) public addressText!: ElementRef;

  @ViewChild('addressField', { static: true, read: ElementRef }) public addressField!: ElementRef;

  @Input() public defaultValue?: string = '';

  @Input() public autofocus = false;

  @Input() public autofocusDelay = 0;

  @Input() public appearance: MatFormFieldAppearance = 'fill';

  @Input() public showPrefixSearchIcon = true;

  @Input() public tooltipContent = '';

  @Input() public isInvalid = false;

  @Input() public isDisabled = false;

  @Input() public placeholder?: string;

  @Input() public clearValueAfterSelection = true;

  @Output() public placeSelected: EventEmitter<google.maps.places.PlaceResult | null> = new EventEmitter();

  @Output() public requestAccess: EventEmitter<ApiResponseSelectableForDashboardEmployerWithAddress> =
    new EventEmitter();

  private autocompleteService!: google.maps.places.AutocompleteService;

  public showOverlay = signal(false);

  public overlayWidth = signal(0);

  public autocompleteItems: google.maps.places.AutocompletePrediction[] = [];

  public employers: SelectableEmployerDetailsWithMatchingAddress[] = [];

  public employerResponse: ApiResponseSelectableForDashboardEmployerWithAddress[] = [];

  public isLoadingEmployers = signal(false);

  constructor(
    private readonly dialog: Dialog,
    private readonly translateService: TranslateService,
    private readonly employerWrapperService: EmployerWrapperService,
    private readonly initialsHelperService: InitialsHelperService
  ) {}

  public ngAfterViewInit(): void {
    this.initPlaceAutocomplete();
    this.overlayWidth.set(this.addressField.nativeElement.offsetWidth);
  }

  private initPlaceAutocomplete(): void {
    this.autocompleteService = new google.maps.places.AutocompleteService();
  }

  public clearInput(): void {
    this.addressText.nativeElement.value = '';
    this.placeSelected.next(null);
  }

  public onInput(): void {
    const input = this.addressText.nativeElement.value;

    if (input) {
      this.showOverlay.set(true);

      // Fetch place predictions
      this.autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
          this.autocompleteItems = predictions;
        } else {
          this.autocompleteItems = [];
        }
      });

      // fetch employers with same addresses
      if (input.length > 5) {
        this.isLoadingEmployers.set(true);
        this.employerWrapperService
          .findByAddressFuzzySearch(input)
          .subscribe((response: ApiResponseSelectableForDashboardEmployerWithAddress[]) => {
            this.employers = [];

            this.employers = response.map((detail) => ({
              employerLogoUrl: detail.logoUrl ?? undefined,
              employerInitials: this.initialsHelperService.createInitials(detail.name),
              employerName: detail.name,
              employerId: detail.id,
              address: detail.address,
              connectedUsers: detail.amountOfConnectedUsers,
            }));

            this.employerResponse = response;

            this.isLoadingEmployers.set(false);
          });
      } else {
        this.employers = [];
      }
    } else {
      this.showOverlay.set(false);
      this.autocompleteItems = [];
    }
  }

  public onFocus(): void {
    if (this.autocompleteItems.length > 0) {
      this.showOverlay.set(true);
    }
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (this.addressText && !this.addressText.nativeElement.contains(event.target as Node)) {
      this.showOverlay.set(false);
    }
  }

  public onSelectPlace(placeId: string): void {
    const placesService = new google.maps.places.PlacesService(this.addressText.nativeElement);

    placesService.getDetails({ placeId }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.placeSelected.emit(place);
      } else {
        this.placeSelected.emit(null);
      }
    });
  }

  public onSelectEmployer(employerDetails: SelectableEmployerDetailsWithMatchingAddress): void {
    const data: ConfirmDialogData = {
      title: this.translateService.instant('destinationOrEmployerPicker.confirmTitle', {
        employerName: employerDetails.employerName,
      }),
      hideIcon: true,
      description: this.translateService.instant('destinationOrEmployerPicker.confirmExplanation', {
        employerName: employerDetails.employerName,
      }),
      color: 'primary',
    };
    const ref = this.dialog.open<boolean, ConfirmDialogData>(ConfirmDialogComponent, {
      id: DialogId.CONFIRM_DIALOG,
      data,
    });

    ref.closed.pipe(take(1)).subscribe((confirmed) => {
      if (confirmed) {
        const employer = this.employerResponse.find((emp) => emp.id === employerDetails.employerId);

        this.requestAccess.emit(employer);
      }
    });
  }
}
