/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeoJsonFeature } from './geoJsonFeature';
import { TripLocation } from './tripLocation';
import { CommuteDetails } from './commuteDetails';
import { SelfReportDetails } from './selfReportDetails';
import { TripAchievements } from './tripAchievements';
import { ModeDetails } from './modeDetails';
import { TripUserDetails } from './tripUserDetails';


export interface Trip { 
    userDetails: TripUserDetails;
    startTime: string;
    endTime: string;
    transportIds: Array<string>;
    startLocation: TripLocation;
    endLocation: TripLocation;
    origin: TripOriginEnum;
    type: TripTypeEnum;
    lengthInMeters: number;
    modeDetails: ModeDetails;
    commuteDetails: CommuteDetails;
    selfReportDetails?: SelfReportDetails;
    matchingWorkLocation: GeoJsonFeature;
    deletedAt?: string;
    route?: string;
    achievements?: TripAchievements;
}
export enum TripOriginEnum {
    SENTIANCE = 'SENTIANCE',
    GPS_TRACKING_PAVE = 'GPS_TRACKING_PAVE',
    SELF_REPORTED = 'SELF_REPORTED'
};
export enum TripTypeEnum {
    TO_POI = 'TO_POI',
    THROUGH_CORRIDOR = 'THROUGH_CORRIDOR'
};



