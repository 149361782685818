export enum DataLayerEvent {
  signUp = 'signUp',
  login = 'login',
  userIdentified = 'userIdentified',
  identityRemoved = 'identityRemoved',
  virtualPageView = 'virtualPageView',
  purchase = 'purchase',
  regionSet = 'regionSet',
  privateEmailEnteredForSignup = 'privateEmailEnteredForSignup',
}
