<span
  (click)="onDomainSuggestionClick($event)"
  [innerHtml]="
    'formValidation.emailDomainSuggestion'
      | translate
        : {
            email: correctedEmail
          }
      | safeHtml
  "
></span>
