/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildTransportWithOptionalTrajectoryModel } from './childTransportWithOptionalTrajectoryModel';
import { ChildTransactionModel } from './childTransactionModel';
import { ChildTripInsightUserAddressesModel } from './childTripInsightUserAddressesModel';
import { ChildUserPermission } from './childUserPermission';
import { ChildSuccessfulCarpool } from './childSuccessfulCarpool';
import { ChildTripModel } from './childTripModel';


export interface ApiResponseGetTripsInsightModel { 
    transports: Array<ChildTransportWithOptionalTrajectoryModel>;
    trips: Array<ChildTripModel>;
    transactions: Array<ChildTransactionModel>;
    userAddresses: Array<ChildTripInsightUserAddressesModel>;
    userPermissions: Array<ChildUserPermission>;
    successfulCarpools: Array<ChildSuccessfulCarpool>;
}

