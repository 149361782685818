import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LayoutService } from '@core/services/layout/layout.service';
import { environment } from 'src/environments/environment';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AmplifyService } from '@core/services/amplify/amplify.service';
import { IncentiveNetworksWrapperService } from '@core/services/incentive-network-wrapper/incentive-networks-wrapper.service';
import { RegionService } from '@core/services/region.service';
import { ApiResponseBaseIncentiveNetwork, ApiResponseEmployer, SubscriptionStatusEnum } from '@swagger/index';
import { tap } from 'rxjs';
import { AdminService } from '../../../core/services/admin/admin.service';
import { AuthenticationStatusService } from '../../../core/services/authentication-status/authentication-status.service';
import { EmployerConnectWrapperService } from '../../../core/services/employer-connect/employer-connect-wrapper.service';
import { EmployerWrapperService } from '../../../core/services/employer/employer-wrapper.service';
import { SidebarMenuService } from '../../../core/services/sidebar-menu/sidebar-menu.service';
import { SidebarMenuItem } from '../../interfaces/sidebar-menu-item.interface';

@Component({
  selector: 'app-sidebar-content',
  templateUrl: './sidebar-content.component.html',
  styleUrls: ['./sidebar-content.component.scss'],
})
export class SidebarContentComponent implements OnInit {
  public readonly envName = environment.name;

  public readonly region = environment.region;

  private destroyRef = inject(DestroyRef);

  public managerMenuItems!: SidebarMenuItem[];

  public adminMenuItems!: SidebarMenuItem[];

  public currentUrl!: string;

  public employer!: ApiResponseEmployer;

  public pendingJoinRequests?: number;

  public baseIncentiveNetwork?: ApiResponseBaseIncentiveNetwork;

  public readonly subscriptionStatus = SubscriptionStatusEnum;

  constructor(
    public readonly layoutService: LayoutService,
    public readonly adminService: AdminService,
    public readonly authStatusService: AuthenticationStatusService,
    public readonly employerWrapperService: EmployerWrapperService,
    public readonly amplifyService: AmplifyService,
    private readonly router: Router,
    private readonly sidebarMenuService: SidebarMenuService,
    private readonly employerConnectWrapperService: EmployerConnectWrapperService,
    public readonly regionService: RegionService,
    private readonly incentiveNetworksWrapperService: IncentiveNetworksWrapperService
  ) {
    [this.currentUrl] = this.router.url.split('?');
  }

  public ngOnInit(): void {
    this.initSubscriptions();
  }

  private initSubscriptions(): void {
    this.employerWrapperService
      .getEmployerStream()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => {
          this.employerConnectWrapperService.getPendingJoinRequests(true).subscribe();
          this.incentiveNetworksWrapperService.getBaseIncentiveNetworksByEmployer(undefined, true).subscribe();
        })
      )
      .subscribe((employer) => {
        this.employer = employer;

        this.prepareMenuItems();
      });

    this.employerConnectWrapperService
      .getPendingJoinRequestStream()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((pendingJoinRequests) => {
        this.pendingJoinRequests = pendingJoinRequests.length;
        this.prepareMenuItems();
      });

    this.incentiveNetworksWrapperService
      .getBaseIncentiveNetworkStream()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((provider) => {
        this.baseIncentiveNetwork = provider;
        this.prepareMenuItems();
      });

    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((navEvent) => {
      if (navEvent instanceof NavigationEnd) {
        [this.currentUrl] = navEvent.url.split('?');
        this.prepareMenuItems();
      }
    });
  }

  private prepareMenuItems(): void {
    this.prepareManagerMenuItems();
    this.prepareAdminMenuItems();
  }

  private prepareManagerMenuItems(): void {
    const menuItems = this.sidebarMenuService.getManagerMenuItems(
      this.employer,
      this.adminService.isAdministrator(),
      this.pendingJoinRequests,
      this.baseIncentiveNetwork
    );

    this.managerMenuItems = this.sidebarMenuService.setMenuSelectedState(menuItems, this.currentUrl);
  }

  private prepareAdminMenuItems(): void {
    const menuItems = this.sidebarMenuService.getAdminMenuItems();

    this.adminMenuItems = this.sidebarMenuService.setMenuSelectedState(menuItems, this.currentUrl);
  }
}
