/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ChildTransistorCoords { 
    floor?: number;
    latitude: number;
    longitude: number;
    accuracy: number;
    altitude: number;
    ellipsoidalAltitude: number;
    heading: number;
    headingAccuracy?: number;
    /**
     * Speed in meters / second.
     */
    speed: number;
    speedAccuracy?: number;
    altitudeAccuracy?: number;
}

