/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildLastTripDetails } from './childLastTripDetails';
import { ChildChallengeContributionDetails } from './childChallengeContributionDetails';


export interface ApiResponseChallengeDetails { 
    username: string;
    currentRank: number;
    contributions: number;
    totalSavedCo2InGrams: number;
    totalTripDistanceInMeters: number;
    displayName: string;
    lastTripDetails?: ChildLastTripDetails;
    challengeContributor: ChildChallengeContributionDetails;
}

