import { Environment } from '@shared/enums/environment.enum';
import { EnvConfig } from './interface/environment.interface';

export const environment: EnvConfig = {
  production: true,
  name: Environment.prod,
  apiUrl: 'https://api.pavecommute.app',
  loginUrl:
    'https://paveapp.auth.us-east-1.amazoncognito.com/login' +
    '?client_id=25s5tlf73qmm9p8beft98npbtl' +
    '&response_type=code' +
    '&scope=phone+email+openid+aws.cognito.signin.user.admin+profile' +
    '&redirect_uri=',
  googleMapsApiKey: 'AIzaSyAytibQM3m6jG6as1FxEHHR4O4isC2SzAI',
  region: 'us',
  sentianceAppId: '60914cf829da9107000002eb',
  applicationId: 'pave-admin-dashboard',
  appDeeplinkBaseUrl: 'https://pavecommute.page.link',
  androidPackageName: 'com.rideamigos.pave_app',
  iosPackageName: 'com.rideamigos.pave.app',
  sentryDns: 'https://9428b2ad2b1f44bda66c7d6f85f3723c@o4506320800972800.ingest.sentry.io/4506395435925508',
  awsS3PublicBucket: 'rideamigos-pave-prod-public-assets',
  googleTagManagerAuth: 'HAG-KSjrvpNROb1zORUKsw',
  googleTagManagerPreview: 'env-17',
  appWebUrl: 'https://web.pavecommute.app',
  intercomId: 'u8zcj4x2',
  hotjarId: '4983023',
  kompassifyMonthlyNpsSurveyId: '62d8e755-71c0-4e9e-a561-d70889d973c9',
  kompassifyCsatSurveyId: '79bdb697-2f06-4494-a675-60bba2c7383d',
};
