/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ApiRequestAdminDeleteOtherActivitiesModel } from '../model/models';
import { ApiRequestCreateOtherActivitiesModel } from '../model/models';
import { ApiRequestDeleteOtherActivitiesModel } from '../model/models';
import { ApiResponseActivityTripAndTransactionModel } from '../model/models';
import { ApiResponseUnseenActivityTripAndTransactionModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ActivityServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param apiRequestAdminDeleteOtherActivitiesModel 
     */
    activityControllerAdminDelete(apiRequestAdminDeleteOtherActivitiesModel: ApiRequestAdminDeleteOtherActivitiesModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestAdminDeleteOtherActivitiesModel 
     */
    activityControllerAdminDelete_1(apiRequestAdminDeleteOtherActivitiesModel: ApiRequestAdminDeleteOtherActivitiesModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestCreateOtherActivitiesModel 
     */
    activityControllerCreate(apiRequestCreateOtherActivitiesModel: ApiRequestCreateOtherActivitiesModel, extraHttpRequestParams?: any): Observable<ApiResponseUnseenActivityTripAndTransactionModel>;

    /**
     * 
     * 
     * @param apiRequestCreateOtherActivitiesModel 
     */
    activityControllerCreate_2(apiRequestCreateOtherActivitiesModel: ApiRequestCreateOtherActivitiesModel, extraHttpRequestParams?: any): Observable<ApiResponseUnseenActivityTripAndTransactionModel>;

    /**
     * 
     * 
     * @param apiRequestDeleteOtherActivitiesModel 
     */
    activityControllerDelete(apiRequestDeleteOtherActivitiesModel: ApiRequestDeleteOtherActivitiesModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param apiRequestDeleteOtherActivitiesModel 
     */
    activityControllerDelete_3(apiRequestDeleteOtherActivitiesModel: ApiRequestDeleteOtherActivitiesModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     */
    activityControllerFindUnseenAndDelete(extraHttpRequestParams?: any): Observable<ApiResponseUnseenActivityTripAndTransactionModel>;

    /**
     * 
     * 
     */
    activityControllerFindUnseenAndDelete_4(extraHttpRequestParams?: any): Observable<ApiResponseUnseenActivityTripAndTransactionModel>;

    /**
     * 
     * 
     * @param page 
     * @param elementsPerBlock 
     */
    activityControllerGet(page: number, elementsPerBlock: number, extraHttpRequestParams?: any): Observable<ApiResponseActivityTripAndTransactionModel>;

    /**
     * 
     * 
     * @param page 
     * @param elementsPerBlock 
     */
    activityControllerGet_5(page: number, elementsPerBlock: number, extraHttpRequestParams?: any): Observable<ApiResponseActivityTripAndTransactionModel>;

}
