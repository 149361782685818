/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildAddressModel } from './childAddressModel';
import { ChildPolygonModel } from './childPolygonModel';


export interface ChildEmployerWorkPlaceModel { 
    /**
     * _id, id is used to be sent along with the dashboard to not trigger reassignment of ObjectId()
     */
    _id?: string;
    id?: string;
    name: string;
    workAddresses: Array<ChildAddressModel>;
    territories: Array<ChildPolygonModel>;
}

