import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AmplifyService } from '@core/services/amplify/amplify.service';
import { AuthenticationTokenService } from '@core/services/authentication-token.service';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuthenticationStatusService } from '../services/authentication-status/authentication-status.service';
import { apiCallsNoAuthorizationWhitelist } from './constants/api-calls-no-authorization-whitelist.constant';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private readonly authStatusService: AuthenticationStatusService,
    private readonly authenticationTokenService: AuthenticationTokenService,
    private readonly amplifyService: AmplifyService
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // don't add header when fetching the token
    if (req.url.endsWith('auth/token')) {
      return next.handle(req);
    }

    const newReq: HttpRequest<any> = this.setBearerHeader(req);

    return next.handle(newReq).pipe(
      catchError((error: any) => {
        // if status is 401 try to refresh the token and send the request again
        if (error && error instanceof HttpErrorResponse && error.status === 401) {
          // if there is no refresh token available clear everything and go to the login screen
          if (!this.authenticationTokenService.getRefreshToken()) {
            this.amplifyService.logout();
            throw new Error('No refresh token available!'); // do not retry just return an error
          }

          // try to refresh token
          return this.authStatusService.refresh().pipe(
            // in case token refresh failed
            catchError((tokenRefreshError: any) => {
              this.amplifyService.logout();
              throw new Error(`Refresh token failed: ${tokenRefreshError}`);
            }),

            // create a new copy of the original request with the new token
            map(() => this.setBearerHeader(req)),

            // try to resend the new copy of the request
            mergeMap((r) =>
              next.handle(r).pipe(
                // intercept response errors of the resent request
                catchError((retryError: any) => {
                  // if error is again 401 clear everything and send user to the login screen
                  if (retryError && retryError instanceof HttpErrorResponse && error.status === 401) {
                    this.amplifyService.logout();
                  }

                  // in any case forward the error to the caller
                  throw new Error('resend of request failed!');
                })
              )
            )
          ) as Observable<HttpEvent<any>>;
        }

        // forward generic http errors to caller
        throw error;
      })
    );
  }

  private setBearerHeader(req: HttpRequest<any>): HttpRequest<any> {
    if (apiCallsNoAuthorizationWhitelist.some((url) => req.url.includes(url))) {
      return req;
    }

    return req.clone({
      headers: req.headers.set('Authorization', `Bearer ${this.authenticationTokenService.getIdToken()}`),
    });
  }
}
