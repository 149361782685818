import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { DateHelperService } from '@core/services/date-helper/date-helper.service';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';

@Component({
  selector: 'date-selector-input',
  templateUrl: './date-selector-input.component.html',
  styleUrls: ['./date-selector-input.component.scss'],
})
export class DateSelectorInputComponent implements OnInit {
  @Input() public formGroup!: FormGroup;

  @Input() public controlName!: string;

  // defaults to today
  @Input() public startAt = new Date();

  // defaults to employer createdAt date
  @Input() public minDate;

  // the selected value should be converted to end of day
  // default = start of day
  @Input() public convertToEndOfDay = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    public readonly dateHelperService: DateHelperService,
    private readonly employerWrapperService: EmployerWrapperService
  ) {
    this.minDate = new Date(this.employerWrapperService.employer.createdAt);
  }

  public ngOnInit(): void {
    this.initInputSubscription();
  }

  private initInputSubscription(): void {
    this.formGroup
      ?.get(this.controlName)
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (!value || !this.convertToEndOfDay) {
          return;
        }

        this.formGroup.get(this.controlName)?.setValue(this.dateHelperService.toEndOfDay(value), { emitEvent: false });
      });
  }
}
