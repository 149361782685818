import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InitialsHelperService {
  public createInitials(text: string): string {
    const splitText: string[] = text
      .trim()
      .split(' ')
      .filter((word) => word.length > 0);

    if (splitText.length === 0) {
      return '??';
    }

    const initials: string = splitText
      .map((word) => word.charAt(0))
      .slice(0, 2)
      .join('');

    return initials;
  }
}
