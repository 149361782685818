<div [formGroup]="formGroup">
  <mat-form-field class="mt-0">
    <mat-select
      placeholder="
          {{
        'commuteModes.' + (multiple ? 'selectCommuteModePlaceholder' : 'selectCommuteModePlaceholderPlural') | translate
      }}
        "
      name="commuteModes"
      matInput
      [formControlName]="controlName"
      [multiple]="multiple"
    >
      <ng-container *ngFor="let item of commuteModes">
        <mat-option [value]="item">
          <div class="flex items-center px-3 gap-3">
            <img class="w-4 mt-[-0.3em]" [src]="enumImageHelperService.getModeImageUrl(item)" [alt]="item + 'emoji'" />
            {{ 'commuteModes.' + item | translate }}
          </div>
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
