import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { ApiResponseEmployerAppModel } from '@swagger/index';
import { firstValueFrom } from 'rxjs';

export const UserAppPreviewResolver: ResolveFn<ApiResponseEmployerAppModel> = async () => {
  const employerWrapperService = inject(EmployerWrapperService);
  const employerDetails = await firstValueFrom(employerWrapperService.getForUser());

  return employerDetails;
};
