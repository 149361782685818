import { Injectable } from '@angular/core';
import { ToastService } from '@core/services/toast/toast.service';
import { catchError, Observable } from 'rxjs';
import { AuthService } from '../../../api';
import { ApiResponseAuthRefreshModel } from '../../../api/model/apiResponseAuthRefreshModel';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationWrapperService {
  constructor(
    private readonly authService: AuthService,
    private readonly toastService: ToastService
  ) {}

  public fetchRefreshToken(refreshToken: string, username: string): Observable<ApiResponseAuthRefreshModel> {
    return this.authService.authControllerRefresh({ token: refreshToken, name: username });
  }

  public verifyEmail(username: string, email: string, token: string): Observable<void> {
    return this.authService
      .authControllerVerifyEmail({
        email,
        token,
        username,
      })
      .pipe(
        catchError((err) => {
          this.toastService.showDefaultErrorToast();
          throw err;
        })
      );
  }

  public changeEmail(email: string): Observable<void> {
    return this.authService
      .authControllerChangeEmail({
        email,
      })
      .pipe(
        catchError((err) => {
          this.toastService.showDefaultErrorToast();
          throw err;
        })
      );
  }

  public resendEmailVerification(email: string): Observable<void> {
    return this.authService.authControllerResendEmailVerification({ email }).pipe(
      catchError((err) => {
        this.toastService.showDefaultErrorToast();
        throw err;
      })
    );
  }
}
