/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ChildAppSubscription { 
    status: ChildAppSubscriptionStatusEnum;
    cancelAt?: string;
    cancelAtPeriodEnd: boolean;
    trialStart?: string;
    trialEnd?: string;
}
export enum ChildAppSubscriptionStatusEnum {
    active = 'active',
    canceled = 'canceled',
    incomplete = 'incomplete',
    incomplete_expired = 'incomplete_expired',
    past_due = 'past_due',
    paused = 'paused',
    trialing = 'trialing',
    unpaid = 'unpaid'
};



