/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ChildConnectedUser { 
    email: string;
    name?: string;
    approvedByEmployer?: boolean;
    role: ChildConnectedUserRoleEnum;
    username: string;
    createdAt: string;
}
export enum ChildConnectedUserRoleEnum {
    commuter = 'commuter',
    manager = 'manager'
};



