import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { dashboardExplanationLink } from '@core/constants/help-center-constant';
import { pageRoutes } from '@core/models/page-routes.model';
import { AmplifyService } from '@core/services/amplify/amplify.service';
import { EmployerConnectWrapperService } from '@core/services/employer-connect/employer-connect-wrapper.service';
import { EmployerWrapperService } from '@core/services/employer/employer-wrapper.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { UserDataService } from '@core/services/user/user-data.service';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { SharedModule } from '@shared/shared.module';
import { CheckoutMobileAppDialogComponent } from '@shared/standalone/checkout-mobile-app-dialog/checkout-mobile-app-dialog.component';
import { RequestAccessEmployerListComponent } from '@shared/standalone/request-access-employer-list/request-access-employer-list.component';
import { ApiResponseSelectableForDashboardEmployer } from '@swagger/index';

@Component({
  selector: 'select-existing-employer',
  standalone: true,
  imports: [SharedModule, CommonModule, RequestAccessEmployerListComponent, SafeHtmlPipe],
  templateUrl: './select-existing-employer.component.html',
  styleUrl: './select-existing-employer.component.scss',
})
export class SelectExistingEmployerComponent implements OnInit {
  public employerDetails: ApiResponseSelectableForDashboardEmployer[] = [];

  public helpCenterLink = dashboardExplanationLink;

  constructor(
    private readonly dialog: Dialog,
    private readonly employerWrapperService: EmployerWrapperService,
    private readonly userDataService: UserDataService,
    private readonly amplifyService: AmplifyService,
    private readonly router: Router,
    private readonly employerConnectWrapperService: EmployerConnectWrapperService,
    private readonly responsiveService: ResponsiveService
  ) {}

  public ngOnInit(): void {
    const domain = this.userDataService.getEmailDomain();

    this.employerWrapperService.findSelectableEmployerByEmailDomainOfEmployer(domain).subscribe((details) => {
      if (details.length === 0) {
        this.employerWrapperService.findSelectableEmployerByPrivateDomainOfConnectedUsers(domain).subscribe((d) => {
          this.employerDetails = d;
        });
      } else {
        this.employerDetails = details;
      }
    });
  }

  public onContinueWithApp(): void {
    CheckoutMobileAppDialogComponent.open(this.dialog, this.responsiveService.isMobileView());
  }

  public onLogout(): void {
    this.amplifyService.logout();
  }

  public onRequestAccess(employerId: string): void {
    this.employerConnectWrapperService.joinCompanyViaDashboard(employerId).subscribe(() => {
      this.goToUserAppPreviewPage();
    });
  }

  private goToUserAppPreviewPage(): void {
    this.router.navigate([pageRoutes.USER_APP_PREVIEW.path]);
  }

  public onContinueWithOnboarding(): void {
    this.router.navigate([pageRoutes.ONBOARDING.path]);
  }
}
