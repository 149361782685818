import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { AdminService } from '@core/services/admin/admin.service';
import { UserDataService } from '@core/services/user/user-data.service';
import { catchError, of, switchMap } from 'rxjs';
import { LocalStorageKey } from '../../shared/enums/local-storage-key.enum';
import { EmployerWrapperService } from '../services/employer/employer-wrapper.service';

export const EmployerGuard: CanMatchFn = () => {
  const userDataService = inject(UserDataService);
  const employerService = inject(EmployerWrapperService);
  const adminService = inject(AdminService);

  return userDataService.userStream().pipe(
    switchMap((user) => {
      const employer = employerService.employer || null;

      if (employer && (adminService.isAdministrator() || employer.id === user.connectedToEmployer)) {
        return of(true);
      }

      const actAsEmployerId = localStorage.getItem(LocalStorageKey.actAsEmployerId);
      const employerIdToFetch = actAsEmployerId || user.connectedToEmployer;

      return employerService.getFullEmployer(employerIdToFetch).pipe(
        catchError(() => of(false)),
        switchMap(() => of(true))
      );
    })
  );
};
