/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildTransistorBattery } from './childTransistorBattery';
import { ChildTransistorActivity } from './childTransistorActivity';
import { ChildTransistorGeofenceEvent } from './childTransistorGeofenceEvent';
import { ChildTransistorCoords } from './childTransistorCoords';


export interface ChildTransistorLocation { 
    timestamp: string;
    age: number;
    event: object;
    mock?: boolean;
    sample: boolean;
    odometer: number;
    isMoving: boolean;
    uuid: string;
    coords: ChildTransistorCoords;
    geofence?: ChildTransistorGeofenceEvent;
    battery: ChildTransistorBattery;
    activity: ChildTransistorActivity;
    extras?: object;
}

