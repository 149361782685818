<div class="box-{{ type }}  px-4 py-4 rounded-2xl flex gap-4 items-center">
  <mat-icon *ngIf="prefixIcon" fontSet="material-icons-round" class="icon-{{ type }} flex-shrink-0 ">{{
    prefixIcon
  }}</mat-icon>
  <img class="h-6 w-6" *ngIf="prefixImageUrl" [src]="prefixImageUrl" alt="info image" />
  <p class="text-base text-left" [innerHTML]="msg"></p>

  <div class="flex-shrink-0 ml-auto">
    <ng-content select="[suffix]"></ng-content>
  </div>
</div>
