import { Component, Input } from '@angular/core';

@Component({
  selector: 'skeleton-image',
  templateUrl: './skeleton-image.component.html',
  styleUrls: ['./skeleton-image.component.scss'],
})
export class SkeletonImageComponent {
  @Input() public imageSrc?: string;

  @Input() public imageAlt?: string;

  /// fallback image src if the primary image could not be loaded
  /// if not provided a default fallback image is used
  /// a local image is highly recommended
  @Input() public fallbackImageSrc?: string = './assets/images/error/fallback-image.png';

  public isImageLoading = true;

  public errorLoadingImage = false;

  public onImageLoaded(): void {
    this.isImageLoading = false;
  }

  public onImageError(): void {
    this.errorLoadingImage = true;
  }
}
