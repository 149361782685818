/**
 * Pave API
 * Pave API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChildAddressModel } from './childAddressModel';
import { ChildEmployerWorkPlaceModel } from './childEmployerWorkPlaceModel';
import { ChildAppSubscription } from './childAppSubscription';
import { ChildCustomerModel } from './childCustomerModel';
import { ChildEmployerGroupsConfigAppModel } from './childEmployerGroupsConfigAppModel';
import { ChildEmployerTripLogConfig } from './childEmployerTripLogConfig';


export interface ApiResponseEmployerAppModel { 
    id: string;
    code: string;
    name: string;
    email: string;
    phoneNumber?: string;
    imageURL: string | null;
    address: ChildAddressModel | null;
    possibleCommuteModes: Array<ApiResponseEmployerAppModelPossibleCommuteModesEnum>;
    workPlaces: Array<ChildEmployerWorkPlaceModel>;
    employerGroupsConfig: ChildEmployerGroupsConfigAppModel;
    isPublic: boolean;
    hasShiftWorkEntries: boolean;
    hasEmployerNetworks: boolean;
    tripLogConfig: ChildEmployerTripLogConfig;
    subscription?: ChildAppSubscription;
    customer?: ChildCustomerModel;
}
export enum ApiResponseEmployerAppModelPossibleCommuteModesEnum {
    car = 'car',
    bike = 'bike',
    walk = 'walk',
    scooter = 'scooter',
    transit = 'transit',
    carpool = 'carpool',
    internalShuttle = 'internalShuttle'
};



