import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LanguageCode } from '@shared/interfaces/language-code.interface';

@Component({
  selector: 'language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  @Input() public formGroup!: FormGroup;

  @Input() public controlName!: string;

  @Input()
  public supportedLanguageCodes!: LanguageCode[];

  @Input()
  public disabledLanguageCodeAlpha2s?: string[];

  public isSelectionDisabled(alpha2: string): boolean {
    if (this.disabledLanguageCodeAlpha2s) {
      return this.disabledLanguageCodeAlpha2s.some((e) => e === alpha2);
    }

    return false;
  }
}
