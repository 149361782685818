import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { ToastService } from '@core/services/toast/toast.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

export interface CropperDialogData {
  imageEvent: any;
  aspectRatio: number;
  showBackIcon?: boolean;
  info?: string;
}

@Component({
  selector: 'cropper-dialog',
  templateUrl: './cropper-dialog.component.html',
  styleUrls: ['./cropper-dialog.component.scss'],
})
export class CropperDialogComponent {
  public aspectRatio!: number;

  public uploadedImageEvent?: any;

  public showBackIcon: boolean;

  public croppedImage?: Blob;

  public readonly imageFormat = 'webp';

  constructor(
    private readonly dialogRef: DialogRef<File>,
    private readonly toastService: ToastService,
    @Inject(DIALOG_DATA) public dialogData: CropperDialogData
  ) {
    this.uploadedImageEvent = this.dialogData.imageEvent;
    this.aspectRatio = this.dialogData.aspectRatio;
    this.showBackIcon = this.dialogData.showBackIcon ?? false;
  }

  public imageCropped(event: ImageCroppedEvent): void {
    if (event.blob) {
      this.croppedImage = event.blob;
    }
  }

  public loadImageFailed(): void {
    this.toastService.show(true, 'incentiveNetworks.wrongFileError');
    this.dialogRef.close();
  }

  public async saveCroppedImage(): Promise<void> {
    if (this.croppedImage) {
      const fileSizeInMb = this.croppedImage.size / 1024 / 1024;

      const isGreaterThan1Mb = fileSizeInMb > 1;

      if (isGreaterThan1Mb) {
        this.toastService.show(true, 'incentiveNetworks.imageUploadFileSizeError');

        return;
      }

      const originalFileName = this.dialogData.imageEvent?.target.files[0].name;
      const newFileName = `${originalFileName.replace(/\.[^/.]+$/, '')}.${this.imageFormat}`;
      const file = new File([this.croppedImage], newFileName, {
        type: `image/${this.imageFormat}`,
      });

      this.dialogRef.close(file);
    }
  }

  public cancelCroppedImage(): void {
    this.dialogRef.close();
  }
}
