import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../services/loading/loading.service';

export const BYPASS_LOADING = new HttpContextToken(() => false);

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private readonly loadingService: LoadingService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(BYPASS_LOADING)) {
      return next.handle(req);
    }

    // start loading
    this.loadingService.startLoading();

    return next.handle(req).pipe(
      finalize(() => {
        // stop loading on complete
        this.loadingService.endLoading();
      })
    );
  }
}
